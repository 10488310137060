import { nop } from './baseActions'
import { remove } from '../services/AtivoService'
import { searchAtivos } from '../services/SearchAtivoService'
import { setLoading } from './loadingActions'

const setAtivos = ativos => {
  return {
    type: 'ATIVO_LIST_FETCH_ALL_ATIVOS',
    payload: ativos
  }
}

export const setTotalItems = totalItems => {
  return {
    type: 'ATIVO_LIST_SET_TOTAL_ITEMS',
    payload: totalItems
  }
}

export const setPage = page => {
  return {
    type: 'ATIVO_LIST_SET_PAGE',
    payload: page
  }
}

export const setItemsPerPage = itemsPerPage => {
  return {
    type: 'ATIVO_LIST_SET_ITEMS_PER_PAGE',
    payload: itemsPerPage
  }
}

export const setRef = ref => {
  return {
    type: 'ATIVO_LIST_SET_REF',
    payload: ref
  }
}

export const setStartsWith = startsWith => {
  return {
    type: 'ATIVO_LIST_SET_STARTS_WITH',
    payload: startsWith
  }
}

export const clearSearch = () => {
  return {
    type: 'ATIVO_LIST_CLEAR_SEARCH'
  }
}

export const clearAtivos = () => {
  return {
    type: 'ATIVO_LIST_CLEAR_ALL_ATIVOS'
  }
}

export const clearPagination = () => {
  return {
    type: 'ATIVO_LIST_CLEAR_PAGINATION'
  }
}

export const fetchAllAtivosByFarmacia = (farmaciaId, ref = '', page = 1, itemsPerPage = 15, startsWith = false) => {
  return async dispatch => {
    try {
      const response = await searchAtivos(farmaciaId, ref, page, itemsPerPage, startsWith);

      return dispatch([
        setAtivos(response.data),
        setTotalItems(response["_metadata"].totalItems)
      ]);
    } catch (e) {
      return dispatch(nop());
    }
  }
}

export const removeAtivo = (farmaciaId, ativo) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true));
      await remove(farmaciaId, ativo);

      // get page to fetch ativos
      const { page, itemsPerPage } = getState().ativo.list.pagination;

      return dispatch([
        fetchAllAtivosByFarmacia(ativo.far_in_codigo, '', page, itemsPerPage),
        setLoading(false)
      ]);
    } catch (e) {
      return dispatch(setLoading(false));
    }
  }
}
