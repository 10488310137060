import React, { useState, useEffect } from 'react';
import './styles.css';

import Title from '../../components/Title';
import { useParams } from 'react-router-dom';
import Row from '../../components/Row';
import Grid from '../../components/Grid';
import { useSelector, useDispatch } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import {
  fetchAtivosByFarmacia,
  fetchAllLinksFormulaAtivo,
  setPage,
  setRef,
  clearFormulasAtivos,
} from '../../stores/linkFormulaAtivoActions';
import {
  linkAtivoToFormula,
  removeLinkAtivoFormula,
} from '../../services/AtivoFormulaService';
import { setLoading as loading } from '../../stores/loadingActions';
import Pagination from '../../components/Pagination';
import Search from '../AtivoListContainer/AtivoSearch';
import AtivoList from './AtivoList';
import LinkedAtivosTable from '../FormulaEdit/AtivoList';

export default function LinkFormulaAtivos() {
  const { id: formulaId } = useParams();
  const farmaciaId = localStorage.getItem('FORMULARIO_FARMACIA_ID');

  const [isLoading, setLoading] = useState(true);
  const [isFirst, setFirst] = useState(true);

  const dispatch = useDispatch();

  // formulas already linked to ativo
  const linksAtivoFormulas = useSelector(
    (state) => state.linkFormulaAtivo.ativosFormulas
  );

  // all ativos by farmacia
  const results = useSelector((state) => state.linkFormulaAtivo.ativos);

  const ativos = results.map((atv) => ({
    ...atv,
    linked: linksAtivoFormulas.find(
      (link) => link.atv_in_codigo === atv.atv_in_codigo
    ),
  }));

  // pagination
  const ref = useSelector((state) => state.linkFormulaAtivo.search.ref);
  const page = useSelector((state) => state.linkFormulaAtivo.pagination.page);
  const itemsPerPage = useSelector(
    (state) => state.linkFormulaAtivo.pagination.itemsPerPage
  );
  const totalItems = useSelector(
    (state) => state.linkFormulaAtivo.pagination.totalItems
  );

  // when a path is set for user to go back
  const goBackPath = useSelector((state) => state.linkFormulaAtivo.goBackPath);

  useEffect(() => {
    dispatch([
      fetchAtivosByFarmacia(farmaciaId, '', 1, itemsPerPage),
      fetchAllLinksFormulaAtivo(farmaciaId, formulaId),
    ]);

    return () => dispatch(clearFormulasAtivos());
  }, []);

  useEffect(() => {
    if (isFirst) setFirst(false);
    else setLoading(false);
  }, [results]);

  function handlePaginate(pgNumber) {
    if (pgNumber === page) return;

    setLoading(true);

    dispatch([
      setPage(pgNumber),
      fetchAtivosByFarmacia(farmaciaId, ref, pgNumber, itemsPerPage),
    ]);
  }

  function handleSearch({ ref }) {
    setLoading(true);

    dispatch([
      setRef(ref),
      setPage(1),
      fetchAtivosByFarmacia(farmaciaId, ref, 1, itemsPerPage),
    ]);
  }

  function handleClearSearch() {
    setLoading(true);

    dispatch([
      setRef(''),
      setPage(1),
      fetchAtivosByFarmacia(farmaciaId, '', 1, itemsPerPage),
    ]);
  }

  async function handleUnlinkFormulaAtivo(ativoId) {
    dispatch(loading(true));

    await removeLinkAtivoFormula(farmaciaId, ativoId, formulaId);

    dispatch([
      loading(false),
      fetchAllLinksFormulaAtivo(farmaciaId, formulaId),
    ]);
  }

  async function handleLinkFormulaAtivo(ativo, dosePrescrita, uso) {
    try {
      dispatch(loading(true));

      const concentracaoMinima =
        uso === 'TOPICO'
          ? ativo.atv_st_concentracaominima_topico
          : ativo.atv_st_concentracaominima_oral;
      const concentracaoMaxima =
        uso === 'TOPICO'
          ? ativo.atv_st_concentracaomaxima_topico
          : ativo.atv_st_concentracaomaxima_oral;
      const concentracaoDiaria =
        uso === 'TOPICO'
          ? ativo.atv_st_concentracaodiaria_topico
          : ativo.atv_st_concentracaodiaria_oral;

      await linkAtivoToFormula(
        farmaciaId,
        ativo.atv_in_codigo,
        formulaId,
        concentracaoMinima,
        concentracaoMaxima,
        concentracaoDiaria,
        dosePrescrita,
        uso
      );

      dispatch([
        loading(false),
        fetchAllLinksFormulaAtivo(farmaciaId, formulaId),
      ]);
    } catch (e) {
      dispatch(loading(false));
    }
  }

  return (
    <div className='ativo-container'>
      <Title link={goBackPath || `/formulas/${formulaId}/edit`}>
        Selecione um ativo para vincular
      </Title>

      <Row>
        <Grid cols='12'>
          <Search onSubmit={handleSearch} onClear={handleClearSearch} />
        </Grid>
      </Row>

      <Row>
        <Grid cols='6'>
          {isLoading ? (
            <Skeleton count={4} width={500} height={60} />
          ) : (
            <AtivoList
              ativos={ativos}
              onUnlinkFormulaAtivo={handleUnlinkFormulaAtivo}
              onLinkFormulaAtivo={handleLinkFormulaAtivo}
            />
          )}
        </Grid>

        <Grid cols='6'>
          <LinkedAtivosTable
            formulaAtivos={linksAtivoFormulas}
            hideViewButton={true}
            onRemoveLink={handleUnlinkFormulaAtivo}
          />
        </Grid>
      </Row>

      <Row>
        <Grid cols='12'>
          <Pagination
            page={page}
            itemsPerPage={itemsPerPage}
            paginate={handlePaginate}
            totalItems={totalItems}
          />
        </Grid>
      </Row>
    </div>
  );
}
