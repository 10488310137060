const INITIAL_STATE = {
  medicamento: null,
  goBack: null,
  logs: [],
}

export default function medicamentoEditReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'MEDICAMENTO_EDIT_SET_MEDICAMENTO':
      return {
        ...state,
        medicamento: action.payload
      }
    case 'MEDICAMENTO_EDIT_SET_GO_BACK':
      return {
        ...state,
        goBack: action.payload
      }
    case 'MEDICAMENTO_EDIT_SET_LOGS':
      return {
        ...state,
        logs: action.payload
      }
    case 'MEDICAMENTO_EDIT_CLEAR_MEDICAMENTO':
      return {
        ...state,
        ...INITIAL_STATE
      }
    default:
      return state
  }
}
