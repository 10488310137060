import React, { useState, useEffect, useRef } from 'react';
import './styles.css';

import { useParams, useHistory } from 'react-router-dom';
import Title from '../../components/Title';
import { Form } from '@unform/web';
import { Input } from '../../components/Form';
import Button from '../../components/Button';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { setLoading } from '../../stores/loadingActions';
import { fetchById, clearUnidade } from '../../stores/unidadeEditActions';
import Row from '../../components/Row';
import Grid from '../../components/Grid';
import { update } from '../../services/UnidadeService';

export default function UnidadeEdit() {
  const { id } = useParams();
  const history = useHistory();

  const dispatch = useDispatch();
  const unidade = useSelector((state) => state.unidade.edit.unidade);

  const farmaciaId = localStorage.getItem('FORMULARIO_FARMACIA_ID');

  const formRef = useRef(null);

  const [descricao, setDescricao] = useState('');
  const [sigla, setSigla] = useState('');

  const [locked, setLocked] = useState(true);

  useEffect(() => {
    // fetch unidade by id
    dispatch(fetchById(farmaciaId, id));

    return () => dispatch(clearUnidade());
  }, []);

  useEffect(() => {
    if (unidade) {
      setDescricao(unidade.uni_st_descricao);
      setSigla(unidade.uni_st_sigla);
    }
  }, [unidade]);

  async function handleSubmit(data, { reset }) {
    try {
      // set loading
      dispatch(setLoading(true));

      // validation
      const schema = Yup.object().shape({
        uni_st_descricao: Yup.string().required('A descrição é obrigatória'),
        uni_st_sigla: Yup.string().required('A sigla é obrigatória'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      // save
      await update(farmaciaId, id, data);

      dispatch(setLoading(false));

      // reset form data
      reset();

      // reset errors
      formRef.current.setErrors({});

      // redirect user
      history.push('/unidades');
    } catch (e) {
      if (e instanceof Yup.ValidationError) {
        const errorMessages = {};

        e.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });

        formRef.current.setErrors(errorMessages);
      }

      dispatch(setLoading(false));
    }
  }

  function ActionButtons() {
    return locked ? (
      <Button size='block' handleclick={() => setLocked(false)}>
        Habilitar campos
      </Button>
    ) : (
      <Row>
        <Grid cols='12 6'>
          <Button
            color='secondary'
            size='block'
            handleclick={() => setLocked(true)}
          >
            Cancelar
          </Button>
        </Grid>

        <Grid cols='12 6'>
          <Button type='submit' size='block'>
            Salvar
          </Button>
        </Grid>
      </Row>
    );
  }

  return (
    <div className='unidade-container'>
      <Title link='/unidades'>Editar</Title>

      <Row>
        <Grid cols='12'>
          <div className='form-container'>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <Input
                id='uni_st_descricao'
                label='* Descrição'
                className='form-control upper'
                name='uni_st_descricao'
                value={descricao}
                onChange={(e) => setDescricao(e.target.value)}
                disabled={locked}
                required
              />

              <Input
                id='uni_st_sigla'
                label='* Sigla'
                className='form-control upper'
                name='uni_st_sigla'
                value={sigla}
                onChange={(e) => setSigla(e.target.value)}
                disabled={locked}
                required
              />

              <div className='w-100 mb-3'>
                <small className='text-muted align-end'>
                  Os campos marcados com (*) são obrigatórios
                </small>
              </div>

              <ActionButtons />
            </Form>
          </div>
        </Grid>
      </Row>
    </div>
  );
}
