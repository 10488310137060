const INITIAL_STATE = {
  especialidade: null,
};

export default function especialidadeEditReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case 'ESPECIALIDADE_EDIT_SET_ESPECIALIDADE':
      return {
        ...state,
        especialidade: action.payload,
      };
    case 'ESPECIALIDADE_EDIT_CLEAR_ALL':
      return {
        ...state,
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
}
