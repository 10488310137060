const INITIAL_STATE = {
  formula: null,
  ativos: [],
  unidades: [],
  medicos: [],
  veiculosUtilizacao: [],
  veiculoUtilizacao: null,
  goBack: null,
  logs: [],
}

export default function formulaEditReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'FORMULA_EDIT_SET_FORMULA':
      return {
        ...state,
        formula: action.payload
      }
    case 'FORMULA_EDIT_SET_ATIVOS':
      return {
        ...state,
        ativos: action.payload
      }
    case 'FORMULA_EDIT_SET_UNIDADES':
      return {
        ...state,
        unidades: action.payload
      }
    case 'FORMULA_EDIT_SET_MEDICOS':
      return {
        ...state,
        medicos: action.payload
      }
    case 'FORMULA_EDIT_SET_VEICULOS_UTILIZACAO':
      return {
        ...state,
        veiculosUtilizacao: action.payload
      }
    case 'FORMULA_EDIT_SET_VEICULO_UTILIZACAO':
      return {
        ...state,
        veiculoUtilizacao: action.payload
      }
    case 'FORMULA_EDIT_SET_GO_BACK':
      return {
        ...state,
        goBack: action.payload
      }
    case 'FORMULA_EDIT_SET_LOGS':
      return {
        ...state,
        logs: action.payload
      }
    case 'FORMULA_EDIT_CLEAR_FORMULA':
      return {
        ...state,
        ...INITIAL_STATE
      }
    default:
      return state
  }
}
