import React from 'react';
import './styles.css';
import PropTypes from 'prop-types';

import Table, {
  Thead as TableHeader,
  TableRow,
  Tbody,
  TbodyCol as TbCol,
} from '../../../components/Table';
import Button from '../../../components/Button';
import { MdDone } from 'react-icons/md';

function FormulaList({ formulas, onValidate: handleValidate }) {
  return formulas.length > 0 ? (
    <Table card>
      <TableHeader
        dark
        columns={['Nome', 'Posologia', 'Observação', 'Validar']}
      />

      <Tbody>
        {formulas.map((formula) => (
          <TableRow className='upper' key={formula.for_in_codigo}>
            <TbCol alignMiddle>{formula.for_st_nome}</TbCol>
            <TbCol alignMiddle label='Posologia'>
              {formula.for_st_posologia || '-'}
            </TbCol>
            <TbCol alignMiddle label='Observação'>
              {formula.for_st_observacao || '-'}
            </TbCol>
            <TbCol alignMiddle label='Ação'>
              <Button
                color='info'
                size='sm'
                handleclick={() => handleValidate(formula)}
              >
                <MdDone size={24} />
              </Button>
            </TbCol>
          </TableRow>
        ))}
      </Tbody>
    </Table>
  ) : (
    <p className='not-found'>Nenhuma fórmula encontrada</p>
  );
}

FormulaList.propTypes = {
  formulas: PropTypes.array.isRequired,
  onValidate: PropTypes.func,
};

export default FormulaList;
