import api from './api'

export function searchByRefAndMedico(farmaciaId, ref, tipo, medicoId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      const response = await api.get(`farmacias/${farmaciaId}/usuarios/${medicoId}/veiculos_utilizacao/search`, {
        headers: { authorization: `Bearer ${token}` },
        params: { ref, tipo }
      });

      return resolve(response.data.data);
    } catch (e) {
      return reject(e);
    }
  })
}
