import { nop } from './baseActions'
import { toast } from 'react-toastify'
import { remove } from '../services/FormulaService'
import { searchFormulas } from '../services/SearchFormulaService'
import { setLoading } from './loadingActions'

const setFormulas = formulas => {
  return {
    type: 'FORMULA_LIST_FETCH_FORMULAS',
    payload: formulas
  }
}

export const clearFormulas = () => {
  return {
    type: 'FORMULA_LIST_CLEAR_FORMULAS'
  }
}

export const setPage = page => {
  return {
    type: 'FORMULA_LIST_SET_PAGE',
    payload: page
  }
}

export const setItemsPerPage = itemsPerPage => {
  return {
    type: 'FORMULA_LIST_SET_ITEMS_PER_PAGE',
    payload: itemsPerPage
  }
}

export const setTotalItems = totalItems => {
  return {
    type: 'FORMULA_LIST_SET_TOTAL_ITEMS',
    payload: totalItems
  }
}

export const setRef = ref => {
  return {
    type: 'FORMULA_LIST_SET_REF',
    payload: ref
  }
}

export const setStartsWith = startsWith => {
  return {
    type: 'FORMULA_LIST_SET_STARTS_WITH',
    payload: startsWith
  }
}

export const clearSearch = () => {
  return {
    type: 'FORMULA_LIST_CLEAR_SEARCH'
  }
}

export const clearPagination = () => {
  return {
    type: 'FORMULA_LIST_CLEAR_PAGINATION'
  }
}

export const fetchAllFormulas = (farmaciaId, ref = '', page = 1, itemsPerPage = 15, startsWith = false) => {
  return async dispatch => {
    try {
      const response = await searchFormulas(farmaciaId, ref, page, itemsPerPage, startsWith);

      return dispatch([
        setFormulas(response.data),
        setTotalItems(response["_metadata"].totalItems)
      ]);
    } catch (e) {
      return dispatch(nop());
    }
  }
}

export const removeFormula = (farmaciaId, formula) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true));

      await remove(farmaciaId, formula.for_in_codigo);

      const { page, itemsPerPage } = getState().formula.list.pagination;
      const { ref } = getState().formula.list.search;

      return dispatch([
        setLoading(false),
        fetchAllFormulas(farmaciaId, ref, page, itemsPerPage)
      ]);
    } catch (e) {
      toast.error("Falha ao remover fórmula");
      return dispatch(setLoading(false));
    }
  }
}
