const INITIAL_STATE = {
  ativos: [],
  pagination: {
    totalItems: 0,
    page: 1,
    itemsPerPage: 15
  },
  search: {
    ref: '',
    startsWith: false
  }
}

export default function ativoListReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'ATIVO_LIST_FETCH_ALL_ATIVOS':
      return {
        ...state,
        ativos: action.payload
      }
    case 'ATIVO_LIST_SET_TOTAL_ITEMS':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          totalItems: action.payload
        }
      }
    case 'ATIVO_LIST_SET_PAGE':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.payload
        }
      }
    case 'ATIVO_LIST_SET_ITEMS_PER_PAGE':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          itemsPerPage: action.payload
        }
      }
    case 'ATIVO_LIST_CLEAR_PAGINATION':
      return {
        ...state,
        pagination: {
          totalItems: 0,
          page: 1,
          itemsPerPage: 15
        }
      }
    case 'ATIVO_LIST_SET_REF':
      return {
        ...state,
        search: {
          ...state.search,
          ref: action.payload
        }
      }
    case 'ATIVO_LIST_SET_STARTS_WITH':
      return {
        ...state,
        search: {
          ...state.search,
          startsWith: action.payload
        }
      }
    case 'ATIVO_LIST_CLEAR_SEARCH':
      return {
        ...state,
        search: {
          ref: ''
        }
      }
    case 'ATIVO_LIST_CLEAR_ALL_ATIVOS':
      return {
        ...state,
        ...INITIAL_STATE
      }
    default:
      return state
  }
}
