import api from './api'
import { toast } from 'react-toastify'

export function searchFormulas(farmaciaId, ref, page, itemsPerPage, startsWith) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      const response = await api.get(`farmacias/${farmaciaId}/formulas/search`, {
        headers: { authorization: `Bearer ${token}` },
        params: { ref, page, itemsPerPage, startsWith }
      });

      return resolve(response.data);
    } catch (e) {
      toast.error("Falha ao buscar fórmulas");
      return reject(e);
    }
  })
}
