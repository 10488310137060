import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import Navbar from './layout/Navbar';
import PrivateRoute from './components/PrivateRoute';
import RoleBasedRoute from './components/RoleBasedRoute';
import ConditionalRoleBasedRoute from './components/ConditionalRoleBasedRoute';

// Components
import Home from './pages/Home';
import Login from './pages/Login';
import NewUsuarioForm from './pages/NewUsuarioForm';
import UsuarioListContainer from './pages/UsuarioListContainer';
import UsuarioEdit from './pages/UsuarioEdit';
import NewPassword from './pages/NewPassword';
import PasswordRecovery from './pages/PasswordRecovery';
import AtivoListContainer from './pages/AtivoListContainer';
import NewAtivoForm from './pages/NewAtivoForm';
import AtivoEdit from './pages/AtivoEdit';
import VeiculoUtilizacaoListContainer from './pages/VeiculoUtilizacaoListContainer';
import NewVeiculoUtilizacaoForm from './pages/NewVeiculoUtilizacaoForm';
import VeiculoUtilizacaoEdit from './pages/VeiculoUtilizacaoEdit';
import LinkAtivoVeiculo from './pages/LinkAtivoVeiculo';
import LinkAtivoFormulas from './pages/LinkAtivoFormulas';
import FormulaListContainer from './pages/FormulaListContainer';
import NewFormulaForm from './pages/NewFormulaForm';
import FormulaEdit from './pages/FormulaEdit';
import MedicamentoListContainer from './pages/MedicamentoListContainer';
import NewMedicamentoForm from './pages/NewMedicamentoForm';
import MedicamentoEdit from './pages/MedicamentoEdit';
import LinkFormulaAtivos from './pages/LinkFormulaAtivos';
import ResultListPage from './pages/ResultListPage';
import ReceitaContainer from './pages/ReceitaContainer';
import AtivosPendentesListContainer from './pages/AtivosPendentesListContainer';
import FormulasPendentesListContainer from './pages/FormulasPendentesListContainer';
import MedicamentosPendentesListContainer from './pages/MedicamentosPendentesListContainer';
import AtivosMedicoListContainer from './pages/AtivosMedicoListContainer';
import FormulasMedicoListContainer from './pages/FormulaMedicoListContainer';
import MedicamentoMedicoListContainer from './pages/MedicamentoMedicoListContainer';
import LinkMedicamentoToMedicoContainer from './pages/LinkMedicamentosToMedicoContainer';
import AtivoFavoritesListContainer from './pages/AtivoFavoritesListContainer';
import FormulaFavoritesListContainer from './pages/FormulaFavoritesListContainer';
import MedicamentoFavoritesListContainer from './pages/MedicamentoFavoritesListContainer';
import NewReceitaForm from './pages/NewReceitaForm';
import UnidadeListContainer from './pages/UnidadeListContainer';
import NewUnidadeForm from './pages/NewUnidadeForm';
import UnidadeEdit from './pages/UnidadeEdit';
import ReceitaListContainer from './pages/ReceitaListContainer';
import ReceitaPage from './pages/ReceitaPage';
import ReceitaMedicoListContainer from './pages/ReceitaMedicoListContainer';
import FormulaMedicoEdit from './pages/FormulaMedicoEdit';
import NewReceitaModeloForm from './pages/NewReceitaModeloForm';
import ReceitaModeloListContainer from './pages/ReceitaModeloListContainer';
import ModeloReceitaEdit from './pages/ModeloReceitaEdit';
import BuildUserProfileContainer from './pages/BuildUserProfileContainer';
import LinkMedicoRepresentanteContainer from './pages/LinkMedicoRepresentanteContainer';
import AtivoRepresentanteListContainer from './pages/AtivoRepresentanteListContainer';
import FormulasRepresentanteListContainer from './pages/FormulaRepresentanteListContainer';
import ReceitaModeloRepresentanteListContainer from './pages/ReceitaModeloRepresentanteListContainer';
import ReceitaRepresentanteListContainer from './pages/ReceitaRepresentanteListContainer';
import MedicamentoLogsListContainer from './pages/MedicamentoLogsListContainer';
import MedicamentoLogsMedicoListContainer from './pages/MedicamentoLogsMedicoListContainer';
import MedicamentoLogsRepresentanteListContainer from './pages/MedicamentoLogsRepresentanteListContainer';
import MedicamentoRepresentanteListContainer from './pages/MedicamentorepresentanteListContainer';
import EspecialidadesContainer from './pages/EspecialidadesContainer';
import NewEspecialidadeForm from './pages/NewEspecialidadeForm';
import EspecialidadeEdit from './pages/EspecialidadeEdit';
import LinkEspecialidadeToMedicoContainer from './pages/LinkEspecialidadeToMedicoContainer';
import LinkEspecialidadeToMedicamentosContainer from './pages/LinkEspecialidadeToMedicamentosContainer';
import ReceitaContainerNew from './pages/ReceitaContainerNew';

export default function Routes() {
  return (
    <BrowserRouter>
      <Navbar />

      <div className='container'>
        <Switch>
          <PrivateRoute exact path='/' component={Home} />
          <PrivateRoute path='/home' component={Home} />
          <Route path='/login' component={Login} />

          <Route exact path='/search' component={ResultListPage} />

          <RoleBasedRoute
            exact
            path='/usuarios'
            component={UsuarioListContainer}
            roles={['G']}
          />
          <RoleBasedRoute
            path='/usuarios/new'
            component={NewUsuarioForm}
            roles={['G']}
          />
          <RoleBasedRoute
            path='/usuarios/:id/edit'
            component={UsuarioEdit}
            roles={['G']}
          />
          <RoleBasedRoute
            path='/usuarios/:id/montar_perfil'
            component={BuildUserProfileContainer}
            roles={['G']}
          />
          <PrivateRoute
            path='/usuarios/:id/newPassword'
            component={NewPassword}
          />

          <ConditionalRoleBasedRoute
            exact
            path='/ativos'
            conditions={[
              { role: 'G', component: AtivoListContainer },
              { role: 'R', component: AtivoRepresentanteListContainer },
              { role: 'M', component: AtivosMedicoListContainer },
            ]}
          />
          <RoleBasedRoute
            path='/ativos/pendentes'
            component={AtivosPendentesListContainer}
            roles={['G']}
          />
          <RoleBasedRoute
            path='/ativos/new'
            component={NewAtivoForm}
            roles={['G', 'M']}
          />
          <RoleBasedRoute
            path='/ativos/:id/edit'
            component={AtivoEdit}
            roles={['G', 'M']}
          />
          <RoleBasedRoute
            path='/ativos/:id/link/veiculosUtilizacao'
            component={LinkAtivoVeiculo}
            roles={['G']}
          />
          <RoleBasedRoute
            path='/ativos/:id/link/formulas'
            component={LinkAtivoFormulas}
            roles={['G']}
          />

          <RoleBasedRoute
            exact
            path='/veiculosUtilizacao'
            component={VeiculoUtilizacaoListContainer}
            roles={['G', 'R']}
          />
          <RoleBasedRoute
            path='/veiculosUtilizacao/new'
            component={NewVeiculoUtilizacaoForm}
            roles={['G', 'R']}
          />
          <RoleBasedRoute
            path='/veiculosUtilizacao/:id/edit'
            component={VeiculoUtilizacaoEdit}
            roles={['G', 'R']}
          />

          <ConditionalRoleBasedRoute
            exact
            path='/formulas'
            conditions={[
              { role: 'G', component: FormulaListContainer },
              { role: 'R', component: FormulasRepresentanteListContainer },
              { role: 'M', component: FormulasMedicoListContainer },
            ]}
          />
          <RoleBasedRoute
            path='/formulas/pendentes'
            component={FormulasPendentesListContainer}
            roles={['G']}
          />
          <RoleBasedRoute
            path='/formulas/new'
            component={NewFormulaForm}
            roles={['G', 'M']}
          />
          <RoleBasedRoute
            path='/formulas/:id/edit'
            component={FormulaEdit}
            roles={['G', 'M']}
          />
          <RoleBasedRoute
            path='/formulas/:id/link/ativos'
            component={LinkFormulaAtivos}
            roles={['G', 'M']}
          />

          <ConditionalRoleBasedRoute
            exact
            path='/medicamentos'
            conditions={[
              { role: 'G', component: MedicamentoListContainer },
              { role: 'R', component: MedicamentoRepresentanteListContainer },
              { role: 'M', component: MedicamentoMedicoListContainer },
            ]}
          />
          <RoleBasedRoute
            path='/medicamentos/pendentes'
            component={MedicamentosPendentesListContainer}
            roles={['G', 'R']}
          />
          <RoleBasedRoute
            path='/medicamentos/new'
            component={NewMedicamentoForm}
            roles={['G', 'M', 'R']}
          />
          <RoleBasedRoute
            path='/medicamentos/:id/edit'
            component={MedicamentoEdit}
            roles={['G', 'M', 'R']}
          />

          <Route path='/passwordRecovery' component={PasswordRecovery} />

          <RoleBasedRoute
            path='/m/formulas/:formulaId/edit'
            component={FormulaMedicoEdit}
            roles={['M']}
          />

          <RoleBasedRoute
            path='/medicos/:medicoId/ativos/favoritos'
            component={AtivoFavoritesListContainer}
            roles={['M']}
          />
          <RoleBasedRoute
            path='/medicos/:medicoId/formulas/favoritos'
            component={FormulaFavoritesListContainer}
            roles={['M']}
          />
          <RoleBasedRoute
            path='/medicos/:medicoId/industrializados/favoritos'
            component={MedicamentoFavoritesListContainer}
            roles={['M']}
          />
          <RoleBasedRoute
            path='/medicos/:medicoId/receitas'
            component={ReceitaListContainer}
            roles={['G', 'M']}
          />

          <RoleBasedRoute
            exact
            path='/medicamentos/link'
            component={LinkMedicamentoToMedicoContainer}
            roles={['G', 'R']}
          />

          <RoleBasedRoute
            exact
            path='/receita'
            component={ReceitaContainer}
            roles={['M']}
          />
          <RoleBasedRoute
            path='/receita/new'
            component={NewReceitaForm}
            roles={['M']}
          />

          <RoleBasedRoute
            path='/receita/manual'
            component={ReceitaContainerNew}
            roles={['M']}
          />

          <RoleBasedRoute
            exact
            path='/unidades'
            component={UnidadeListContainer}
            roles={['G', 'R']}
          />
          <RoleBasedRoute
            path='/unidades/new'
            component={NewUnidadeForm}
            roles={['G', 'R']}
          />
          <RoleBasedRoute
            path='/unidades/:id/edit'
            component={UnidadeEdit}
            roles={['G', 'R']}
          />

          <ConditionalRoleBasedRoute
            exact
            path='/receitas'
            conditions={[
              { role: 'G', component: ReceitaListContainer },
              { role: 'R', component: ReceitaRepresentanteListContainer },
            ]}
          />
          <RoleBasedRoute
            path='/receitas/:receitaId'
            component={ReceitaPage}
            roles={['G', 'M', 'R']}
          />

          <ConditionalRoleBasedRoute
            exact
            path='/modelos_receita'
            conditions={[
              { role: 'G', component: ReceitaModeloListContainer },
              { role: 'R', component: ReceitaModeloRepresentanteListContainer },
            ]}
          />
          <RoleBasedRoute
            path='/modelos_receita/new'
            component={NewReceitaModeloForm}
            roles={['G', 'R']}
          />
          <RoleBasedRoute
            path='/modelos_receita/:id/edit'
            component={ModeloReceitaEdit}
            roles={['G', 'R']}
          />

          <RoleBasedRoute
            path='/representantes/vincular_medicos'
            component={LinkMedicoRepresentanteContainer}
            roles={['G']}
          />

          <ConditionalRoleBasedRoute
            path='/medicamentos/logs'
            conditions={[
              { role: 'G', component: MedicamentoLogsListContainer },
              { role: 'M', component: MedicamentoLogsMedicoListContainer },
              {
                role: 'R',
                component: MedicamentoLogsRepresentanteListContainer,
              },
            ]}
          />

          <RoleBasedRoute
            exact
            path='/especialidades'
            component={EspecialidadesContainer}
            roles={['G']}
          />
          <RoleBasedRoute
            path='/especialidades/new'
            component={NewEspecialidadeForm}
            roles={['G']}
          />
          <RoleBasedRoute
            path='/especialidades/:id/edit'
            component={EspecialidadeEdit}
            roles={['G']}
          />

          <RoleBasedRoute
            path='/especialidades/medicos/link'
            component={LinkEspecialidadeToMedicoContainer}
            roles={['G']}
          />

          <RoleBasedRoute
            path='/especialidades/medicamentos/link'
            component={LinkEspecialidadeToMedicamentosContainer}
            roles={['G']}
          />
          <Redirect to='/' />
        </Switch>
      </div>
    </BrowserRouter>
  );
}
