import React, { useState } from 'react'
import './styles.css'
import PropTypes from 'prop-types'

import Table, { Thead as TableHeader, TableRow, Tbody, TbodyCol as TbCol } from '../../../components/Table'
import ButtonView from '../../../components/ButtonView'
import ButtonFavorite from '../../../components/ButtonFavorite'
import ModalViewFormula from './ModalViewFormula'
import ButtonAdd from '../../../components/ButtonAdd'
import ButtonEdit from '../../../components/ButtonEdit'
import Media from 'react-media'
import ButtonRemove from '../../../components/ButtonRemove'

function FormulaList({ formulas, favorites, receita,
  onFavorite: handleFavorite,
  onUnfavorite: handleUnfavorite,
  onAddFormulaReceita: handleAddFormulaReceita,
  onEditFormula: handleEditFormula,
  onRemoveFormula: handleRemoveLinkFormula }) {

  const [formula, setFormula] = useState(null);

  return (
    <>
      {formulas.length > 0 ? (
        <Table card>
          <TableHeader dark
            columns={[
              'Nome',
              'Recomendação de uso',
              'Ativos',
              'Posologia',
              'Observação',
              'Ação']} />

          <Tbody>
            {formulas.map(formula => {
              const isFormulaOnReceita = receita.find(med => (med.codigo === formula.for_in_codigo && med.tipo === 'FORMULA'));

              return (
                <TableRow className="upper" key={formula.for_in_codigo}>
                  <TbCol alignMiddle>{formula.for_st_nome}</TbCol>
                  <TbCol alignMiddle label="Recomendação de uso">
                    {formula.formulas_ativos.length === 0
                      ? ' - '
                      : formula.formulas_ativos.map(formulaAtivo => `${formulaAtivo.ativo.atv_st_indicacaouso} `)}
                  </TbCol>
                  <TbCol alignMiddle label="Ativos">
                    {formula.formulas_ativos.length === 0
                      ? ' - '
                      : formula.formulas_ativos.map(formulaAtivo => `${formulaAtivo.ativo.atv_st_descricao}; `)}
                  </TbCol>
                  <TbCol alignMiddle label="Posologia">{formula.for_st_posologia || '-'}</TbCol>
                  <TbCol alignMiddle label="Observação">{formula.for_st_observacao || '-'}</TbCol>
                  <Media query={{ maxWidth: 600 }}>
                    {matches => (
                      <TbCol flex={!matches} alignMiddle>
                        <ButtonView target="#modalViewFormula" className="mr-1"
                          onClick={() => setFormula(formula)} />

                        <ButtonEdit className="mr-1"
                          onClick={() => handleEditFormula(formula)} />

                        <ButtonRemove className="mr-1"
                          onClick={() => handleRemoveLinkFormula(formula)} />

                        {isFormulaOnReceita ? null : (
                          <ButtonAdd className="mr-1"
                            onClick={() => handleAddFormulaReceita(formula)} />
                        )}

                        <ButtonFavorite isFavorite={!!favorites.find(fav => fav.for_in_codigo === formula.for_in_codigo)}
                          onFavorite={() => handleFavorite(formula)}
                          onUnfavorite={() => handleUnfavorite(formula)} />
                      </TbCol>
                    )}
                  </Media>
                </TableRow>
              )
            })}
          </Tbody>
        </Table>
      ) : <p className="not-found">Nenhuma fórmula encontrada</p>}

      <ModalViewFormula id="modalViewFormula" formula={formula} />
    </>
  )
}

FormulaList.propTypes = {
  formulas: PropTypes.array.isRequired,
  favorites: PropTypes.array,
  receita: PropTypes.array,
  onFavorite: PropTypes.func,
  onUnfavorite: PropTypes.func,
  onAddFormulaReceita: PropTypes.func,
  onEditFormula: PropTypes.func,
  onRemoveFormula: PropTypes.func,
}

export default FormulaList;