import { nop } from './baseActions'
import { findFormulasByRepresentante } from '../services/RepresentanteMedicamentosService'

const setFormulas = formulas => {
  return {
    type: 'REPRESENTANTE_FORMULA_LIST_SET_FORMULAS',
    payload: formulas
  }
}

export const setPage = page => {
  return {
    type: 'REPRESENTANTE_FORMULA_LIST_PAGINATION_SET_PAGE',
    payload: page
  }
}

export const setItemsPerPage = itemsPerPage => {
  return {
    type: 'REPRESENTANTE_FORMULA_LIST_PAGINATION_SET_ITEMS_PER_PAGE',
    payload: itemsPerPage
  }
}

const setTotalItems = totalItems => {
  return {
    type: 'REPRESENTANTE_FORMULA_LIST_PAGINATION_SET_TOTAL_ITEMS',
    payload: totalItems
  }
}

export const clearAll = () => {
  return {
    type: 'REPRESENTANTE_FORMULA_LIST_CLEAR_ALL'
  }
}

export const fetchFormulasByRepresentante = (farmaciaId, representanteId, pagination = { page: 1, itemsPerPage: 15 }) => {
  return async dispatch => {
    try {
      const response = await findFormulasByRepresentante(farmaciaId, representanteId, pagination);

      return dispatch([
        setFormulas(response.data),
        setTotalItems(response["_metadata"].totalItems)
      ]);
    } catch (e) {
      return dispatch(nop());
    }
  }
}
