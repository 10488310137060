import React from 'react';
import './styles.css';
import PropTypes from 'prop-types';

import Card, {
  CardBody as Body,
  CardText as Text,
  CardTitle as Title,
} from '../../../../components/Card';
import Row from '../../../../components/Row';
import Grid from '../../../../components/Grid';
// import defaultImg from '../../../../assets/default-img.png'
import { Link } from 'react-router-dom';
import ButtonFavorite from '../../../../components/ButtonFavorite';
import ModalButton from '../../../../components/ModalButton';
import { MdAdd } from 'react-icons/md';
import Badge from '../../../../components/Badge';

function IndustrializadoCard({
  industrializado,
  role,
  isFavorite,
  target,
  onAddMedicamento: handleAddMedicamento,
  onRedirectToMedicamentoPage: handleRedirectToMedicamentoPage,
  isMedicamentoOnReceita,
  onFavorite: handleFavorite,
  onUnfavorite: handleUnfavorite,
}) {
  const industrializadoPageURL = `/medicamentos/${industrializado.codigo}/edit`;

  return (
    <Card className='medicamento-card'>
      <Row>
        {/* <Grid cols="12 4">
          <Link to={industrializadoPageURL}
            onClick={() => handleRedirectToMedicamentoPage(industrializado.tipo)}>
            <img id={`img-${industrializado.codigo}`}
              src={defaultImg}
              className="card-img"
              alt={industrializado.nome} />
          </Link>
        </Grid> */}
        <Grid cols='12'>
          <Body pl0>
            <div className='d-flex align-items-center justify-content-between'>
              <Link
                to={industrializadoPageURL}
                className='text-dark text-decoration-none'
                onClick={() =>
                  handleRedirectToMedicamentoPage(industrializado.tipo)
                }
              >
                <Title className='upper'>
                  {industrializado.nome}{' '}
                  <small>
                    <Badge color='primary'>Industrializado</Badge>
                  </small>
                </Title>
              </Link>

              {role === 'M' ? (
                <div className='card-title-btn-container'>
                  <ButtonFavorite
                    isFavorite={isFavorite}
                    onUnfavorite={() => handleUnfavorite(industrializado)}
                    onFavorite={() => handleFavorite(industrializado)}
                  />

                  {isMedicamentoOnReceita ? (
                    <small>Medicamento já adicionado</small>
                  ) : (
                    <ModalButton
                      target={target}
                      size='sm'
                      color='light'
                      title='Adicionar a receita'
                      handleclick={() => handleAddMedicamento(industrializado)}
                    >
                      <MdAdd className='text-success' size={28} />
                    </ModalButton>
                  )}
                </div>
              ) : null}
            </div>
            <Text>{industrializado.observacao}</Text>
            <Text>
              <b>Posologia: </b>
              {industrializado.posologia}
            </Text>
            <hr className='divider' />
            <Text>
              <small className='text-muted'>
                <b>Recomendação de uso: </b>
                {industrializado.uso}
              </small>
            </Text>
          </Body>
        </Grid>
      </Row>
    </Card>
  );
}

IndustrializadoCard.propTypes = {
  industrializado: PropTypes.object,
  role: PropTypes.string,
  isFavorite: PropTypes.bool,
  target: PropTypes.string,
  onAddMedicamento: PropTypes.func,
  onRedirectToMedicamentoPage: PropTypes.func,
  isMedicamentoOnReceita: PropTypes.bool,
  onFavorite: PropTypes.func,
  onUnfavorite: PropTypes.func,
};

export default IndustrializadoCard;
