import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Modal from '../../components/Modal';
import Row from '../../components/Row';
import Grid from '../../components/Grid';
import Button from '../../components/Button';

function NewViaModal({ id, vias, saveNewVia }) {
  const [name, setName] = useState(null);

  return (
    <Modal id={id} title='Controle de vias cadastradas' hideFooter>
      <div style={{ width: '100%' }}>
        <Row>
          <Grid cols='12 12'>
            <label>Vias cadastradas: </label>
            <select className='form-control'>
              {vias.length < 1 ? (
                <option>Nenhuma via cadastrada...</option>
              ) : (
                vias.map((via) => (
                  <option key={via.via_in_codigo}>{via.via_st_nome}</option>
                ))
              )}
            </select>
          </Grid>
        </Row>
        <Row>
          <Grid cols='12 12'>
            <input
              className='form-control'
              type='text'
              placeholder='cadastrar nova via'
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <Button
              className='btn btn-primary mt-3'
              type='button'
              handleclick={() => {
                saveNewVia({ via_st_nome: name });
                setName('');
              }}
            >
              Salvar
            </Button>
          </Grid>
        </Row>
      </div>
    </Modal>
  );
}

NewViaModal.propTypes = {
  id: PropTypes.string.isRequired,
};

export default NewViaModal;
