import api from './api'
import { toast } from 'react-toastify'

export function buildProfile(farmaciaId, usuarioId, profiles) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      await api.post(`farmacias/${farmaciaId}/usuarios/${usuarioId}/build_profile`, {
        profiles
      }, {
        headers: { authorization: `Bearer ${token}` }
      });

      return resolve();
    } catch (e) {
      toast.error("Falha ao montar o perfil do médico");
      return reject(e);
    }
  })
}
