import React, { useState, useEffect } from 'react';
import './styles.css';

import { useSelector, useDispatch } from 'react-redux';
import {
  fetchAllMedicosByFarmacia,
  searchEspecialidades,
  setSelectedMedico,
  fetchLinksMedicoEspecialidades,
  clearAll,
} from '../../stores/linkEspeciliadadeMedicoActions';
import Title from '../../components/Title';
import MedicoList from './MedicoList';
import Skeleton from 'react-loading-skeleton';
import EspecialidadesList from './EspecialidadesList';
import {
  linkEspecialidadeToMedico,
  unlinkEspecialidadeMedico,
} from '../../services/LinkMedicoToEspecialidadeService';
import EspecialidadeSearch from './EspecialidadeSearch';
import { useHistory } from 'react-router-dom';
import { setLoading as loading } from '../../stores/loadingActions';

export default function LinkMedicamentoToMedicoContainer() {
  const dispatch = useDispatch();
  // search
  // const ref = useSelector((state) => state.linkEspecialidadeMedico.search.ref);

  const allMedicos = useSelector(
    (state) => state.linkEspecialidadeMedico.allMedicos
  );
  const selectedMedico = useSelector(
    (state) => state.linkEspecialidadeMedico.selectedMedico
  );

  const history = useHistory();

  // medicamentos
  const results = useSelector(
    (state) => state.linkEspecialidadeMedico.allEspecialidades
  );

  // medicamentos linked to selected medico
  const linksMedicoEspecialidades = useSelector(
    (state) => state.linkEspecialidadeMedico.linksMedicoEspecialidades
  );

  const especialidades = results.map((result) => {
    const isLinked = linksMedicoEspecialidades.find((link) => {
      // ativo
      if (result.esp_in_codigo === link.esp_in_codigo) return true;

      return false;
    });

    if (isLinked) {
      return { ...result, linked: true };
    } else {
      return result;
    }
  });

  const [isFirst, setFirst] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const [ref, setRef] = useState('');

  const [step, setStep] = useState(1);

  const farmaciaId = localStorage.getItem('FORMULARIO_FARMACIA_ID');

  useEffect(() => {
    dispatch(fetchAllMedicosByFarmacia(farmaciaId));

    return () => dispatch(clearAll());
  }, []);

  // loading medicos
  useEffect(() => {
    if (isFirst) setFirst(false);
    else setLoading(false);
  }, [results, allMedicos, ref]);

  function handleSelectMedico(medico) {
    setLoading(true);

    dispatch([
      loading(true),
      setSelectedMedico(medico),
      searchEspecialidades(farmaciaId),
      fetchLinksMedicoEspecialidades(farmaciaId, medico.usu_in_codigo),
    ]);

    setStep(2);
  }

  async function handleLinkEspecialidade(especialidade) {
    dispatch(loading(true));

    await linkEspecialidadeToMedico(
      farmaciaId,
      selectedMedico.usu_in_codigo,
      especialidade.esp_in_codigo
    );

    dispatch(
      fetchLinksMedicoEspecialidades(farmaciaId, selectedMedico.usu_in_codigo)
    );
  }

  async function handleUnlinkEspecialidade(especialidade) {
    dispatch(loading(true));

    await unlinkEspecialidadeMedico(
      farmaciaId,
      selectedMedico.usu_in_codigo,
      especialidade.esp_in_codigo
    );

    dispatch(
      fetchLinksMedicoEspecialidades(farmaciaId, selectedMedico.usu_in_codigo)
    );
  }

  function handleSearch({ ref }) {
    console.log('chegou essa ref: ', ref);
    setLoading(true);

    setRef(ref);
  }

  function handleClearSearch() {
    setLoading(true);

    setRef('');
  }

  function goBack() {
    if (step === 2) setStep(1);
    if (step === 1) history.push('/');
  }

  return (
    <div className='link-container'>
      <Title onClick={goBack}>Vinculo médico - especialidade</Title>

      {
        {
          1: (
            <div>
              <p>Selecione o médico:</p>

              {isLoading ? (
                <Skeleton count={3} height={60} width={200} />
              ) : (
                <MedicoList
                  medicos={allMedicos}
                  onSelect={handleSelectMedico}
                />
              )}
            </div>
          ),

          2: (
            <div>
              <EspecialidadeSearch
                onSubmit={handleSearch}
                onClear={handleClearSearch}
              />

              {isLoading ? (
                <Skeleton count={3} height={60} width={200} />
              ) : (
                <EspecialidadesList
                  especialidades={especialidades}
                  onLinkEspecialidade={handleLinkEspecialidade}
                  onUnlinkEspecialidade={handleUnlinkEspecialidade}
                  search={ref}
                />
              )}
            </div>
          ),
        }[step]
      }
    </div>
  );
}
