import React from 'react';
import './styles.css';
import PropTypes from 'prop-types';

import Modal from '../../../components/Modal';
import CloseModalButton from '../../../components/CloseModalButton';
import Row from '../../../components/Row';
import Grid from '../../../components/Grid';

function ModalNewSugestion({
  id,
  onSugestNewAtivo: handleSugestNewAtivo,
  onSugestNewFormula: handleSugestNewFormula,
  onSugestNewMedicamentoIndustrializado: handleSugestNewMedicamentoIndustrializado,
}) {
  return (
    <Modal
      id={id}
      title='Selecione o tipo de medicamento a ser adicionado'
      hideFooter
    >
      <Row>
        <Grid cols='12 4'>
          <CloseModalButton
            size='sm btn-block'
            type='button'
            handleclick={handleSugestNewAtivo}
          >
            Ativo
          </CloseModalButton>
        </Grid>
        <Grid cols='12 4'>
          <CloseModalButton
            size='sm btn-block'
            type='button'
            handleclick={handleSugestNewFormula}
          >
            Fórmula
          </CloseModalButton>
        </Grid>
        <Grid cols='12 4'>
          <CloseModalButton
            size='sm btn-block'
            type='button'
            handleclick={handleSugestNewMedicamentoIndustrializado}
          >
            Industrializado
          </CloseModalButton>
        </Grid>
      </Row>
    </Modal>
  );
}

ModalNewSugestion.propTypes = {
  id: PropTypes.string.isRequired,
  onSugestNewAtivo: PropTypes.func,
  onSugestNewFormula: PropTypes.func,
  onSugestNewMedicamentoIndustrializado: PropTypes.func,
};

export default ModalNewSugestion;
