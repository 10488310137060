const INITIAL_STATE = {
  veiculosUtilizacao: [],
  ativosVeiculos: []
}

export default function linkAtivoVeiculoReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'LINK_ATIVO_VEICULO_SET_VEICULOS_UTILIZACAO':
      return {
        ...state,
        veiculosUtilizacao: action.payload
      }
    case 'LINK_ATIVO_VEICULO_SET_ATIVOS_VEICULOS':
      return {
        ...state,
        ativosVeiculos: action.payload
      }
    case 'LINK_ATIVO_VEICULO_CLEAR_ATIVOS_VEICULOS':
      return {
        ...state,
        ...INITIAL_STATE
      }
    default:
      return state
  }
}
