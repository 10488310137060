const INITIAL_STATE = {
  isAuthenticated: localStorage.getItem('FORMULARIO_USER') ? true : false,
  user: JSON.parse(localStorage.getItem('FORMULARIO_USER')) || null,
  farmacia: JSON.parse(localStorage.getItem('FORMULARIO_FARMACIA')) || null
}

export default function authReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'LOGIN':
      const user = action.payload.user;
      const token = action.payload.token;
      const farmacia = action.payload.farmacia;

      if (user.usu_ch_tipo !== 'M')
        document.title = farmacia.far_st_nome;

      localStorage.setItem('FORMULARIO_USER', JSON.stringify(user));
      localStorage.setItem('FORMULARIO_FARMACIA', JSON.stringify(farmacia));
      localStorage.setItem('FORMULARIO_FARMACIA_ID', user.far_in_codigo);
      localStorage.setItem('FORMULARIO_JSONWEBTOKEN', token);
      localStorage.setItem('FORMULARIO_JSONWEBTOKEN_EXP', action.payload.expiration);

      return {
        ...state,
        isAuthenticated: true,
        user,
        farmacia
      }
    case 'LOGOUT':
      document.title = "Bem-vindo!";

      localStorage.removeItem('FORMULARIO_USER');
      localStorage.removeItem('FORMULARIO_FARMACIA');
      localStorage.removeItem('FORMULARIO_FARMACIA_ID');
      localStorage.removeItem('FORMULARIO_JSONWEBTOKEN');

      return {
        ...state,
        isAuthenticated: false,
        user: null,
        farmacia: null
      }
    default:
      return state
  }
}
