import React, { useState, useRef, useEffect } from 'react';
import './styles.css';

import { useSelector, useDispatch } from 'react-redux';
import { setLoading } from '../../stores/loadingActions';
import { useHistory, useLocation } from 'react-router-dom';

import Title from '../../components/Title';
import { Form } from '@unform/web';
import { Input, Checkbox, TextArea, Select } from '../../components/Form';
import Button from '../../components/Button';
import Row from '../../components/Row';
import Grid from '../../components/Grid';
import * as Yup from 'yup';
import { save } from '../../services/FormulaService';
import {
  fetchUnidades,
  fetchMedicos,
  searchVeiculosUtilizacao as searchVeiculos,
  clearAll,
} from '../../stores/newFormulaActions';
import ModalSearchVeiculosUtilizacaoResults from '../ResultListPage/NewFormulaWizard/ModalSearchVeiculosUtilizacaoResults';
import ModalButton from '../../components/ModalButton';
import { save as saveVeiculoUtilizacao } from '../../services/VeiculoUtilizacaoService';
import { toast } from 'react-toastify';

import { getVias } from '../../stores/viaActions';

export default function NewFormulaForm() {
  const formRef = useRef(null);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const [nome, setNome] = useState('');
  const [posologia, setPosologia] = useState('');
  const [unidade, setUnidade] = useState(-1);
  const [veiculoUtilizacao, setVeiculoUtilizacao] = useState(null);
  const [recomendacaoUso] = useState('');
  const [obs, setObs] = useState('');
  const [sinonimos, setSinonimos] = useState('');
  const [checked, setChecked] = useState(true);
  const [qsp, setQSP] = useState(false);
  const [quantidadeVeiculo, setQuantidadeVeiculo] = useState(0);
  const [medico, setMedico] = useState(-1);

  const [selectedVia, setSelectedVia] = useState(null);

  // modal results loading
  const [isFirst, setFirst] = useState(true);
  const [modalLoading, setModalLoading] = useState(true);

  // first time searching veiculos utilizacao
  const [veiculosFirstSearch, setVeiculosFirstSearch] = useState(true);

  const farmaciaId = localStorage.getItem('FORMULARIO_FARMACIA_ID');

  const role = useSelector((state) => state.auth.user.usu_ch_tipo);

  const unidades = useSelector((state) => state.formula.new.unidades);
  const veiculosUtilizacao = useSelector(
    (state) => state.formula.new.veiculosUtilizacao
  );
  const medicos = useSelector((state) => state.formula.new.medicos);
  const vias = useSelector((state) => state.vias.vias);

  const status = location.state?.status;
  const usuarioId = location.state?.usuarioId;

  // if role === M, then check for status props on location
  if (role === 'M' && !status) history.push('/');

  useEffect(() => {
    dispatch([fetchUnidades(farmaciaId), fetchMedicos(farmaciaId), getVias()]);

    return () => dispatch(clearAll());
  }, [dispatch, farmaciaId]);

  useEffect(() => {
    // user search a veiculo utilizacao
    if (veiculoUtilizacao) {
      document.getElementById('veiculoUtilizacao').value =
        veiculoUtilizacao.veu_st_descricao;
    }
  }, [veiculoUtilizacao]);

  // modal results loading
  useEffect(() => {
    if (isFirst) setFirst(false);
    else setModalLoading(false);
  }, [isFirst, veiculosUtilizacao]);

  function getMedicoId() {
    if (usuarioId) return usuarioId;
    if (medico != -1) return medico;

    return null;
  }

  async function handleSaveFormula(callback) {
    try {
      if (!veiculoUtilizacao) {
        toast.warning('Selecione um veic. utilização/forma farmacêutica');
        return;
      }

      // set loading
      dispatch(setLoading(true));

      // build formula object
      const data = {
        for_st_nome: nome,
        for_bo_disponivel: checked,
        for_st_recomendacaouso: recomendacaoUso,
        for_st_posologia: posologia,
        uni_in_codigo: unidade,
        veu_in_codigo: veiculoUtilizacao.veu_in_codigo,
        for_bo_qsp: qsp,
        for_in_quantidadeveiculo: quantidadeVeiculo,
        for_st_observacao: obs,
        for_st_sinonimos: sinonimos,
        for_ch_status: status || 'A',
        usu_in_codigo: usuarioId,
        usu_in_codigo_exclusividade: getMedicoId(),
        via_in_codigo: selectedVia,
      };

      // validation
      const schema = Yup.object().shape({
        for_st_nome: Yup.string().required('O nome é obrigatório'),
        for_bo_disponivel: Yup.boolean(),
        for_st_posologia: Yup.string(),
        for_st_observacao: Yup.string(),
        for_st_sinonimos: Yup.string(),
        veu_in_codigo: Yup.number().required(
          'Selecione um veic. utilização/forma farmacêutica'
        ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      // save
      const savedFormula = await save(farmaciaId, data);

      // clear errors
      formRef.current.setErrors({});

      callback(savedFormula);
    } catch (e) {
      if (e instanceof Yup.ValidationError) {
        const errorMessages = {};

        e.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });

        formRef.current.setErrors(errorMessages);
      }

      dispatch(setLoading(false));
    }
  }

  function redirectToFormulaList() {
    // remove loading spinner
    dispatch(setLoading(false));

    // redirect
    history.push('/formulas');
  }

  function redirectToLinkAtivo(formula) {
    dispatch(setLoading(false));

    history.push(`/formulas/${formula.for_in_codigo}/link/ativos`);
  }

  async function handleSaveNewVeiculoUtilizacao({
    veu_st_descricao,
    veu_st_tipo,
  }) {
    dispatch(setLoading(true));

    let data = { veu_st_descricao, veu_st_tipo };

    // link to medico
    if (role === 'M') {
      data['usu_in_codigo'] = usuarioId;
    }

    const veiculoUtilizacao = await saveVeiculoUtilizacao(farmaciaId, data);

    setVeiculoUtilizacao(veiculoUtilizacao);

    dispatch(setLoading(false));
  }

  function handleSelectVeiculoUtilizacao(veiculoUtilizacao) {
    setVeiculoUtilizacao(veiculoUtilizacao);
  }

  function searchVeiculosUtilizacao(ref) {
    setModalLoading(true);

    dispatch(searchVeiculos(farmaciaId, ref, role, usuarioId));

    // open modal to show results
    document.getElementById('modal_results_button').click();
  }

  function handleSearchVeiculoUtilizacao(e) {
    if (e.key === 'Enter' || (e.key === 'Tab' && veiculosFirstSearch)) {
      e.preventDefault();

      setVeiculosFirstSearch(false);

      // search veiculoUtilizacao
      searchVeiculosUtilizacao(e.target.value);
    }
  }

  return (
    <div className='formula-container'>
      <Title link='/formulas'>Cadastro de fórmula</Title>

      <Row>
        <Grid cols='12'>
          <div className='form-container'>
            <Form ref={formRef}>
              <Input
                id='for_st_nome'
                className='form-control upper'
                label='* Nome'
                name='for_st_nome'
                value={nome}
                onChange={(e) => setNome(e.target.value)}
                required
              />

              <Input
                id='for_st_posologia'
                className='form-control upper'
                label='Posologia'
                name='for_st_posologia'
                value={posologia}
                onChange={(e) => setPosologia(e.target.value)}
              />

              <Input
                id='veiculoUtilizacao'
                name='veiculoUtilizacao'
                label='Veic. utilização/Forma farmacêutica'
                className='form-control upper'
                placeholder='Pesquise por descrição'
                onKeyDown={handleSearchVeiculoUtilizacao}
              >
                <small className='text-muted'>
                  Pressione "Enter" para pesquisar.
                </small>
              </Input>

              <Row>
                <Grid cols='12 6'>
                  <Checkbox
                    type='checkbox'
                    id='for_bo_qsp'
                    label='Veículo com QSP'
                    name='for_bo_qsp'
                    value={qsp}
                    onChange={(e) => setQSP(e.target.checked)}
                    checked={qsp}
                  />
                </Grid>
                <Grid cols='12 6'>
                  <Row>
                    <Grid cols='12 6'>
                      <Input
                        type='number'
                        id='for_in_quantidadeveiculo'
                        name='for_in_quantidadeveiculo'
                        value={quantidadeVeiculo}
                        onChange={(e) => setQuantidadeVeiculo(e.target.value)}
                        required
                      />
                    </Grid>
                    <Grid cols='12 6'>
                      <Select
                        id='uni_in_codigo'
                        name='uni_in_codigo'
                        className='form-control upper'
                        value={unidade}
                        onChange={(e) => setUnidade(e.target.value)}
                      >
                        <option value={-1} disabled>
                          Selecione...
                        </option>
                        {unidades.map(
                          ({ uni_in_codigo: codigo, uni_st_sigla: sigla }) => (
                            <option key={codigo} value={codigo}>
                              {sigla}
                            </option>
                          )
                        )}
                      </Select>
                    </Grid>
                  </Row>
                </Grid>
              </Row>
              <Row>
                <Grid cols='12 6'>
                  <label>Via *</label>
                  <Select
                    id='via_in_codigo'
                    label='Via'
                    name='via_in_codigo'
                    value={selectedVia}
                    onChange={(e) => setSelectedVia(e.target.value)}
                  >
                    <option value='' selected disabled>
                      Selecione...
                    </option>
                    {vias.map((via) => (
                      <option value={via.via_in_codigo} key={via.via_in_codigo}>
                        {via.via_st_nome}
                      </option>
                    ))}
                  </Select>
                </Grid>
              </Row>

              {role === 'G' && (
                <Select
                  id='usu_in_codigo_exclusividade'
                  label='A que médico essa fórmula pertence?'
                  name='usu_in_codigo_exclusividade'
                  value={medico}
                  onChange={(e) => setMedico(e.target.value)}
                >
                  <option value={-1}>Todos os médicos</option>
                  {medicos.map((medico) => (
                    <option
                      value={medico.usu_in_codigo}
                      key={medico.usu_in_codigo}
                    >
                      {medico.usu_st_nome}
                    </option>
                  ))}
                </Select>
              )}

              <Input
                id='for_st_sinonimos'
                className='form-control upper'
                label='Sinônimos'
                name='for_st_sinonimos'
                value={sinonimos}
                onChange={(e) => setSinonimos(e.target.value)}
              />

              <TextArea
                id='for_st_observacao'
                label='Observação'
                name='for_st_observacao'
                className='form-control upper'
                value={obs}
                onChange={(e) => setObs(e.target.value)}
              ></TextArea>

              {role === 'G' && (
                <Checkbox
                  type='checkbox'
                  id='for_bo_disponivel'
                  label='Disponível'
                  name='for_bo_disponivel'
                  value={checked}
                  onChange={(e) => setChecked(e.target.checked)}
                  checked={checked}
                />
              )}

              <div className='w-100 mb-3'>
                <small className='text-muted align-end'>
                  Os campos marcados com (*) são obrigatórios
                </small>
              </div>

              <Row>
                <Grid cols='12 6'>
                  <Button
                    type='button'
                    color='primary'
                    size='block'
                    handleclick={() => handleSaveFormula(redirectToFormulaList)}
                  >
                    Salvar
                  </Button>
                </Grid>
                <Grid cols='12 6'>
                  <Button
                    type='button'
                    color='secondary'
                    size='block'
                    handleclick={() => handleSaveFormula(redirectToLinkAtivo)}
                  >
                    Montar fórmulas
                  </Button>
                </Grid>
              </Row>
            </Form>
          </div>
        </Grid>
      </Row>

      {/* veiculosUtilizacao search results modal */}
      <ModalButton
        id='modal_results_button'
        target='#modal_results'
        className='d-none'
      />
      <ModalSearchVeiculosUtilizacaoResults
        id='modal_results'
        veiculosUtilizacao={veiculosUtilizacao}
        onSelectVeiculo={handleSelectVeiculoUtilizacao}
        onSaveNewVeiculoUtilizacao={handleSaveNewVeiculoUtilizacao}
        loading={modalLoading}
        setLoading={setModalLoading}
      />
    </div>
  );
}
