import React from 'react'
import './styles.css'

import PropTypes from 'prop-types'
import ThCol from './TheadCol'

function Thead({ dark, columns }) {
  const className = dark ? 'thead-dark' : null;

  return (
    <thead className={className}>
      <tr>
        {columns.map((col, index) => <ThCol key={index}>{col}</ThCol>)}
      </tr>
    </thead>
  )
}

Thead.propTypes = {
  dark: PropTypes.bool
}

export default Thead;
