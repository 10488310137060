import React, { useRef, useState } from 'react';
import './styles.css';

import PropTypes from 'prop-types';
import { Form } from '@unform/web';
import Input from '../../../components/Form/input';
import Select from '../../../components/Form/select';
import Button from '../../../components/Button';
import { MdClear, MdSearch } from 'react-icons/md';
import Row from '../../../components/Row';
import Grid from '../../../components/Grid';

function VeiculoUtilizacaoSearch({
  onSubmit: handleSubmit,
  onClear: handleClear,
}) {
  const formRef = useRef(null);

  const [tipo, setTipo] = useState(-1);
  const [ref, setRef] = useState('');

  function resetForm() {
    setTipo(-1);
    setRef('');
  }

  return (
    <div className='search-container'>
      <Form
        ref={formRef}
        onSubmit={(e) => {
          handleSubmit(e);
          resetForm();
        }}
      >
        <Row>
          <Grid cols='12 4'>
            <Select
              id='tipo'
              label='Tipo'
              name='tipo'
              value={tipo}
              onChange={(e) => setTipo(e.target.value)}
            >
              <option value={-1}>Todos</option>
              <option value='AMBOS'>Ambos</option>
              <option value='TOPICO'>Tópico</option>
              <option value='ORAL'>Oral</option>
            </Select>
          </Grid>
        </Row>

        <Row>
          <Grid cols='12 10'>
            <Input
              id='ref'
              name='ref'
              value={ref}
              onChange={(e) => setRef(e.target.value)}
              placeholder='Pesquise por descrição'
            />
          </Grid>

          <Grid cols='12 2'>
            <Button color='info' type='submit'>
              <MdSearch size={18} />
            </Button>

            <Button
              type='button'
              className='ml-2'
              color='info'
              handleclick={() => {
                handleClear();
                resetForm();
              }}
            >
              <MdClear size={18} />
            </Button>
          </Grid>
        </Row>
      </Form>

      <hr className='divider' />
    </div>
  );
}

VeiculoUtilizacaoSearch.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClear: PropTypes.func,
};

export default VeiculoUtilizacaoSearch;
