import React from 'react'
import PropTypes from 'prop-types'
import './styles.css'

function TableRow({ children, onClick: handleClick, className, ...props }) {
  return (
    <tr className={`${className} ${handleClick && 'cursor-pointer'}`} onClick={handleClick} {...props}>
      {children}
    </tr>
  )
}

TableRow.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func,
  className: PropTypes.string,
}

export default TableRow;
