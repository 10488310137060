const INITIAL_STATE = {
  formulas: []
}

export default function formulasPendentesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'FORMULAS_PENDENTES_SET_FORMULAS':
      return {
        ...state,
        formulas: action.payload
      }
    case 'FORMULAS_PENDENTES_CLEAR_FORMULAS':
      return {
        ...state,
        ...INITIAL_STATE
      }
    default:
      return state
  }
}
