import { nop } from './baseActions'
import { findAll, remove } from '../services/UnidadeService'
import { setLoading } from './loadingActions'

const setUnidades = unidades => {
  return {
    type: 'UNIDADE_LIST_SET_UNIDADES',
    payload: unidades
  }
}

export const clearUnidades = () => {
  return {
    type: 'UNIDADE_LIST_CLEAR_ALL'
  }
}

export const fetchAllUnidades = farmaciaId => {
  return async dispatch => {
    try {
      const unidades = await findAll(farmaciaId);

      return dispatch(setUnidades(unidades));
    } catch (e) {
      return dispatch(nop());
    }
  }
}

export const removeUnidade = (farmaciaId, unidade) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));

      await remove(farmaciaId, unidade);

      const unidades = await findAll(farmaciaId);

      return dispatch([
        setUnidades(unidades),
        setLoading(false)
      ]);
    } catch (e) {
      return dispatch(nop());
    }
  }
}
