import React, { useRef } from 'react';
import './styles.css';
import { useSelector, useDispatch } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import Button from '../../components/Button';
import { Form } from '@unform/web';

import { Input } from '../../components/Form';

import { login } from '../../stores/authActions';

import logoImg from '../../assets/login/logo_v_white.png';

export default function Login() {
  const formRef = useRef(null);

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();

  async function handleSubmit(data) {
    dispatch(login(data.email, data.password));
  }

  if (isAuthenticated) {
    return <Redirect to='/' />;
  }

  return (
    <div className='login-container fake-background'>
      <div className='box-login bg-light p-5'>
        {process.env.REACT_APP_FIREBASE_PROJECT_ID === 'formularioreact' ? (
          <h3 className='mb-4'>Login</h3>
        ) : (
          <img className='login-img' src={logoImg} alt='DoctorLib' />
        )}

        <Form ref={formRef} onSubmit={handleSubmit}>
          <Input
            id='email'
            name='email'
            label='E-mail'
            type='email'
            placeholder='example@mail.com'
            required
          />

          <Input
            id='password'
            name='password'
            label='Senha'
            type='password'
            placeholder='******'
            min='6'
          >
            <small>
              <Link className='secondary' to='/passwordRecovery'>
                Esqueceu sua senha?
              </Link>
            </small>
          </Input>

          <hr className='divider' />

          <Button type='submit' color='primary' size='block'>
            Login
          </Button>
        </Form>
      </div>
    </div>
  );
}
