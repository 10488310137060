const INITIAL_STATE = {
  receita: null,
  goBack: null,
  modelo: null,
}

export default function receitaPageReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'RECEITA_PAGE_SET_RECEITA':
      return {
        ...state,
        receita: action.payload
      }
    case 'RECEITA_PAGE_SET_GO_BACK':
      return {
        ...state,
        goBack: action.payload
      }
    case 'RECEITA_PAGE_SET_MODELO':
      return {
        ...state,
        modelo: action.payload
      }
    case 'RECEITA_PAGE_CLEAR_ALL':
      return {
        ...state,
        ...INITIAL_STATE
      }
    default:
      return state
  }
}
