import React, { useState, useEffect, useRef } from 'react';
import './styles.css';

import { useParams, useHistory } from 'react-router-dom';
import Title from '../../components/Title';
import { Form } from '@unform/web';
import { Input, TextArea } from '../../components/Form';
import Button from '../../components/Button';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { setLoading } from '../../stores/loadingActions';
import {
  fetchById,
  clearMedicamento,
  fetchLogsByIndustrializado,
} from '../../stores/medicamentoEditActions';
import Row from '../../components/Row';
import Grid from '../../components/Grid';
import { update } from '../../services/MedicamentoService';
// import defaultImg from '../../assets/default-img.png'
// import { save as saveFile, storage } from '../../config/firebase'
// import { getFileExtension } from '../../utils/files'
// import { v4 as uuidv4 } from 'uuid'
// import ImageInput from '@bit/ericktrettel.franchini.image-input'
import IndustrializadoLogs from '../../components/Logs';
import { clearLogs } from '../../services/IndustrializadoLogsService';
import { contains } from '../../utils/arrays';

export default function MedicamentoEdit() {
  const { id: industrializadoId } = useParams();
  const history = useHistory();

  const dispatch = useDispatch();
  const medicamento = useSelector(
    (state) => state.medicamentosIndustrializados.edit.medicamento
  );

  const formRef = useRef(null);

  const farmaciaId = localStorage.getItem('FORMULARIO_FARMACIA_ID');

  const [nome, setNome] = useState('');
  const [fabricante, setFabricante] = useState('');
  const [recomendacaoUso, setRecomendacaoUso] = useState('');
  const [posologia, setPosologia] = useState('');
  const [obs, setObs] = useState('');
  // const [image, setImage] = useState({ url: defaultImg, file: null });

  const [isLocked, setLocked] = useState(true);

  const role = useSelector((state) => state.auth.user.usu_ch_tipo);

  // if array does not contain user role, then readonly is true
  const readonly = !contains(['G', 'R'], role);

  const goBackPath = useSelector(
    (state) => state.medicamentosIndustrializados.edit.goBack
  );

  // logs
  const logs = useSelector(
    (state) => state.medicamentosIndustrializados.edit.logs
  );
  const { usu_in_codigo: usuarioId } = useSelector((state) => state.auth.user);

  useEffect(() => {
    // fetch medicamento by id
    dispatch([
      fetchById(farmaciaId, industrializadoId),
      fetchLogsByIndustrializado(farmaciaId, industrializadoId),
    ]);

    return () => dispatch(clearMedicamento());
  }, []);

  useEffect(() => {
    if (medicamento) {
      setNome(medicamento.med_st_nome);
      setFabricante(medicamento.med_st_fabricante);
      setRecomendacaoUso(medicamento.med_st_recomendacaouso);
      setPosologia(medicamento.med_st_posologia);
      setObs(medicamento.med_st_observacao);

      // if (medicamento.med_st_imagem) {
      //   const ref = storage.ref(medicamento.med_st_imagem);
      //   ref.getDownloadURL()
      //     .then(img => document.getElementById('imagem').src = img)
      //     .catch(err => console.log("Erro ao buscar imagem: ", err));
      // }
    }
  }, [medicamento]);

  async function handleSubmit(data, { reset }) {
    try {
      // set loading
      dispatch(setLoading(true));

      // validation
      const schema = Yup.object().shape({
        med_st_nome: Yup.string().required('O nome é obrigatório'),
        med_st_fabricante: Yup.string().required('O fabricante é obrigatório'),
        med_st_recomendacaouso: Yup.string().required(
          'Informe a recomendação de uso'
        ),
        med_st_posologia: Yup.string().required('Informe a posologia'),
        med_st_observacao: Yup.string(),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      let medicamento = data;
      // const file = image.file;

      // if (file) {
      //   const fileExtension = getFileExtension(file.name);
      //   const blobName = `medicamentos/${medicamento.med_st_nome}/${uuidv4() + fileExtension}`;
      //   saveFile(file, blobName);

      //   medicamento = {
      //     ...medicamento,
      //     med_st_imagem: blobName
      //   }
      // }

      // save
      await update(farmaciaId, industrializadoId, medicamento, usuarioId);

      dispatch(setLoading(false));

      // reset form data
      reset();

      // reset errors
      formRef.current.setErrors({});

      // redirect user
      history.push('/medicamentos');
    } catch (e) {
      if (e instanceof Yup.ValidationError) {
        const errorMessages = {};

        e.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });

        formRef.current.setErrors(errorMessages);
      }

      dispatch(setLoading(false));
    }
  }

  async function handleClearLogs() {
    dispatch(setLoading(true));

    await clearLogs(farmaciaId, industrializadoId, usuarioId);

    dispatch([
      fetchLogsByIndustrializado(farmaciaId, industrializadoId),
      setLoading(false),
    ]);
  }

  // function handleLoadImage(e) {
  //   const file = e.target.files[0];
  //   const url = window.URL.createObjectURL(file);

  //   setImage({ url, file });
  // }

  function ActionButtons() {
    return isLocked ? (
      <Button size='block' handleclick={() => setLocked(false)}>
        Habilitar campos
      </Button>
    ) : (
      <Row>
        <Grid cols='12 6'>
          <Button
            color='secondary'
            size='block'
            handleclick={() => setLocked(true)}
          >
            Cancelar
          </Button>
        </Grid>

        <Grid cols='12 6'>
          <Button type='submit' size='block'>
            Salvar
          </Button>
        </Grid>
      </Row>
    );
  }

  return (
    <div className='medicamento-container'>
      <Title link={goBackPath || '/medicamentos'}>
        {readonly ? 'Informações do industrializado' : 'Editar'}
      </Title>

      <Row>
        <Grid cols='12 6'>
          <div className='form-container'>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <Input
                id='med_st_nome'
                label='* Nome'
                className='form-control upper'
                name='med_st_nome'
                value={nome}
                onChange={(e) => setNome(e.target.value)}
                disabled={isLocked}
                required
              />

              <Input
                id='med_st_fabricante'
                label='* Fabricante'
                className='form-control upper'
                name='med_st_fabricante'
                value={fabricante}
                onChange={(e) => setFabricante(e.target.value)}
                disabled={isLocked}
                required
              />

              <TextArea
                id='med_st_recomendacaouso'
                label='* Recomendação de uso'
                className='form-control upper'
                name='med_st_recomendacaouso'
                value={recomendacaoUso}
                onChange={(e) => setRecomendacaoUso(e.target.value)}
                disabled={isLocked}
                required
              ></TextArea>

              <Input
                id='med_st_posologia'
                label='* Posologia'
                className='form-control upper'
                name='med_st_posologia'
                value={posologia}
                onChange={(e) => setPosologia(e.target.value)}
                disabled={isLocked}
                required
              />

              <TextArea
                id='med_st_observacao'
                label='Observação'
                className='form-control upper'
                name='med_st_observacao'
                value={obs}
                onChange={(e) => setObs(e.target.value)}
                disabled={isLocked}
              ></TextArea>

              {/* {!isLocked ? (
                <ImageInput className="form-group"
                  id="for_st_imagem"
                  name="for_st_imagem"
                  onLoadImage={handleLoadImage}
                  isRequired={false} />
              ) : null} */}

              {readonly ? null : (
                <div className='w-100 mb-3'>
                  <small className='text-muted align-end'>
                    Os campos marcados com (*) são obrigatórios
                  </small>
                </div>
              )}

              {readonly ? null : <ActionButtons />}
            </Form>
          </div>
        </Grid>

        <Grid cols='12 6'>
          <IndustrializadoLogs
            logs={logs.map((log) => ({
              datahora: log.ilo_dt_datahora,
              mensagem: log.ilo_st_mensagem,
            }))}
            onClearLogs={handleClearLogs}
          />
        </Grid>

        {/* <Grid cols="12 6">
          <img id="imagem" className="img-fluid img-view" src={image.url} alt="Imagem selecionada" />
        </Grid> */}
      </Row>
    </div>
  );
}
