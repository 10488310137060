import React from 'react'
import './styles.css'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

export default function RoleBasedRoute({ component: Component, roles, ...rest }) {
  const auth = useSelector(state => state.auth);
  const userRole = auth.user?.usu_ch_tipo

  if(!auth.isAuthenticated) return <Redirect to="/login" />

  if(!roles.includes(userRole)) return <Redirect to="/login" />

  return (
    <Route {...rest} render={props => (
      <Component {...props} /> 
    )} />
  )
}
