import React from 'react'
import PropTypes from 'prop-types'

function Container({ label, children }) {
  return (
    <li className="nav-item dropdown">
      <a className="nav-link dropdown-toggle cursor-pointer"
        href id="navbarDropdown"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false">
        {label}
      </a>
      <div className="dropdown-menu" aria-labelledby="navbarDropdown">
        {children}
      </div>
    </li>
  )
}

Container.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
}

export default Container;
