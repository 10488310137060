import React, { useState } from 'react';
import './styles.css';
import PropTypes from 'prop-types';

import Table, {
  Thead as TableHeader,
  TableRow,
  Tbody,
  TbodyCol as TbCol,
} from '../../../components/Table';
import Modal from '../../../components/Modal';
import ModalButton from '../../../components/ModalButton';
import Button from '../../../components/Button';
import { MdDelete, MdEdit } from 'react-icons/md';
import { insertIf } from '../../../utils/arrays';

function UnidadeList({
  unidades,
  readonly,
  onDelete: handleDelete,
  onEdit: handleEdit,
}) {
  const [unidade, setUnidade] = useState(null);

  return unidades.length > 0 ? (
    <div>
      <Table card>
        <TableHeader
          dark
          columns={['Descrição', 'Sigla', ...insertIf(!readonly, 'Ação')]}
        />

        <Tbody>
          {unidades.map((unidade) => (
            <TableRow className='upper' key={unidade.uni_in_codigo}>
              <TbCol alignMiddle label='Descrição'>
                {unidade.uni_st_descricao}
              </TbCol>
              <TbCol alignMiddle label='Sigla'>
                {unidade.uni_st_sigla}
              </TbCol>
              {readonly ? null : (
                <TbCol alignMiddle label='Ação'>
                  <ModalButton
                    target='#modalDeleteUnidade'
                    color='danger'
                    size='sm'
                    handleclick={() => setUnidade(unidade)}
                  >
                    <MdDelete size={24} />
                  </ModalButton>

                  <Button
                    className='ml-1'
                    color='warning'
                    size='sm'
                    handleclick={() => handleEdit(unidade)}
                  >
                    <MdEdit size={24} />
                  </Button>
                </TbCol>
              )}
            </TableRow>
          ))}
        </Tbody>
      </Table>

      <Modal
        id='modalDeleteUnidade'
        handleClick={() => handleDelete(unidade)}
        handleCancelarClick={() => setUnidade(null)}
        btnColor='danger'
        size='sm'
        text='Você tem certeza de que deseja remover a unidade?'
        title='Remover unidade'
      />
    </div>
  ) : (
    <p className='not-found'>Nenhuma unidade encontrada</p>
  );
}

UnidadeList.propTypes = {
  unidades: PropTypes.array.isRequired,
  readonly: PropTypes.bool,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
};

UnidadeList.defaultProps = {
  readonly: false,
};

export default UnidadeList;
