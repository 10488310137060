import { nop } from './baseActions';
import { findAllMedicosByFarmacia } from '../services/FindMedicosService';
import { findAllByFarmacia } from '../services/EspecialidadeService';
import { findEspecialidadesByMedico } from '../services/LinkMedicoToEspecialidadeService';
import { setLoading } from './loadingActions';

const setAllMedicos = (medicos) => {
  return {
    type: 'LINK_MEDICO_ESPECIALIDADE_SET_MEDICOS',
    payload: medicos,
  };
};

export const setSelectedMedico = (medico) => {
  return {
    type: 'LINK_MEDICO_ESPECIALIDADE_SET_MEDICO',
    payload: medico,
  };
};

const setAllEspecialidades = (especialidades) => {
  return {
    type: 'LINK_MEDICO_ESPECIALIDADE_SET_ESPECIALIDADES',
    payload: especialidades,
  };
};

const setLinksMedicoEspecialidades = (links) => {
  return {
    type: 'LINK_MEDICO_ESPECIALIDADE_SET_LINKS',
    payload: links,
  };
};

export const setRef = (ref) => {
  return {
    type: 'LINK_MEDICO_ESPECIALIDADE_SET_REF',
    payload: ref,
  };
};

export const setPage = (page) => {
  return {
    type: 'LINK_MEDICO_ESPECIALIDADE_SET_PAGE',
    payload: page,
  };
};

export const clearAll = () => {
  return {
    type: 'LINK_MEDICO_ESPECIALIDADE_CLEAR_ALL',
  };
};

export const fetchAllMedicosByFarmacia = (farmaciaId) => {
  return async (dispatch) => {
    try {
      const medicos = await findAllMedicosByFarmacia(farmaciaId);

      return dispatch(setAllMedicos(medicos));
    } catch (e) {
      return dispatch(nop());
    }
  };
};

export const fetchLinksMedicoEspecialidades = (farmaciaId, usuarioId) => {
  return async (dispatch) => {
    try {
      const especialidades = await findEspecialidadesByMedico(
        farmaciaId,
        usuarioId
      );

      return dispatch([
        setLinksMedicoEspecialidades(especialidades),
        setLoading(false),
      ]);
    } catch (e) {
      return dispatch(nop());
    }
  };
};

export const searchEspecialidades = (farmaciaId) => {
  return async (dispatch) => {
    try {
      const response = await findAllByFarmacia(farmaciaId);
      console.log('response search: ', response);
      return dispatch([setAllEspecialidades(response)]);
    } catch (e) {
      return dispatch(nop());
    }
  };
};
