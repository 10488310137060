import React, { useRef, useState } from 'react';

import { useDispatch } from 'react-redux';
import { setLoading } from '../../stores/loadingActions';
import { useHistory } from 'react-router-dom';

import Title from '../../components/Title';
import { Form } from '@unform/web';
import { Input, Checkbox } from '../../components/Form';
import Button from '../../components/Button';
import * as Yup from 'yup';
import ImageInput from '../../components/ImageInput';
import defaultImg from '../../assets/default-img.png';
import { save as saveFile } from '../../config/firebase';
import { getFileExtension } from '../../utils/files';
import { v4 as uuidv4 } from 'uuid';
import Row from '../../components/Row';
import Grid from '../../components/Grid';

import { save } from '../../services/ReceitaModeloService';
import { toast } from 'react-toastify';

export default function NewReceitaModeloForm() {
  const formRef = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();

  const [image, setImage] = useState({ url: defaultImg, file: null });
  const [timbrado, setTimbrado] = useState(false);

  const farmaciaId = localStorage.getItem('FORMULARIO_FARMACIA_ID');

  async function handleSubmit(data, { reset }) {
    try {
      // set loading
      dispatch(setLoading(true));

      // validation
      const schema = Yup.object().shape({
        rem_st_descricao: Yup.string().required('A descrição é obrigatória'),
        rem_st_caminhoimagem: Yup.string(),
        rem_st_marginleft: Yup.string().required('As margens são obrigatórias'),
        rem_st_margintop: Yup.string().required('As margens são obrigatórias'),
      });

      let modelo = data;

      // save image
      const file = image.file;

      if (!file) {
        toast.warning('Selecione uma imagem');
        dispatch(setLoading(false));
        return;
      }

      const fileExtension = getFileExtension(file.name);
      const blobName = `receitas/${modelo.rem_st_descricao}/${
        uuidv4() + fileExtension
      }`;
      saveFile(image.file, blobName);

      modelo = {
        ...modelo,
        rem_st_caminhoimagem: blobName,
      };

      await schema.validate(modelo, {
        abortEarly: false,
      });

      // save
      await save(farmaciaId, modelo);

      dispatch(setLoading(false));

      reset();

      // clear errors
      formRef.current.setErrors({});

      // redirect
      history.push('/modelos_receita');
    } catch (e) {
      if (e instanceof Yup.ValidationError) {
        const errorMessages = {};

        e.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });

        formRef.current.setErrors(errorMessages);
      }

      dispatch(setLoading(false));
    }
  }

  function handleLoadImage(e) {
    const file = e.target.files[0];
    const url = window.URL.createObjectURL(file);

    setImage({ url, file });
  }

  return (
    <div className='receita-container'>
      <Title link='/modelos_receita'>Cadastro de modelo de receita</Title>

      <div className='form-container'>
        <Row>
          <Grid cols='12 6'>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <Input
                id='rem_st_descricao'
                label='* Descrição'
                className='form-control'
                name='rem_st_descricao'
                required
              />

              <Input
                id='rem_st_marginleft'
                label='* Margem da esquerda (número em pixels)'
                placeholder='Ex: 25'
                className='form-control'
                name='rem_st_marginleft'
                required
              />

              <Input
                id='rem_st_margintop'
                label='* Margem do topo (número em pixels)'
                placeholder='Ex: 25'
                className='form-control'
                name='rem_st_margintop'
                required
              />

              <Checkbox
                id='rem_bo_timbrado'
                label='Imprimir timbrado?'
                name='rem_bo_timbrado'
                value={timbrado}
                checked={timbrado}
                onChange={(e) => setTimbrado(e.target.checked)}
              />

              <ImageInput
                className='form-group'
                id='med_st_imagem'
                name='med_st_imagem'
                onLoadImage={handleLoadImage}
              />

              <div className='w-100 mb-3'>
                <small className='text-muted align-end'>
                  Os campos marcados com (*) são obrigatórios
                </small>
              </div>

              <Button type='submit' color='primary' size='block'>
                Salvar
              </Button>
            </Form>
          </Grid>

          <Grid cols='12 6'>
            <img
              className='img-fluid img-view'
              src={image.url}
              alt='Imagem selecionada'
            />
          </Grid>
        </Row>
      </div>
    </div>
  );
}
