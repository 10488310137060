import React, { useEffect } from 'react';
import './App.css';

import { useSelector, useDispatch } from 'react-redux'
import Routes from './routes';
import { toast } from 'react-toastify';
import { css } from '@emotion/core';
import { ClipLoader } from 'react-spinners';
import { logout } from './stores/authActions';

const override = css`
    display: block;
    margin: 0 auto;
`;

toast.configure({
  position: 'top-right',
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  newestOnTop: true
});

export default function App() {
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.loading.isLoading);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  function checkJWTExpiration() {
    // check jwt expiration date
    const exp = localStorage.getItem('FORMULARIO_JSONWEBTOKEN_EXP');

    if (exp && new Date() >= new Date(exp)) return dispatch(logout());
  }

  // app init
  useEffect(() => {
    if (isAuthenticated) checkJWTExpiration();
  }, []);

  return (
    <>
      <Routes />

      <div className="loading-spinner-container">
        <div className='sweet-loading spinner-bg'>
          <ClipLoader
            css={override}
            sizeUnit={"px"}
            size={150}
            color={'#F0F8FF'}
            loading={isLoading}
          />
        </div>
      </div>

      <div className="loading-background" style={{ display: isLoading ? 'block' : 'none' }}></div>
    </>
  )
}
