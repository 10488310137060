import { nop } from './baseActions'
import { findFormulas } from '../services/FindMedicamentosByMedicoService'
import { findAllFavoriteFormulas } from '../services/FavoriteFormulasService'
import { unlinkFormulaMedico } from '../services/LinkMedicoToMedicamentoService'

const setFormulas = formulas => {
  return {
    type: 'MEDICOS_FORMULAS_SET_FORMULAS',
    payload: formulas
  }
}

const setFavorites = favorites => {
  return {
    type: 'MEDICOS_FORMULAS_SET_FAVORITES',
    payload: favorites
  }
}

export const setPage = page => {
  return {
    type: 'MEDICOS_FORMULAS_SET_PAGE',
    payload: page
  }
}

export const setItemsPerPage = itemsPerPage => {
  return {
    type: 'MEDICOS_FORMULAS_SET_ITEMS_PER_PAGE',
    payload: itemsPerPage
  }
}

const setTotalItems = totalItems => {
  return {
    type: 'MEDICOS_FORMULAS_SET_TOTAL_ITEMS',
    payload: totalItems
  }
}

export const setRef = ref => {
  return {
    type: 'MEDICOS_FORMULAS_SET_REF',
    payload: ref
  }
}

export const setStartsWith = startsWith => {
  return {
    type: 'MEDICOS_FORMULAS_SET_STARTS_WITH',
    payload: startsWith
  }
}

export const clearFormulas = () => {
  return {
    type: 'MEDICOS_FORMULAS_CLEAR_FORMULAS'
  }
}

export const fetchAllFormulasByMedico = (farmaciaId, usuarioId, ref, page, itemsPerPage, startsWith) => {
  return async dispatch => {
    try {
      const response = await findFormulas(farmaciaId, usuarioId, ref, page, itemsPerPage, startsWith);

      return dispatch([
        setFormulas(response.data),
        setTotalItems(response['_metadata'].totalItems)
      ]);
    } catch (e) {
      return dispatch(nop());
    }
  }
}

export const fetchFavoriteFormulas = (farmaciaId, usuarioId) => {
  return async dispatch => {
    try {
      const favorites = await findAllFavoriteFormulas(farmaciaId, usuarioId);

      return dispatch(setFavorites(favorites));
    } catch (e) {
      return dispatch(nop());
    }
  }
}

export const removeLinkFormulaMedico = (farmaciaId, usuarioId, formulaId) => {
  return async (dispatch, getState) => {
    try {
      await unlinkFormulaMedico(farmaciaId, usuarioId, formulaId);

      const { pagination, search } = getState().medico.formula.list;

      return dispatch(fetchAllFormulasByMedico(
        farmaciaId,
        usuarioId,
        search.ref,
        pagination.page,
        pagination.itemsPerPage
      ));
    } catch (e) {
      return dispatch(nop());
    }
  }
}
