import React from 'react';
import './styles.css';
import PropTypes from 'prop-types';

import Card, {
  CardBody as Body,
  CardText as Text,
  CardTitle as Title,
} from '../../../../components/Card';
import Row from '../../../../components/Row';
import Grid from '../../../../components/Grid';
import { Link } from 'react-router-dom';
import ButtonFavorite from '../../../../components/ButtonFavorite';
import { MdAdd } from 'react-icons/md';
import Button from '../../../../components/Button';
import Badge from '../../../../components/Badge';

function FormulaCard({
  formula,
  role,
  isFavorite,
  onAddMedicamento: handleAddMedicamento,
  onRedirectToMedicamentoPage: handleRedirectToMedicamentoPage,
  isMedicamentoOnReceita,
  onFavorite: handleFavorite,
  onUnfavorite: handleUnfavorite,
}) {
  const formulaPageURL = `/formulas/${formula.codigo}/edit`;

  return (
    <Card className='formula-card'>
      <Row>
        <Grid cols='12'>
          <Body pl0>
            <div className='d-flex align-items-center justify-content-between'>
              <Link
                to={formulaPageURL}
                className='text-dark text-decoration-none'
                onClick={() => handleRedirectToMedicamentoPage(formula.tipo)}
              >
                <Title className='upper'>
                  {formula.nome}{' '}
                  <small>
                    <Badge color='warning'>Fórmula</Badge>
                  </small>
                </Title>
              </Link>

              {role === 'M' ? (
                <div className='d-flex align-items-center card-title-btn-container'>
                  <ButtonFavorite
                    isFavorite={isFavorite}
                    onUnfavorite={() => handleUnfavorite(formula)}
                    onFavorite={() => handleFavorite(formula)}
                  />

                  {isMedicamentoOnReceita ? (
                    <small>Medicamento já adicionado</small>
                  ) : (
                    <Button
                      size='sm'
                      color='light'
                      title='Adicionar a receita'
                      handleclick={() => handleAddMedicamento(formula)}
                    >
                      <MdAdd className='text-success' size={28} />
                    </Button>
                  )}
                </div>
              ) : null}
            </div>
            <Text>{formula.observacao}</Text>
            <Text>
              <b>Posologia: </b>
              {formula.posologia}
            </Text>
          </Body>
        </Grid>
      </Row>
    </Card>
  );
}

FormulaCard.propTypes = {
  formula: PropTypes.object,
  role: PropTypes.string,
  isFavorite: PropTypes.bool,
  onAddMedicamento: PropTypes.func,
  onRedirectToMedicamentoPage: PropTypes.func,
  isMedicamentoOnReceita: PropTypes.bool,
  onFavorite: PropTypes.func,
  onUnfavorite: PropTypes.func,
};

export default FormulaCard;
