import { nop } from './baseActions'
import { toast } from 'react-toastify'
import { findById as findAtivoById } from '../services/AtivoService'
import { findAtivosFormulas } from '../services/AtivoFormulaService'
import { searchFormulas } from '../services/SearchFormulaService'

const setAtivo = ativo => {
  return {
    type: 'LINK_ATIVO_FORMULA_SET_ATIVO',
    payload: ativo
  }
}

const setFormulas = data => {
  return {
    type: 'LINK_ATIVO_FORMULA_SET_FORMULAS',
    payload: data
  }
}

const setAtivosFormulas = data => {
  return {
    type: 'LINK_ATIVO_FORMULA_SET_ATIVOS_FORMULAS',
    payload: data
  }
}

export const setPage = page => {
  return {
    type: 'LINK_ATIVO_FORMULA_SET_PAGE',
    payload: page
  }
}

export const setItemsPerPage = itemsPerPage => {
  return {
    type: 'LINK_ATIVO_FORMULA_SET_ITEMS_PER_PAGE',
    payload: itemsPerPage
  }
}

export const setTotalItems = totalItems => {
  return {
    type: 'LINK_ATIVO_FORMULA_SET_TOTAL_ITEMS',
    payload: totalItems
  }
}

export const setRef = ref => {
  return {
    type: 'LINK_ATIVO_FORMULA_SET_REF',
    payload: ref
  }
}

export const clearSearch = () => {
  return {
    type: 'LINK_ATIVO_FORMULA_CLEAR_SEARCH'
  }
}

export const clearAtivosFormulas = () => {
  return {
    type: 'LINK_ATIVO_FORMULA_CLEAR_ATIVOS_FORMULAS'
  }
}

export const fetchAtivoById = (farmaciaId, ativoId) => {
  return async dispatch => {
    try {
      const ativo = await findAtivoById(farmaciaId, ativoId);

      return dispatch(setAtivo(ativo));
    } catch (e) {
      toast.error("Erro ao buscar ativo");
      return dispatch(nop());
    }
  }
}

export const fetchFormulasByFarmacia = (farmaciaId, ref, page, itemsPerPage) => {
  return async dispatch => {
    try {
      const response = await searchFormulas(farmaciaId, ref, page, itemsPerPage);

      return dispatch([
        setFormulas(response.data),
        setTotalItems(response["_metadata"].totalItems),
      ]);
    } catch (e) {
      toast.error("Erro ao buscar fórmulas");
      return dispatch(nop());
    }
  }
}

export const fetchAllLinksAtivoFormula = (farmaciaId, ativoId) => {
  return async dispatch => {
    try {
      const data = await findAtivosFormulas(farmaciaId, ativoId);

      return dispatch(setAtivosFormulas(data));
    } catch (e) {
      toast.error("Erro ao buscar os vínculos entre ativos e fórmulas");
      return dispatch(nop());
    }
  }
}
