import React, { useRef, useState } from 'react';
import './styles.css';

import { useSelector, useDispatch } from 'react-redux';
import { setLoading } from '../../stores/loadingActions';
import { useHistory, useLocation } from 'react-router-dom';

import Title from '../../components/Title';
import { Form } from '@unform/web';
import { Input, TextArea, Select } from '../../components/Form';
import Button from '../../components/Button';
import * as Yup from 'yup';

import { save } from '../../services/AtivoService';

export default function NewAtivoForm() {
  const formRef = useRef(null);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const farmaciaId = localStorage.getItem('FORMULARIO_FARMACIA_ID');

  const idFarmacia = useSelector((state) => state.auth.user.far_in_codigo);
  const role = useSelector((state) => state.auth.user.usu_ch_tipo);

  const status = location.state?.status;
  const usuarioId = location.state?.usuarioId;

  // if role === M, then check for status props on location
  if (role === 'M' && !status) history.push('/');

  const [uso, setUso] = useState('ORAL');

  async function handleSubmit(data, { reset }) {
    try {
      // set loading
      dispatch(setLoading(true));

      // validation
      const schema = Yup.object().shape({
        atv_st_descricao: Yup.string().required('A descrição é obrigatória'),
        atv_st_concentracaominima_topico: Yup.string(),
        atv_st_concentracaomaxima_topico: Yup.string(),
        atv_st_concentracaodiaria_topico: Yup.string(),
        atv_st_concentracaominima_oral: Yup.string(),
        atv_st_concentracaomaxima_oral: Yup.string(),
        atv_st_concentracaodiaria_oral: Yup.string(),
        atv_st_observacao: Yup.string(),
        atv_st_sinonimos: Yup.string(),
        atv_st_indicacaouso: Yup.string().required(
          'Informe a indicação de uso do ativo'
        ),
        atv_st_uso: Yup.string().required('O campo uso é obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      // put key far_in_codigo in ativo
      const ativo = {
        ...data,
        far_in_codigo: idFarmacia,
        atv_ch_status: status ? status : 'A',
        usu_in_codigo: usuarioId,
      };

      // save
      await save(farmaciaId, ativo);

      dispatch(setLoading(false));

      reset();

      // clear errors
      formRef.current.setErrors({});

      // redirect
      history.push('/ativos');
    } catch (e) {
      if (e instanceof Yup.ValidationError) {
        const errorMessages = {};

        e.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });

        formRef.current.setErrors(errorMessages);
      }

      dispatch(setLoading(false));
    }
  }

  return (
    <div className='ativo-container'>
      <Title link='/ativos'>Cadastro de ativo</Title>

      <div className='form-container'>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Input
            id='atv_st_descricao'
            label='* Descrição'
            className='form-control upper'
            name='atv_st_descricao'
            required
          />

          <Select
            id='atv_st_uso'
            label='* Uso'
            className='form-control upper'
            name='atv_st_uso'
            required
            value={uso}
            onChange={(e) => setUso(e.target.value)}
          >
            <option value='ORAL'>Oral</option>
            <option value='TOPICO'>Tópico</option>
            <option value='AMBOS'>Ambos</option>
          </Select>

          {uso === 'TOPICO' || uso === 'AMBOS' ? (
            <fieldset>
              <Input
                id='atv_st_concentracaominima_topico'
                label='Concentração mínima (tópico)'
                className='form-control upper'
                name='atv_st_concentracaominima_topico'
              />

              <Input
                id='atv_st_concentracaomaxima_topico'
                label='Concentração máxima (tópico)'
                className='form-control upper'
                name='atv_st_concentracaomaxima_topico'
              />

              <Input
                id='atv_st_concentracaodiaria_topico'
                label='Concentração usual (tópico)'
                className='form-control upper'
                name='atv_st_concentracaodiaria_topico'
              />
            </fieldset>
          ) : null}

          {uso === 'ORAL' || uso === 'AMBOS' ? (
            <fieldset>
              <Input
                id='atv_st_concentracaominima_oral'
                label='Concentração mínima (oral)'
                className='form-control upper'
                name='atv_st_concentracaominima_oral'
              />

              <Input
                id='atv_st_concentracaomaxima_oral'
                label='Concentração máxima (oral)'
                className='form-control upper'
                name='atv_st_concentracaomaxima_oral'
              />

              <Input
                id='atv_st_concentracaodiaria_oral'
                label='Concentração usual (oral)'
                className='form-control upper'
                name='atv_st_concentracaodiaria_oral'
              />
            </fieldset>
          ) : null}

          <TextArea
            id='atv_st_indicacaouso'
            label='* Indicação de uso'
            className='form-control upper'
            name='atv_st_indicacaouso'
            required
          ></TextArea>

          <Input
            id='atv_st_sinonimos'
            label='Sinônimos'
            className='form-control upper'
            name='atv_st_sinonimos'
          />

          <TextArea
            id='atv_st_observacao'
            label='Observação'
            className='form-control upper'
            name='atv_st_observacao'
          ></TextArea>

          <div className='w-100 mb-3'>
            <small className='text-muted align-end'>
              Os campos marcados com (*) são obrigatórios
            </small>
          </div>

          <Button type='submit' color='primary' size='block'>
            Salvar
          </Button>
        </Form>
      </div>
    </div>
  );
}
