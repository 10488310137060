import React, { useRef } from 'react';
import './styles.css';
import PropTypes from 'prop-types';

import Modal from '../../../components/Modal';
import { Form } from '@unform/web';
import { Input, TextArea } from '../../../components/Form';

function ModalViewMedicamento({ id, medicamento }) {
  const formRef = useRef(null);

  return (
    <Modal id={id} title='Visualizar medicamento' hideFooter>
      <Form ref={formRef}>
        <Input
          name='nome'
          id='nome'
          label='Nome'
          placeholder='Nome'
          value={medicamento?.nome || ''}
          disabled
        />

        <TextArea
          name='uso'
          id='uso'
          label='Uso'
          placeholder='Uso'
          value={medicamento?.uso || ''}
          disabled
        />

        <Input
          name='posologia'
          id='posologia'
          label='Posologia'
          placeholder='Posologia'
          value={medicamento?.posologia || ''}
          disabled
        />

        <TextArea
          name='observacao'
          id='observacao'
          label='Observacao'
          placeholder='Observacao'
          value={medicamento?.observacao || ''}
          disabled
        />

        <TextArea
          name='resumo'
          id='resumo'
          label='Resumo'
          placeholder='Resumo'
          value={medicamento?.resumo || ''}
          disabled
        />
      </Form>
    </Modal>
  );
}

ModalViewMedicamento.propTypes = {
  id: PropTypes.string.isRequired,
  medicamento: PropTypes.any,
};

export default ModalViewMedicamento;
