export function isEqual(obj1, obj2) {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}

export function isEqualByProps(obj1, obj2, ...props) {
  let isEqual = true;

  props.forEach(prop => {
    if (JSON.stringify(obj1[prop]) !== JSON.stringify(obj2[prop]))
      isEqual = false;
  });

  return isEqual;
}

/**
 * Transforms every string attr of obj to upper case
 * @param {object} obj 
 */
export function toUpperCase(obj) {
  let newObj = {};

  for (let key in obj) {
    if (typeof obj[key] === 'string') {
      newObj[key] = obj[key].toUpperCase();
    } else {
      newObj[key] = obj[key];
    }
  }

  return newObj;
}
