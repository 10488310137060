import React, { useState, useEffect } from 'react';
import './styles.css';

import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchAllAtivosByMedico,
  fetchAllFavoriteAtivos,
  removeLinkAtivoMedico,
  clearAtivos,
  setPage,
  setRef,
  setStartsWith,
} from '../../stores/medicoAtivosListActions';
import Title from '../../components/Title';
import Skeleton from 'react-loading-skeleton';
import Row from '../../components/Row';
import Grid from '../../components/Grid';
import AtivoList from './AtivoList';
import Button from '../../components/Button';
import {
  favoriteAtivo,
  unfavoriteAtivo,
} from '../../services/FavoriteAtivosService';
import { MdFavorite } from 'react-icons/md';
import { setLoading as loading } from '../../stores/loadingActions';
import Pagination from '../../components/Pagination';
import Search from '../AtivoListContainer/AtivoSearch';
import { addAtivoReceita } from '../../stores/searchMedicamentosActions';
import { v4 as uuidv4 } from 'uuid';

export default function AtivosMedicoListContainer() {
  const [isLoading, setLoading] = useState(true);
  const [isFirst, setFirst] = useState(true);

  const history = useHistory();

  const dispatch = useDispatch();
  const ativos = useSelector((state) => state.medico.ativo.ativos);
  const usuarioId = useSelector((state) => state.auth.user.usu_in_codigo);

  const favorites = useSelector((state) => state.medico.ativo.favorites);

  // pagination
  const page = useSelector((state) => state.medico.ativo.pagination.page);
  const itemsPerPage = useSelector(
    (state) => state.medico.ativo.pagination.itemsPerPage
  );
  const totalItems = useSelector(
    (state) => state.medico.ativo.pagination.totalItems
  );

  // search
  const ref = useSelector((state) => state.medico.ativo.search.ref);
  const startsWith = useSelector(
    (state) => state.medico.ativo.search.startsWith
  );

  const farmaciaId = localStorage.getItem('FORMULARIO_FARMACIA_ID');

  useEffect(() => {
    dispatch([
      fetchAllAtivosByMedico(farmaciaId, usuarioId, '', 1, itemsPerPage, false),
      fetchAllFavoriteAtivos(farmaciaId, usuarioId),
    ]);

    return () => dispatch(clearAtivos());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isFirst) setLoading(false);
    else setFirst(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ativos]);

  async function handleFavorite(ativo) {
    dispatch(loading(true));

    await favoriteAtivo(farmaciaId, usuarioId, ativo.atv_in_codigo);

    dispatch([fetchAllFavoriteAtivos(farmaciaId, usuarioId), loading(false)]);
  }

  async function handleUnfavorite(ativo) {
    dispatch(loading(true));

    await unfavoriteAtivo(farmaciaId, usuarioId, ativo.atv_in_codigo);

    dispatch([fetchAllFavoriteAtivos(farmaciaId, usuarioId), loading(false)]);
  }

  function sugestNewAtivo() {
    history.push('/ativos/new', { status: 'P', usuarioId });
  }

  function ButtonFavorites() {
    return (
      <Button
        color='secondary'
        size='sm'
        className='d-flex align-items-center mr-1'
        handleclick={() =>
          history.push(`/medicos/${usuarioId}/ativos/favoritos`)
        }
      >
        Favoritos
        <MdFavorite className='ml-1' size={18} />
      </Button>
    );
  }

  function ButtonSugest() {
    return (
      <Button color='secondary' size='sm' handleclick={sugestNewAtivo}>
        Adicionar ativo
      </Button>
    );
  }

  function handlePaginate(pgNumber) {
    if (pgNumber === page) return;

    setLoading(true);

    dispatch([
      setPage(pgNumber),
      fetchAllAtivosByMedico(
        farmaciaId,
        usuarioId,
        ref,
        pgNumber,
        itemsPerPage,
        startsWith
      ),
    ]);
  }

  function handleSearch({ ref: refText, startsWith: searchStartsWith }) {
    if (refText === ref && searchStartsWith === startsWith) return;

    setLoading(true);

    dispatch([
      setPage(1),
      setRef(refText),
      setStartsWith(searchStartsWith),
      fetchAllAtivosByMedico(
        farmaciaId,
        usuarioId,
        refText,
        1,
        itemsPerPage,
        searchStartsWith
      ),
    ]);
  }

  function handleClearSearch() {
    setLoading(true);

    dispatch([
      setPage(1),
      setRef(''),
      setStartsWith(false),
      fetchAllAtivosByMedico(farmaciaId, usuarioId, '', 1, itemsPerPage, false),
    ]);
  }

  function handleAddAtivoReceita(ativo, uso, concentracaoPrescrita) {
    const concentracaoMinima =
      uso === 'ORAL'
        ? ativo.atv_st_concentracaominima_oral
        : ativo.atv_st_concentracaominima_topico;
    const concentracaoMaxima =
      uso === 'ORAL'
        ? ativo.atv_st_concentracaomaxima_oral
        : ativo.atv_st_concentracaomaxima_topico;
    const concentracaoDiaria =
      uso === 'ORAL'
        ? ativo.atv_st_concentracaodiaria_oral
        : ativo.atv_st_concentracaodiaria_topico;

    dispatch(
      addAtivoReceita({
        fat_in_codigo: uuidv4(),
        fat_st_concentracaominima: concentracaoMinima,
        fat_st_concentracaomaxima: concentracaoMaxima,
        fat_st_concentracaodiaria: concentracaoDiaria,
        fat_st_concentracaoprescrita: concentracaoPrescrita,
        atv_in_codigo: ativo.atv_in_codigo,
        ativo: {
          ...ativo,
          atv_st_uso: uso,
        },
      })
    );

    history.push('/search', { step: 'NEW_FORMULA' });
  }

  function handleRemoveAtivo(ativo) {
    setLoading(true);

    dispatch(removeLinkAtivoMedico(farmaciaId, usuarioId, ativo.atv_in_codigo));
  }

  return (
    <div className='ativo-container'>
      <Title
        link='/'
        component={() => [
          <ButtonFavorites key={ButtonFavorites} />,
          <ButtonSugest key={ButtonSugest} />,
        ]}
      >
        Listagem de ativos
      </Title>

      <Row>
        <Grid cols='12'>
          <Search onSubmit={handleSearch} onClear={handleClearSearch} />
        </Grid>
      </Row>

      <Row>
        <Grid cols='12'>
          {isLoading ? (
            <Skeleton count={5} height={30} />
          ) : (
            <AtivoList
              ativos={ativos}
              favorites={favorites}
              onFavorite={handleFavorite}
              onUnfavorite={handleUnfavorite}
              onAddAtivoReceita={handleAddAtivoReceita}
              onRemoveAtivo={handleRemoveAtivo}
            />
          )}
        </Grid>
      </Row>

      {ativos.length > 0 ? (
        <Row>
          <Grid cols='12'>
            <Pagination
              page={page}
              itemsPerPage={itemsPerPage}
              totalItems={totalItems}
              paginate={handlePaginate}
            />
          </Grid>
        </Row>
      ) : null}
    </div>
  );
}
