import React from 'react'
import PropTypes from 'prop-types'

function PageNumber({ page, className, paginate, children, disabled }) {
  return (
    <li className={`page-item ${className ? className : null} ${disabled ? 'disabled' : null}`}>
      <button className="page-link" onClick={paginate}>{children ? children : page}</button>
    </li>
  )
}

PageNumber.propTypes = {
  /** Page number */
  page: PropTypes.number,
  /** Pagination function */
  paginate: PropTypes.func,
  /** Additional classes */
  className: PropTypes.string,
  /** Label of the button (optional) */
  children: PropTypes.any,
  /** Is the button disabled? */
  disabled: PropTypes.bool
}

export default PageNumber;
