import React, { useState } from 'react';
import './styles.css';
import PropTypes from 'prop-types';

import Table, {
  Thead as TableHeader,
  TableRow,
  Tbody,
  TbodyCol as TbCol,
} from '../../../components/Table';
import ButtonFavorite from '../../../components/ButtonFavorite';
import ButtonView from '../../../components/ButtonView';
import ModalButton from '../../../components/ModalButton';
import ModalViewAtivo from './ModalViewAtivo';
import Media from 'react-media';
import { MdAdd } from 'react-icons/md';
import ModalSelectUso from './ModalSelectUso';
import ButtonRemove from '../../../components/ButtonRemove';

function AtivoList({
  ativos,
  favorites,
  onFavorite: handleFavorite,
  onUnfavorite: handleUnfavorite,
  onAddAtivoReceita: handleAddAtivoReceita,
  onRemoveAtivo: handleRemoveAtivo,
}) {
  const [ativo, setAtivo] = useState(null);

  return (
    <>
      {ativos.length > 0 ? (
        <Table card>
          <TableHeader
            dark
            columns={[
              'Descrição',
              'Indicação de uso',
              'Uso',
              'Observação',
              'Ação',
            ]}
          />

          <Tbody>
            {ativos.map((ativo) => (
              <TableRow className='upper' key={ativo.atv_in_codigo}>
                <TbCol alignMiddle label='Descrição'>
                  {ativo.atv_st_descricao}
                </TbCol>
                <TbCol alignMiddle label='Indicação de uso'>
                  {ativo.atv_st_indicacaouso}
                </TbCol>
                <TbCol alignMiddle label='Uso'>
                  {ativo.atv_st_uso}
                </TbCol>
                <TbCol alignMiddle label='Observação'>
                  {ativo.atv_st_observacao || ' - '}
                </TbCol>
                <Media query={{ maxWidth: 600 }}>
                  {(matches) => (
                    <TbCol
                      alignMiddle
                      flex={!matches}
                      alignItemsCenter={!matches}
                    >
                      <ButtonView
                        target='#modalViewAtivo'
                        onClick={() => setAtivo(ativo)}
                        className='mr-1'
                        title='Visualizar detalhes'
                      />

                      <ButtonRemove
                        className='mr-1'
                        onClick={() => handleRemoveAtivo(ativo)}
                      />

                      <ModalButton
                        target='#modalSelectUso'
                        color='success'
                        size='sm'
                        className='mr-1'
                        handleclick={() => setAtivo(ativo)}
                        title='Adicionar à receita'
                      >
                        <MdAdd size={18} />
                      </ModalButton>

                      <ButtonFavorite
                        isFavorite={
                          !!favorites.find(
                            (f) => f.atv_in_codigo === ativo.atv_in_codigo
                          )
                        }
                        onFavorite={() => handleFavorite(ativo)}
                        onUnfavorite={() => handleUnfavorite(ativo)}
                      />
                    </TbCol>
                  )}
                </Media>
              </TableRow>
            ))}
          </Tbody>
        </Table>
      ) : (
        <p className='not-found'>Nenhum ativo encontrado</p>
      )}

      <ModalViewAtivo id='modalViewAtivo' ativo={ativo} />
      <ModalSelectUso
        id='modalSelectUso'
        ativo={ativo}
        onSelectUso={handleAddAtivoReceita}
      />
    </>
  );
}

AtivoList.propTypes = {
  ativos: PropTypes.array.isRequired,
  favorites: PropTypes.array,
  onFavorite: PropTypes.func,
  onUnfavorite: PropTypes.func,
  onAddAtivoReceita: PropTypes.func,
  onRemoveAtivo: PropTypes.func,
};

export default AtivoList;
