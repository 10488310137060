import React, { useEffect, useState } from 'react';
import './styles.js';
import { ReceitaPageContainer } from './styles.js';

import Title from '../../components/Title';
import MedicamentoList from '../ReceitaContainer/MedicamentoList';
import { useSelector, useDispatch } from 'react-redux';
import Button from '../../components/Button';
import { useHistory } from 'react-router-dom';
import ModalPreImpressao from '../Home/Summary/ModalPreImpressao';
import ModalButton from '../../components/ModalButton';
import {
  removeMedicamento,
  clearMedicamentos,
  setPaciente,
  setReceita,
  setReceberOrcamento,
  fetchModeloReceitaById,
} from '../../stores/newReceitaActions';
import {
  save as saveReceita,
  saveMedicamentosReceita,
} from '../../services/ReceitaService';
import { sendEmail } from '../../services/SendEmailReceitaService';
import { toast } from 'react-toastify';
import Receita from '../NewReceitaForm/Receita';
import { update as updateReceita } from '../../services/ReceitaService';
import { findAllByMedico } from '../../services/PacienteService';

export default function ReceitaContainer() {
  const dispatch = useDispatch();
  const medicamentos = useSelector((state) => state.receita.new.medicamentos);
  const modelo = useSelector((state) => state.receita.new.modelo);
  const receberOracemnto = useSelector((state) => state.receita.new.orcamento);
  const { usu_in_codigo: usuarioId } = useSelector((state) => state.auth.user);
  const usuario = useSelector((state) => state.auth.user);
  const paciente = useSelector((state) => state.receita.new.paciente);
  const farmaciaId = localStorage.getItem('FORMULARIO_FARMACIA_ID');
  const receita = useSelector((state) => state.receita.new.receita);
  const history = useHistory();
  const [isFirst, setFirst] = useState(true);
  const [modificado, setModificado] = useState(false);
  const [isEmailSent, setEmailSent] = useState(false);
  const [pacientes, setPacientes] = useState([]);
  const [pacienteFieldValue, setPacienteFieldValue] = useState('');
  const [medicamentosFieldValue, setMedicamentosFieldValue] = useState('');

  useEffect(() => {
    async function getPacientes() {
      console.log('getting pacs 1');
      const pacs = await findAllByMedico(usuarioId);
      setPacientes(pacs);
    }

    getPacientes();
  }, []);

  useEffect(() => {
    dispatch(fetchModeloReceitaById(farmaciaId, usuario.rem_in_codigo));
  }, []);

  function removeMedicamentoReceita(medicamento) {
    dispatch(removeMedicamento(medicamento));
  }

  function clearReceita() {
    dispatch(clearMedicamentos());

    goToHome();
  }

  function goToHome() {
    history.push('/');
  }

  function ButtonLimpar() {
    return (
      <Button
        size='sm'
        color='danger'
        className='mr-1'
        handleclick={clearReceita}
      >
        Limpar
      </Button>
    );
  }

  function ButtonAddMedicamentos() {
    return (
      <Button
        size='sm'
        color='secondary'
        className='mr-1'
        handleclick={goToHome}
      >
        Adicionar medicamentos
      </Button>
    );
  }

  function buildTxtReceita() {
    const plainTextReceita = `${pacienteFieldValue}

    Medicamentos:
    ${medicamentosFieldValue}`;

    return plainTextReceita;
  }

  async function sendEmailReceita() {
    console.log('inicio send email');

    try {
      // e-mail already sent, no need to send again
      console.log('primeiro return');
      if (isEmailSent) return;
      console.log('fim primeiro return');

      // paciente does not allow contact
      console.log('segundo return');
      if (!receberOracemnto) return;
      console.log('fim segundo return');

      const txtReceita = buildTxtReceita();

      await sendEmail(farmaciaId, usuario.usu_in_codigo, {
        rec_in_codigo: receita.rec_in_codigo,
        rec_st_nomepaciente: paciente.pac_st_nome,
        rec_st_telefonepaciente: paciente.pac_st_telefone,
        rec_st_emailpaciente: paciente.pac_st_emailcontato,
        rec_st_txtreceita: txtReceita,
      });

      setEmailSent(true);

      dispatch(setReceberOrcamento(false));
    } catch (error) {
      console.log('email error: ', error);
    }
    console.log('fim send email');
  }

  async function saveReceita() {
    const txtReceita = buildTxtReceita();

    // update receita text
    await updateReceita(
      farmaciaId,
      usuario.usu_in_codigo,
      receita.rec_in_codigo,
      {
        rec_st_txtreceita: txtReceita,
      }
    );
  }

  function ButtonPrint() {
    return (
      <Button
        color='secondary'
        size='sm'
        style={{ marginLeft: '5px' }}
        className='button-print'
        handleclick={() => {
          window.print();

          sendEmailReceita();
        }}
      >
        Imprimir
      </Button>
    );
  }

  function ButtonEnd() {
    return (
      <Button
        size='sm'
        className='ml-1 button-print'
        handleclick={() => {
          saveReceita();

          sendEmailReceita();

          dispatch(clearMedicamentos());

          history.push('/');
        }}
      >
        Finalizar
      </Button>
    );
  }

  function ButtonPreImpressao() {
    return (
      <ModalButton target='#modalPreImpressao' size='sm' color='secondary'>
        Pré-impressão
      </ModalButton>
    );
  }

  async function handleSetPaciente(paciente, receberOrcamentos) {
    // if (medicamentos.length === 0) {
    //   toast.warning('A receita deve ter pelo menos um medicamento');
    //   return;
    // }

    // // save receita
    const receita = await saveReceita(farmaciaId, usuarioId, {
      rec_st_nomepaciente: paciente.pac_st_nome,
      rec_st_telefonepaciente: paciente.pac_st_telefone,
      rec_st_emailpaciente: paciente.pac_st_emailcontato,
      pac_in_codigo: paciente.pac_in_codigo,
    });

    // // save medicamentos
    await saveMedicamentosReceita(farmaciaId, usuarioId, receita.rec_in_codigo);

    console.log('=======================');
    console.log(receita);
    console.log(paciente);
    console.log(receberOrcamentos);
    console.log('=======================');

    dispatch([
      setReceita(receita),
      setPaciente(paciente),
      (receberOrcamentos = true ? setReceberOrcamento(true) : null),
    ]);

    // history.push('/receita/manual');
  }

  async function handleSkipSetPaciente() {
    // if (medicamentos.length === 0) {
    //   toast.warning('A receita deve ter pelo menos um medicamento');
    //   return;
    // }

    // save receita
    const receita = await saveReceita(farmaciaId, usuarioId, {});

    // // save medicamentos
    await saveMedicamentosReceita(farmaciaId, usuarioId, receita.rec_in_codigo);

    dispatch(setReceita(receita));

    // history.push('/receita/new');
  }

  function handleEditFormula(formula) {
    history.push('/search', {
      step: 'NEW_FORMULA',
      formula: {
        ...formula,
        id_unidade: formula.unidade,
      },
    });
  }

  return (
    <div className='receita-container'>
      <Title
        link='/'
        component={() => [
          <ButtonLimpar key={1} />,
          // <ButtonAddMedicamentos key={2} />,
          <ButtonPreImpressao key={3} />,
          <ButtonPrint key={4} />,
          <ButtonEnd key={5} />,
        ]}
      >
        Sua receita manual
      </Title>
      <ReceitaPageContainer>
        <Receita
          modelo={modelo}
          pacienteFieldValue={pacienteFieldValue}
          setPacienteFieldValue={setPacienteFieldValue}
          medicamentosFieldValue={medicamentosFieldValue}
          setMedicamentosFieldValue={setMedicamentosFieldValue}
        />
      </ReceitaPageContainer>
      {/* <MedicamentoList
        medicamentos={medicamentos}
        onRemoveMedicamento={removeMedicamentoReceita}
        onEditFormula={handleEditFormula}
      /> */}

      <ModalPreImpressao
        id='modalPreImpressao'
        onSetPaciente={handleSetPaciente}
        onSkipSetPaciente={handleSkipSetPaciente}
        pacientesList={pacientes}
      />
    </div>
  );
}
