import React, { useState, useEffect } from 'react';
import './styles.css';

import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchAllVeiculosUtilizacao,
  removeVeiculoUtilizacao as deleteVeiculoUtilizacao,
  clearVeiculosUtilizacao,
  setPage,
} from '../../stores/veiculoUtilizacaoListActions';
import Title from '../../components/Title';
import ButtonAdd from '../../components/ButtonAdd';
import Skeleton from 'react-loading-skeleton';
import Row from '../../components/Row';
import Grid from '../../components/Grid';
import VeiculoUtilizacaoList from './VeiculoUtilizacaoList';
import Pagination from '../../components/Pagination';

export default function VeiculoUtilizacaoListContainer() {
  const [isLoading, setLoading] = useState(true);
  const [isFirst, setFirst] = useState(true);

  const history = useHistory();
  const dispatch = useDispatch();

  const veiculosUtilizacao = useSelector(
    (state) => state.veiculoUtilizacao.list.veiculosUtilizacao
  );

  // pagination
  const page = useSelector(
    (state) => state.veiculoUtilizacao.list.pagination.page
  );
  const itemsPerPage = useSelector(
    (state) => state.veiculoUtilizacao.list.pagination.itemsPerPage
  );
  const totalItems = useSelector(
    (state) => state.veiculoUtilizacao.list.pagination.totalItems
  );

  const farmaciaId = localStorage.getItem('FORMULARIO_FARMACIA_ID');

  useEffect(() => {
    dispatch(fetchAllVeiculosUtilizacao(farmaciaId, { page: 1, itemsPerPage }));

    return () => dispatch(clearVeiculosUtilizacao());
  }, []);

  useEffect(() => {
    if (!isFirst) setLoading(false);
    else setFirst(false);
  }, [veiculosUtilizacao]);

  function removeVeiculoUtilizacao(veiculoUtilizacao) {
    dispatch(deleteVeiculoUtilizacao(farmaciaId, veiculoUtilizacao));
  }

  function editVeiculoUtilizacao(veiculoUtilizacao) {
    history.push(`/veiculosUtilizacao/${veiculoUtilizacao.veu_in_codigo}/edit`);
  }

  function handlePaginate(pgNumber) {
    if (pgNumber === page) return;

    setLoading(true);

    dispatch([
      setPage(pgNumber),
      fetchAllVeiculosUtilizacao(farmaciaId, { page: pgNumber, itemsPerPage }),
    ]);
  }

  return (
    <div className='veiculo-container'>
      <Title
        link='/'
        component={() => (
          <ButtonAdd onClick={() => history.push('/veiculosUtilizacao/new')} />
        )}
      >
        Veic. utilização/Forma farmacêutica
      </Title>

      <Row>
        <Grid cols='12'>
          {isLoading ? (
            <Skeleton count={5} height={30} />
          ) : (
            <VeiculoUtilizacaoList
              veiculosUtilizacao={veiculosUtilizacao}
              onDelete={removeVeiculoUtilizacao}
              onEdit={editVeiculoUtilizacao}
            />
          )}
        </Grid>
      </Row>

      {totalItems === 0 ? null : (
        <Pagination
          page={page}
          itemsPerPage={itemsPerPage}
          totalItems={totalItems}
          paginate={handlePaginate}
        />
      )}
    </div>
  );
}
