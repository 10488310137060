import React, { useRef } from 'react';
import './styles.css';
import PropTypes from 'prop-types';

import Modal from '../../../../components/Modal';
import { Form } from '@unform/web';
import { Input, Checkbox, TextArea } from '../../../../components/Form';

function ModalViewFormula({ id, formula }) {
  const formRef = useRef(null);

  return (
    <Modal
      id={id}
      title={formula?.for_st_nome || 'Informações da fórmula'}
      hideFooter
    >
      <Form ref={formRef}>
        <Input
          name='nome'
          id='nome'
          label='Nome'
          className='form-control upper'
          placeholder='Nome'
          defaultValue={formula?.for_st_nome}
          disabled
        />

        <Checkbox
          name='disponivel'
          id='disponivel'
          label='Disponível'
          placeholder='Disponível'
          checked={formula?.for_bo_disponivel}
          disabled
        />

        <TextArea
          name='recomendacaoUso'
          id='recomendacaoUso'
          label='Recomendação de uso'
          className='form-control upper'
          placeholder='Recomendação de uso'
          defaultValue={formula?.for_st_recomendacaouso}
          disabled
        />

        <Input
          name='posologia'
          id='posologia'
          label='Posologia'
          className='form-control upper'
          placeholder='Posologia'
          defaultValue={formula?.for_st_posologia}
          disabled
        />

        <TextArea
          name='obs'
          id='obs'
          label='Observação'
          className='form-control upper'
          placeholder='Observação'
          defaultValue={formula?.for_st_observacao}
          disabled
        />
      </Form>
    </Modal>
  );
}

ModalViewFormula.propTypes = {
  id: PropTypes.string.isRequired,
  formula: PropTypes.object,
};

export default ModalViewFormula;
