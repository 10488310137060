import React from 'react';
import './styles.css';
import PropTypes from 'prop-types';

import Table, {
  Thead as TableHeader,
  TableRow,
  Tbody,
  TbodyCol as TbCol,
} from '../../../components/Table';
import Button from '../../../components/Button';
import { MdDone } from 'react-icons/md';

function AtivoList({ ativos, onValidate: handleValidate }) {
  return ativos.length > 0 ? (
    <Table card>
      <TableHeader
        dark
        columns={[
          'Descrição',
          'Indicação de uso',
          'Uso',
          'Observação',
          'Validar',
        ]}
      />

      <Tbody>
        {ativos.map((ativo) => (
          <TableRow className='upper' key={ativo.atv_in_codigo}>
            <TbCol alignMiddle label='Descrição'>
              {ativo.atv_st_descricao}
            </TbCol>
            <TbCol alignMiddle label='Indicação de uso'>
              {ativo.atv_st_indicacaouso}
            </TbCol>
            <TbCol alignMiddle label='Uso'>
              {ativo.atv_st_uso}
            </TbCol>
            <TbCol alignMiddle label='Observação'>
              {ativo.atv_st_observacao || ' - '}
            </TbCol>
            <TbCol alignMiddle label='Validar'>
              <Button
                className='ml-1'
                color='info'
                size='sm'
                handleclick={() => handleValidate(ativo)}
              >
                <MdDone size={24} />
              </Button>
            </TbCol>
          </TableRow>
        ))}
      </Tbody>
    </Table>
  ) : (
    <p className='not-found'>Nenhum ativo encontrado</p>
  );
}

AtivoList.propTypes = {
  ativos: PropTypes.array.isRequired,
  onValidate: PropTypes.func,
};

export default AtivoList;
