import React, { useRef, useState, useEffect } from 'react';
import './styles.css';
import PropTypes from 'prop-types';

import Modal from '../../../../components/Modal';
import { Form } from '@unform/web';
import { Input } from '../../../../components/Form';
import ButtonGroup, { Button } from '../../../../components/ButtonGroup';

function ModalViewAtivo({ id, ativo }) {
  const formRef = useRef(null);

  const [topico, setTopico] = useState(false);
  const [oral, setOral] = useState(false);

  useEffect(() => {
    if (ativo?.atv_st_uso === 'TOPICO') setTopico(true);
    if (ativo?.atv_st_uso === 'ORAL') setOral(true);
    if (ativo?.atv_st_uso === 'AMBOS') {
      setTopico(true);
      setOral(true);
    }
  }, [ativo]);

  return (
    <Modal
      id={id}
      hideFooter
      title={ativo?.atv_st_descricao?.toUpperCase() || 'Informações do ativo'}
    >
      <Form ref={formRef}>
        <Input
          label='Descrição'
          name='descricao'
          id='descricao'
          placeholder='Descrição'
          defaultValue={ativo?.atv_st_descricao}
          disabled
        />

        <div className='d-flex justify-content-center mb-4'>
          <ButtonGroup>
            <Button
              name='topico'
              id='TOPICO'
              checked={topico}
              disabled={ativo?.atv_st_uso === 'ORAL'}
              onChange={(e) => setTopico(e.target.checked)}
            >
              TÓPICO
            </Button>
            <Button
              name='oral'
              id='ORAL'
              checked={oral}
              disabled={ativo?.atv_st_uso === 'TOPICO'}
              onChange={(e) => setOral(e.target.checked)}
            >
              ORAL
            </Button>
          </ButtonGroup>
        </div>

        {topico ? (
          <>
            <Input
              label='Concentração mínima (topico)'
              name='concentracaoMinimaTopico'
              id='concentracaoMinimaTopico'
              placeholder='Concentração mínima'
              defaultValue={ativo?.atv_st_concentracaominima_topico}
              className='form-control upper'
              disabled
            />

            <Input
              label='Concentração máxima (topico)'
              name='concentracaoMaximaTopico'
              id='concentracaoMaximaTopico'
              placeholder='Concentração máxima'
              defaultValue={ativo?.atv_st_concentracaomaxima_topico}
              className='form-control upper'
              disabled
            />

            <Input
              label='Concentração usual (topico)'
              name='concentracaoDiariaTopico'
              id='concentracaoDiariaTopico'
              placeholder='Concentração usual'
              defaultValue={ativo?.atv_st_concentracaodiaria_topico}
              className='form-control upper'
              disabled
            />
          </>
        ) : null}

        {oral ? (
          <>
            <Input
              label='Concentração mínima (oral)'
              name='concentracaoMinimaOral'
              id='concentracaoMinimaOral'
              placeholder='Concentração mínima'
              defaultValue={ativo?.atv_st_concentracaominima_oral}
              className='form-control upper'
              disabled
            />

            <Input
              label='Concentração máxima (oral)'
              name='concentracaoMaximaOral'
              id='concentracaoMaximaOral'
              placeholder='Concentração máxima'
              defaultValue={ativo?.atv_st_concentracaomaxima_oral}
              className='form-control upper'
              disabled
            />

            <Input
              label='Concentração usual (oral)'
              name='concentracaoDiariaOral'
              id='concentracaoDiariaOral'
              placeholder='Concentração usual'
              defaultValue={ativo?.atv_st_concentracaodiaria_oral}
              className='form-control upper'
              disabled
            />
          </>
        ) : null}

        <Input
          label='Indicação de uso'
          name='indicacaoUso'
          id='indicacaoUso'
          placeholder='Indicação de uso'
          defaultValue={ativo?.atv_st_indicacaouso}
          className='form-control upper'
          disabled
        />

        <Input
          label='Uso'
          name='uso'
          id='uso'
          placeholder='Uso'
          defaultValue={ativo?.atv_st_uso}
          className='form-control upper'
          disabled
        />
      </Form>
    </Modal>
  );
}

ModalViewAtivo.propTypes = {
  id: PropTypes.string.isRequired,
  ativo: PropTypes.object,
};

export default ModalViewAtivo;
