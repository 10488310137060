import React from 'react';
// import Button from '@bit/ericktrettel.franchini.button'
import Button from '../Button';
import PropTypes from 'prop-types';

const ModalButton = ({ children, target, ...props }) => {
  return (
    <Button {...props} data-toggle='modal' data-target={target}>
      {children}
    </Button>
  );
};

ModalButton.propTypes = {
  target: PropTypes.string.isRequired,
  color: PropTypes.string,
  size: PropTypes.string,
  children: PropTypes.any,
  handleclick: PropTypes.func,
  className: PropTypes.string,
};

ModalButton.defaultProps = {
  color: 'primary',
  size: 'md',
  children: <span>Enviar</span>,
  handleclick: () => {},
  className: '',
};

export default ModalButton;
