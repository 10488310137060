export const setModelos = modelos => {
  return {
    type: 'RECEITA_MODELO_REPRESENTANTE_SET_MODELOS',
    payload: modelos
  }
}

export const clearAll = () => {
  return {
    type: 'RECEITA_MODELO_REPRESENTANTE_CLEAR_ALL',
  }
}
