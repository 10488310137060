import api from './api'
import { toast } from 'react-toastify'

export function findLogsByIndustrializado(farmaciaId, industrializadoId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      const response = await api.get(`/farmacias/${farmaciaId}/medicamentos_industrializados/${industrializadoId}/logs`, {
        headers: { authorization: `Bearer ${token}` }
      });

      return resolve(response.data.data);
    } catch (e) {
      toast.error("Falha ao buscar logs do industrializado");
      return reject(e);
    }
  })
}

export function clearLogs(farmaciaId, industrializadoId, usuarioId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      await api.delete(`/farmacias/${farmaciaId}/medicamentos_industrializados/${industrializadoId}/logs`, {
        headers: { authorization: `Bearer ${token}` },
        params: { usuarioId }
      });

      return resolve();
    } catch (e) {
      toast.error("Falha ao limpar logs do industrializado");
      return reject(e);
    }
  })
}
