import React, { useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { Form } from '@unform/web';
import { Input } from '../../components/Form';
import Button from '../Button';
import { MdClear, MdSearch } from 'react-icons/md';
import Row from '../Row';
import Grid from '../Grid';

function LogSearch({ onSubmit: handleSubmit, onClear: handleClear }) {
  const formRef = useRef(null);

  const [ref, setRef] = useState('');

  function resetForm() {
    setRef('');
  }

  return (
    <div className='search-container'>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Row>
          <Grid cols='12 10'>
            <Input
              id='ref'
              name='ref'
              value={ref}
              onChange={(e) => setRef(e.target.value)}
              placeholder='Pesquise por mensagem'
            />
          </Grid>

          <Grid cols='12 2'>
            <Button color='info' type='submit'>
              <MdSearch size={18} />
            </Button>

            <Button
              type='button'
              className='ml-2'
              color='info'
              handleclick={() => {
                handleClear();
                resetForm();
              }}
            >
              <MdClear size={18} />
            </Button>
          </Grid>
        </Row>
      </Form>

      <hr className='divider' />
    </div>
  );
}

LogSearch.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClear: PropTypes.func,
};

export default LogSearch;
