import api from './api'

export function sendEmail(farmaciaId, usuarioId, receita) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      await api.post(`farmacias/${farmaciaId}/usuarios/${usuarioId}/receitas/email`, receita, {
        headers: { authorization: `Bearer ${token}` }
      });

      return resolve();
    } catch (e) {
      return reject(e);
    }
  })
}
