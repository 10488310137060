import React, { useEffect, useState } from 'react';
import './styles.css';

import Title from '../../components/Title';
import MedicamentoList from './MedicamentoList';
import { useSelector, useDispatch } from 'react-redux';
import Button from '../../components/Button';
import { useHistory } from 'react-router-dom';
import ModalPreImpressao from '../Home/Summary/ModalPreImpressao';
import ModalButton from '../../components/ModalButton';
import {
  removeMedicamento,
  clearMedicamentos,
  setPaciente,
  setReceita,
  setReceberOrcamento,
} from '../../stores/newReceitaActions';
import {
  save as saveReceita,
  saveMedicamentosReceita,
} from '../../services/ReceitaService';
import { findAllByMedico } from '../../services/PacienteService';
import { toast } from 'react-toastify';

export default function ReceitaContainer() {
  const dispatch = useDispatch();
  const medicamentos = useSelector((state) => state.receita.new.medicamentos);
  const [pacientes, setPacientes] = useState([]);

  const farmaciaId = localStorage.getItem('FORMULARIO_FARMACIA_ID');
  const { usu_in_codigo: usuarioId } = useSelector((state) => state.auth.user);

  const history = useHistory();

  useEffect(() => {
    async function getPacientes() {
      console.log('getting pacs 1');
      const pacs = await findAllByMedico(usuarioId);
      setPacientes(pacs);
    }

    getPacientes();
  }, []);

  function removeMedicamentoReceita(medicamento) {
    dispatch(removeMedicamento(medicamento));
  }

  function clearReceita() {
    dispatch(clearMedicamentos());

    goToHome();
  }

  function goToHome() {
    history.push('/');
  }

  function ButtonLimpar() {
    return (
      <Button
        size='sm'
        color='danger'
        className='mr-1'
        handleclick={clearReceita}
      >
        Limpar
      </Button>
    );
  }

  function ButtonAddMedicamentos() {
    return (
      <Button
        size='sm'
        color='secondary'
        className='mr-1'
        handleclick={goToHome}
      >
        Adicionar medicamentos
      </Button>
    );
  }

  function ButtonPreImpressao() {
    return (
      <ModalButton target='#modalPreImpressao' size='sm' color='secondary'>
        Pré-impressão
      </ModalButton>
    );
  }

  async function handleSetPaciente(paciente, receberOrcamentos) {
    if (medicamentos.length === 0) {
      toast.warning('A receita deve ter pelo menos um medicamento');
      return;
    }
    console.log(
      'paciente cod and type: ',
      paciente.pac_in_codigo,
      ' - ',
      typeof paciente.pac_in_codigo
    );
    // save receita
    const receita = await saveReceita(
      farmaciaId,
      usuarioId,
      paciente.pac_in_codigo
    );

    // save medicamentos
    await saveMedicamentosReceita(
      farmaciaId,
      usuarioId,
      receita.rec_in_codigo,
      medicamentos.map((medicamento) => ({
        rfm_st_nomemedicamento: medicamento.nome,
        rfm_st_veiculoutilizacao: medicamento.veiculoUtilizacao,
        rfm_st_recomendacaouso: medicamento.uso,
        rfm_st_posologia: medicamento.posologia,
        rfm_st_observacao: medicamento.observacao,
        rfm_bo_modificado: false,
        rfm_st_tipomedicamento: medicamento.tipo,
        rfm_st_unidade: medicamento.unidade,
        rfm_in_quantidadeveiculo: medicamento.quantidadeVeiculo,
        rfm_bo_qsp: medicamento.qsp,
        ativos:
          medicamento.ativos?.map((ativo) => ({
            atv_in_codigo: ativo.atv_in_codigo || ativo.codigo,
            rfa_st_concentracaoprescrita: ativo.fat_st_concentracaoprescrita,
          })) || [],
      }))
    );

    console.log('=======================');
    console.log(receita);
    console.log(paciente);
    console.log(receberOrcamentos);
    console.log('=======================');
    dispatch([
      setReceita(receita),
      setPaciente(paciente),
      (receberOrcamentos = true ? setReceberOrcamento(true) : null),
    ]);

    history.push('/receita/new');
  }

  async function handleSkipSetPaciente() {
    if (medicamentos.length === 0) {
      toast.warning('A receita deve ter pelo menos um medicamento');
      return;
    }

    // save receita
    const receita = await saveReceita(farmaciaId, usuarioId, {});

    // save medicamentos
    await saveMedicamentosReceita(
      farmaciaId,
      usuarioId,
      receita.rec_in_codigo,
      medicamentos.map((medicamento) => ({
        rfm_st_nomemedicamento: medicamento.nome,
        rfm_st_veiculoutilizacao: medicamento.veiculoUtilizacao,
        rfm_st_recomendacaouso: medicamento.uso,
        rfm_st_posologia: medicamento.posologia,
        rfm_st_observacao: medicamento.observacao,
        rfm_bo_modificado: false,
        rfm_st_tipomedicamento: medicamento.tipo,
        rfm_st_unidade: medicamento.unidade,
        rfm_in_quantidadeveiculo: medicamento.quantidadeVeiculo,
        rfm_bo_qsp: medicamento.qsp,
        ativos:
          medicamento.ativos?.map((ativo) => ({
            atv_in_codigo: ativo.atv_in_codigo || ativo.codigo,
            rfa_st_concentracaoprescrita: ativo.fat_st_concentracaoprescrita,
          })) || [],
      }))
    );

    dispatch(setReceita(receita));

    history.push('/receita/new');
  }

  function handleEditFormula(formula) {
    history.push('/search', {
      step: 'NEW_FORMULA',
      formula: {
        ...formula,
        id_unidade: formula.unidade,
      },
    });
  }

  return (
    <div className='receita-container'>
      <Title
        link='/'
        component={() => [
          <ButtonLimpar key={1} />,
          <ButtonAddMedicamentos key={2} />,
          <ButtonPreImpressao key={3} />,
        ]}
      >
        Sua receita atual
      </Title>

      <MedicamentoList
        medicamentos={medicamentos}
        onRemoveMedicamento={removeMedicamentoReceita}
        onEditFormula={handleEditFormula}
      />
      <ModalPreImpressao
        id='modalPreImpressao'
        onSetPaciente={handleSetPaciente}
        onSkipSetPaciente={handleSkipSetPaciente}
        pacientesList={pacientes}
      />
    </div>
  );
}
