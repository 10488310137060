import React, { useState } from 'react';
import './styles.css';
import PropTypes from 'prop-types';

import Table, {
  Thead,
  TableRow,
  Tbody,
  TbodyCol,
} from '../../../components/Table';
import { insertIf } from '../../../utils/arrays';
import Button from '../../../components/Button';
import ModalButton from '../../../components/ModalButton';
import Modal from '../../../components/Modal';
import {
  MdContentCopy,
  MdSearch,
  MdFiberManualRecord,
  MdCheck,
  MdClear,
} from 'react-icons/md';
import moment from 'moment';

function ReceitaList({
  receitas,
  readonly,
  role,
  onCopyReceita: handleCopyReceita,
  onViewReceita: handleViewReceita,
}) {
  const [receitaEmail, setReceitaEmail] = useState(null);

  function ButtonsMedico({ receita }) {
    return (
      <>
        <Button
          type='button'
          color='warning'
          size='sm'
          title='Copiar a receita'
          className='mr-1'
          handleclick={() => handleCopyReceita(receita)}
        >
          <MdContentCopy size={18} />
        </Button>
        <Button
          type='button'
          color='info'
          size='sm'
          title='Visualizar a receita'
          handleclick={() => handleViewReceita(receita)}
        >
          <MdSearch size={18} />
        </Button>
      </>
    );
  }

  function ButtonReceitaEmailInfo({ emailReceita }) {
    // emailReceita is empty -> did not send e-mail
    if (!emailReceita) {
      return (
        <ModalButton
          target='#modalReceitaEmailInfo'
          type='button'
          color='light'
          size='sm'
          handleclick={() => setReceitaEmail(null)}
        >
          <MdFiberManualRecord size={18} />
        </ModalButton>
      );
    }

    // sucessoenvio is false -> error on sending e-mail
    if (!emailReceita.ere_bo_sucessoenvio) {
      return (
        <ModalButton
          target='#modalReceitaEmailInfo'
          type='button'
          color='light'
          size='sm'
          handleclick={() => setReceitaEmail(emailReceita)}
        >
          <MdClear size={18} color={'#f03434'} />
        </ModalButton>
      );
    }

    // success on sending e-mail
    return (
      <ModalButton
        target='#modalReceitaEmailInfo'
        type='button'
        color='light'
        size='sm'
        handleclick={() => setReceitaEmail(emailReceita)}
      >
        <MdCheck size={18} color={'#00e640'} />
      </ModalButton>
    );
  }

  function getModalTitle() {
    if (!receitaEmail) return 'Envio não autorizado';

    if (!receitaEmail.ere_bo_sucessoenvio) return 'Erro ao enviar e-mail';

    return 'E-mail enviado com sucesso';
  }

  function getModalContent() {
    if (!receitaEmail) {
      return (
        <div>
          <p className='text-warning'>
            O médico/profissional de saúde não autorizou o envio do e-mail.
          </p>
        </div>
      );
    }

    if (!receitaEmail.ere_bo_sucessoenvio) {
      return (
        <div>
          <p>Falha ao enviar e-mail da receita</p>
          <label>Mensagem de erro:</label>{' '}
          <span className='text-danger'>
            {receitaEmail?.ere_st_mensagemerro}
          </span>
        </div>
      );
    }

    return (
      <div>
        <label>Enviado para:</label>{' '}
        <span className='font-weight-bold'>{receitaEmail?.ere_st_to}</span>
        <br />
        <label>De:</label>{' '}
        <span className='font-weight-bold'>{receitaEmail?.ere_st_from}</span>
        <br />
        <br />
        <p className='text-success'>Sucesso ao enviar e-mail da receita!</p>
      </div>
    );
  }

  return receitas.length > 0 ? (
    <>
      <Table hoverable>
        <Thead
          dark
          columns={[
            'Paciente',
            'Data',
            ...insertIf(role === 'G', 'E-mail'),
            ...insertIf(!readonly, 'Ação'),
          ]}
        />

        <Tbody>
          {receitas.map((receita) => (
            <TableRow key={receita.rec_in_codigo}>
              <TbodyCol
                onClick={() => handleViewReceita(receita)}
                alignMiddle
                label='Paciente'
              >
                {receita.paciente?.pac_st_nome || ' - '}
              </TbodyCol>
              <TbodyCol
                onClick={() => handleViewReceita(receita)}
                alignMiddle
                label='Data'
              >
                {moment(receita.createdAt).format('DD/MM/YYYY')}
              </TbodyCol>
              {role === 'G' ? (
                <TbodyCol alignMiddle label='E-mail'>
                  <ButtonReceitaEmailInfo
                    emailReceita={receita.email_receita}
                  />
                </TbodyCol>
              ) : null}
              {readonly ? null : (
                <TbodyCol onClick={() => handleViewReceita(receita)}>
                  {role === 'M' ? <ButtonsMedico receita={receita} /> : null}
                </TbodyCol>
              )}
            </TableRow>
          ))}
        </Tbody>
      </Table>

      <Modal id='modalReceitaEmailInfo' hideFooter title={getModalTitle()}>
        {getModalContent()}
      </Modal>
    </>
  ) : (
    <p>Nenhuma receita encontrada...</p>
  );
}

ReceitaList.propTypes = {
  receitas: PropTypes.array.isRequired,
  readonly: PropTypes.bool,
  role: PropTypes.string,
  onCopyReceita: PropTypes.func,
  onViewReceita: PropTypes.func,
};

export default ReceitaList;
