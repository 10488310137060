const INITIAL_STATE = {
  ativos: [],
  ativosFormulas: [],
  goBackPath: null,
  search: {
    ref: ''
  },
  pagination: {
    page: 1,
    itemsPerPage: 3,
    totalItems: 0
  }
}

export default function linkFormulaAtivoReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'LINK_FORMULA_ATIVO_SET_ATIVOS':
      return {
        ...state,
        ativos: action.payload
      }
    case 'LINK_FORMULA_ATIVO_SET_FORMULAS_ATIVOS':
      return {
        ...state,
        ativosFormulas: action.payload
      }
    case 'LINK_FORMULA_ATIVO_SET_GO_BACK_PATH':
      return {
        ...state,
        goBackPath: action.payload
      }
    case 'LINK_FORMULA_ATIVO_SET_REF':
      return {
        ...state,
        search: {
          ...state.search,
          ref: action.payload
        }
      }
    case 'LINK_FORMULA_ATIVO_SET_PAGE':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.payload
        }
      }
    case 'LINK_FORMULA_ATIVO_SET_ITEMS_PER_PAGE':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          itemsPerPage: action.payload
        }
      }
    case 'LINK_FORMULA_ATIVO_SET_TOTAL_ITEMS':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          totalItems: action.payload
        }
      }
    case 'LINK_FORMULA_ATIVO_CLEAR_SEARCH':
      return {
        ...state,
        search: {
          ref: ''
        }
      }
    case 'LINK_FORMULA_ATIVO_CLEAR_FORMULAS_ATIVOS':
      return {
        ...state,
        ...INITIAL_STATE
      }
    default:
      return state
  }
}
