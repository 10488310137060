import React, { useState, useEffect, useRef, memo } from 'react';
import './styles.css';
import PropTypes from 'prop-types';

import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';
import CloseModalButton from '../../../../components/CloseModalButton';
import { Form } from '@unform/web';
import { Input, Select } from '../../../../components/Form';
import Table, {
  TableRow,
  Tbody,
  TbodyCol,
  Thead,
} from '../../../../components/Table';
import Skeleton from 'react-loading-skeleton';

function renderVeiculos(veiculosUtilizacao, handleSelectVeiculo, setLoading) {
  return veiculosUtilizacao.map((veu) => (
    <TableRow key={veu.veu_in_codigo}>
      <TbodyCol label='Veic. utilização/Forma farmacêutica'>
        {veu.veu_st_descricao}
      </TbodyCol>
      <TbodyCol>
        <Button
          handleclick={() => {
            handleSelectVeiculo(veu);

            // close modal
            document.getElementById('close_modal_button').click();

            // set loading
            setLoading(true);
          }}
          color='info'
          size='sm'
        >
          Selecionar
        </Button>
      </TbodyCol>
    </TableRow>
  ));
}

function NewVeiculoUtilizacao({ onSubmit: handleSubmit, setLoading }) {
  const formRef = useRef(null);

  return (
    <div>
      <p>
        Nenhum veic. utilização/forma farmacêutica encontrado, deseja cadastrar
        um novo veículo?
      </p>

      <Form
        ref={formRef}
        onSubmit={(data) => {
          handleSubmit(data);

          // close modal
          document.getElementById('close_modal_button').click();

          // set loading
          setLoading(true);
        }}
      >
        <Input
          id='veu_st_descricao'
          label='Descrição do veículo'
          name='veu_st_descricao'
          placeholder='Descrição do veículo'
        />

        <Select id='veu_st_tipo' label='Tipo de uso' name='veu_st_tipo'>
          <option value='ORAL'>Oral</option>
          <option value='TOPICO'>Tópico</option>
          <option value='AMBOS'>Ambos</option>
        </Select>

        <Button type='submit' size='sm btn-block'>
          Salvar
        </Button>
      </Form>
    </div>
  );
}

function ModalSearchVeiculosUtilizacaoResults({
  id,
  veiculosUtilizacao,
  onSelectVeiculo: handleSelectVeiculo,
  onSaveNewVeiculoUtilizacao: handleSaveNewVeiculoUtilizacao,
  loading: isLoading,
  setLoading,
}) {
  const [newVeiculo, setNewVeiculo] = useState(false);

  useEffect(() => {
    if (veiculosUtilizacao.length === 0) setNewVeiculo(true);
    else setNewVeiculo(false);
  }, [veiculosUtilizacao]);

  return (
    <Modal id={id} title='Resultados da pesquisa' hideFooter>
      <div>
        {isLoading ? (
          <Skeleton count={3} height={15} />
        ) : newVeiculo ? (
          <NewVeiculoUtilizacao
            onSubmit={handleSaveNewVeiculoUtilizacao}
            setLoading={setLoading}
          />
        ) : (
          <>
            <Table headerSm>
              <Thead
                dark
                columns={['Veic. utilização/Forma farmacêutica', '']}
              />

              <Tbody>
                {renderVeiculos(
                  veiculosUtilizacao,
                  handleSelectVeiculo,
                  setLoading
                )}
              </Tbody>
            </Table>

            <p>
              Não encontrou o que procurava?{' '}
              <Button
                color='link'
                size='sm'
                handleclick={() => setNewVeiculo(true)}
              >
                Novo veículo
              </Button>
            </p>
          </>
        )}

        <CloseModalButton id='close_modal_button' className='d-none'>
          Fechar
        </CloseModalButton>
      </div>
    </Modal>
  );
}

ModalSearchVeiculosUtilizacaoResults.propTypes = {
  id: PropTypes.string.isRequired,
  veiculosUtilizacao: PropTypes.array,
  onSelectVeiculo: PropTypes.func,
  onSaveNewVeiculoUtilizacao: PropTypes.func,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
};

export default memo(ModalSearchVeiculosUtilizacaoResults);
