const INITIAL_STATE = {
  medicos: [],
}

export default function buildUserProfileReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'BUILD_USER_PROFILE_SET_MEDICOS':
      return {
        ...state,
        medicos: action.payload
      }
    case 'BUILD_USER_PROFILE_CLEAR_ALL':
      return {
        ...state,
        ...INITIAL_STATE
      }
    default:
      return state
  }
}
