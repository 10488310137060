import { nop } from './baseActions'
import { findFavoriteAtivos } from '../services/FavoriteAtivosService'
import { unlinkAtivoMedico } from '../services/LinkMedicoToMedicamentoService'

const setAtivos = ativos => {
  return {
    type: 'FAVORITE_ATIVOS_SET_ATIVOS',
    payload: ativos
  }
}

export const setPage = page => {
  return {
    type: 'FAVORITE_ATIVOS_SET_PAGE',
    payload: page
  }
}

export const setItemsPerPage = itemsPerPage => {
  return {
    type: 'FAVORITE_ATIVOS_SET_ITEMS_PER_PAGE',
    payload: itemsPerPage
  }
}

const setTotalItems = totalItems => {
  return {
    type: 'FAVORITE_ATIVOS_SET_TOTAL_ITEMS',
    payload: totalItems
  }
}

export const setRef = ref => {
  return {
    type: 'FAVORITE_ATIVOS_SET_REF',
    payload: ref
  }
}

export const clearAll = () => {
  return {
    type: 'FAVORITE_ATIVOS_CLEAR_ALL'
  }
}

export const fetchMedicoFavoriteAtivos = (farmaciaId, usuarioId, page, itemsPerPage, ref) => {
  return async dispatch => {
    try {
      const response = await findFavoriteAtivos(farmaciaId, usuarioId, page, itemsPerPage, ref);

      return dispatch([
        setAtivos(response.data),
        setTotalItems(response["_metadata"].totalItems)
      ]);
    } catch (e) {
      return dispatch(nop());
    }
  }
}

export const removeLinkAtivoMedico = (farmaciaId, usuarioId, ativoId) => {
  return async (dispatch, getState) => {
    try {
      await unlinkAtivoMedico(farmaciaId, usuarioId, ativoId);

      const { search, pagination } = getState().medico.ativo;

      return dispatch(fetchMedicoFavoriteAtivos(
        farmaciaId,
        usuarioId,
        pagination.page,
        pagination.itemsPerPage,
        search.ref,
      ));
    } catch (e) {
      return dispatch(nop());
    }
  }
}
