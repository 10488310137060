import React, { useState } from 'react';
// import './styles.css'
import PropTypes from 'prop-types';

import Table, {
  Thead as TableHeader,
  TableRow,
  Tbody,
  TbodyCol as TbCol,
} from '../../../components/Table';
import Modal from '../../../components/Modal';
import ModalButton from '../../../components/ModalButton';
import Button from '../../../components/Button';
import { MdDelete, MdEdit } from 'react-icons/md';
import { insertIf } from '../../../utils/arrays';

function EspecialidadeList({
  especialidades,
  onSelectEspecialidade,
  readonly,
  search: searchRef,
}) {
  return especialidades.length > 0 ? (
    <div>
      <Table card>
        {console.log('especs list: ', especialidades)}
        <TableHeader
          dark
          columns={['Especialidade', ...insertIf(!readonly, 'Ação')]}
        />
        <Tbody>
          {especialidades.map((especialidade) =>
            especialidade.esp_st_descricao
              .toUpperCase()
              .includes(searchRef.toUpperCase()) ? (
              <TableRow className='upper' key={especialidade.esp_in_codigo}>
                <TbCol alignMiddle label='Descrição'>
                  {especialidade.esp_st_descricao}
                </TbCol>
                {readonly ? null : (
                  <TbCol alignMiddle label='Ação'>
                    <Button
                      className='ml-1'
                      color='success'
                      size='sm'
                      handleclick={() => onSelectEspecialidade(especialidade)}
                    >
                      Selecionar especialidade
                    </Button>
                  </TbCol>
                )}
              </TableRow>
            ) : null
          )}
        </Tbody>
      </Table>
    </div>
  ) : (
    <p className='not-found'>Nenhuma especialidade encontrada</p>
  );
}

EspecialidadeList.propTypes = {
  especialidades: PropTypes.array.isRequired,
  readonly: PropTypes.bool,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
};

EspecialidadeList.defaultProps = {
  readonly: false,
};

export default EspecialidadeList;
