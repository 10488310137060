import React from 'react'
import PropTypes from 'prop-types'

import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

/*
  [{ role: 'M', component: MedicoComponent }, { role: 'G', component: GestorComponent }, { role: 'R', component: RepresentanteComponent }]
*/

function ConditionalRoleBasedRoute({ conditions, ...rest }) {
  const auth = useSelector(state => state.auth);
  const userRole = auth.user?.usu_ch_tipo

  if (!auth.isAuthenticated) return <Redirect to="/login" />

  // user role does not match any of the conditions
  if (!conditions.some(c => c.role === userRole)) return <Redirect to="/login" />

  const Component = conditions.find(c => c.role === userRole).component;

  return (
    <Route {...rest} render={props => (
      <Component {...props} />
    )} />
  )
}

ConditionalRoleBasedRoute.propTypes = {
  conditions: PropTypes.arrayOf(PropTypes.shape({
    role: PropTypes.string,
    component: PropTypes.element,
  }))
}

export default ConditionalRoleBasedRoute;
