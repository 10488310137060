const INITIAL_STATE = {
  formulas: [],
  pagination: {
    page: 1,
    itemsPerPage: 15,
    totalItems: 0
  },
  search: {
    ref: ''
  }
}

export default function favoriteFormulasReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'FAVORITE_FORMULAS_SET_FORMULAS':
      return {
        ...state,
        formulas: action.payload
      }
    case 'FAVORITE_FORMULAS_SET_PAGE':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.payload
        }
      }
    case 'FAVORITE_FORMULAS_SET_ITEMS_PER_PAGE':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          itemsPerPage: action.payload
        }
      }
    case 'FAVORITE_FORMULAS_SET_TOTAL_ITEMS':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          totalItems: action.payload
        }
      }
    case 'FAVORITE_FORMULAS_SET_REF':
      return {
        ...state,
        search: {
          ...state.search,
          ref: action.payload
        }
      }
    case 'FAVORITE_FORMULAS_CLEAR_ALL':
      return {
        ...state,
        ...INITIAL_STATE
      }
    default:
      return state
  }
}
