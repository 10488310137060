export const setIndustrializados = industrializados => {
  return {
    type: 'REPRESENTANTE_INDUSTRIALIZADOS_LIST_SET_INDUSTRIALIZADOS',
    payload: industrializados
  }
}

export const clearAll = () => {
  return {
    type: 'REPRESENTANTE_INDUSTRIALIZADOS_LIST_CLEAR_ALL',
  }
}

export const setPage = page => {
  return {
    type: 'REPRESENTANTE_INDUSTRIALIZADOS_LIST_PAGINATION_SET_PAGE',
    payload: page
  }
}

export const setItemsPerPage = itemsPerPage => {
  return {
    type: 'REPRESENTANTE_INDUSTRIALIZADOS_LIST_PAGINATION_SET_ITEMS_PER_PAGE',
    payload: itemsPerPage
  }
}

export const setTotalItems = totalItems => {
  return {
    type: 'REPRESENTANTE_INDUSTRIALIZADOS_LIST_PAGINATION_SET_TOTAL_ITEMS',
    payload: totalItems
  }
}
