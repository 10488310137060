import React from 'react'
import './styles.css'
import PropTypes from 'prop-types'

import { MdFavorite, MdFavoriteBorder } from 'react-icons/md'

function ButtonFavorite({ isFavorite,
  onFavorite: handleFavorite,
  onUnfavorite: handleUnfavorite,
  className,
  size }) {
  return isFavorite ? (
    <MdFavorite size={size || 24} color="#f03434"
      className={`mr-1 cursor-pointer ${className}`}
      onClick={handleUnfavorite} />
  ) : (
      <MdFavoriteBorder size={size || 24} color="#f03434"
        className={`mr-1 cursor-pointer ${className}`}
        onClick={handleFavorite} />
    )
}

ButtonFavorite.propTypes = {
  isFavorite: PropTypes.bool,
  onFavorite: PropTypes.func,
  onUnfavorite: PropTypes.func,
  className: PropTypes.string,
  size: PropTypes.number,
}

export default ButtonFavorite;
