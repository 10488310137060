import { nop } from './baseActions'
import { findIndustrializados } from '../services/FindMedicamentosByMedicoService'
import { findAllFavoriteIndustrializados } from '../services/FavoriteIndustrializadosService'
import { unlinkIndustrializadoMedico } from '../services/LinkMedicoToMedicamentoService'

const setIndustrializados = industrializados => {
  return {
    type: 'MEDICOS_INDUSTRIALIZADOS_SET_INDUSTRIALIZADOS',
    payload: industrializados
  }
}

const setFavorites = favorites => {
  return {
    type: 'MEDICOS_INDUSTRIALIZADOS_SET_FAVORITES',
    payload: favorites
  }
}

export const setPage = page => {
  return {
    type: 'MEDICOS_INDUSTRIALIZADOS_SET_PAGE',
    payload: page
  }
}

export const setItemsPerPage = itemsPerPage => {
  return {
    type: 'MEDICOS_INDUSTRIALIZADOS_SET_ITEMS_PER_PAGE',
    payload: itemsPerPage
  }
}

export const setTotalItems = totalItems => {
  return {
    type: 'MEDICOS_INDUSTRIALIZADOS_SET_TOTAL_ITEMS',
    payload: totalItems
  }
}

export const setRef = ref => {
  return {
    type: 'MEDICOS_INDUSTRIALIZADOS_SET_REF',
    payload: ref
  }
}

export const setStartsWith = startsWith => {
  return {
    type: 'MEDICOS_INDUSTRIALIZADOS_SET_STARTS_WITH',
    payload: startsWith
  }
}

export const clearIndustrializados = () => {
  return {
    type: 'MEDICOS_INDUSTRIALIZADOS_CLEAR_INDUSTRIALIZADOS'
  }
}

export const fetchAllIndustrializadosByMedico = (farmaciaId, usuarioId, ref, page, itemsPerPage, startsWith) => {
  return async dispatch => {
    try {
      const response = await findIndustrializados(farmaciaId, usuarioId, ref, page, itemsPerPage, startsWith);

      return dispatch([
        setIndustrializados(response.data),
        setTotalItems(response["_metadata"].totalItems)
      ]);
    } catch (e) {
      return dispatch(nop());
    }
  }
}

export const fetchFavoriteIndustrializados = (farmaciaId, usuarioId) => {
  return async dispatch => {
    try {
      const favorites = await findAllFavoriteIndustrializados(farmaciaId, usuarioId);

      return dispatch(setFavorites(favorites));
    } catch (e) {
      return dispatch(nop());
    }
  }
}

export const removeLinkMedicamentoMedico = (farmaciaId, usuarioId, industrializadoId) => {
  return async (dispatch, getState) => {
    try {
      await unlinkIndustrializadoMedico(farmaciaId, usuarioId, industrializadoId);

      const { search, pagination } = getState().medico.industrializado;

      return dispatch(fetchAllIndustrializadosByMedico(
        farmaciaId,
        usuarioId,
        search.ref,
        pagination.page,
        pagination.itemsPerPage
      ));
    } catch (e) {
      return dispatch(nop());
    }
  }
}
