import React, { useState, useEffect } from 'react'
import './styles.css'

import MedicamentoList from './MedicamentoList'
import Title from '../../components/Title'
import Skeleton from 'react-loading-skeleton'
import { fetchIndustrializadosPendentes, clearIndustrializados } from '../../stores/medicamentosPendentesActions'
import { useSelector, useDispatch } from 'react-redux'
import { validateIndustrializado } from '../../services/MedicamentosPendentesService'

export default function MedicamentosPendentesListContainer() {
  const [isFirst, setFirst] = useState(true);
  const [isLoading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const medicamentos = useSelector(state => state.medicamentosIndustrializados.pendentes.industrializados);

  const farmaciaId = localStorage.getItem('FORMULARIO_FARMACIA_ID');

  // fetch all industrializados pendentes
  useEffect(() => {
    dispatch(fetchIndustrializadosPendentes(farmaciaId));

    return () => dispatch(clearIndustrializados());
  }, []);

  // set loading
  useEffect(() => {
    if (isFirst) setFirst(false);
    else setLoading(false);
  }, [medicamentos]);

  async function handleValidate(medicamentoIndustrializado) {
    setLoading(true);

    await validateIndustrializado(farmaciaId, medicamentoIndustrializado.med_in_codigo);

    dispatch(fetchIndustrializadosPendentes(farmaciaId));
  }

  return (
    <div className="ativo-container">
      <Title link="/medicamentos">Industrializados pendentes</Title>

      {isLoading
        ? <Skeleton count={5} height={30} />
        : <MedicamentoList medicamentos={medicamentos}
          onValidate={handleValidate} />}
    </div>
  )
}
