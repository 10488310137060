import React, { useState, useEffect } from 'react';
import './styles.css';

import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import {
  fetchMedicoFavoriteAtivos,
  removeLinkAtivoMedico,
  setPage,
  clearAll,
  setRef,
} from '../../stores/favoriteAtivosActions';
import AtivoList from './AtivoList';
import Skeleton from 'react-loading-skeleton';
import Title from '../../components/Title';
import { unfavoriteAtivo } from '../../services/FavoriteAtivosService';
import { setLoading as loading } from '../../stores/loadingActions';
import Pagination from '../../components/Pagination';
import Search from '../AtivoListContainer/AtivoSearch';
import Row from '../../components/Row';
import Grid from '../../components/Grid';
import { addAtivoReceita } from '../../stores/searchMedicamentosActions';
import { v4 as uuidv4 } from 'uuid';

export default function AtivoFavoritesListContainer() {
  const [isLoading, setLoading] = useState(true);
  const [isFirst, setFirst] = useState(true);

  const { medicoId } = useParams();
  const history = useHistory();

  const dispatch = useDispatch();
  const ativos = useSelector((state) => state.ativo.favorites.ativos);

  const farmaciaId = localStorage.getItem('FORMULARIO_FARMACIA_ID');

  // pagination
  const page = useSelector((state) => state.ativo.favorites.pagination.page);
  const itemsPerPage = useSelector(
    (state) => state.ativo.favorites.pagination.itemsPerPage
  );
  const totalItems = useSelector(
    (state) => state.ativo.favorites.pagination.totalItems
  );

  // search
  const ref = useSelector((state) => state.ativo.favorites.search.ref);

  useEffect(() => {
    dispatch(
      fetchMedicoFavoriteAtivos(farmaciaId, medicoId, 1, itemsPerPage, '')
    );

    return () => dispatch(clearAll());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFirst) setFirst(false);
    else setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ativos]);

  async function handleUnfavorite(ativo) {
    dispatch(loading(true));

    await unfavoriteAtivo(farmaciaId, medicoId, ativo.atv_in_codigo);

    dispatch([
      fetchMedicoFavoriteAtivos(farmaciaId, medicoId, page, itemsPerPage, ref),
      loading(false),
    ]);
  }

  function handlePaginate(pgNumber) {
    if (pgNumber === page) return;

    setLoading(true);

    dispatch([
      fetchMedicoFavoriteAtivos(
        farmaciaId,
        medicoId,
        pgNumber,
        itemsPerPage,
        ref
      ),
      setPage(pgNumber),
    ]);
  }

  function handleSearch({ ref }) {
    setLoading(true);

    dispatch([
      fetchMedicoFavoriteAtivos(farmaciaId, medicoId, 1, itemsPerPage, ref),
      setPage(1),
      setRef(ref),
    ]);
  }

  function handleClearSearch() {
    setLoading(true);

    dispatch([
      fetchMedicoFavoriteAtivos(farmaciaId, medicoId, 1, itemsPerPage, ''),
      setPage(1),
      setRef(''),
    ]);
  }

  function handleRemoveAtivo(ativo) {
    setLoading(true);

    dispatch(removeLinkAtivoMedico(farmaciaId, medicoId, ativo.atv_in_codigo));
  }

  function handleAddAtivoReceita(ativo, uso, concentracaoPrescrita) {
    const concentracaoMinima =
      uso === 'ORAL'
        ? ativo.atv_st_concentracaominima_oral
        : ativo.atv_st_concentracaominima_topico;
    const concentracaoMaxima =
      uso === 'ORAL'
        ? ativo.atv_st_concentracaomaxima_oral
        : ativo.atv_st_concentracaomaxima_topico;
    const concentracaoDiaria =
      uso === 'ORAL'
        ? ativo.atv_st_concentracaodiaria_oral
        : ativo.atv_st_concentracaodiaria_topico;

    dispatch(
      addAtivoReceita({
        fat_in_codigo: uuidv4(),
        fat_st_concentracaominima: concentracaoMinima,
        fat_st_concentracaomaxima: concentracaoMaxima,
        fat_st_concentracaodiaria: concentracaoDiaria,
        fat_st_concentracaoprescrita: concentracaoPrescrita,
        atv_in_codigo: ativo.atv_in_codigo,
        ativo: {
          ...ativo,
          atv_st_uso: uso,
        },
      })
    );

    history.push('/search', { step: 'NEW_FORMULA' });
  }

  return (
    <div className='ativo-container'>
      <Title link='/ativos'>Ativos favoritos</Title>

      <Row>
        <Grid cols='12'>
          <Search onSubmit={handleSearch} onClear={handleClearSearch} />
        </Grid>
      </Row>

      <Row>
        <Grid cols='12'>
          {isLoading ? (
            <Skeleton count={5} height={35} />
          ) : (
            <AtivoList
              ativos={ativos}
              onUnfavorite={handleUnfavorite}
              onAddAtivoReceita={handleAddAtivoReceita}
              onRemoveAtivo={handleRemoveAtivo}
            />
          )}
        </Grid>
      </Row>

      {ativos.length > 0 ? (
        <Row>
          <Grid cols='12'>
            <Pagination
              page={page}
              itemsPerPage={itemsPerPage}
              totalItems={totalItems}
              paginate={handlePaginate}
            />
          </Grid>
        </Row>
      ) : null}
    </div>
  );
}
