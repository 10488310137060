import React from 'react'
import './styles.css'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

function UserCard({ user }) {
  return (
    <div className="dropdown mr-1 d-flex align-items-center">
      <button type="button"
        className="btn btn-light btn-sm dropdown-toggle mr-3"
        id="dropdownMenuOffset"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        data-offset="10,20">
        {user.usu_st_nome}
      </button>

      <div className="dropdown-menu" aria-labelledby="dropdownMenuOffset">
        <Link className="dropdown-item" to={`/usuarios/${user.usu_in_codigo}/newPassword`}>
          Alterar senha
        </Link>
      </div>
    </div>
  )
}

UserCard.propTypes = {
  user: PropTypes.object.isRequired
}

export default UserCard;
