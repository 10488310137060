import React, { useRef, useState, useEffect } from 'react';
import './styles.css';

import Button from '../../../../components/Button';
import CloseModalButton from '../../../../components/CloseModalButton';
import Modal from '../../../../components/Modal';
import { Form } from '@unform/web';
import { Input } from '../../../../components/Form';

import PropTypes from 'prop-types';

function ModalEdit({
  formulaAtivo,
  onSubmit: handleSubmit,
  onCancelarClick: handleCancelarClick,
}) {
  const formRef = useRef(null);
  const [concentracaoPrescrita, setConcentracaoPrescrita] = useState('');

  useEffect(() => {
    if (formulaAtivo && formulaAtivo.fat_st_concentracaoprescrita) {
      setConcentracaoPrescrita(formulaAtivo.fat_st_concentracaoprescrita);
    }
  }, [formulaAtivo]);

  return (
    <Modal
      id='modalEdit'
      title={formulaAtivo?.ativo?.atv_st_descricao}
      btnText='Salvar'
      handleCancelarClick={handleCancelarClick}
      hideFooter
    >
      <Form id='formularioquenaofunciona' ref={formRef} onSubmit={handleSubmit}>
        <Input
          id='ativoId'
          name='ativoId'
          value={formulaAtivo?.atv_in_codigo || ''}
          disabled
          hidden
        />

        <Input
          id='doseMinima'
          label='Concentração mínima'
          name='doseMinima'
          value={formulaAtivo?.fat_st_concentracaominima || ''}
          disabled
        />

        <Input
          id='doseMaxima'
          label='Concentração máxima'
          name='doseMaxima'
          value={formulaAtivo?.fat_st_concentracaomaxima || ''}
          disabled
        />

        <Input
          id='doseDiaria'
          label='Concentração usual'
          name='doseDiaria'
          value={formulaAtivo?.fat_st_concentracaodiaria || ''}
          disabled
        />

        <Input
          id='dosePrescrita'
          label='Concentração prescrita'
          name='dosePrescrita'
          value={concentracaoPrescrita}
          onChange={(e) => setConcentracaoPrescrita(e.target.value)}
        />

        <Button
          type='submit'
          handleclick={() =>
            document.getElementById('closeModalButton').click()
          }
          size='sm btn-block'
        >
          Enviar
        </Button>

        {/* NOTE: for some reason, when the button has the property 'data-dismiss' it cannot submit */}
        <CloseModalButton id='closeModalButton' style={{ display: 'none' }}>
          Fechar modal
        </CloseModalButton>
      </Form>
    </Modal>
  );
}

ModalEdit.propTypes = {
  formulaAtivo: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancelarClick: PropTypes.func,
};

export default ModalEdit;
