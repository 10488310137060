import React, { useState, useEffect } from 'react';
import './styles.css';

import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import {
  fetchMedicoFavoriteIndustrializados,
  removeLinkMedicamentoMedico,
  setPage,
  setRef,
  clearAll,
} from '../../stores/favoriteIndustrializadosActions';
import MedicamentoList from './MedicamentoList';
import Skeleton from 'react-loading-skeleton';
import Title from '../../components/Title';
import { unfavoriteIndustrializado } from '../../services/FavoriteIndustrializadosService';
import { setLoading as loading } from '../../stores/loadingActions';
import Pagination from '../../components/Pagination';
import Search from '../MedicamentoListContainer/MedicamentoSearch';
import Row from '../../components/Row';
import Grid from '../../components/Grid';
import { addMedicamento } from '../../stores/newReceitaActions';

export default function MedicamentoFavoritesListContainer() {
  const [isLoading, setLoading] = useState(true);
  const [isFirst, setFirst] = useState(true);

  const { medicoId } = useParams();
  const history = useHistory();

  const dispatch = useDispatch();
  const industrializados = useSelector(
    (state) => state.medicamentosIndustrializados.favorites.industrializados
  );

  // pagination
  const page = useSelector(
    (state) => state.medicamentosIndustrializados.favorites.pagination.page
  );
  const itemsPerPage = useSelector(
    (state) =>
      state.medicamentosIndustrializados.favorites.pagination.itemsPerPage
  );
  const totalItems = useSelector(
    (state) =>
      state.medicamentosIndustrializados.favorites.pagination.totalItems
  );

  // search
  const ref = useSelector(
    (state) => state.medicamentosIndustrializados.favorites.search.ref
  );

  const farmaciaId = localStorage.getItem('FORMULARIO_FARMACIA_ID');

  const receita = useSelector((state) => state.receita.new.medicamentos);

  useEffect(() => {
    dispatch(
      fetchMedicoFavoriteIndustrializados(
        farmaciaId,
        medicoId,
        1,
        itemsPerPage,
        ''
      )
    );

    return () => dispatch(clearAll());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFirst) setFirst(false);
    else setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [industrializados]);

  async function handleUnfavorite(industrializado) {
    dispatch(loading(true));

    await unfavoriteIndustrializado(
      farmaciaId,
      medicoId,
      industrializado.med_in_codigo
    );

    dispatch([
      loading(false),
      fetchMedicoFavoriteIndustrializados(
        farmaciaId,
        medicoId,
        page,
        itemsPerPage,
        ref
      ),
    ]);
  }

  function handlePaginate(pgNumber) {
    if (pgNumber === page) return;

    setLoading(true);

    dispatch([
      fetchMedicoFavoriteIndustrializados(
        farmaciaId,
        medicoId,
        pgNumber,
        itemsPerPage,
        ref
      ),
      setPage(pgNumber),
    ]);
  }

  function handleSearch({ ref }) {
    setLoading(true);

    dispatch([
      fetchMedicoFavoriteIndustrializados(
        farmaciaId,
        medicoId,
        1,
        itemsPerPage,
        ref
      ),
      setPage(1),
      setRef(ref),
    ]);
  }

  function handleClearSearch() {
    setLoading(true);

    dispatch([
      fetchMedicoFavoriteIndustrializados(
        farmaciaId,
        medicoId,
        1,
        itemsPerPage,
        ''
      ),
      setPage(1),
      setRef(''),
    ]);
  }

  function handleRemoveLinkMedicamento(industrializado) {
    setLoading(true);

    dispatch(
      removeLinkMedicamentoMedico(
        farmaciaId,
        medicoId,
        industrializado.med_in_codigo
      )
    );
  }
  function handleAddIndustrializadoReceita(industrializado) {
    dispatch(
      addMedicamento({
        codigo: industrializado.med_in_codigo,
        nome: industrializado.med_st_nome,
        uso: industrializado.med_st_recomendacaouso,
        fabricante: industrializado.med_st_fabricante,
        posologia: industrializado.med_st_posologia,
        observacao: industrializado.med_st_observacao,
        imagem: industrializado.med_st_imagem,
        tipo: 'INDUSTRIALIZADO',
        resumo: `${industrializado.med_st_nome}\n${industrializado.med_st_posologia}`,
      })
    );

    history.push('/receita');
  }

  return (
    <div className='medicamento-container'>
      <Title link='/medicamentos'>Industrializados favoritos</Title>

      <Row>
        <Grid cols='12'>
          <Search onSearch={handleSearch} onClear={handleClearSearch} />
        </Grid>
      </Row>

      <Row>
        <Grid cols='12'>
          {isLoading ? (
            <Skeleton count={5} height={35} />
          ) : (
            <MedicamentoList
              industrializados={industrializados}
              receita={receita}
              onUnfavorite={handleUnfavorite}
              onAddIndustrializadoReceita={handleAddIndustrializadoReceita}
              onRemoveLinkMedicamento={handleRemoveLinkMedicamento}
            />
          )}
        </Grid>
      </Row>

      {industrializados.length > 0 ? (
        <Row>
          <Grid cols='12'>
            <Pagination
              page={page}
              itemsPerPage={itemsPerPage}
              totalItems={totalItems}
              paginate={handlePaginate}
            />
          </Grid>
        </Row>
      ) : null}
    </div>
  );
}
