import { nop } from './baseActions'
import { findById } from '../services/UsuarioService'
import { toast } from 'react-toastify'

const setUser = user => {
  return {
    type: 'NEW_PASSWORD_SET_USER',
    payload: user
  }
}

export const clearUser = () => {
  return {
    type: 'NEW_PASSWORD_CLEAR_USER'
  }
}

export const fetchUserById = id => {
  return async dispatch => {
    try {
      const user = await findById(id);

      return dispatch(setUser(user));
    } catch (e) {
      toast.error('Erro ao buscar usuário');
      return dispatch(nop());
    }
  }
}
