import React from 'react'
import PropTypes from 'prop-types'

import Table, { Thead as TableHeader, TableRow, Tbody, TbodyCol as TbCol } from '../../../components/Table'

function FormulaList({ formulas }) {
  return formulas.length > 0 ? (
    <Table card>
      <TableHeader dark
        columns={[
          'Nome',
          'Posologia',
          'Observação']} />

      <Tbody>
        {formulas.map(formula => {
          return (
            <TableRow className="upper" key={formula.for_in_codigo}>
              <TbCol alignMiddle>{formula.for_st_nome}</TbCol>
              <TbCol alignMiddle label="Posologia">{formula.for_st_posologia || '-'}</TbCol>
              <TbCol alignMiddle label="Observação">{formula.for_st_observacao || '-'}</TbCol>
            </TableRow>
          )
        })}
      </Tbody>
    </Table>
  ) : <p className="not-found">Nenhuma fórmula encontrada</p>
}

FormulaList.propTypes = {
  formulas: PropTypes.array.isRequired
}

export default FormulaList;