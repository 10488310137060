import React from 'react';
import './styles.css';
import PropTypes from 'prop-types';

import Card, {
  CardBody,
  CardContainer,
  CardTitle,
} from '../../../components/Card';
import Button from '../../../components/Button';
import { isEqual } from '../../../utils/objects';
import Table, {
  Thead as TableHeader,   
  TableRow,
  Tbody,
  TbodyCol as TbCol
} from '../../../components/Table';

function ButtonSelect({ medico, handleSelectMedico }) {
  return (
    <Button
      color='info'
      handleclick={() => handleSelectMedico(medico)}
      size='sm'
    >
      Selecionar
    </Button>
  );
}

function ButtonUnselect({ medico, handleUnselect }) {
  return (
    <Button color='danger' size='sm' handleclick={() => handleUnselect(medico)}>
      Remover
    </Button>
  );
}

function MedicoList({
  medicos,
  selected,
  onUnselect: handleUnselect,
  onSelect: handleSelectMedico,
}) {
  return (
    <Table card>
      <TableHeader 
        dark
        columns={['Descrição', 'Ação']}
      />
      <Tbody>
        {medicos.length === 0 ? (
          <p>Nenhum médico encontrado.</p>
        ) : (
          medicos.map(medico => {
            return (
            <TableRow key={medico.usu_in_codigo}>
              <TbCol label='Descrição'>
                {medico.usu_st_nome}
              </TbCol>
              <TbCol label='Ação'>
                {selected?.find((m) => isEqual(m, medico)) ? (
                  handleUnselect ? (
                    <ButtonUnselect
                      handleUnselect={handleUnselect}
                      medico={medico}
                    />
                  ) : (
                    <small className='text-muted'>Selecionado</small>
                  )
                ) : (
                  <ButtonSelect
                    medico={medico}
                    handleSelectMedico={handleSelectMedico}
                  />
                )}
              </TbCol>
            </TableRow>
          )})
        )}
      </Tbody>
    </Table>
    // troca de card button para table
    // <CardContainer>
    //   {medicos.length === 0 ? (
    //     <p>Nenhum médico encontrado.</p>
    //   ) : (
    //     medicos.map((medico) => (
    //       <Card className='align-items-center' key={medico.usu_in_codigo}>
    //         <CardTitle>{medico.usu_st_nome}</CardTitle>

    //         <CardBody>
    //           {selected?.find((m) => isEqual(m, medico)) ? (
    //             handleUnselect ? (
    //               <ButtonUnselect
    //                 handleUnselect={handleUnselect}
    //                 medico={medico}
    //               />
    //             ) : (
    //               <small className='text-muted'>Selecionado</small>
    //             )
    //           ) : (
    //             <ButtonSelect
    //               medico={medico}
    //               handleSelectMedico={handleSelectMedico}
    //             />
    //           )}
    //         </CardBody>
    //       </Card>
    //     ))
    //   )}
    // </CardContainer>
  );
}

MedicoList.propTypes = {
  medicos: PropTypes.array.isRequired,
  onSelect: PropTypes.func,
  onUnselect: PropTypes.func,
  selected: PropTypes.array,
};

export default MedicoList;
