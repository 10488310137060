import { combineReducers } from 'redux';

import authReducer from './authReducer';
import usuarioListReducer from './usuarioListReducer';
import usuarioEditReducer from './usuarioEditReducer';
import newPasswordReducer from './newPasswordReducer';
import loadingReducer from './loadingReducer';
import ativoListReducer from './ativoListReducer';
import ativoEditReducer from './ativoEditReducer';
import veiculoUtilizacaoListReducer from './veiculoUtilizacaoListReducer';
import veiculoUtilizacaoEditReducer from './veiculoUtilizacaoEditReducer';
import linkAtivoVeiculoReducer from './linkAtivoVeiculoReducer';
import formulaListReducer from './formulaListReducer';
import formulaEditReducer from './formulaEditReducer';
import linkAtivoFormulaReducer from './linkAtivoFormulaReducer';
import medicamentoListReducer from './medicamentoListReducer';
import medicamentoEditReducer from './medicamentoEditReducer';
import linkFormulaAtivoReducer from './linkFormulaAtivoReducer';
import searchMedicamentosReducer from './searchMedicamentosReducer';
import newReceitaReducer from './newReceitaReducer';
import ativosPendentesReducer from './ativosPendentesReducer';
import formulasPendentesReducer from './formulasPendentesReducer';
import medicamentosPendentesReducer from './medicamentosPendentesReducer';
import medicoAtivosListReducer from './medicoAtivosListReducer';
import medicoFormulasListReducer from './medicoFormulasListReducer';
import medicoIndustrializadosListReducer from './medicoIndustrializadosListReducer';
import linkMedicoToMedicamentosReducer from './linkMedicoToMedicamentosReducer';
import unidadeListReducer from './unidadeListReducer';
import unidadeEditReducer from './unidadeEditReducer';
import newFormulaReducer from './newFormulaReducer';
import receitaListReducer from './receitaListReducer';
import receitaPageReducer from './receitaPageReducer';
import medicoReceitaListReducer from './medicoReceitaListReducer';
import favoriteAtivosReducer from './favoriteAtivosReducer';
import favoriteFormulasReducer from './favoriteFormulasReducer';
import favoriteIndustrializadosReducer from './favoriteIndustrializadosReducer';
import newUsuarioReducer from './newUsuarioReducer';
import medicoFormulaEditReducer from './medicoFormulaEditReducer';
import navbarReducer from './navbarReducer';
import receitaModeloListReducer from './receitaModeloListReducer';
import receitaModeloEditReducer from './receitaModeloEditReducer';
import buildUserProfileReducer from './buildUserProfileReducer';
import linkRepresentanteMedicoReducer from './linkRepresentanteMedicoReducer';
import representanteAtivoListReducer from './representanteAtivoListReducer';
import representanteFormulaListReducer from './representanteFormulaListReducer';
import receitaModeloRepresentanteReducer from './receitaModeloRepresentanteReducer';
import receitaRepresentanteListReducer from './receitaRepresentanteListReducer';
import loggerMedicamentosListReducer from './loggerMedicamentosListReducer';
import loggerMedicamentosMedicoListReducer from './loggerMedicamentosMedicoListReducer';
import loggerMedicamentosRepresentanteListReducer from './loggerMedicamentosRepresentanteListReducer';
import representanteIndustrializadosListReducer from './representanteIndustrializadosListReducer';
import viaReducer from './viaReducer';
import especialidadeListReducer from './especialidadeListReducer';
import especialidadeEditReducer from './especialidadeEditReducer';
import linkEspecialidadeToMedicoReducer from './linkEspecialidadeToMedicoReducer';
import linkEspecialidadeToMedicamentosReducer from './linkEspecialidadeToMedicamentosReducer';

export default combineReducers({
  auth: authReducer,
  navbar: navbarReducer,
  usuario: combineReducers({
    list: usuarioListReducer,
    edit: usuarioEditReducer,
    new: newUsuarioReducer,
  }),
  vias: viaReducer,
  newPassword: newPasswordReducer,
  loading: loadingReducer,
  ativo: combineReducers({
    list: ativoListReducer,
    edit: ativoEditReducer,
    pendentes: ativosPendentesReducer,
    favorites: favoriteAtivosReducer,
  }),
  veiculoUtilizacao: combineReducers({
    list: veiculoUtilizacaoListReducer,
    edit: veiculoUtilizacaoEditReducer,
  }),
  linkAtivoVeiculoUtilizcao: linkAtivoVeiculoReducer,
  formula: combineReducers({
    new: newFormulaReducer,
    list: formulaListReducer,
    edit: formulaEditReducer,
    pendentes: formulasPendentesReducer,
    favorites: favoriteFormulasReducer,
  }),
  linkAtivoFormula: linkAtivoFormulaReducer,
  medicamentosIndustrializados: combineReducers({
    list: medicamentoListReducer,
    edit: medicamentoEditReducer,
    pendentes: medicamentosPendentesReducer,
    favorites: favoriteIndustrializadosReducer,
  }),
  linkFormulaAtivo: linkFormulaAtivoReducer,
  home: searchMedicamentosReducer,
  receita: combineReducers({
    new: newReceitaReducer,
    list: receitaListReducer,
    page: receitaPageReducer,
  }),
  medico: combineReducers({
    ativo: medicoAtivosListReducer,
    formula: combineReducers({
      list: medicoFormulasListReducer,
      edit: medicoFormulaEditReducer,
    }),
    industrializado: medicoIndustrializadosListReducer,
    receita: medicoReceitaListReducer,
    logger: combineReducers({
      medicamentos: loggerMedicamentosMedicoListReducer,
    }),
  }),
  linkMedicoMedicamento: linkMedicoToMedicamentosReducer,
  unidade: combineReducers({
    list: unidadeListReducer,
    edit: unidadeEditReducer,
  }),
  receitaModelo: combineReducers({
    list: receitaModeloListReducer,
    edit: receitaModeloEditReducer,
  }),
  buildUserProfile: buildUserProfileReducer,
  linkRepresentanteMedico: linkRepresentanteMedicoReducer,
  representante: combineReducers({
    ativo: representanteAtivoListReducer,
    formula: representanteFormulaListReducer,
    industrializado: representanteIndustrializadosListReducer,
    receitaModelo: combineReducers({
      list: receitaModeloRepresentanteReducer,
    }),
    receita: combineReducers({
      list: receitaRepresentanteListReducer,
    }),
    logger: combineReducers({
      medicamentos: loggerMedicamentosRepresentanteListReducer,
    }),
  }),
  logger: combineReducers({
    medicamentos: loggerMedicamentosListReducer,
  }),
  especialidades: combineReducers({
    list: especialidadeListReducer,
    edit: especialidadeEditReducer,
  }),
  linkEspecialidadeMedico: linkEspecialidadeToMedicoReducer,
  linkEspecialidadeMedicamentos: linkEspecialidadeToMedicamentosReducer,
});
