import { nop } from './baseActions'
import { findAllRepresentantesByFarmacia } from '../services/RepresentanteService'
import { findAllMedicosByFarmacia } from '../services/FindMedicosService'
import { findAllLinksRepresentante } from '../services/LinkMedicoToRepresentanteService'
import { setLoading } from './loadingActions'

const setRepresentantes = representantes => {
  return {
    type: 'LINK_REPRESENTANTE_MEDICO_SET_REPRESENTANTES',
    payload: representantes
  }
}

const setMedicos = medicos => {
  return {
    type: 'LINK_REPRESENTANTE_MEDICO_SET_MEDICOS',
    payload: medicos
  }
}

const setLinksRepresentante = links => {
  return {
    type: 'LINK_REPRESENTANTE_MEDICO_SET_LINKS_REPRESENTANTE',
    payload: links
  }
}

export const setRepresentante = representante => {
  return {
    type: 'LINK_REPRESENTANTE_MEDICO_SET_REPRESENTANTE',
    payload: representante
  }
}

export const clearAll = () => {
  return {
    type: 'LINK_REPRESENTANTE_MEDICO_CLEAR_ALL'
  }
}

export const fetchRepresentantesByFarmacia = farmaciaId => {
  return async dispatch => {
    try {
      const representantes = await findAllRepresentantesByFarmacia(farmaciaId);

      return dispatch(setRepresentantes(representantes));
    } catch (e) {
      return dispatch(nop());
    }
  }
}

export const fetchMedicosByFarmacia = farmaciaId => {
  return async dispatch => {
    try {
      const medicos = await findAllMedicosByFarmacia(farmaciaId);

      return dispatch(setMedicos(medicos));
    } catch (e) {
      return dispatch(nop());
    }
  }
}

export const fetchLinksRepresentanteMedico = (farmaciaId, representanteId) => {
  return async dispatch => {
    try {
      const links = await findAllLinksRepresentante(farmaciaId, representanteId);

      return dispatch([
        setLinksRepresentante(links),
        setLoading(false)
      ]);
    } catch (e) {
      return dispatch(nop());
    }
  }
}
