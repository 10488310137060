import { nop } from './baseActions'
import { findById } from '../services/ReceitaModeloService'

export const clearAll = () => {
  return {
    type: 'RECEITA_MODELO_EDIT_CLEAR_ALL'
  }
}

const setModelo = modelo => {
  return {
    type: 'RECEITA_MODELO_EDIT_SET_MODELO',
    payload: modelo
  }
}

export const fetchById = (farmaciaId, modeloId) => {
  return async dispatch => {
    try {
      const modelo = await findById(farmaciaId, modeloId);

      return dispatch(setModelo(modelo));
    } catch (e) {
      return dispatch(nop());
    }
  }
}
