const INITIAL_STATE = {
  logs: [],
  search: {
    ref: '',
  },
  pagination: {
    page: 1,
    itemsPerPage: 15,
    totalItems: 0,
  },
}

export default function loggerMedicamentosListReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'LOGS_LIST_SET_LOGS':
      return {
        ...state,
        logs: action.payload
      }
    case 'LOGS_LIST_SEARCH_SET_REF':
      return {
        ...state,
        search: {
          ref: action.payload,
        }
      }
    case 'LOGS_LIST_PAGINATION_SET_PAGE':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.payload,
        }
      }
    case 'LOGS_LIST_PAGINATION_SET_ITEMS_PER_PAGE':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          itemsPerPage: action.payload,
        }
      }
    case 'LOGS_LIST_PAGINATION_SET_TOTAL_ITEMS':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          totalItems: action.payload,
        }
      }
    case 'LOGS_LIST_CLEAR_ALL':
      return {
        ...state,
        ...INITIAL_STATE
      }
    default:
      return state
  }
}
