import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  setModelos,
  clearAll,
} from '../../stores/receitaModeloRepresentanteActions';
import Title from '../../components/Title';
import ButtonAdd from '../../components/ButtonAdd';
import Skeleton from 'react-loading-skeleton';
import Row from '../../components/Row';
import Grid from '../../components/Grid';
import ReceitaModeloList from '../ReceitaModeloListContainer/ReceitaModeloList';
import { findAllModelosReceitaByRepresentante } from '../../services/ReceitaModeloRepresentanteService';
import { remove as deleteModelo } from '../../services/ReceitaModeloService';

export default function ReceitaModeloRepresentanteListContainer() {
  const [isFirst, setFirst] = useState(true);
  const [isLoading, setLoading] = useState(true);

  const history = useHistory();
  const dispatch = useDispatch();

  const modelos = useSelector(
    (state) => state.representante.receitaModelo.list.modelos
  );

  const { usu_in_codigo: representanteId } = useSelector(
    (state) => state.auth.user
  );

  const farmaciaId = localStorage.getItem('FORMULARIO_FARMACIA_ID');

  async function getModelosRepresentante() {
    const modelos = await findAllModelosReceitaByRepresentante(
      farmaciaId,
      representanteId
    );

    dispatch(setModelos(modelos));
  }

  useEffect(() => {
    getModelosRepresentante();

    return () => dispatch(clearAll());
  }, []);

  useEffect(() => {
    if (!isFirst) setLoading(false);
    else setFirst(false);
  }, [modelos]);

  async function removeModelo(modelo) {
    await deleteModelo(farmaciaId, modelo);

    getModelosRepresentante();
  }

  function editModelo(modelo) {
    history.push(`/modelos_receita/${modelo.rem_in_codigo}/edit`);
  }

  return (
    <div className='receita-container'>
      <Title
        link='/'
        component={() => (
          <ButtonAdd onClick={() => history.push('/modelos_receita/new')} />
        )}
      >
        Listagem de modelos
      </Title>

      <Row>
        <Grid cols='12'>
          {isLoading ? (
            <Skeleton count={5} height={30} />
          ) : (
            <ReceitaModeloList
              modelos={modelos}
              onDelete={removeModelo}
              onEdit={editModelo}
            />
          )}
        </Grid>
      </Row>
    </div>
  );
}
