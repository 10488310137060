import React from 'react'
import './styles.css'

import PropTypes from 'prop-types'

function Card({ children, className }) {
  let classes = 'card mb-3 p-2 ';
  if (className) classes = classes.concat(className);

  return (
    <div className={classes}>
      {children}
    </div>
  )
}

Card.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string
}

export default Card;
