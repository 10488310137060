import api from './api'
import { toast } from 'react-toastify';

export function findAll(farmaciaId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      const response = await api.get(`farmacias/${farmaciaId}/modelos_receitas`, {
        headers: { authorization: `Bearer ${token}` }
      });

      return resolve(response.data.data);
    } catch (e) {
      toast.error("Falha ao buscar os modelos da receita");
      return reject(e);
    }
  })
}

export function findById(farmaciaId, modeloId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      const response = await api.get(`farmacias/${farmaciaId}/modelos_receitas/${modeloId}`, {
        headers: { authorization: `Bearer ${token}` }
      });

      return resolve(response.data.data);
    } catch (e) {
      toast.error("Falha ao buscar o modelo da receita");
      return reject(e);
    }
  })
}

export function save(farmaciaId, modelo) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      const response = await api.post(`farmacias/${farmaciaId}/modelos_receitas`, modelo, {
        headers: { authorization: `Bearer ${token}` }
      });

      return resolve(response.data.data);
    } catch (e) {
      toast.error("Falha ao salvar o modelo da receita");
      return reject(e);
    }
  })
}

export function update(farmaciaId, modeloId, modelo) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      await api.put(`farmacias/${farmaciaId}/modelos_receitas/${modeloId}`, modelo, {
        headers: { authorization: `Bearer ${token}` }
      });

      return resolve();
    } catch (e) {
      toast.error("Falha ao salvar o modelo da receita");
      return reject(e);
    }
  })
}

export function remove(farmaciaId, modelo) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      await api.delete(`farmacias/${farmaciaId}/modelos_receitas/${modelo.rem_in_codigo}`, {
        headers: { authorization: `Bearer ${token}` }
      });

      return resolve();
    } catch (e) {
      toast.error("Falha ao remover o modelo da receita");
      return reject(e);
    }
  })
}
