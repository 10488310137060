import api from './api'
import { toast } from 'react-toastify'

function getURLByRole(role, farmaciaId, usuarioId) {
  if (role === 'M') return `farmacias/${farmaciaId}/usuarios/${usuarioId}/search/medicamentos`;
  if (role === 'G') return `farmacias/${farmaciaId}/search/medicamentos`;
  if (role === 'R') return `farmacias/${farmaciaId}/representantes/${usuarioId}/search/medicamentos`;
}

function getParamsByRole(role, searchFilters, paginationFilters) {
  if (role === 'M') return {
    ref: searchFilters.ref,
    favorites: searchFilters.favorites,
    tipos: `${searchFilters.tipos.map(tipo => `${tipo}`)}`,
    startsWith: searchFilters.startsWith,

    page: paginationFilters.page,
    itemsPerPage: paginationFilters.itemsPerPage,
  };

  if (role === 'G') return {
    ref: searchFilters.ref,
    tipo: searchFilters.tipo,
    activeOnly: searchFilters.activeOnly,
    excludeExclusive: searchFilters.excludeExclusive,

    page: paginationFilters.page,
    itemsPerPage: paginationFilters.itemsPerPage
  };

  if (role === 'R') return {
    ref: searchFilters.ref,

    page: paginationFilters.page,
    itemsPerPage: paginationFilters.itemsPerPage
  };
}

// NOTE: tipos -> tipos de medicamento (formula, ativo, industrializado). tipo -> tipo de uso (oral, topico)
export function searchMedicamentos(farmaciaId, searchFilters, paginationFilters, usuarioId = null, role) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      const url = getURLByRole(role, farmaciaId, usuarioId);

      const params = getParamsByRole(role, searchFilters, paginationFilters);

      console.log("url service: ", url)
      const response = await api.get(url, {
        headers: { authorization: `Bearer ${token}` },
        params
      });

      return resolve(response.data);
    } catch (e) {
      toast.error("Falha ao buscar medicamentos");
      return reject(e);
    }
  })
}
