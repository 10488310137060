import React from 'react'
import './styles.css'
import PropTypes from 'prop-types'

function Container({ children }) {
  return (
    <div className="btn-group btn-group-toggle">
      {children}
    </div>
  )
}

Container.propTypes = {
  children: PropTypes.any,
}

export default Container;
