import React, { useState, useRef, useEffect } from 'react';
import './styles.css';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

import { Form } from '@unform/web';
import { Input, TextArea, Select, Checkbox } from '../../../components/Form';
import Button from '../../../components/Button';
import Row from '../../../components/Row';
import Grid from '../../../components/Grid';
import AtivoList from '../../FormulaEdit/AtivoList';

function NewFormulaWizard({
  vias,
  formula,
  ativos,
  unidades,
  showNomeFormula,
  showComposicao,
  onSubmit: handleSubmit,
  onRemoveAtivo: handleRemoveAtivo,
  onGoBack: handleGoBack,
  onGoForward: handleGoForward,
  onSearchVeiculosUtilizacao: searchVeiculosUtilizacao,
  veiculoUtilizacao: selectedVeiculoUtilizacao,
  clearFormulaVeiculo,
}) {
  const formRef = useRef(null);

  const [nome, setNome] = useState('');
  const [resumo, setResumo] = useState('');
  const [veiculoUtilizacao, setVeiculoUtilizacao] = useState('');
  const [qsp, setQSP] = useState(false);
  const [unidade, setUnidade] = useState('');
  const [posologia, setPosologia] = useState('');
  const [observacao, setObservacao] = useState('');

  const [currentVia, setCurrentVia] = useState(null);
  const [formulaAtivos, setFormulaAtivos] = useState([]);

  const [isNomeFormula, setNomeFormula] = useState(showNomeFormula);
  const [isComposicao, setComposicao] = useState(showComposicao);

  useEffect(() => {
    if (formula) {
      setNome(formula.nome || '');
      setQSP(formula.qsp === true || formula.qsp == 'true' || false);
      setUnidade(formula.id_unidade || '');
      setPosologia(formula.posologia || '');
      setObservacao(formula.observacao || '');
      setResumo(formula.resumo || '');
      setCurrentVia(formula.viaId ? formula.viaId : -1);
    }
  }, [formula]);

  useEffect(() => {
    // to load default formula veiculo utilizacao
    // user search a veiculo utilizacao
    if (selectedVeiculoUtilizacao) {
      setVeiculoUtilizacao(selectedVeiculoUtilizacao);
      document.getElementById('veiculoUtilizacao').value =
        selectedVeiculoUtilizacao.veu_st_descricao;
    }
  }, [selectedVeiculoUtilizacao]);

  useEffect(() => {
    setFormulaAtivos(ativos);
  }, [ativos]);

  function getViaName(viaId) {
    for (var i = 0; i < vias.length; i++) {
      if (vias[i].via_in_codigo == viaId) {
        return vias[i].via_st_nome;
      }
    }
  }

  function buildResumo(
    nome,
    ativos,
    veiculoUtilizacao,
    quantidadeVeiculo,
    unidade,
    posologia,
    qsp,
    obs,
    currentVia
  ) {
    console.log('bulding resumo');
    let resumo = '';
    const element = document.getElementById('vias');
    let selectedVia = '';
    if (currentVia > 0) {
      selectedVia = getViaName(currentVia);
      console.log('via name: ', selectedVia);
    }
    // only attach formula's name to resumo, if this option is checked
    if (isNomeFormula) resumo += nome + '\n';

    // only attach ativos to resumo, if this option is checked
    if (isComposicao) {
      ativos.forEach((fa) => {
        resumo +=
          fa.ativo.atv_st_descricao +
          ' - ' +
          fa.fat_st_concentracaoprescrita +
          '\n';
      });
    }

    resumo += veiculoUtilizacao + ' ';

    if (qsp) resumo += 'q.s.p. ';

    resumo += quantidadeVeiculo + unidade + '\n';
    resumo += posologia + '\n';
    resumo += obs;
    resumo += selectedVia;

    setResumo(resumo);
  }

  function gerarResumo() {
    const quantidadeVeiculo = document.getElementById('quantidadeVeiculo')
      .value;
    const veiculoUtilizacao = document.getElementById('veiculoUtilizacao')
      .value;

    const unidadeElement = document.getElementById('unidade');
    const unidadeText =
      unidadeElement.options[unidadeElement.selectedIndex].text;

    buildResumo(
      nome || '',
      formulaAtivos,
      veiculoUtilizacao,
      quantidadeVeiculo,
      unidadeText,
      posologia,
      qsp,
      observacao,
      currentVia
    );
  }

  function updateConcentracao(fat_in_codigo, fat_st_concentracaoprescrita) {
    const updatedAtivos = formulaAtivos.map((ativo) => {
      if (ativo.fat_in_codigo === fat_in_codigo) {
        return {
          ...ativo,
          fat_st_concentracaoprescrita: fat_st_concentracaoprescrita,
        };
      }

      return ativo;
    });

    setFormulaAtivos(updatedAtivos);
  }

  function handleSearchVeiculoUtilizacao(e) {
    if (e.key === 'Enter') {
      e.preventDefault();

      // search veiculoUtilizacao
      searchVeiculosUtilizacao(e.target.value);
    }
  }

  return (
    <div className='formula-container'>
      {formula?.tipo === 'FORMULA' || formula === null ? (
        <Form
          ref={formRef}
          onSubmit={(data) =>
            handleSubmit({
              ...data,
              veiculoUtilizacao: veiculoUtilizacao.veu_in_codigo,
              ativos: formulaAtivos,
              tipo: 'FORMULA',
              via: currentVia,
            })
          }
        >
          {/* ativos vinculados */}
          <AtivoList
            formulaAtivos={formulaAtivos}
            modalRemove={false}
            onRemoveLink={handleRemoveAtivo}
            onUpdateConcentracao={updateConcentracao}
            onLinkButtonClick={handleGoForward}
            hideViewButton
          />

          <Input
            id='veiculoUtilizacao'
            name='veiculoUtilizacao'
            label='Veic. utilização/Forma farmacêutica'
            className='form-control upper'
            placeholder='Pesquise por descrição'
            onKeyPress={handleSearchVeiculoUtilizacao}
          />

          <Row>
            <Grid cols='12 6'>
              <Checkbox
                type='checkbox'
                id='qsp'
                label='Veículo com QSP'
                name='qsp'
                value={qsp}
                checked={qsp}
                onChange={(e) => setQSP(e.target.checked)}
              />
            </Grid>
            <Grid cols='12 6'>
              <Row>
                <Grid cols='12 6'>
                  <Input
                    type='number'
                    id='quantidadeVeiculo'
                    name='quantidadeVeiculo'
                    defaultValue={formula?.quantidadeVeiculo || 0}
                    required
                  />
                </Grid>
                <Grid cols='12 6'>
                  <Select
                    id='unidade'
                    name='unidade'
                    className='form-control upper'
                    value={unidade}
                    onChange={(e) => setUnidade(e.target.value)}
                  >
                    <option value={-1} disabled>
                      Selecione...
                    </option>
                    {unidades.map(
                      ({ uni_in_codigo: codigo, uni_st_sigla: sigla }) => (
                        <option key={codigo} value={codigo}>
                          {sigla}
                        </option>
                      )
                    )}
                  </Select>
                </Grid>
              </Row>
            </Grid>
          </Row>

          <Row>
            <Grid cols='12 6'>
              <label>Via *</label>
              <Select
                id='vias'
                name='vias'
                className='form-control upper'
                value={currentVia}
                onChange={(e) => setCurrentVia(e.target.value)}
              >
                <option value={-1} disabled>
                  Selecione...
                </option>
                {vias.map((via) => (
                  <option key={via.via_in_codigo} value={via.via_in_codigo}>
                    {via.via_st_nome}
                  </option>
                ))}
              </Select>
            </Grid>
          </Row>

          <Input
            id='posologia'
            name='posologia'
            label='Posologia'
            className='form-control upper'
            placeholder='Posologia'
            value={posologia}
            onChange={(e) => setPosologia(e.target.value)}
          />

          <TextArea
            id='observacao'
            name='observacao'
            label='Observação'
            className='form-control upper'
            placeholder='Observação'
            value={observacao}
            onChange={(e) => setObservacao(e.target.value)}
          />

          <Input
            id='nome'
            name='nome'
            label='Nome'
            placeholder='Nome da fórmula'
            value={nome}
            onChange={(e) => setNome(e.target.value)}
          />

          <Checkbox
            id='isNomeFormula'
            name='isNomeFormula'
            label='Nome da fórmula'
            checked={isNomeFormula}
            value={isNomeFormula}
            onChange={(e) => setNomeFormula(e.target.checked)}
          />

          <Checkbox
            id='isComposicao'
            name='isComposicao'
            label='Composição'
            checked={isComposicao}
            value={isComposicao}
            onChange={(e) => setComposicao(e.target.checked)}
          />

          <Row>
            <Grid cols='12'>
              <Button
                type='button'
                size='sm btn-block'
                handleclick={gerarResumo}
              >
                Gerar resumo
              </Button>
            </Grid>
          </Row>

          <TextArea
            id='resumo'
            name='resumo'
            label='Resumo'
            className='form-control upper h250px'
            autoCorrect='off'
            spellCheck='false'
            value={resumo}
            onChange={(e) => setResumo(e.target.value)}
          />

          <div className='text-right'>
            <Button
              type='button'
              color='secondary'
              className='mr-1'
              handleclick={() => {
                handleGoBack();
                clearFormulaVeiculo();
              }}
            >
              Cancelar
            </Button>

            <Button
              type='submit'
              handleclick={() => {
                gerarResumo();
                setTimeout(() => {
                  clearFormulaVeiculo();
                }, 500);
              }}
            >
              Vincular
            </Button>
          </div>
        </Form>
      ) : (
        <p>Medicamento inválido</p>
      )}
    </div>
  );
}

NewFormulaWizard.propTypes = {
  /** Formula to be added to receita */
  formula: PropTypes.object,
  /** All the unidades */
  unidades: PropTypes.array,
  /** Ativos linked to formula */
  ativos: PropTypes.array,
  /** Option to show nome formula on resumo */
  showNomeFormula: PropTypes.bool,
  /** Option to show composição formula on resumo */
  showComposicao: PropTypes.bool,
  /** Function to add medicamento to receita */
  onSubmit: PropTypes.func,
  /** Function to remove ativo from receita */
  onRemoveAtivo: PropTypes.func,
  /** Function to go back to the result list page */
  onGoBack: PropTypes.func,
  /** Function to go to the next step */
  onGoForward: PropTypes.func,
  /** Selected veículo utilização (search results) */
  veiculoUtilizacao: PropTypes.object,
  /** Clear formulaVeiculoUtilizacao value on redux */
  clearFormulaVeiculo: PropTypes.func,
};

export default NewFormulaWizard;
