import React from 'react'
import PropTypes from 'prop-types'
import PageNumber from './pageNumber'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'

function Pagination({ page, itemsPerPage, totalItems, paginate }) {
  const numberOfPages = Math.ceil(totalItems / itemsPerPage);

  const prevNumbers = page - 3 >= 1 ? [1] : null;
  const nextNumbers = page + 3 < numberOfPages ? [numberOfPages] : null;

  let from = 1;
  let to = numberOfPages;

  if (prevNumbers && prevNumbers.length > 0) {
    from = page - 1;
  }

  if (nextNumbers && nextNumbers.length > 0) {
    to = page + 1;
  }

  let pageNumbers = [];
  for (let i = from; i <= to; i++) {
    pageNumbers = [...pageNumbers, i];
  }

  return (
    <nav>
      <ul className="pagination justify-content-center">
        <PageNumber className={page === 1 ? 'disabled' : null}
          paginate={() => paginate(page - 1)}>
          <MdChevronLeft size={16} />
        </PageNumber>

        {prevNumbers ? (
          <>
            {prevNumbers.map(number =>
              <PageNumber key={number}
                page={number}
                paginate={() => paginate(number)}
                className={page === number ? 'active' : null} />
            )}

            <PageNumber disabled={true}>
              ...
            </PageNumber>
          </>
        ) : null}

        {pageNumbers.map(number =>
          <PageNumber key={number}
            page={number}
            paginate={() => paginate(number)}
            className={page === number ? 'active' : null} />
        )}

        {nextNumbers ? (
          <>
            <PageNumber disabled={true}>
              ...
            </PageNumber>

            {nextNumbers.map(number =>
              <PageNumber key={number}
                page={number}
                paginate={() => paginate(number)}
                className={page === number ? 'active' : null} />
            )}
          </>
        ) : null}

        <PageNumber className={page === numberOfPages ? 'disabled' : null}
          paginate={() => paginate(page + 1)}>
          <MdChevronRight size={16} />
        </PageNumber>
      </ul>
    </nav>
  )
}

Pagination.propTypes = {
  /** Current page */
  page: PropTypes.number,
  /** Number of items per page */
  itemsPerPage: PropTypes.number,
  /** Total of items */
  totalItems: PropTypes.number.isRequired,
  /** Function to render page */
  paginate: PropTypes.func.isRequired,
}

Pagination.defaultProps = {
  page: 1,
  itemsPerPage: 15,
  previousLabel: 'Previous',
  nextLabel: 'Next'
}

export default Pagination;
