import React, { useRef, useState, useEffect } from 'react';
import './styles.css';

import { useSelector, useDispatch } from 'react-redux';
import { setLoading } from '../../stores/loadingActions';
import { useHistory } from 'react-router-dom';

import Title from '../../components/Title';
import { Form } from '@unform/web';
import {
  Input,
  Select,
  Checkbox,
  TextArea as Textarea,
} from '../../components/Form';
import Button from '../../components/Button';
import * as Yup from 'yup';
import MaskInput from '../../components/Form/maskInput';
import { save } from '../../services/UsuarioService';
import {
  fetchAllModelosReceita,
  clearAll,
} from '../../stores/newUsuarioActions';
import {
  ImageSelectorContainer,
  ImageSelectorItem,
} from '../../components/ImageSelector';
import { toast } from 'react-toastify';
import Row from '../../components/Row';
import Grid from '../../components/Grid';

export default function NewUsuarioForm() {
  const formRef = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();

  const [tipo, setTipo] = useState('G');
  const [atendeConvenio, setAtendeConvenio] = useState(false);
  const [composicaoFormula, setComposicaoFormula] = useState(false);
  const [nomeFormula, setNomeFormula] = useState(false);
  const [pacienteRecebeOrcamentos, setPacienteRecebeOrcamentos] = useState(
    false
  );

  const idFarmacia = useSelector((state) => state.auth.user.far_in_codigo);
  const farmacia = useSelector((state) => state.auth.farmacia);

  // modelos receita
  const receitaModelos = useSelector(
    (state) => state.usuario.new.receitaModelos
  );

  const [selectedModelo, setSelectedModelo] = useState(null);

  const [redirectTo, setRedirectTo] = useState('USER_LIST');

  useEffect(() => {
    // find receita layouts
    dispatch(fetchAllModelosReceita(idFarmacia));

    return () => dispatch(clearAll());
  }, []);

  async function handleSubmit(data, { reset }) {
    try {
      // set loading
      dispatch(setLoading(true));

      // validation
      const schema = Yup.object().shape({
        usu_st_nome: Yup.string().required('O nome é obrigatório'),
        usu_st_email: Yup.string()
          .email('Digite um e-mail válido')
          .required('O e-mail é obrigatório'),
        usu_st_senha: Yup.string()
          .required('A senha é obrigatória')
          .min(6, 'A senha deve ter no mínimo 6 caracteres'),
        confirmPassword: Yup.string()
          .required('Confirme sua senha')
          .oneOf([Yup.ref('usu_st_senha'), null], 'As senhas devem ser iguais')
          .min(6, 'A senha deve ter no mínimo 6 caracteres'),
        usu_st_telefone: Yup.string(),
        usu_ch_tipo: Yup.string().required().length(1),
        usu_st_crm: Yup.string(),
        usu_st_especialidade: Yup.string(),
        usu_st_rua: Yup.string(),
        usu_st_bairro: Yup.string(),
        usu_st_cidade: Yup.string(),
        usu_st_estado: Yup.string(),
        usu_st_cep: Yup.string(),
        usu_bo_atendeconvenio: Yup.bool(),
        usu_bo_composicaoformula: Yup.bool(),
        usu_bo_nomeformula: Yup.bool(),
        usu_bo_pacienterecebeorcamento: Yup.bool(),
        usu_st_convenios: Yup.string(),
        usu_st_emailreceita: Yup.string(),
        usu_st_observacao: Yup.string(),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      // check if usuario selected an modelo for receita (medico)
      if (data.usu_ch_tipo === 'M' && !selectedModelo) {
        toast.warning('Selecione um modelo para suas receitas');
        dispatch(setLoading(false));
        return;
      }

      // destruct all keys of data to user but confirmPassword
      let { confirmPassword, ...user } = data;

      // put key far_in_codigo to user
      user = {
        ...user,
        far_in_codigo: idFarmacia,
        rem_in_codigo: selectedModelo?.rem_in_codigo,
      };

      // save
      const savedUser = await save(user);

      const usuarioId = savedUser.usu_in_codigo;

      dispatch(setLoading(false));

      reset();

      // clear errors
      formRef.current.setErrors({});

      if (redirectTo === 'USER_LIST') history.push('/usuarios');

      if (redirectTo === 'BUILD_PROFILE')
        history.push(`/usuarios/${usuarioId}/montar_perfil`);
    } catch (e) {
      if (e instanceof Yup.ValidationError) {
        const errorMessages = {};

        e.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });

        formRef.current.setErrors(errorMessages);
      }

      dispatch(setLoading(false));
    }
  }

  return (
    <div className='usuario-container'>
      <Title link='/usuarios'>Cadastro de novo usuário</Title>

      <div className='form-container'>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <fieldset>
            <legend>Dados cadastrais</legend>

            <Input
              id='usu_st_nome'
              label='* Nome'
              name='usu_st_nome'
              required
            />

            <Input
              id='usu_st_email'
              label='* E-mail'
              name='usu_st_email'
              type='email'
              placeholder='exemplo@mail.com'
              required
            />

            <Input
              id='usu_st_senha'
              label='* Senha'
              name='usu_st_senha'
              type='password'
              placeholder='******'
              required
            >
              <small className='text-muted'>
                A senha deve conter no mínimo 6 caracteres
              </small>
            </Input>

            <Input
              id='confirmPassword'
              label='* Confirme sua senha'
              name='confirmPassword'
              type='password'
              placeholder='******'
              required
            />

            <MaskInput
              id='usu_st_telefone'
              mask='(99) 99999 9999'
              maskChar=' '
              label='Telefone'
              name='usu_st_telefone'
              placeholder='(99) 99999 9999'
            />

            <Select
              id='usu_ch_tipo'
              label='Tipo'
              name='usu_ch_tipo'
              defaultValue='G'
              onChange={(e) => setTipo(e.target.value)}
            >
              <option value='G'>Gestor</option>
              <option value='M'>Prescritor</option>
              <option value='R'>Representante</option>
            </Select>
          </fieldset>

          {tipo === 'M' ? (
            <>
              <Input
                id='usu_st_crm'
                label='* Código do conselho de registro'
                name='usu_st_crm'
                required
              />

              <Input
                id='usu_st_especialidade'
                label='* Especialidade'
                name='usu_st_especialidade'
                required
              />

              <fieldset>
                <legend>Endereço do consultório</legend>

                <Input
                  id='usu_st_rua'
                  label='* Rua'
                  name='usu_st_rua'
                  required
                />

                <Input
                  id='usu_st_bairro'
                  label='* Bairro'
                  name='usu_st_bairro'
                  required
                />

                <Input
                  id='usu_st_cidade'
                  label='* Cidade'
                  name='usu_st_cidade'
                  required
                />

                <Select
                  id='usu_st_estado'
                  name='usu_st_estado'
                  defaultValue='SP'
                  label='Estado'
                >
                  <option value='AC'>Acre</option>
                  <option value='AL'>Alagoas</option>
                  <option value='AP'>Amapá</option>
                  <option value='AM'>Amazonas</option>
                  <option value='BA'>Bahia</option>
                  <option value='CE'>Ceará</option>
                  <option value='DF'>Distrito Federal</option>
                  <option value='ES'>Espírito Santo</option>
                  <option value='GO'>Goiás</option>
                  <option value='MA'>Maranhão</option>
                  <option value='MT'>Mato Grosso</option>
                  <option value='MS'>Mato Grosso do Sul</option>
                  <option value='MG'>Minas Gerais</option>
                  <option value='PA'>Pará</option>
                  <option value='PB'>Paraíba</option>
                  <option value='PR'>Paraná</option>
                  <option value='PE'>Pernambuco</option>
                  <option value='PI'>Piauí</option>
                  <option value='RJ'>Rio de Janeiro</option>
                  <option value='RN'>Rio Grande do Norte</option>
                  <option value='RS'>Rio Grande do Sul</option>
                  <option value='RO'>Rondônia</option>
                  <option value='RR'>Roraima</option>
                  <option value='SC'>Santa Catarina</option>
                  <option value='SP'>São Paulo</option>
                  <option value='SE'>Sergipe</option>
                  <option value='TO'>Tocantins</option>
                </Select>

                <MaskInput
                  id='usu_st_cep'
                  label='* CEP'
                  name='usu_st_cep'
                  mask='99999-999'
                  placeholder='99999-999'
                  maskChar=' '
                  required
                />
              </fieldset>

              <fieldset>
                <legend>Convênio</legend>

                <Checkbox
                  id='usu_bo_atendeconvenio'
                  label='Atende convênio?'
                  name='usu_bo_atendeconvenio'
                  value={atendeConvenio}
                  checked={atendeConvenio}
                  onChange={(e) => setAtendeConvenio(e.target.checked)}
                />

                {atendeConvenio ? (
                  <Textarea
                    id='usu_st_convenios'
                    label='Quais convênios?'
                    name='usu_st_convenios'
                    placeholder='UNIMED, Intermédica, Bradesco, Amil...'
                    required
                  ></Textarea>
                ) : null}
              </fieldset>

              <fieldset>
                <legend>Receita</legend>

                {/* componente seletor de imagens */}
                <ImageSelectorContainer>
                  {receitaModelos.map((modelo) => (
                    <ImageSelectorItem
                      key={modelo.rem_in_codigo}
                      selected={
                        selectedModelo?.rem_in_codigo === modelo.rem_in_codigo
                      }
                      onSelect={() => setSelectedModelo(modelo)}
                      label={modelo.rem_st_descricao}
                      imagePath={modelo.rem_st_caminhoimagem}
                    />
                  ))}
                </ImageSelectorContainer>

                <br />

                <Checkbox
                  id='usu_bo_composicaoformula'
                  label='Permitir exibir a composição da fórmula no resumo da receita?'
                  name='usu_bo_composicaoformula'
                  value={composicaoFormula}
                  checked={composicaoFormula}
                  onChange={(e) => setComposicaoFormula(e.target.checked)}
                />

                <Checkbox
                  id='usu_bo_nomeformula'
                  label='Permitir exibir o nome da fórmula no resumo da receita?'
                  name='usu_bo_nomeformula'
                  value={nomeFormula}
                  checked={nomeFormula}
                  onChange={(e) => setNomeFormula(e.target.checked)}
                />

                <Checkbox
                  id='usu_bo_pacienterecebeorcamento'
                  label='Paciente aceita receber orçamentos?'
                  name='usu_bo_pacienterecebeorcamento'
                  value={pacienteRecebeOrcamentos}
                  checked={pacienteRecebeOrcamentos}
                  onChange={(e) =>
                    setPacienteRecebeOrcamentos(e.target.checked)
                  }
                />
              </fieldset>

              <Input
                id='usu_st_emailreceita'
                label='Endereço para envio de receitas'
                placeholder='contato_farmacia@gmail.com'
                name='usu_st_emailreceita'
                defaultValue={farmacia.far_st_emailcontato}
              />

              <Textarea
                id='usu_st_observacao'
                label='Observação'
                name='usu_st_observacao'
              ></Textarea>
            </>
          ) : null}

          <div className='w-100 mb-3'>
            <small className='text-muted align-end'>
              Os campos marcados com (*) são obrigatórios
            </small>
          </div>

          <Row>
            <Grid cols='12 6'>
              <Button
                type='submit'
                color='primary'
                size='block'
                handleclick={() => setRedirectTo('USER_LIST')}
              >
                Salvar
              </Button>
            </Grid>

            <Grid cols='12 6'>
              {tipo === 'M' && (
                <Button
                  type='submit'
                  color='secondary'
                  size='block'
                  handleclick={() => setRedirectTo('BUILD_PROFILE')}
                >
                  Copiar perfis
                </Button>
              )}
            </Grid>
          </Row>
        </Form>
      </div>
    </div>
  );
}
