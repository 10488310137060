import { nop } from './baseActions'
import { findById as findModeloById } from '../services/ReceitaModeloService'

export const setMedicamentos = medicamentos => {
  return {
    type: 'NEW_RECEITA_SET_MEDICAMENTOS',
    payload: medicamentos
  }
}

export const addMedicamento = medicamento => {
  return {
    type: 'NEW_RECEITA_ADD_MEDICAMENTO',
    payload: medicamento
  }
}

export const removeMedicamento = medicamento => {
  return {
    type: 'NEW_RECEITA_REMOVE_MEDICAMENTO',
    payload: medicamento
  }
}

export const clearMedicamentos = () => {
  return {
    type: 'NEW_RECEITA_CLEAR_MEDICAMENTOS'
  }
}

export const setPaciente = paciente => {
  return {
    type: 'NEW_RECEITA_SET_PACIENTE',
    payload: paciente
  }
}

export const setReceita = receita => {
  return {
    type: 'NEW_RECEITA_SET_RECEITA',
    payload: receita
  }
}

const setModelo = modelo => {
  return {
    type: 'NEW_RECEITA_SET_MODELO',
    payload: modelo
  }
}

export const setReceberOrcamento = modelo => {
  return {
    type: 'NEW_RECEITA_SET_RECEBER_ORCAMENTO',
    payload: modelo
  }
}

export const fetchModeloReceitaById = (farmaciaId, modeloId) => {
  return async dispatch => {
    try {
      const modelo = await findModeloById(farmaciaId, modeloId);

      return dispatch(setModelo(modelo));
    } catch (e) {
      return dispatch(nop());
    }
  }
}
