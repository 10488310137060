import { nop } from './baseActions'
import { findAll as findAllModelos } from '../services/ReceitaModeloService'

const setReceitaModelos = modelos => {
  return {
    type: 'NEW_USUARIO_SET_RECEITA_MODELOS',
    payload: modelos
  }
}

export const clearAll = () => {
  return {
    type: 'NEW_USUARIO_CLEAR_ALL'
  }
}

export const fetchAllModelosReceita = farmaciaId => {
  return async dispatch => {
    try {
      const modelos = await findAllModelos(farmaciaId);

      return dispatch(setReceitaModelos(modelos));
    } catch (e) {
      return dispatch(nop());
    }
  }
}
