import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Title from '../../components/Title';
import Button from '../../components/Button';
import {
  fetchReceitaById,
  clearAll,
  fetchModeloReceitaById,
} from '../../stores/receitaPageActions';
import { ReceitaPageContainer } from './styles';
import Receita from '../NewReceitaForm/Receita';

function getPacienteInitialText(receita) {
  return (
    receita.rec_st_txtreceita?.substring(
      0,
      receita.rec_st_txtreceita.indexOf('Medicamentos')
    ) || ''
  );
}

function getMedicamentosInitialText(receita) {
  return (
    receita.rec_st_txtreceita?.substring(
      receita.rec_st_txtreceita.indexOf('Medicamentos')
    ) || ''
  );
}

export default function ReceitaPage() {
  const { receitaId } = useParams();

  const dispatch = useDispatch();
  const goBackPath = useSelector((state) => state.receita.page.goBack);
  const receita = useSelector((state) => state.receita.page.receita);
  const usuario = useSelector((state) => state.auth.user);

  const modelo = useSelector((state) => state.receita.page.modelo);

  const [pacienteFieldValue, setPacienteFieldValue] = useState('');
  const [medicamentosFieldValue, setMedicamentosFieldValue] = useState('');

  const farmaciaId = localStorage.getItem('FORMULARIO_FARMACIA_ID');

  useEffect(() => {
    if (usuario.usu_ch_tipo === 'M') {
      dispatch([
        fetchReceitaById(farmaciaId, receitaId),
        fetchModeloReceitaById(farmaciaId, usuario.rem_in_codigo),
      ]);
    } else {
      dispatch(fetchReceitaById(farmaciaId, receitaId));
    }

    return () => dispatch(clearAll());
  }, []);

  useEffect(() => {
    if (!receita) return;

    // paciente
    const pacienteInfoText = getPacienteInitialText(receita);
    setPacienteFieldValue(pacienteInfoText);

    // medicamentos
    const medicamentosInfoText = getMedicamentosInitialText(receita);
    setMedicamentosFieldValue(medicamentosInfoText);
  }, [receita]);

  function ButtonPrint() {
    return (
      <Button
        color='secondary'
        size='sm'
        className='button-print'
        handleclick={() => window.print()}
      >
        Imprimir
      </Button>
    );
  }

  return (
    <ReceitaPageContainer>
      <Title
        link={goBackPath || '/receitas'}
        component={() => <ButtonPrint />}
      />

      <Receita
        dataReceita={receita?.createdAt}
        modelo={modelo}
        pacienteFieldValue={pacienteFieldValue}
        setPacienteFieldValue={setPacienteFieldValue}
        medicamentosFieldValue={medicamentosFieldValue}
        setMedicamentosFieldValue={setMedicamentosFieldValue}
      />
    </ReceitaPageContainer>
  );
}
