import React, { useState, useEffect, useCallback } from 'react';

import Title from '../../components/Title';
import ReceitaList from '../ReceitaListContainer/ReceitaList';
import { useSelector, useDispatch } from 'react-redux';
import {
  setReceitas,
  clearAll,
  setTotalItems,
  setPage,
} from '../../stores/receitaRepresentanteListActions';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';
import { findAllReceitasByRepresentante } from '../../services/ReceitaRepresentanteService';
import Pagination from '../../components/Pagination';

export default function ReceitaRepresentanteListContainer() {
  const [isLoading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const receitas = useSelector(
    (state) => state.representante.receita.list.receitas
  );

  // pagination
  const page = useSelector(
    (state) => state.representante.receita.list.pagination.page
  );
  const itemsPerPage = useSelector(
    (state) => state.representante.receita.list.pagination.itemsPerPage
  );
  const totalItems = useSelector(
    (state) => state.representante.receita.list.pagination.totalItems
  );

  const { usu_ch_tipo: role, usu_in_codigo: representanteId } = useSelector(
    (state) => state.auth.user
  );

  const farmaciaId = localStorage.getItem('FORMULARIO_FARMACIA_ID');

  const history = useHistory();

  const getReceitas = useCallback(
    async (pagination) => {
      setLoading(true);

      const response = await findAllReceitasByRepresentante(
        farmaciaId,
        representanteId,
        { page: pagination.page, itemsPerPage: pagination.itemsPerPage }
      );

      dispatch([
        setPage(pagination.page),
        setReceitas(response.data),
        setTotalItems(response['_metadata'].totalItems),
      ]);

      setLoading(false);
    },
    [dispatch, farmaciaId, representanteId]
  );

  useEffect(() => {
    getReceitas({ page: 1, itemsPerPage });

    return () => dispatch(clearAll());
  }, [dispatch, getReceitas, itemsPerPage]);

  function viewReceita(receita) {
    history.push(`/receitas/${receita.rec_in_codigo}`);
  }

  async function handlePaginate(pgNumber) {
    if (pgNumber === page) return;

    getReceitas({ page: pgNumber, itemsPerPage });
  }

  return (
    <div className='receita-container'>
      <Title link='/'>Listagem de receitas</Title>

      {isLoading ? (
        <Skeleton count={5} height={35} />
      ) : (
        <ReceitaList
          receitas={receitas}
          role={role}
          readonly
          onViewReceita={viewReceita}
        />
      )}

      {totalItems > 0 && (
        <Pagination
          page={page}
          itemsPerPage={itemsPerPage}
          totalItems={totalItems}
          paginate={handlePaginate}
        />
      )}
    </div>
  );
}
