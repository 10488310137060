import React, { useState, useEffect, useRef } from 'react';
import './styles.css';

import { useParams, useHistory } from 'react-router-dom';
import Title from '../../components/Title';
import { Form } from '@unform/web';
import { Input, TextArea, Checkbox, Select } from '../../components/Form';
import Button from '../../components/Button';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { setLoading } from '../../stores/loadingActions';
import {
  fetchById,
  fetchAtivosByFormula,
  fetchUnidades,
  fetchMedicos,
  searchVeiculosUtilizacao as searchVeiculos,
  clearFormula,
  fetchVeiculoUtilizacaoById,
  fetchLogsByFormula,
} from '../../stores/formulaEditActions';
import Row from '../../components/Row';
import Grid from '../../components/Grid';
import { update } from '../../services/FormulaService';
import {
  removeLinkAtivoFormula,
  updateLinkAtivoFormula,
} from '../../services/AtivoFormulaService';
import AtivoList from './AtivoList';
import { save as saveVeiculoUtilizacao } from '../../services/VeiculoUtilizacaoService';
import ModalSearchVeiculosUtilizacaoResults from '../ResultListPage/NewFormulaWizard/ModalSearchVeiculosUtilizacaoResults';
import ModalButton from '../../components/ModalButton';
import { toast } from 'react-toastify';
import FormulaLogs from '../../components/Logs';
import { clearLogs } from '../../services/FormulaLogsService';

export default function FormulaEdit() {
  const { id: formulaId } = useParams();
  const history = useHistory();

  const dispatch = useDispatch();
  const formula = useSelector((state) => state.formula.edit.formula);
  const formulaAtivos = useSelector((state) => state.formula.edit.ativos);
  const medicos = useSelector((state) => state.formula.edit.medicos);

  const formRef = useRef(null);

  const farmaciaId = localStorage.getItem('FORMULARIO_FARMACIA_ID');

  const [nome, setNome] = useState('');
  const [disponivel, setDisponivel] = useState(false);
  const [posologia, setPosologia] = useState('');
  const [unidade, setUnidade] = useState(-1);
  const [veiculoUtilizacao, setVeiculoUtilizacao] = useState(null);
  const [qsp, setQSP] = useState(false);
  const [quantidadeVeiculo, setQuantidadeVeiculo] = useState(0);
  const [obs, setObs] = useState('');
  const [sinonimos, setSinonimos] = useState('');
  const [medico, setMedico] = useState(-1);

  const [selectedVia, setSelectedVia] = useState(null);

  const [locked, setLocked] = useState(true);

  // modal results loading
  const [isFirst, setFirst] = useState(true);
  const [modalLoading, setModalLoading] = useState(true);

  // first time searching veiculos utilizacao
  const [veiculosFirstSearch, setVeiculosFirstSearch] = useState(true);

  const unidades = useSelector((state) => state.formula.edit.unidades);
  const veiculosUtilizacao = useSelector(
    (state) => state.formula.edit.veiculosUtilizacao
  );
  const initialVeiculoUtilizacao = useSelector(
    (state) => state.formula.edit.veiculoUtilizacao
  );

  const vias = useSelector((state) => state.vias.vias);

  const role = useSelector((state) => state.auth.user.usu_ch_tipo);
  const readonly = role !== 'G';

  const goBackPath = useSelector((state) => state.formula.edit.goBack);

  // logs
  const logs = useSelector((state) => state.formula.edit.logs);
  const { usu_in_codigo: usuarioId } = useSelector((state) => state.auth.user);

  useEffect(() => {
    // fetch formula by id
    dispatch([
      fetchById(farmaciaId, formulaId),
      fetchAtivosByFormula(farmaciaId, formulaId),
      fetchUnidades(farmaciaId),
      fetchMedicos(farmaciaId),
      fetchLogsByFormula(farmaciaId, formulaId),
    ]);

    return () => dispatch(clearFormula());
  }, []);

  useEffect(() => {
    if (formula) {
      setNome(formula.for_st_nome);
      setDisponivel(formula.for_bo_disponivel);
      setPosologia(formula.for_st_posologia);
      setUnidade(formula.uni_in_codigo);
      setQuantidadeVeiculo(formula.for_in_quantidadeveiculo);
      setQSP(formula.for_bo_qsp);
      setObs(formula.for_st_observacao);
      setSinonimos(formula.for_st_sinonimos);
      setMedico(formula.usu_in_codigo_exclusividade || -1);

      // find veiculo formula
      dispatch(fetchVeiculoUtilizacaoById(farmaciaId, formula.veu_in_codigo));
    }
  }, [formula]);

  useEffect(() => {
    // load initial veiculo utilizacao
    if (initialVeiculoUtilizacao) {
      setVeiculoUtilizacao(initialVeiculoUtilizacao);
      document.getElementById('veiculoUtilizacao').value =
        initialVeiculoUtilizacao.veu_st_descricao;
    }
  }, [initialVeiculoUtilizacao]);

  useEffect(() => {
    // user search a veiculo utilizacao
    if (veiculoUtilizacao) {
      document.getElementById('veiculoUtilizacao').value =
        veiculoUtilizacao.veu_st_descricao;
    }
  }, [veiculoUtilizacao]);

  useEffect(() => {
    // modal results loading
    if (isFirst) setFirst(false);
    else setModalLoading(false);
  }, [veiculosUtilizacao]);

  function getMedicoId() {
    if (medico != -1) return medico;

    return null;
  }

  async function handleSubmit(data, { reset }) {
    try {
      if (!veiculoUtilizacao) {
        toast.warning('Selecione um veic. utilização/forma farmacêutica');
        return;
      }

      // set loading
      dispatch(setLoading(true));

      // validation
      const schema = Yup.object().shape({
        for_st_nome: Yup.string().required('O nome é obrigatório'),
        for_bo_disponivel: Yup.boolean().required(
          'Selecione a disponibilidade'
        ),
        for_st_posologia: Yup.string(),
        for_st_observacao: Yup.string(),
        for_st_sinonimos: Yup.string(),
        veu_in_codigo: Yup.number().required(
          'Selecione o veic. utilização/forma farmacêutica'
        ),
      });

      const formula = {
        ...data,
        usu_in_codigo_exclusividade: getMedicoId(),
        veu_in_codigo: veiculoUtilizacao.veu_in_codigo,
      };

      // extract key 'veiculoUtilizacao' from formula obj
      var formulaToSave = Object.assign({}, formula);
      delete formulaToSave.veiculoUtilizacao;

      await schema.validate(formulaToSave, {
        abortEarly: false,
      });

      // save
      await update(farmaciaId, formulaId, formulaToSave, usuarioId);

      dispatch(setLoading(false));

      // reset form data
      reset();

      // reset errors
      formRef.current.setErrors({});

      // redirect user
      history.push('/formulas');
    } catch (e) {
      if (e instanceof Yup.ValidationError) {
        const errorMessages = {};

        e.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });

        formRef.current.setErrors(errorMessages);
      }

      dispatch(setLoading(false));
    }
  }

  async function handleClearLogs() {
    dispatch(setLoading(true));

    await clearLogs(farmaciaId, formulaId, usuarioId);

    dispatch([fetchLogsByFormula(farmaciaId, formulaId), setLoading(false)]);
  }

  function goToLinkFormulaAtivoPage() {
    history.push(`/formulas/${formulaId}/link/ativos`);
  }

  async function handleUpdateFormulaAtivo({
    ativoId,
    doseMinima,
    doseMaxima,
    doseDiaria,
    dosePrescrita,
  }) {
    dispatch(setLoading(true));

    await updateLinkAtivoFormula(
      farmaciaId,
      ativoId,
      formulaId,
      doseMinima,
      doseMaxima,
      doseDiaria,
      dosePrescrita
    );

    dispatch([setLoading(false), fetchAtivosByFormula(farmaciaId, formulaId)]);
  }

  async function removeLink(ativoId) {
    dispatch(setLoading(true));

    await removeLinkAtivoFormula(farmaciaId, ativoId, formulaId);

    dispatch([
      setLoading(false),
      fetchById(farmaciaId, formulaId),
      fetchAtivosByFormula(farmaciaId, formulaId),
    ]);
  }

  async function handleSaveNewVeiculoUtilizacao({
    veu_st_descricao,
    veu_st_tipo,
  }) {
    dispatch(setLoading(true));

    const veiculoUtilizacao = await saveVeiculoUtilizacao(farmaciaId, {
      veu_st_descricao,
      veu_st_tipo,
    });

    setVeiculoUtilizacao(veiculoUtilizacao);

    dispatch(setLoading(false));
  }

  function handleSelectVeiculoUtilizacao(veiculoUtilizacao) {
    setVeiculoUtilizacao(veiculoUtilizacao);
  }

  function searchVeiculosUtilizacao(ref) {
    setModalLoading(true);

    dispatch(searchVeiculos(farmaciaId, ref));

    // open modal to show results
    document.getElementById('modal_results_button').click();
  }

  function handleSearchVeiculoUtilizacao(e) {
    if (e.key === 'Enter' || (e.key === 'Tab' && veiculosFirstSearch)) {
      e.preventDefault();

      setVeiculosFirstSearch(false);

      // search veiculoUtilizacao
      searchVeiculosUtilizacao(e.target.value);
    }
  }

  function ActionButtons() {
    return locked ? (
      <Button size='block' handleclick={() => setLocked(false)}>
        Habilitar campos
      </Button>
    ) : (
      <Row>
        <Grid cols='12 6'>
          <Button
            color='secondary'
            size='block'
            handleclick={() => setLocked(true)}
          >
            Cancelar
          </Button>
        </Grid>

        <Grid cols='12 6'>
          <Button type='submit' size='block'>
            Salvar
          </Button>
        </Grid>
      </Row>
    );
  }

  return (
    <div className='formula-container'>
      <Title link={goBackPath || '/formulas'}>
        {readonly ? 'Informações da fórmula' : 'Editar'}
      </Title>

      <Row>
        <Grid cols='12 6'>
          <div className='form-container'>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <Input
                id='for_st_nome'
                label='* Nome'
                className='form-control upper'
                name='for_st_nome'
                value={nome}
                onChange={(e) => setNome(e.target.value)}
                disabled={locked}
                required
              />

              <Input
                id='for_st_posologia'
                label='Posologia'
                className='form-control upper'
                name='for_st_posologia'
                value={posologia}
                onChange={(e) => setPosologia(e.target.value)}
                disabled={locked}
              />

              <Input
                id='veiculoUtilizacao'
                name='veiculoUtilizacao'
                label='Veic. utilização/Forma farmacêutica'
                className='form-control upper'
                placeholder='Pesquise por descrição'
                onKeyPress={handleSearchVeiculoUtilizacao}
                disabled={locked}
              >
                <small className='text-muted'>
                  Pressione "Enter" para pesquisar.
                </small>
              </Input>

              <Row>
                <Grid cols='12 6'>
                  <Select
                    id='via_in_codigo'
                    name='via_in_codigo'
                    className='form-control upper'
                    value={selectedVia}
                    onChange={(e) => setSelectedVia(e.target.value)}
                    disabled={locked}
                  >
                    <option value={-1} disabled>
                      Selecione...
                    </option>
                    {vias.map((via) => (
                      <option key={via.via_in_codigo} value={via.via_in_codigo}>
                        {via.via_st_nome}
                      </option>
                    ))}
                  </Select>
                </Grid>
              </Row>

              <Row>
                <Grid cols='12 6'>
                  <Checkbox
                    type='checkbox'
                    id='for_bo_qsp'
                    label='Veículo com QSP'
                    name='for_bo_qsp'
                    value={qsp}
                    onChange={(e) => setQSP(e.target.checked)}
                    checked={qsp}
                    disabled={locked}
                  />
                </Grid>
                <Grid cols='12 6'>
                  <Row>
                    <Grid cols='12 6'>
                      <Input
                        type='number'
                        id='for_in_quantidadeveiculo'
                        name='for_in_quantidadeveiculo'
                        value={quantidadeVeiculo}
                        onChange={(e) => setQuantidadeVeiculo(e.target.value)}
                        disabled={locked}
                        required
                      />
                    </Grid>
                    <Grid cols='12 6'>
                      <Select
                        id='uni_in_codigo'
                        name='uni_in_codigo'
                        className='form-control upper'
                        value={unidade}
                        onChange={(e) => setUnidade(e.target.value)}
                        disabled={locked}
                      >
                        <option value={-1} disabled>
                          Selecione...
                        </option>
                        {unidades.map(
                          ({ uni_in_codigo: codigo, uni_st_sigla: sigla }) => (
                            <option key={codigo} value={codigo}>
                              {sigla}
                            </option>
                          )
                        )}
                      </Select>
                    </Grid>
                  </Row>
                </Grid>
              </Row>

              {role === 'G' ? (
                <Select
                  id='usu_in_codigo_exclusividade'
                  label='A que médico essa fórmula pertence?'
                  name='usu_in_codigo_exclusividade'
                  value={medico}
                  onChange={(e) => setMedico(e.target.value)}
                  disabled={locked}
                >
                  <option value={-1}>Todos os médicos</option>
                  {medicos.map((medico) => (
                    <option
                      value={medico.usu_in_codigo}
                      key={medico.usu_in_codigo}
                    >
                      {medico.usu_st_nome}
                    </option>
                  ))}
                </Select>
              ) : null}

              <Input
                id='for_st_sinonimos'
                label='Sinônimos'
                className='form-control upper'
                name='for_st_sinonimos'
                onChange={(e) => setSinonimos(e.target.value)}
                value={sinonimos}
                disabled={locked}
              />

              <TextArea
                id='for_st_observacao'
                label='Observação'
                className='form-control upper'
                name='for_st_observacao'
                onChange={(e) => setObs(e.target.value)}
                value={obs}
                disabled={locked}
              ></TextArea>

              <Checkbox
                type='checkbox'
                id='for_bo_disponivel'
                label='Disponível'
                name='for_bo_disponivel'
                value={disponivel}
                onChange={(e) => setDisponivel(e.target.checked)}
                checked={disponivel}
                disabled={locked}
              />

              <div className='w-100 mb-3'>
                <small className='text-muted align-end'>
                  Os campos marcados com (*) são obrigatórios
                </small>
              </div>

              {readonly ? null : <ActionButtons />}
            </Form>
          </div>
        </Grid>

        <Grid cols='12 6'>
          <FormulaLogs
            logs={logs.map((log) => ({
              datahora: log.flo_dt_datahora,
              mensagem: log.flo_st_mensagem,
            }))}
            onClearLogs={handleClearLogs}
          />

          <br />

          <AtivoList
            readonly={readonly}
            formulaAtivos={formulaAtivos}
            onLinkButtonClick={goToLinkFormulaAtivoPage}
            onUpdateLink={handleUpdateFormulaAtivo}
            onRemoveLink={removeLink}
          />
        </Grid>
      </Row>

      {/* veiculosUtilizacao search results modal */}
      <ModalButton
        id='modal_results_button'
        target='#modal_results'
        className='d-none'
      />
      <ModalSearchVeiculosUtilizacaoResults
        id='modal_results'
        veiculosUtilizacao={veiculosUtilizacao}
        onSelectVeiculo={handleSelectVeiculoUtilizacao}
        onSaveNewVeiculoUtilizacao={handleSaveNewVeiculoUtilizacao}
        loading={modalLoading}
        setLoading={setModalLoading}
      />
    </div>
  );
}
