import api from './api'

export function searchMedicamentosIndustrializados(farmaciaId, ref = '', page = 1, itemsPerPage = 15, startsWith = false) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      const response = await api.get(`farmacias/${farmaciaId}/medicamentos_industrializados/search`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { ref, page, itemsPerPage, startsWith }
      });

      return resolve(response.data);
    } catch (e) {
      return reject(e);
    }
  })
}
