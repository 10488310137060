const INITIAL_STATE = {
  medicamentos: [],
  pagination: {
    page: 1,
    itemsPerPage: 15,
    totalItems: 0
  },
  search: {
    ref: '',
    startsWith: false,
  }
}

export default function medicamentoListReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'MEDICAMENTO_LIST_FETCH_MEDICAMENTOS':
      return {
        ...state,
        medicamentos: action.payload
      }
    case 'MEDICAMENTO_LIST_SET_PAGE':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.payload
        }
      }
    case 'MEDICAMENTO_LIST_SET_ITEMS_PER_PAGE':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          itemsPerPage: action.payload
        }
      }
    case 'MEDICAMENTO_LIST_SET_TOTAL_ITEMS':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          totalItems: action.payload
        }
      }
    case 'MEDICAMENTO_LIST_CLEAR_PAGINATION':
      return {
        ...state,
        pagination: INITIAL_STATE.pagination
      }
    case 'MEDICAMENTO_LIST_SET_REF':
      return {
        ...state,
        search: {
          ...state.search,
          ref: action.payload
        }
      }
    case 'MEDICAMENTO_LIST_SET_STARTS_WITH':
      return {
        ...state,
        search: {
          ...state.search,
          startsWith: action.payload
        }
      }
    case 'MEDICAMENTO_LIST_CLEAR_SEARCH':
      return {
        ...state,
        search: INITIAL_STATE.search
      }
    case 'MEDICAMENTO_LIST_CLEAR_MEDICAMENTOS':
      return {
        ...state,
        ...INITIAL_STATE
      }
    default:
      return state
  }
}

