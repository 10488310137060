import React, { useState, useEffect } from 'react';
import './styles.css';

import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  searchMedicamentos,
  fetchFavorites,
  setPage,
  fetchAtivosByFormula,
  setAtivos as setFormulaAtivos,
  fetchUnidades,
  removeAtivo,
  addAtivoReceita,
  clearAllAtivos,
  searchVeiculosUtilizacao,
  fetchVeiculoUtilizacaoById,
  setRef,
  clearFormulaVeiculoUtilizacao,
  setStartsWith,
} from '../../stores/searchMedicamentosActions';
import {
  addMedicamento,
  removeMedicamento,
  clearMedicamentos,
} from '../../stores/newReceitaActions';
import Row from '../../components/Row';
import Grid from '../../components/Grid';
import Skeleton from 'react-loading-skeleton';
import Title from '../../components/Title';
import ResultList from './ResultList';
import Pagination from '../../components/Pagination';
import { setGoBack as setAtivoGoBack } from '../../stores/ativoEditActions';
import { setGoBack as setFormulaGoBack } from '../../stores/formulaEditActions';
import { setGoBack as setMedicamentoIndustrializadoGoBack } from '../../stores/medicamentoEditActions';
import Summary from '../Home/Summary';
import {
  favoriteIndustrializado,
  unfavoriteIndustrializado,
} from '../../services/FavoriteIndustrializadosService';
import {
  favoriteFormula,
  unfavoriteFormula,
} from '../../services/FavoriteFormulasService';
import {
  favoriteAtivo,
  unfavoriteAtivo,
} from '../../services/FavoriteAtivosService';
import { setLoading as loading } from '../../stores/loadingActions';
import NewFormulaWizard from './NewFormulaWizard';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import ModalSearchVeiculosUtilizacaoResults from './NewFormulaWizard/ModalSearchVeiculosUtilizacaoResults';
import ModalButton from '../../components/ModalButton';
import { save as saveVeiculoUtilizacao } from '../../services/VeiculoUtilizacaoService';
import AtivoList from '../FormulaEdit/AtivoList';
import Button from '../../components/Button';
import { MdEdit } from 'react-icons/md';
import { getVias } from '../../stores/viaActions';

export default function ResultListPage() {
  const [isLoading, setLoading] = useState(true);
  const [isFirst, setFirst] = useState(true);

  const vias = useSelector((state) => state.vias.vias);

  // search
  const ref = useSelector((state) => state.home.search.ref);
  const startsWith = useSelector((state) => state.home.search.startsWith);
  const favoritesOnly = useSelector((state) => state.home.search.favorites);
  const tipos = useSelector((state) => state.home.search.tipos);

  // pagination
  const page = useSelector((state) => state.home.pagination.page);
  const itemsPerPage = useSelector(
    (state) => state.home.pagination.itemsPerPage
  );
  const totalItems = useSelector((state) => state.home.pagination.totalItems);

  const { usu_ch_tipo: role, usu_in_codigo: usuarioId } = useSelector(
    (state) => state.auth.user
  );

  const medicamentos = useSelector((state) => state.home.medicamentos);
  const [formula, setFormula] = useState(null);

  // favorites
  const favoriteAtivos = useSelector((state) => state.home.favorites.ativos);
  const favoriteFormulas = useSelector(
    (state) => state.home.favorites.formulas
  );
  const favoriteIndustrializados = useSelector(
    (state) => state.home.favorites.industrializados
  );

  const formulaAtivos = [
    ...useSelector((state) => state.home.ativos),
    ...useSelector((state) => state.home.receitaAtivos),
  ];
  const veiculosUtilizacao = useSelector(
    (state) => state.home.veiculosUtilizacao
  );
  const unidades = useSelector((state) => state.home.unidades);

  // selected veiculo utilizacao when building formula
  const [selectedVeiculoUtilizacao, setSelectedVeiculoUtilizacao] = useState(
    null
  );

  // veiculo utilizacao from formula that user selected
  const formulaVeiculoUtilizacao = useSelector(
    (state) => state.home.formulaVeiculoUtilizacao
  );

  // option to show nome and composição formula on receita resumo
  const {
    usu_bo_nomeformula: showNomeFormula,
    usu_bo_composicaoformula: showComposicaoFormula,
    usu_bo_pacienterecebeorcamento: pacienteRecebeOrcamentos,
  } = useSelector((state) => state.auth.user);

  // step
  const [step, setStep] = useState('RESULT_LIST');

  const receita = useSelector((state) => state.receita.new.medicamentos);

  const farmaciaId = localStorage.getItem('FORMULARIO_FARMACIA_ID');

  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();

  // modal loading
  const [modalLoading, setModalLoading] = useState(true);

  useEffect(() => {
    // when user add medicamento by list page
    if (location.state?.step) {
      setStep(location.state.step);

      if (location.state?.formula) handleEditFormula(location.state.formula);

      dispatch([
        searchMedicamentos(
          farmaciaId,
          ' ',
          favoritesOnly,
          tipos,
          1,
          itemsPerPage,
          usuarioId,
          false,
          role
        ),
        fetchFavorites(farmaciaId, usuarioId),
        fetchUnidades(farmaciaId),
        getVias(),
      ]);
      return;
    }

    // user added formula by its list page
    if (location.state?.formula) {
      handleSetFormula(location.state.formula);
      return;
    }

    // search
    if (ref) {
      handleSearchMedicamentos(ref);
      return;
    }

    history.push('/home');
    return;

    // NOTE: removed clearSearch when component dismount
    // bc of goBack path on medicamento edit page
  }, []);

  useEffect(() => {
    // set veiculo utilizacao from formula, when user select formula
    if (formulaVeiculoUtilizacao) {
      setSelectedVeiculoUtilizacao(formulaVeiculoUtilizacao);

      // clear veículo on redux after setting on component's state
      dispatch(clearFormulaVeiculoUtilizacao());
    }
  }, [formulaVeiculoUtilizacao]);

  // loading
  useEffect(() => {
    if (isFirst) setFirst(false);
    else {
      setLoading(false);
      dispatch(loading(false));
    }
  }, [
    medicamentos,
    favoriteAtivos,
    favoriteFormulas,
    favoriteIndustrializados,
  ]);

  // modal loading
  useEffect(() => {
    if (isFirst) setFirst(false);
    else setModalLoading(false);
  }, [veiculosUtilizacao]);

  function handleSearchMedicamentos(ref, startsWith) {
    setLoading(true);

    const favorites = role === 'M' ? favoritesOnly : null;
    const tiposFilter = role === 'M' ? tipos : null;

    dispatch([
      setRef(ref),
      setStartsWith(startsWith),
      setPage(1),
      searchMedicamentos(
        farmaciaId,
        ref,
        favorites,
        tiposFilter,
        1,
        itemsPerPage,
        usuarioId,
        startsWith,
        role
      ),
      fetchFavorites(farmaciaId, usuarioId),
      fetchUnidades(farmaciaId),
    ]);
  }

  function handlePaginate(pgNumber) {
    if (page === pgNumber) return;

    setLoading(true);

    dispatch([
      setPage(pgNumber),
      searchMedicamentos(
        farmaciaId,
        ref,
        favoritesOnly,
        tipos,
        pgNumber,
        itemsPerPage,
        usuarioId,
        startsWith,
        role
      ),
    ]);
  }

  function addMedicamentoReceita(medicamento) {
    dispatch(addMedicamento(medicamento));

    // clear store
    dispatch(clearAllAtivos());

    // clear formula
    setFormula(null);

    if (medicamento.tipo === 'FORMULA') setStep('RESULT_LIST');

    toast.info('Medicamento adicionado');
  }

  function handleRedirectToMedicamentoPage(type) {
    if (type === 'ATIVO') dispatch(setAtivoGoBack('/search'));

    if (type === 'FORMULA') dispatch(setFormulaGoBack('/search'));

    if (type === 'INDUSTRIALIZADO')
      dispatch(setMedicamentoIndustrializadoGoBack('/search'));
  }

  function removeMedicamentoReceita(medicamento) {
    dispatch(removeMedicamento(medicamento));
  }

  async function handleFavorite(medicamento) {
    dispatch(loading(true));

    if (medicamento.tipo === 'ATIVO') {
      await favoriteAtivo(farmaciaId, usuarioId, medicamento.codigo);
    }

    if (medicamento.tipo === 'FORMULA') {
      await favoriteFormula(farmaciaId, usuarioId, medicamento.codigo);
    }

    if (medicamento.tipo === 'INDUSTRIALIZADO') {
      await favoriteIndustrializado(farmaciaId, usuarioId, medicamento.codigo);
    }

    // fetch favoritos
    dispatch(fetchFavorites(farmaciaId, usuarioId));
  }

  async function handleUnfavorite(medicamento) {
    dispatch(loading(true));

    if (medicamento.tipo === 'ATIVO') {
      await unfavoriteAtivo(farmaciaId, usuarioId, medicamento.codigo);
    }

    if (medicamento.tipo === 'FORMULA') {
      await unfavoriteFormula(farmaciaId, usuarioId, medicamento.codigo);
    }

    if (medicamento.tipo === 'INDUSTRIALIZADO') {
      await unfavoriteIndustrializado(
        farmaciaId,
        usuarioId,
        medicamento.codigo
      );
    }

    // fetch favoritos
    dispatch(fetchFavorites(farmaciaId, usuarioId));
  }

  function handleClearSummary() {
    dispatch(clearMedicamentos());
  }

  function findAtivosByFormula(formula) {
    dispatch(fetchAtivosByFormula(farmaciaId, formula.codigo));
  }

  function handleRemoveAtivoReceita(ativoId) {
    dispatch(removeAtivo(ativoId));
  }

  function handleSetFormula(formula) {
    // set formula
    setFormula(formula);

    // find ativos by formula
    findAtivosByFormula(formula);

    // find veiculo utilizacao formula
    dispatch(
      fetchVeiculoUtilizacaoById(farmaciaId, formula.id_veiculo_utilizacao)
    );

    // set step
    setStep('NEW_FORMULA');
  }

  function clearVeiculoUtilizacaoFormula() {
    setSelectedVeiculoUtilizacao(null);
  }

  function linkAtivoToFormula(medicamento) {
    // validation -> medicamento must be an ativo
    if (medicamento.tipo !== 'ATIVO') {
      toast.warning('Somente ativos podem ser vinculados à fórmula');
      return;
    }

    const concentracaoMinima =
      medicamento.tipo_uso === 'ORAL'
        ? medicamento.concentracaoMinimaOral
        : medicamento.concentracaoMinimaTopico;
    const concentracaoMaxima =
      medicamento.tipo_uso === 'ORAL'
        ? medicamento.concentracaoMaximaOral
        : medicamento.concentracaoMaximaTopico;
    const concentracaoDiaria =
      medicamento.tipo_uso === 'ORAL'
        ? medicamento.concentracaoDiariaOral
        : medicamento.concentracaoDiariaTopico;

    dispatch(
      addAtivoReceita({
        ...medicamento,
        fat_in_codigo: uuidv4(),
        fat_st_concentracaominima: concentracaoMinima,
        fat_st_concentracaomaxima: concentracaoMaxima,
        fat_st_concentracaodiaria: concentracaoDiaria,
        fat_st_concentracaoprescrita: medicamento.concentracaoPrescrita,
        ativo: {
          atv_in_codigo: medicamento.codigo,
          atv_st_descricao: medicamento.nome,
          atv_st_observacao: medicamento.observacao,
          atv_st_indicacaouso: medicamento.uso,
          atv_st_uso: medicamento.tipo_uso,
        },
      })
    );

    // setting formula's tipo for when user add this formula to receita
    setFormula({ ...formula, tipo: 'FORMULA' });

    setStep('NEW_FORMULA');
  }

  function handleSearchVeiculosUtilizacao(ref) {
    setModalLoading(true);

    dispatch(searchVeiculosUtilizacao(farmaciaId, ref, role, usuarioId));

    // open modal to show results
    document.getElementById('modal_results_button').click();
  }

  function handleSelectVeiculoUtilizacao(veiculoUtilizacao) {
    setSelectedVeiculoUtilizacao(veiculoUtilizacao);
  }

  async function handleSaveNewVeiculoUtilizacao({
    veu_st_descricao,
    veu_st_tipo,
  }) {
    dispatch(loading(true));

    let data = { veu_st_descricao, veu_st_tipo };

    // link to medico
    if (role === 'M') {
      data['usu_in_codigo'] = usuarioId;
    }

    const veiculoUtilizacao = await saveVeiculoUtilizacao(farmaciaId, data);

    setSelectedVeiculoUtilizacao(veiculoUtilizacao);

    dispatch(loading(false));
  }

  function handleEditFormula(formula) {
    // set formula
    setFormula(formula);

    // set ativos that medico has chosen
    if (formula.ativos) dispatch(setFormulaAtivos(formula.ativos));
    else {
      // find ativos by formula
      if (formula.codigo) findAtivosByFormula(formula);
    }

    // find veiculo utilizacao formula
    if (formula.veiculoUtilizacao)
      dispatch(
        fetchVeiculoUtilizacaoById(farmaciaId, formula.veiculoUtilizacao)
      );

    // set step
    setStep('NEW_FORMULA');
  }

  function ButtonEditFormula({ formula }) {
    return (
      <Button
        size='sm'
        color='warning'
        className='ml-1'
        handleclick={() => handleEditFormula(formula)}
        title='Editar fórmula'
      >
        <MdEdit size={18} />
      </Button>
    );
  }

  function ReceitaWizard({ step }) {
    const renderStep = {
      RESULT_LIST: (
        <ResultList
          results={medicamentos}
          medicamentos={receita}
          favorites={[
            ...favoriteAtivos,
            ...favoriteFormulas,
            ...favoriteIndustrializados,
          ]}
          role={role}
          onAddMedicamento={addMedicamentoReceita}
          onRedirectToMedicamentoPage={handleRedirectToMedicamentoPage}
          onSetFormula={handleSetFormula}
          onFavorite={handleFavorite}
          onUnfavorite={handleUnfavorite}
          onAddAtivo={linkAtivoToFormula}
          onSearchMedicamentos={handleSearchMedicamentos}
        />
      ),
      NEW_FORMULA: (
        <NewFormulaWizard
          vias={vias}
          formula={formula}
          ativos={formulaAtivos}
          unidades={unidades}
          onSubmit={(data) => addMedicamentoReceita({ ...formula, ...data })}
          onRemoveAtivo={handleRemoveAtivoReceita}
          veiculoUtilizacao={selectedVeiculoUtilizacao}
          showComposicao={showComposicaoFormula}
          showNomeFormula={showNomeFormula}
          onGoBack={() => {
            dispatch(clearAllAtivos());
            setFormula(null);
            setStep('RESULT_LIST');
          }}
          onGoForward={() => setStep('ATIVO_LIST')}
          onSearchVeiculosUtilizacao={handleSearchVeiculosUtilizacao}
          clearFormulaVeiculo={clearVeiculoUtilizacaoFormula}
        />
      ),
      ATIVO_LIST: (
        <ResultList
          results={medicamentos.filter((m) => m.tipo === 'ATIVO')}
          medicamentos={receita}
          ativos={formulaAtivos}
          favorites={[...favoriteAtivos]}
          role={role}
          onAddAtivo={linkAtivoToFormula}
          onRedirectToMedicamentoPage={handleRedirectToMedicamentoPage}
          onFavorite={handleFavorite}
          onUnfavorite={handleUnfavorite}
          onSearchMedicamentos={handleSearchMedicamentos}
        />
      ),
    };

    return renderStep[step];
  }

  function SummaryWizard({ step }) {
    const renderStep = {
      RESULT_LIST:
        formulaAtivos.length > 0 ? (
          <>
            <Button
              size='md'
              color='link'
              handleclick={() => setStep('NEW_FORMULA')}
            >
              Fórmula atual
            </Button>
            <AtivoList
              formulaAtivos={formulaAtivos}
              modalRemove={false}
              onRemoveLink={handleRemoveAtivoReceita}
              hideViewButton
            />
          </>
        ) : medicamentos.length > 0 ? (
          <Summary
            medicamentos={receita}
            headerSm
            onRemove={removeMedicamentoReceita}
            onClear={handleClearSummary}
            optionalButton={ButtonEditFormula}
            pacienteRecebeOrcamentos={pacienteRecebeOrcamentos}
          />
        ) : null,
      NEW_FORMULA:
        medicamentos.length > 0 ? (
          <Summary
            medicamentos={receita}
            headerSm
            onRemove={removeMedicamentoReceita}
            onClear={handleClearSummary}
            pacienteRecebeOrcamentos={pacienteRecebeOrcamentos}
          />
        ) : null,
      ATIVO_LIST: (
        <>
          <Button
            size='md'
            color='link'
            handleclick={() => setStep('NEW_FORMULA')}
          >
            Fórmula atual
          </Button>
          <AtivoList
            formulaAtivos={formulaAtivos}
            modalRemove={false}
            onRemoveLink={handleRemoveAtivoReceita}
            hideViewButton
          />
        </>
      ),
    };

    return (
      renderStep[step] || (
        <p>Parece que algo deu errado, tente recarregar a página</p>
      )
    );
  }

  function StepPagination({ step }) {
    if (step === 'RESULT_LIST') {
      return (
        <Row>
          <Grid cols='12'>
            <Pagination
              page={page}
              itemsPerPage={itemsPerPage}
              totalItems={totalItems}
              paginate={handlePaginate}
            />
          </Grid>
        </Row>
      );
    }

    if (step === 'NEW_FORMULA') {
      return null;
    }

    if (step === 'ATIVO_LIST') {
      return (
        <Row>
          <Grid cols='12'>
            <Pagination
              page={page}
              itemsPerPage={itemsPerPage}
              totalItems={totalItems}
              paginate={handlePaginate}
            />
          </Grid>
        </Row>
      );
    }
  }

  function StepTitle({ step }) {
    if (step === 'RESULT_LIST') {
      return (
        <Title link='/home'>
          Exibindo resultados para "
          <span className='font-weight-normal'>{ref}</span>"
        </Title>
      );
    }

    if (step === 'NEW_FORMULA') {
      return (
        <Title hideButton>
          Adicionar na receita a fórmula "
          <span className='font-weight-normal'>{formula?.nome || ''}</span>"
        </Title>
      );
    }

    if (step === 'ATIVO_LIST') {
      return (
        <Title hideButton>Selecione um ativo para vincular à fórmula</Title>
      );
    }

    return null;
  }

  return (
    <div className='results-container'>
      <StepTitle step={step} />

      {role === 'G' ? (
        <Row>
          <Grid cols='12'>
            {isLoading ? (
              <Skeleton count={20} height={70} />
            ) : medicamentos.length === 0 ? (
              <p>Nenhum resultado encontrado.</p>
            ) : (
              <ResultList
                results={medicamentos}
                medicamentos={receita}
                role={role}
                onAddMedicamento={addMedicamentoReceita}
                onRedirectToMedicamentoPage={handleRedirectToMedicamentoPage}
                onSearchMedicamentos={handleSearchMedicamentos}
              />
            )}
          </Grid>
        </Row>
      ) : (
        <Row>
          <Grid cols='12 6'>
            {isLoading ? (
              <Skeleton count={20} height={70} />
            ) : medicamentos.length === 0 ? (
              <p>Nenhum resultado encontrado.</p>
            ) : (
              <ReceitaWizard step={step} />
            )}
          </Grid>
          <Grid cols='12 6'>
            <SummaryWizard step={step} />
          </Grid>
        </Row>
      )}

      {medicamentos.length === 0 ? null : <StepPagination step={step} />}

      {/* veiculosUtilizacao search results modal */}
      <ModalButton
        id='modal_results_button'
        target='#modal_results'
        className='d-none'
      />
      <ModalSearchVeiculosUtilizacaoResults
        id='modal_results'
        veiculosUtilizacao={veiculosUtilizacao}
        onSelectVeiculo={handleSelectVeiculoUtilizacao}
        onSaveNewVeiculoUtilizacao={handleSaveNewVeiculoUtilizacao}
        loading={modalLoading}
        setLoading={setModalLoading}
      />
    </div>
  );
}
