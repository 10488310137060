import React, { useRef, useState, useEffect } from 'react';
import './styles.css';

import { Form } from '@unform/web';
import {
  Input,
  RadioButtonContainer,
  RadioButton,
  Checkbox,
} from '../../../components/Form';
import Row from '../../../components/Row';
import Grid from '../../../components/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  setStartsWith as setStartsWithAction,
  setRef as setRefAction,
  setFavorites as setFavoritesAction,
  setTipos as setTiposAction,
} from '../../../stores/searchMedicamentosActions';
import { MdSearch } from 'react-icons/md';
import ButtonFavorite from '../../../components/ButtonFavorite';

export default function SearchContainer() {
  const formRef = useRef(null);

  const history = useHistory();

  const dispatch = useDispatch();

  const [ref, setRef] = useState('');
  const [startsWith, setStartsWith] = useState(false);
  const [favorites, setFavorites] = useState(false);
  const [tipos, setTipos] = useState(['ATIVO', 'FORMULA', 'INDUSTRIALIZADO']);

  const { usu_ch_tipo: role } = useSelector((state) => state.auth.user);

  useEffect(() => {
    const storedFavorites =
      localStorage.getItem('FORMULARIO_SEARCH_FAVORITES') == 'true' || false;

    setFavorites(storedFavorites);
  }, []);

  function handleSubmit({ ref, startsWith, favorites, tipos }) {
    // check if ref is empty
    if (!ref) return;
    if (tipos.length === 0) return;

    // store value on local storage for the next searches
    localStorage.setItem('FORMULARIO_SEARCH_FAVORITES', favorites);

    // set ref on redux
    // search
    dispatch([
      setStartsWithAction(startsWith),
      setRefAction(ref),
      setFavoritesAction(favorites),
      setTiposAction(tipos),
    ]);

    // redirect to result list
    history.push('/search');
  }

  function handleToggleTipo(value) {
    // check if tipos array contain this value
    const valueExists = tipos.find((tipo) => tipo === value);

    // if contains, then remove else add it
    if (valueExists) setTipos(tipos.filter((tipo) => tipo !== value));
    else setTipos([...tipos, value]);
  }

  return (
    <div className='search-container'>
      <Form
        ref={formRef}
        onSubmit={() => handleSubmit({ ref, startsWith, favorites, tipos })}
      >
        <Row>
          <Grid cols='12'>
            {role === 'M' ? (
              <>
                <div className='d-flex justify-content-center'>
                  <Checkbox
                    id='startsWith'
                    name='startsWith'
                    label='Iniciar com...'
                    checked={startsWith}
                    value={startsWith}
                    onChange={(e) => setStartsWith(e.target.checked)}
                  />
                </div>

                <div className='options-container'>
                  <RadioButtonContainer>
                    <RadioButton
                      id='ativo'
                      name='tipo'
                      value='ATIVO'
                      checked={tipos.find((tipo) => tipo === 'ATIVO')}
                      color='secondary'
                      onClick={(e) => handleToggleTipo(e.target.value)}
                    >
                      Ativos
                    </RadioButton>
                    <RadioButton
                      id='formula'
                      name='tipo'
                      value='FORMULA'
                      checked={tipos.find((tipo) => tipo === 'FORMULA')}
                      color='secondary'
                      onClick={(e) => handleToggleTipo(e.target.value)}
                    >
                      Fórmulas
                    </RadioButton>
                    <RadioButton
                      id='industrializado'
                      name='tipo'
                      value='INDUSTRIALIZADO'
                      checked={tipos.find((tipo) => tipo === 'INDUSTRIALIZADO')}
                      color='secondary'
                      onClick={(e) => handleToggleTipo(e.target.value)}
                    >
                      Industrializados
                    </RadioButton>
                  </RadioButtonContainer>

                  <ButtonFavorite
                    className='ml-1'
                    isFavorite={favorites}
                    onFavorite={() => setFavorites(true)}
                    onUnfavorite={() => setFavorites(false)}
                    size={32}
                  />
                </div>
              </>
            ) : null}

            <div className='input-box'>
              <MdSearch size={20} className='search-icon' />
              <Input
                id='ref'
                name='ref'
                placeholder='Pesquise fórmulas, ativos ou industrializados'
                value={ref}
                onChange={(e) => setRef(e.target.value)}
                className='form-control home-search'
              />
            </div>
          </Grid>
        </Row>
      </Form>
    </div>
  );
}
