import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Table, {
  Thead as TableHeader,
  TableRow,
  Tbody,
  TbodyCol as TbCol,
} from '../../../components/Table';
import Modal from '../../../components/Modal';
import ModalButton from '../../../components/ModalButton';
import Button from '../../../components/Button';
import { MdDelete, MdEdit } from 'react-icons/md';
import { insertIf } from '../../../utils/arrays';

function ReceitaModeloList({
  modelos,
  readonly,
  onDelete: handleDelete,
  onEdit: handleEdit,
}) {
  const [modelo, setModelo] = useState(null);

  return modelos.length > 0 ? (
    <div>
      <Table card>
        <TableHeader
          dark
          columns={[
            'Descrição',
            'Margem esquerda',
            'Margem topo',
            ...insertIf(!readonly, 'Ação'),
          ]}
        />

        <Tbody>
          {modelos.map((modelo) => (
            <TableRow key={modelo.rem_in_codigo}>
              <TbCol alignMiddle label='Descrição'>
                {modelo.rem_st_descricao}
              </TbCol>
              <TbCol alignMiddle label='Margem esquerda'>
                {modelo.rem_st_marginleft}
              </TbCol>
              <TbCol alignMiddle label='Margem topo'>
                {modelo.rem_st_margintop}
              </TbCol>
              {readonly ? null : (
                <TbCol alignMiddle label='Ação'>
                  <ModalButton
                    target='#modalDeleteModelo'
                    color='danger'
                    size='sm'
                    handleclick={() => setModelo(modelo)}
                  >
                    <MdDelete size={24} />
                  </ModalButton>

                  <Button
                    className='ml-1'
                    color='warning'
                    size='sm'
                    handleclick={() => handleEdit(modelo)}
                  >
                    <MdEdit size={24} />
                  </Button>
                </TbCol>
              )}
            </TableRow>
          ))}
        </Tbody>
      </Table>

      <Modal
        id='modalDeleteModelo'
        handleClick={() => handleDelete(modelo)}
        handleCancelarClick={() => setModelo(null)}
        btnColor='danger'
        size='sm'
        text='Você tem certeza de que deseja remover o modelo?'
        title='Remover modelo'
      />
    </div>
  ) : (
    <p className='not-found'>Nenhum modelo encontrado</p>
  );
}

ReceitaModeloList.propTypes = {
  modelos: PropTypes.array.isRequired,
  readonly: PropTypes.bool,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
};

ReceitaModeloList.defaultProps = {
  readonly: false,
};

export default ReceitaModeloList;
