import React from 'react';
import './styles.css';
import PropTypes from 'prop-types';

import Button from '../Button';
import { MdEdit } from 'react-icons/md';

function ButtonEdit({ onClick: handleClick, className }) {
  return (
    <Button
      size='sm'
      color='warning'
      handleclick={handleClick}
      className={className}
    >
      <MdEdit size={18} />
    </Button>
  );
}

ButtonEdit.propTypes = {
  onClick: PropTypes.func,
  classnames: PropTypes.string,
};

export default ButtonEdit;
