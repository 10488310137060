import api from './api'
import { toUpperCase } from '../utils/objects'

export function findAll(farmaciaId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      const response = await api.get(`farmacias/${farmaciaId}/medicamentos_industrializados`, {
        headers: { authorization: `Bearer ${token}` }
      });

      return resolve(response.data.data);
    } catch (e) {
      return reject(e);
    }
  })
}

export function findById(farmaciaId, medicamentoId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      const response =
        await api.get(`farmacias/${farmaciaId}/medicamentos_industrializados/${medicamentoId}`, {
          headers: { authorization: `Bearer ${token}` }
        });

      return resolve(response.data.data);
    } catch (e) {
      return reject(e);
    }
  })
}

export function save(farmaciaId, medicamento) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      const response = await api.post(`farmacias/${farmaciaId}/medicamentos_industrializados`,
        toUpperCase(medicamento), {
        headers: { authorization: `Bearer ${token}` }
      });

      return resolve(response.data.data);
    } catch (e) {
      return reject(e);
    }
  })
}

export function update(farmaciaId, medicamentoId, medicamento) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      await api.put(`farmacias/${farmaciaId}/medicamentos_industrializados/${medicamentoId}`,
        toUpperCase(medicamento), {
        headers: { authorization: `Bearer ${token}` }
      });

      return resolve();
    } catch (e) {
      return reject(e);
    }
  })
}

export function remove(farmaciaId, medicamentoId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      await api.delete(`farmacias/${farmaciaId}/medicamentos_industrializados/${medicamentoId}`, {
        headers: { authorization: `Bearer ${token}` }
      });

      return resolve();
    } catch (e) {
      return reject(e);
    }
  })
}
