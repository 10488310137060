const INITIAL_STATE = {
  unidades: []
}

export default function unidadeListReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'UNIDADE_LIST_SET_UNIDADES':
      return {
        ...state,
        unidades: action.payload
      }
    case 'UNIDADE_LIST_CLEAR_ALL':
      return {
        ...state,
        ...INITIAL_STATE
      }
    default:
      return state;
  }
}
