import React, { useRef, useEffect } from 'react'
import { useField } from '@unform/core'
import PropTypes from 'prop-types'

function RadioButton({ children, color, name, id, value, checked, disabled, ...props }) {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value'
    })
  }, [fieldName, registerField]);

  return (
    <label className={`btn btn-${color} ${checked ? 'active ' : 'unactive'} ${disabled ? 'disabled ' : null}`}>
      <input ref={inputRef}
        type="radio"
        id={id} name={name}
        value={value}
        checked={checked}
        disabled={disabled}
        defaultValue={defaultValue}
        {...props} /> {children}

      {error && <p className="text-danger">{error}</p>}
    </label>
  )
}

RadioButton.propTypes = {
  children: PropTypes.any.isRequired,
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  radio: PropTypes.bool,
}

RadioButton.defaultProps = {
  color: 'secondary'
}

export default RadioButton;
