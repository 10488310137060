import { nop } from './baseActions';
import { searchMedicamentos as search } from '../services/SearchMedicamentosService';
import { findAllFavoriteIndustrializados } from '../services/FavoriteIndustrializadosService';
import { findAllFavoriteFormulas } from '../services/FavoriteFormulasService';
import { findAllFavoriteAtivos } from '../services/FavoriteAtivosService';
import { findFormulasAtivos } from '../services/FormulaAtivoService';
import { findAll as findAllUnidades } from '../services/UnidadeService';
import { searchByRef as searchVeiculos } from '../services/SearchVeiculoUtilizacaoService';
import { findById as findVeiculoUtilizacaoById } from '../services/VeiculoUtilizacaoService';
import { searchByRefAndMedico as searchVeiculosByMedico } from '../services/MedicoVeiculoUtilizacaoService';

const setMedicamentos = (medicamentos) => {
  return {
    type: 'SEARCH_MEDICAMENTOS_SET_MEDICAMENTOS',
    payload: medicamentos,
  };
};

export const clearAllAtivos = () => {
  return {
    type: 'SEARCH_MEDICAMENTOS_CLEAR_ALL_ATIVOS',
  };
};

export const setReceitaAtivos = (ativos) => {
  return {
    type: 'SEARCH_MEDICAMENTOS_SET_RECEITA_ATIVOS',
    payload: ativos,
  };
};

export const setAtivos = (ativos) => {
  return {
    type: 'SEARCH_MEDICAMENTOS_SET_ATIVOS',
    payload: ativos,
  };
};

export const addAtivoReceita = (ativo) => {
  return {
    type: 'SEARCH_MEDICAMENTOS_ADD_ATIVO_RECEITA',
    payload: ativo,
  };
};

export const removeAtivo = (ativoId) => {
  return {
    type: 'SEARCH_MEDICAMENTOS_REMOVE_ATIVO',
    payload: ativoId,
  };
};

const setVeiculosUtilizacao = (veiculosUtilizacao) => {
  return {
    type: 'SEARCH_MEDICAMENTOS_SET_VEICULOS_UTILIZACAO',
    payload: veiculosUtilizacao,
  };
};

const setUnidades = (unidades) => {
  return {
    type: 'SEARCH_MEDICAMENTOS_SET_UNIDADES',
    payload: unidades,
  };
};

const setFavoriteAtivos = (ativos) => {
  return {
    type: 'SEARCH_MEDICAMENTOS_SET_FAVORITES_ATIVOS',
    payload: ativos,
  };
};

const setFavoriteFormulas = (formulas) => {
  return {
    type: 'SEARCH_MEDICAMENTOS_SET_FAVORITES_FORMULAS',
    payload: formulas,
  };
};

const setFavoriteIndustrializados = (industrializados) => {
  return {
    type: 'SEARCH_MEDICAMENTOS_SET_FAVORITES_INDUSTRIALIZADOS',
    payload: industrializados,
  };
};

export const setRef = (ref) => {
  return {
    type: 'SEARCH_MEDICAMENTOS_SET_REF',
    payload: ref,
  };
};

export const setStartsWith = (startsWith) => {
  return {
    type: 'SEARCH_MEDICAMENTOS_SET_STARTS_WITH',
    payload: startsWith,
  };
};

export const setFavorites = (favorites) => {
  return {
    type: 'SEARCH_MEDICAMENTOS_SET_FAVORITES',
    payload: favorites,
  };
};

export const setTipos = (tipos) => {
  return {
    type: 'SEARCH_MEDICAMENTOS_SET_TIPOS',
    payload: tipos,
  };
};

export const setPage = (page) => {
  return {
    type: 'SEARCH_MEDICAMENTOS_SET_PAGE',
    payload: page,
  };
};

export const setItemsPerPage = (itemsPerPage) => {
  return {
    type: 'SEARCH_MEDICAMENTOS_SET_ITEMS_PER_PAGE',
    payload: itemsPerPage,
  };
};

export const setTotalItems = (totalItems) => {
  return {
    type: 'SEARCH_MEDICAMENTOS_SET_TOTAL_ITEMS',
    payload: totalItems,
  };
};

export const clearPagination = () => {
  return {
    type: 'SEARCH_MEDICAMENTOS_CLEAR_PAGINATION',
  };
};

export const clearSearch = () => {
  return {
    type: 'SEARCH_MEDICAMENTOS_CLEAR_SEARCH',
  };
};

const setFormulaVeiculoUtilizacao = (veiculoUtilizacao) => {
  return {
    type: 'SEARCH_MEDICAMENTOS_SET_FORMULA_VEICULO_UTILIZACAO',
    payload: veiculoUtilizacao,
  };
};

export const clearFormulaVeiculoUtilizacao = () => {
  return {
    type: 'SEARCH_MEDICAMENTOS_CLEAR_FORMULA_VEICULO_UTILIZACAO',
  };
};

export const clearMedicamentos = () => {
  return {
    type: 'SEARCH_MEDICAMENTOS_CLEAR_MEDICAMENTOS',
  };
};

export const searchMedicamentos = (
  farmaciaId,
  ref,
  favorites,
  tipos,
  page,
  itemsPerPage,
  usuarioId = null,
  startsWith = false,
  role
) => {
  return async (dispatch) => {
    try {
      const response = await search(
        farmaciaId,
        { ref, startsWith, favorites, tipos },
        { page, itemsPerPage },
        usuarioId,
        role
      );
      // console.log("action medicamento: ", response.data)
      return dispatch([
        setMedicamentos(response.data),
        setTotalItems(response['_metadata']?.totalItems),
      ]);
    } catch (e) {
      return dispatch(nop());
    }
  };
};

export const fetchFavorites = (farmaciaId, usuarioId) => {
  return async (dispatch) => {
    try {
      const ativos = await findAllFavoriteAtivos(farmaciaId, usuarioId);
      const formulas = await findAllFavoriteFormulas(farmaciaId, usuarioId);
      const industrializados = await findAllFavoriteIndustrializados(
        farmaciaId,
        usuarioId
      );

      return dispatch([
        setFavoriteAtivos(ativos),
        setFavoriteFormulas(formulas),
        setFavoriteIndustrializados(industrializados),
      ]);
    } catch (e) {
      return dispatch(nop());
    }
  };
};

export const fetchAtivosByFormula = (farmaciaId, formulaId) => {
  return async (dispatch) => {
    try {
      const ativos = await findFormulasAtivos(farmaciaId, formulaId);

      return dispatch(setAtivos(ativos));
    } catch (e) {
      return dispatch(nop());
    }
  };
};

export const fetchUnidades = (farmaciaId) => {
  return async (dispatch) => {
    try {
      const unidades = await findAllUnidades(farmaciaId);

      return dispatch(setUnidades(unidades));
    } catch (e) {
      return dispatch(nop());
    }
  };
};

export const searchVeiculosUtilizacao = (farmaciaId, ref, role, medicoId) => {
  return async (dispatch) => {
    try {
      let veiculosUtilizacao = [];

      if (role === 'M')
        veiculosUtilizacao = await searchVeiculosByMedico(
          farmaciaId,
          ref,
          null,
          medicoId
        );
      else veiculosUtilizacao = await searchVeiculos(farmaciaId, ref);

      return dispatch(setVeiculosUtilizacao(veiculosUtilizacao));
    } catch (e) {
      return dispatch(nop());
    }
  };
};

export const fetchVeiculoUtilizacaoById = (farmaciaId, veiculoUtilizacaoId) => {
  return async (dispatch) => {
    try {
      const veiculoUtilizacao = await findVeiculoUtilizacaoById(
        farmaciaId,
        veiculoUtilizacaoId
      );

      return dispatch(setFormulaVeiculoUtilizacao(veiculoUtilizacao));
    } catch (e) {
      return dispatch(nop());
    }
  };
};
