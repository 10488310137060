import api from './api'

export function findAtivosVeiculosUtilizacao(farmaciaId, ativoId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      const response = await api.get(`farmacias/${farmaciaId}/ativos/${ativoId}/veiculos_utilizacao`, {
        headers: { authorization: `Bearer ${token}` }
      });

      return resolve(response.data.data);
    } catch (e) {
      return reject(e);
    }
  })
}


export function linkAtivoToVeiculoUtilizacao(farmaciaId, ativoId, veiculoUtilizacaoId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      await api.post(`farmacias/${farmaciaId}/ativos/${ativoId}/veiculos_utilizacao/${veiculoUtilizacaoId}`, {}, {
        headers: { authorization: `Bearer ${token}` }
      });

      return resolve();
    } catch (e) {
      return reject(e);
    }
  })
}

export function linkMultipleVeiculosUtilizacaoToAtivo(farmaciaId, ativoId, veiculosUtilizacao) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      await api.post(`farmacias/${farmaciaId}/ativos/${ativoId}/veiculos_utilizacao`, {
        veiculosUtilizacao
      }, {
        headers: { authorization: `Bearer ${token}` }
      });

      return resolve();
    } catch (e) {
      return reject(e);
    }
  })
}

export function removeLinkAtivoVeiculo(farmaciaId, ativoId, veiculoUtilizacaoId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      await api.delete(`farmacias/${farmaciaId}/ativos/${ativoId}/veiculos_utilizacao/${veiculoUtilizacaoId}`, {
        headers: { authorization: `Bearer ${token}` }
      });

      return resolve();
    } catch (e) {
      return reject(e);
    }
  })
}