import { nop } from './baseActions'
import { findIndustrializadosPendentes } from '../services/MedicamentosPendentesService'

const setIndustrializados = industrializados => {
  return {
    type: 'INDUSTRIALIZADOS_PENDENTES_SET_INDUSTRIALIZADOS',
    payload: industrializados
  }
}

export const clearIndustrializados = () => {
  return {
    type: 'INDUSTRIALIZADOS_PENDENTES_CLEAR_INDUSTRIALIZADOS'
  }
}

export const fetchIndustrializadosPendentes = farmaciaId => {
  return async dispatch => {
    try {
      const industrializados = await findIndustrializadosPendentes(farmaciaId);

      return dispatch(setIndustrializados(industrializados));
    } catch (e) {
      return dispatch(nop());
    }
  }
}
