import React, { useState, Fragment } from 'react';
import './styles.css';
import PropTypes from 'prop-types';

import Table, {
  Thead as TableHeader,
  TableRow,
  Tbody,
  TbodyCol as TbCol,
} from '../../../components/Table';
import Modal from '../../../components/Modal';
import ModalButton from '../../../components/ModalButton';
import Button from '../../../components/Button';
import { MdDelete, MdEdit } from 'react-icons/md';
import { insertIf } from '../../../utils/arrays';
import Media from 'react-media';

function renderMedicos(medicosAtivos, handleRemoveLinkAtivoMedico) {
  if (medicosAtivos.length > 0)
    return (
      <>
        <p>
          <span className='font-weight-bold'>Médicos</span>
        </p>
        <ul className='list-group list-group-flush'>
          {medicosAtivos.map((medicoAtivo, i) => (
            <li
              key={`medico-${i}`}
              className='list-group-item d-flex justify-content-between'
            >
              {medicoAtivo.usuario.usu_st_nome}

              <Button
                size='sm'
                color='danger'
                handleclick={() => handleRemoveLinkAtivoMedico(medicoAtivo)}
              >
                <MdDelete size={18} />
              </Button>
            </li>
          ))}
        </ul>
      </>
    );

  return <p>Nenhum médico vinculado...</p>;
}

function AtivoList({
  ativos,
  readonly,
  onDelete: handleDelete,
  onEdit: handleEdit,
  onRemoveLinkAtivoMedico: handleRemoveLinkAtivoMedico,
}) {
  const [ativo, setAtivo] = useState(null);

  const columns = [
    'Descrição',
    'Indicação de uso',
    'Uso',
    'Observação',
    ...insertIf(!readonly, 'Ação'),
  ];

  return ativos.length > 0 ? (
    <div>
      <Table card>
        <TableHeader dark columns={columns} />

        <Tbody>
          {ativos.map((ativo, index) => (
            <Fragment key={`ativo-row-${index}`}>
              <TableRow
                className='clickable-row upper'
                data-toggle='collapse'
                data-target={`#table-row-${index}`}
              >
                <TbCol alignMiddle label='Descrição'>
                  {ativo.atv_st_descricao}
                </TbCol>
                <TbCol alignMiddle label='Indicação de uso'>
                  {ativo.atv_st_indicacaouso}
                </TbCol>
                <TbCol alignMiddle label='Uso'>
                  {ativo.atv_st_uso}
                </TbCol>
                <TbCol alignMiddle label='Observação'>
                  {ativo.atv_st_observacao || ' - '}
                </TbCol>
                {readonly ? null : (
                  <Media query={{ maxWidth: 600 }}>
                    {(matches) =>
                      matches ? (
                        <TbCol alignMiddle label='Ação'>
                          <ModalButton
                            target='#modalDeleteAtivo'
                            color='danger'
                            size='sm'
                            handleclick={() => setAtivo(ativo)}
                          >
                            <MdDelete size={24} />
                          </ModalButton>

                          <Button
                            className='ml-1'
                            color='warning'
                            size='sm'
                            handleclick={() => handleEdit(ativo)}
                          >
                            <MdEdit size={24} />
                          </Button>
                        </TbCol>
                      ) : (
                        <TbCol alignMiddle flex label='Ação'>
                          <ModalButton
                            target='#modalDeleteAtivo'
                            color='danger'
                            size='sm'
                            handleclick={() => setAtivo(ativo)}
                          >
                            <MdDelete size={24} />
                          </ModalButton>

                          <Button
                            className='ml-1'
                            color='warning'
                            size='sm'
                            handleclick={() => handleEdit(ativo)}
                          >
                            <MdEdit size={24} />
                          </Button>
                        </TbCol>
                      )
                    }
                  </Media>
                )}
              </TableRow>

              {/* Hidden row */}
              <TableRow className='upper'>
                <td colSpan={columns.length} className='hidden-row text-start'>
                  <div
                    id={`table-row-${index}`}
                    className='accordian-body collapse p-1'
                  >
                    {renderMedicos(
                      ativo.medicos_ativos,
                      handleRemoveLinkAtivoMedico
                    )}
                  </div>
                </td>
              </TableRow>
            </Fragment>
          ))}
        </Tbody>
      </Table>

      <Modal
        id='modalDeleteAtivo'
        handleClick={() => handleDelete(ativo)}
        handleCancelarClick={() => setAtivo(null)}
        btnColor='danger'
        size='sm'
        text='Você tem certeza de que deseja remover este ativo?'
        title='Remover ativo'
      />
    </div>
  ) : (
    <p className='not-found'>Nenhum ativo encontrado</p>
  );
}

AtivoList.propTypes = {
  ativos: PropTypes.array.isRequired,
  readonly: PropTypes.bool,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onRemoveLinkAtivoMedico: PropTypes.func,
};

AtivoList.defaultProps = {
  readonly: false,
};

export default AtivoList;
