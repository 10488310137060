const INITIAL_STATE = {
  representantes: [],
  medicos: [],
  selectedRepresentante: null,
  links: [],
}

export default function linkRepresentanteMedicoReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'LINK_REPRESENTANTE_MEDICO_SET_REPRESENTANTES':
      return {
        ...state,
        representantes: action.payload
      }
    case 'LINK_REPRESENTANTE_MEDICO_SET_MEDICOS':
      return {
        ...state,
        medicos: action.payload
      }
    case 'LINK_REPRESENTANTE_MEDICO_SET_REPRESENTANTE':
      return {
        ...state,
        selectedRepresentante: action.payload
      }
    case 'LINK_REPRESENTANTE_MEDICO_SET_LINKS_REPRESENTANTE':
      return {
        ...state,
        links: action.payload
      }
    case 'LINK_REPRESENTANTE_MEDICO_CLEAR_ALL':
      return {
        ...state,
        ...INITIAL_STATE
      }
    default:
      return state
  }
}
