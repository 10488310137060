import { nop } from './baseActions';
import { findById } from '../services/EspecialidadeService';

const setEspecialidade = (especialidade) => {
  return {
    type: 'ESPECIALIDADE_EDIT_SET_ESPECIALIDADE',
    payload: especialidade,
  };
};

export const clearEspecialidade = () => {
  return {
    type: 'ESPECIALIDADE_EDIT_CLEAR_ALL',
  };
};

export const fetchById = (id) => {
  return async (dispatch) => {
    try {
      const especialidade = await findById(id);

      return dispatch(setEspecialidade(especialidade));
    } catch (e) {
      return dispatch(nop());
    }
  };
};
