const INITIAL_STATE = {
  receitas: [],
  medicos: {
    list: [],
    selectedMedico: null,
  },
  search: {
    ref: '',
    from: null,
    to: null,
  },
  pagination: {
    page: 1,
    itemsPerPage: 15,
    totalItems: 0,
  }
}

export default function receitaListReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'RECEITA_LIST_SET_RECEITAS':
      return {
        ...state,
        receitas: action.payload
      }
    case 'RECEITA_LIST_SET_MEDICOS_LIST':
      return {
        ...state,
        medicos: {
          ...state.medicos,
          list: action.payload
        }
      }
    case 'RECEITA_LIST_SET_MEDICOS_SELECTED':
      return {
        ...state,
        medicos: {
          ...state.medicos,
          selectedMedico: action.payload
        }
      }
    case 'RECEITA_LIST_SET_PAGINATION_PAGE':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.payload,
        }
      }
    case 'RECEITA_LIST_SET_PAGINATION_ITEMS_PER_PAGE':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          itemsPerPage: action.payload,
        }
      }
    case 'RECEITA_LIST_SET_PAGINATION_TOTAL_ITEMS':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          totalItems: action.payload,
        }
      }
    case 'RECEITA_LIST_SET_SEARCH_REF':
      return {
        ...state,
        search: {
          ...state.search,
          ref: action.payload,
        }
      }
    case 'RECEITA_LIST_SET_SEARCH_FROM':
      return {
        ...state,
        search: {
          ...state.search,
          from: action.payload,
        }
      }
    case 'RECEITA_LIST_SET_SEARCH_TO':
      return {
        ...state,
        search: {
          ...state.search,
          to: action.payload,
        }
      }
    case 'RECEITA_LIST_CLEAR_ALL':
      return {
        ...state,
        ...INITIAL_STATE
      }
    default:
      return state;
  }
}
