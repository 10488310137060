import React, { useState, useRef, useEffect } from 'react';
import './styles.css';
import PropTypes from 'prop-types';

import Modal from '../../../components/Modal';
import { Form } from '@unform/web';
import {
  Input,
  TextArea,
  RadioButtonContainer as ButtonGroup,
  RadioButton as GroupBtn,
} from '../../../components/Form';
import Button from '../../../components/Button';
import CloseModalButton from '../../../components/CloseModalButton';

function ModalAddAtivo({ id, ativo, onSubmit: handleSubmit }) {
  const formRef = useRef(null);

  const [uso, setUso] = useState('');
  const [indicacaoUso, setIndicacaoUso] = useState('');
  const [concentracaoMinima, setConcentracaoMinima] = useState('');
  const [concentracaoMaxima, setConcentracaoMaxima] = useState('');
  const [concentracaoPrescrita, setConcentracaoPrescrita] = useState('');
  const [observacao, setObservacao] = useState('');

  useEffect(() => {
    if (ativo?.tipo_uso === 'TOPICO') setUso('TOPICO');

    if (ativo?.tipo_uso === 'ORAL') setUso('ORAL');

    setIndicacaoUso(ativo?.uso);
    setConcentracaoMinima(getConcentracaoMinima(uso));
    setConcentracaoMaxima(getConcentracaoMaxima(uso));
    setConcentracaoPrescrita(getConcentracaoDiaria(uso));
    setObservacao(ativo?.observacao);
  }, [ativo, uso]);

  function getConcentracaoMinima(uso) {
    if (uso === 'TOPICO') return ativo?.concentracaoMinimaTopico;
    if (uso === 'ORAL') return ativo?.concentracaoMinimaOral;
  }

  function getConcentracaoMaxima(uso) {
    if (uso === 'TOPICO') return ativo?.concentracaoMaximaTopico;
    if (uso === 'ORAL') return ativo?.concentracaoMaximaOral;
  }

  function getConcentracaoDiaria(uso) {
    if (uso === 'TOPICO') return ativo?.concentracaoDiariaTopico;
    if (uso === 'ORAL') return ativo?.concentracaoDiariaOral;
  }

  return (
    <Modal
      id={id}
      title={`Adicionar na receita o ativo ${ativo?.nome}`}
      hideFooter
    >
      {ativo?.tipo === 'ATIVO' ? (
        <Form
          ref={formRef}
          onSubmit={(data) => handleSubmit({ ...data, tipo_uso: uso })}
        >
          <TextArea
            id='indicacaoUso'
            name='indicacaoUso'
            label='Indicação de uso'
            className='form-control h125px'
            placeholder='Indicação de uso'
            value={indicacaoUso}
            onChange={(e) => setIndicacaoUso(e.target.value)}
          />

          <TextArea
            id='observacao'
            name='observacao'
            label='Observação'
            className='form-control h125px'
            placeholder='Observação'
            value={observacao}
            onChange={(e) => setObservacao(e.target.value)}
          />

          <div className='d-flex justify-content-center mb-4'>
            <ButtonGroup>
              {ativo?.tipo_uso === 'ORAL' ? null : (
                <GroupBtn
                  name='tipo_uso'
                  id='TOPICO'
                  value='TOPICO'
                  checked={uso === 'TOPICO'}
                  disabled={ativo?.tipo_uso === 'ORAL'}
                  onChange={(e) => setUso(e.target.checked ? 'TOPICO' : '')}
                >
                  TÓPICO
                </GroupBtn>
              )}

              {ativo?.tipo_uso === 'TOPICO' ? null : (
                <GroupBtn
                  name='tipo_uso'
                  id='ORAL'
                  value='ORAL'
                  checked={uso === 'ORAL'}
                  disabled={ativo?.tipo_uso === 'TOPICO'}
                  onChange={(e) => setUso(e.target.checked ? 'ORAL' : '')}
                >
                  ORAL
                </GroupBtn>
              )}
            </ButtonGroup>
          </div>

          {uso !== 'TOPICO' && uso !== 'ORAL' ? null : (
            <>
              <Input
                id='concentracaoMinima'
                name='concentracaoMinima'
                label={`Concentração mínima ${
                  uso === 'ORAL' ? '(oral)' : '(tópico)'
                }`}
                placeholder='Concentração mínima'
                value={concentracaoMinima}
                onChange={(e) => setConcentracaoMinima(e.target.value)}
              />

              <Input
                id='concentracaoMaxima'
                name='concentracaoMaxima'
                label={`Concentração máxima ${
                  uso === 'ORAL' ? '(oral)' : '(tópico)'
                }`}
                placeholder='Concentração máxima'
                value={concentracaoMaxima}
                onChange={(e) => setConcentracaoMaxima(e.target.value)}
              />
            </>
          )}

          <TextArea
            id='concentracaoPrescrita'
            name='concentracaoPrescrita'
            label='Concentração prescrita'
            placeholder='Concentração prescrita'
            value={concentracaoPrescrita}
            onChange={(e) => setConcentracaoPrescrita(e.target.value)}
          />

          <div className='text-right'>
            {/* NOTE: for some reason, when the button has the property 'data-dismiss' it cannot submit */}
            <CloseModalButton
              id='closeModalButton'
              color='secondary'
              className='mr-1'
            >
              Cancelar
            </CloseModalButton>

            <Button
              type='submit'
              handleclick={() => {
                document.getElementById('closeModalButton').click();
              }}
            >
              Vincular
            </Button>
          </div>
        </Form>
      ) : (
        <p>Medicamento inválido</p>
      )}
    </Modal>
  );
}

ModalAddAtivo.propTypes = {
  id: PropTypes.string.isRequired,
  ativo: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default ModalAddAtivo;
