import React, { useState, useEffect } from 'react';
import './styles.css';

import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchAllAtivosByFarmacia,
  removeAtivo as deleteAtivo,
  clearAtivos,
  setPage,
  setRef,
  setStartsWith,
} from '../../stores/ativoListActions';
import Title from '../../components/Title';
import ButtonAdd from '../../components/ButtonAdd';
import Button from '../../components/Button';
import Skeleton from 'react-loading-skeleton';
import Row from '../../components/Row';
import Grid from '../../components/Grid';
import AtivoList from './AtivoList';
import Pagination from '../../components/Pagination';
import AtivoSearch from './AtivoSearch';
import { unlinkAtivoMedico } from '../../services/LinkMedicoToMedicamentoService';

export default function AtivoListContainer() {
  const [isLoading, setLoading] = useState(true);
  const [isFirst, setFirst] = useState(true);

  const history = useHistory();
  const dispatch = useDispatch();

  const ativos = useSelector((state) => state.ativo.list.ativos);

  // pagination
  const page = useSelector((state) => state.ativo.list.pagination.page);
  const itemsPerPage = useSelector(
    (state) => state.ativo.list.pagination.itemsPerPage
  );
  const totalItems = useSelector(
    (state) => state.ativo.list.pagination.totalItems
  );

  // search
  const ref = useSelector((state) => state.ativo.list.search.ref);
  const startsWith = useSelector((state) => state.ativo.list.search.startsWith);

  const farmaciaId = localStorage.getItem('FORMULARIO_FARMACIA_ID');

  useEffect(() => {
    dispatch(fetchAllAtivosByFarmacia(farmaciaId, '', 1, itemsPerPage, false));

    return () => dispatch(clearAtivos());
  }, []);

  useEffect(() => {
    if (!isFirst) setLoading(false);
    else setFirst(false);
  }, [ativos]);

  function removeAtivo(ativo) {
    dispatch(deleteAtivo(farmaciaId, ativo));
  }

  function editAtivo(ativo) {
    history.push(`/ativos/${ativo.atv_in_codigo}/edit`);
  }

  async function removeLinkAtivoMedico(medicoFormula) {
    setLoading(true);

    await unlinkAtivoMedico(
      farmaciaId,
      medicoFormula.usu_in_codigo,
      medicoFormula.atv_in_codigo
    );

    dispatch(
      fetchAllAtivosByFarmacia(farmaciaId, ref, page, itemsPerPage, startsWith)
    );
  }

  function handlePaginate(pgNumber) {
    if (pgNumber === page) return;

    setLoading(true);

    dispatch(setPage(pgNumber));
    dispatch(
      fetchAllAtivosByFarmacia(
        farmaciaId,
        ref,
        pgNumber,
        itemsPerPage,
        startsWith
      )
    );
  }

  function handleSearch({ ref: refText, startsWith: searchStartsWith }) {
    if (ref === refText && searchStartsWith === startsWith) return;

    setLoading(true);

    dispatch([
      setPage(1),
      setRef(refText),
      setStartsWith(searchStartsWith),
      fetchAllAtivosByFarmacia(
        farmaciaId,
        refText,
        1,
        itemsPerPage,
        searchStartsWith
      ),
    ]);
  }

  function handleClearSearch() {
    setLoading(true);

    dispatch([
      setPage(1),
      setRef(''),
      setStartsWith(false),
      fetchAllAtivosByFarmacia(farmaciaId, '', 1, itemsPerPage, false),
    ]);
  }

  return (
    <div className='ativo-container'>
      <Title
        link='/'
        component={() => [
          <Button
            key={1}
            color='secondary'
            size='sm'
            className='mr-1'
            handleclick={() => history.push('/ativos/pendentes')}
          >
            Ativos pendentes
          </Button>,
          <ButtonAdd key={2} onClick={() => history.push('/ativos/new')} />,
        ]}
      >
        Listagem de ativos
      </Title>

      <Row>
        <Grid cols='12'>
          <AtivoSearch onSubmit={handleSearch} onClear={handleClearSearch} />
        </Grid>
      </Row>

      <Row>
        <Grid cols='12'>
          {isLoading ? (
            <Skeleton count={5} height={30} />
          ) : (
            <AtivoList
              ativos={ativos}
              onDelete={removeAtivo}
              onEdit={editAtivo}
              onRemoveLinkAtivoMedico={removeLinkAtivoMedico}
            />
          )}
        </Grid>
      </Row>

      {ativos.length === 0 ? null : (
        <Row>
          <Grid cols='12'>
            <Pagination
              itemsPerPage={itemsPerPage}
              page={page}
              paginate={handlePaginate}
              totalItems={totalItems}
            />
          </Grid>
        </Row>
      )}
    </div>
  );
}
