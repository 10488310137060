const INITIAL_STATE = {
  modelos: [],
}

export default function receitaModeloRepresentanteReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'RECEITA_MODELO_REPRESENTANTE_SET_MODELOS':
      return {
        ...state,
        modelos: action.payload,
      }
    case 'RECEITA_MODELO_REPRESENTANTE_CLEAR_ALL':
      return {
        ...state,
        ...INITIAL_STATE
      }
    default:
      return state
  }
}
