import React from 'react'
import './styles.css'
import PropTypes from 'prop-types'

function CardTitle({ children, className }) {
  return <h5 className={`card-title text-dark ${className}`}>{children}</h5>;
}

CardTitle.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
}

export default CardTitle;
