import React from 'react';
import './styles.css';
import PropTypes from 'prop-types';

import Modal from '../../../../components/Modal';

function ModalView({
  formulaAtivo,
  onClick: handleClick,
  onCancelarClick: handleCancelarClick,
}) {
  return (
    <Modal
      id='modalView'
      title={formulaAtivo?.ativo?.atv_st_descricao}
      handleClick={handleClick}
      handleCancelarClick={handleCancelarClick}
    >
      <div>
        <div className='form-group mb-2'>
          <label htmlFor='doseMinimaView'>Concentração mínima</label>
          <input
            id='doseMinimaView'
            className='form-control'
            name='doseMinimaView'
            value={formulaAtivo?.fat_st_concentracaominima || ''}
            disabled
          />
        </div>

        <div className='form-group mb-2'>
          <label htmlFor='doseMaximaView'>Concentração máxima</label>
          <input
            id='doseMaximaView'
            className='form-control'
            name='doseMaximaView'
            value={formulaAtivo?.fat_st_concentracaomaxima || ''}
            disabled
          />
        </div>

        <div className='form-group mb-2'>
          <label htmlFor='doseDiariaView'>Concentração usual</label>
          <input
            id='doseDiariaView'
            className='form-control'
            name='doseDiariaView'
            value={formulaAtivo?.fat_st_concentracaodiaria || ''}
            disabled
          />
        </div>

        <div className='form-group'>
          <label htmlFor='indicacaoUsoView'>Indicação de uso</label>
          <input
            id='indicacaoUsoView'
            className='form-control'
            name='indicacaoUsoView'
            value={formulaAtivo?.ativo?.atv_st_indicacaouso || ''}
            disabled
          />
        </div>
      </div>
    </Modal>
  );
}

ModalView.propTypes = {
  formulaAtivo: PropTypes.object,
  onCancelarClick: PropTypes.func,
};

export default ModalView;
