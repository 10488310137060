import api from './api'
import { toast } from 'react-toastify'

export function searchByFarmacia(farmaciaId, ref, from, to) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      const response = await api.get(`farmacias/${farmaciaId}/receitas/search`, {
        headers: { authorization: `Bearer ${token}` },
        params: { ref, from, to }
      });

      return resolve(response.data.data);
    } catch (e) {
      toast.error("Falha ao pesquisar receita");
      return reject(e);
    }
  })
}

export function searchByMedico(
  farmaciaId,
  usuarioId,
  searchFilters = { ref: '' },
  paginationFilters = { page: 1, itemsPerPage: 15 }) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      const response = await api.get(`farmacias/${farmaciaId}/usuarios/${usuarioId}/receitas/search`, {
        headers: { authorization: `Bearer ${token}` },
        params: { ...searchFilters, ...paginationFilters }
      });

      return resolve(response.data);
    } catch (e) {
      toast.error("Falha ao pesquisar receita");
      return reject(e);
    }
  })
}
