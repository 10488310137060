import React, { useState, useEffect } from 'react';
import './styles.css';

import { useSelector, useDispatch } from 'react-redux';
import {
  fetchAllFormulasByMedico,
  removeLinkFormulaMedico,
  fetchFavoriteFormulas,
  clearFormulas,
  setPage,
  setRef,
  setStartsWith,
} from '../../stores/medicoFormulasListActions';
import Title from '../../components/Title';
import Skeleton from 'react-loading-skeleton';
import Row from '../../components/Row';
import Grid from '../../components/Grid';
import FormulaList from './FormulaList';
import {
  favoriteFormula,
  unfavoriteFormula,
} from '../../services/FavoriteFormulasService';
import Button from '../../components/Button';
import { MdFavorite } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { setLoading as loading } from '../../stores/loadingActions';
import Pagination from '../../components/Pagination';
import Search from '../FormulaListContainer/FormulaSearch';

export default function FormulasMedicoListContainer() {
  const [isLoading, setLoading] = useState(true);
  const [isFirst, setFirst] = useState(true);

  const dispatch = useDispatch();
  const history = useHistory();

  const formulas = useSelector((state) => state.medico.formula.list.formulas);
  const usuarioId = useSelector((state) => state.auth.user.usu_in_codigo);

  const favorites = useSelector((state) => state.medico.formula.list.favorites);

  // receita
  const receita = useSelector((state) => state.receita.new.medicamentos);

  // pagination
  const page = useSelector(
    (state) => state.medico.formula.list.pagination.page
  );
  const itemsPerPage = useSelector(
    (state) => state.medico.formula.list.pagination.itemsPerPage
  );
  const totalItems = useSelector(
    (state) => state.medico.formula.list.pagination.totalItems
  );

  // search
  const ref = useSelector((state) => state.medico.formula.list.search.ref);
  const startsWith = useSelector(
    (state) => state.medico.formula.list.search.startsWith
  );

  const farmaciaId = localStorage.getItem('FORMULARIO_FARMACIA_ID');

  useEffect(() => {
    dispatch([
      fetchAllFormulasByMedico(
        farmaciaId,
        usuarioId,
        ref,
        page,
        itemsPerPage,
        false
      ),
      fetchFavoriteFormulas(farmaciaId, usuarioId),
    ]);

    return () => dispatch(clearFormulas());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isFirst) setLoading(false);
    else setFirst(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formulas]);

  async function handleFavorite(formula) {
    dispatch(loading(true));

    await favoriteFormula(farmaciaId, usuarioId, formula.for_in_codigo);

    dispatch([fetchFavoriteFormulas(farmaciaId, usuarioId), loading(false)]);
  }

  async function handleUnfavorite(formula) {
    dispatch(loading(true));

    await unfavoriteFormula(farmaciaId, usuarioId, formula.for_in_codigo);

    dispatch([fetchFavoriteFormulas(farmaciaId, usuarioId), loading(false)]);
  }

  function handlePaginate(pgNumber) {
    if (pgNumber === page) return;

    setLoading(true);

    dispatch([
      setPage(pgNumber),
      fetchAllFormulasByMedico(
        farmaciaId,
        usuarioId,
        ref,
        pgNumber,
        itemsPerPage,
        startsWith
      ),
    ]);
  }

  function handleSearch({ ref: refText, startsWith: searchStartsWith }) {
    if (refText === ref && searchStartsWith === startsWith) return;

    setLoading(true);

    dispatch([
      setPage(1),
      setRef(refText),
      setStartsWith(searchStartsWith),
      fetchAllFormulasByMedico(
        farmaciaId,
        usuarioId,
        refText,
        1,
        itemsPerPage,
        searchStartsWith
      ),
    ]);
  }

  function handleClearSearch() {
    setLoading(true);

    dispatch([
      setPage(1),
      setRef(''),
      setStartsWith(false),
      fetchAllFormulasByMedico(
        farmaciaId,
        usuarioId,
        '',
        1,
        itemsPerPage,
        false
      ),
    ]);
  }

  function sugestNewFormula() {
    history.push('/formulas/new', { status: 'P', usuarioId });
  }

  function handleRemoveLinkFormula(formula) {
    setLoading(true);

    dispatch(
      removeLinkFormulaMedico(farmaciaId, usuarioId, formula.for_in_codigo)
    );
  }

  function ButtonFavorites() {
    return (
      <Button
        color='secondary'
        size='sm'
        className='d-flex align-items-center mr-1'
        handleclick={() =>
          history.push(`/medicos/${usuarioId}/formulas/favoritos`)
        }
      >
        Favoritos
        <MdFavorite size={18} />
      </Button>
    );
  }

  function ButtonSugest() {
    return (
      <Button color='secondary' size='sm' handleclick={sugestNewFormula}>
        Adicionar fórmula
      </Button>
    );
  }

  function handleAddFormulaReceita(formula) {
    history.push('/search', {
      step: 'NEW_FORMULA',
      formula: {
        codigo: formula.for_in_codigo,
        nome: formula.for_st_nome,
        uso: formula.for_st_recomendacaouso,
        posologia: formula.for_st_posologia,
        observacao: formula.for_st_observacao,
        qsp: formula.for_bo_qsp,
        quantidadeVeiculo: formula.for_in_quantidadeveiculo,
        id_unidade: formula.uni_in_codigo,
        veiculoUtilizacao: formula.veu_in_codigo,
        tipo: 'FORMULA',
      },
    });
  }

  function handleEditFormula(formula) {
    history.push(`/m/formulas/${formula.for_in_codigo}/edit`, {
      origin: '/formulas',
      page,
      ref,
    });
  }

  return (
    <div className='formula-container'>
      <Title
        link='/'
        component={() => [
          <ButtonFavorites key={ButtonFavorites} />,
          <ButtonSugest key={ButtonSugest} />,
        ]}
      >
        Listagem de formulas
      </Title>

      <Row>
        <Grid cols='12'>
          <Search onSubmit={handleSearch} onClear={handleClearSearch} />
        </Grid>
      </Row>

      <Row>
        <Grid cols='12'>
          {isLoading ? (
            <Skeleton count={5} height={30} />
          ) : (
            <FormulaList
              formulas={formulas}
              favorites={favorites}
              onFavorite={handleFavorite}
              onUnfavorite={handleUnfavorite}
              onAddFormulaReceita={handleAddFormulaReceita}
              onEditFormula={handleEditFormula}
              onRemoveFormula={handleRemoveLinkFormula}
              receita={receita}
            />
          )}
        </Grid>
      </Row>

      {formulas.length > 0 ? (
        <Row>
          <Grid cols='12'>
            <Pagination
              page={page}
              itemsPerPage={itemsPerPage}
              totalItems={totalItems}
              paginate={handlePaginate}
            />
          </Grid>
        </Row>
      ) : null}
    </div>
  );
}
