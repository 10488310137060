import { nop } from './baseActions';
import {
  findAllMedicosByFarmacia,
  findAllMedicosByRepresentante,
} from '../services/FindMedicosService';
import { searchMedicamentos as search } from '../services/SearchMedicamentosService';
import { findMedicamentosByMedico } from '../services/LinkMedicoToMedicamentoService';
import { setLoading } from './loadingActions';

const setAllMedicos = (medicos) => {
  return {
    type: 'LINK_MEDICO_MEDICAMENTO_SET_MEDICOS',
    payload: medicos,
  };
};

export const setSelectedMedico = (medico) => {
  return {
    type: 'LINK_MEDICO_MEDICAMENTO_SET_MEDICO',
    payload: medico,
  };
};

const setAllMedicamentos = (medicamentos) => {
  return {
    type: 'LINK_MEDICO_MEDICAMENTO_SET_MEDICAMENTOS',
    payload: medicamentos,
  };
};

const setLinksMedicoMedicamentos = (links) => {
  return {
    type: 'LINK_MEDICO_MEDICAMENTO_SET_LINKS',
    payload: links,
  };
};

export const setRef = (ref) => {
  return {
    type: 'LINK_MEDICO_MEDICAMENTO_SET_REF',
    payload: ref,
  };
};

export const setTipo = (tipo) => {
  return {
    type: 'LINK_MEDICO_MEDICAMENTO_SET_TIPO',
    payload: tipo,
  };
};

const setTotalItems = (totalItems) => {
  return {
    type: 'LINK_MEDICO_MEDICAMENTO_SET_TOTAL_ITEMS',
    payload: totalItems,
  };
};

export const setPage = (page) => {
  return {
    type: 'LINK_MEDICO_MEDICAMENTO_SET_PAGE',
    payload: page,
  };
};

export const clearAll = () => {
  return {
    type: 'LINK_MEDICO_MEDICAMENTO_CLEAR_ALL',
  };
};

export const fetchAllMedicosByFarmacia = (farmaciaId) => {
  return async (dispatch) => {
    try {
      const medicos = await findAllMedicosByFarmacia(farmaciaId);

      return dispatch(setAllMedicos(medicos));
    } catch (e) {
      return dispatch(nop());
    }
  };
};

export const fetchAllMedicosByFarmaciaAndReprentante = (
  farmaciaId,
  representanteId
) => {
  return async (dispatch) => {
    try {
      const medicos = await findAllMedicosByRepresentante(
        farmaciaId,
        representanteId
      );

      return dispatch(setAllMedicos(medicos));
    } catch (e) {
      return dispatch(nop());
    }
  };
};

export const fetchLinksMedicoMedicamentos = (farmaciaId, usuarioId) => {
  return async (dispatch) => {
    try {
      const medicamentos = await findMedicamentosByMedico(
        farmaciaId,
        usuarioId
      );

      return dispatch([
        setLinksMedicoMedicamentos(medicamentos),
        setLoading(false),
      ]);
    } catch (e) {
      return dispatch(nop());
    }
  };
};

export const searchMedicamentos = (
  farmaciaId,
  searchFilters = { ref: '', favorites: false },
  paginationFilters = { page: 1, itemsPerPage: 15 },
  representanteId
) => {
  return async (dispatch) => {
    console.log('*** REP ID: ', representanteId);
    try {
      const response = representanteId
        ? await search(
            farmaciaId,
            searchFilters,
            paginationFilters,
            representanteId,
            'R'
          )
        : await search(farmaciaId, searchFilters, paginationFilters, null, 'G');

      return dispatch([
        setAllMedicamentos(response.data),
        setTotalItems(response['_metadata']?.totalItems),
      ]);
    } catch (e) {
      return dispatch(nop());
    }
  };
};
