import React, { useState } from 'react'
import './styles.css'
import PropTypes from 'prop-types'

import Table, { TableRow, Tbody, TbodyCol, Thead } from '../../../components/Table'
import { MdFavorite } from 'react-icons/md'
// import defaultImg from '../../../assets/default-img.png'
// import Media from 'react-media'
import ModalViewIndustrializado from '../../MedicamentoMedicoListContainer/MedicamentoList/ModalViewIndustrializado'
import ButtonView from '../../../components/ButtonView'
import ButtonRemove from '../../../components/ButtonRemove'
import ButtonAdd from '../../../components/ButtonAdd'
import Media from 'react-media'
// import { storage } from '../../../config/firebase'

function MedicamentoList({ industrializados,
  receita,
  onUnfavorite: handleUnfavorite,
  onAddIndustrializadoReceita: handleAddIndustrializadoReceita,
  onRemoveLinkMedicamento: handleRemoveLinkMedicamento }) {
  const [industrializado, setIndustrializado] = useState(null);
  // const [image, setImage] = useState(null);

  // useEffect(() => {
  //   industrializados.forEach(ind => {
  //     if (ind.med_st_imagem) {
  //       const ref = storage.ref(ind.med_st_imagem);

  //       ref.getDownloadURL().then(url => {
  //         document.getElementById(`img-${ind.med_in_codigo}`).src = url;
  //       });
  //     }
  //   });
  // }, []);

  return (
    <div>
      {industrializados.length === 0 ? <p>Nenhum industrializado encontrado...</p>
        : (
          <Table card>
            <Thead dark
              columns={[
                'Nome',
                'Fabricante',
                'Recomendação de uso',
                'Posologia',
                'Observação',
                '*']} />

            <Tbody>
              {industrializados.map(medicamento => {
                const isMedicamentoOnReceita = receita.find(med => (med.codigo === medicamento.med_in_codigo && med.tipo === 'INDUSTRIALIZADO'));

                return (
                  <TableRow className="upper" key={medicamento.atv_in_codigo}>
                    <TbodyCol alignMiddle
                    // alignStart
                    >
                      {/* <img id={`img-${medicamento.med_in_codigo}`}
                      className="img-fluid img-thumbnail img-list mr-1"
                      src={defaultImg} alt="Imagem do medicamento" />
                    <Media query={{ maxWidth: 600 }}>
                      {matches => matches ? <span className="medicamento-title">{medicamento.med_st_nome}</span> : medicamento.med_st_nome}
                    </Media> */}
                      {medicamento.med_st_nome}
                    </TbodyCol>
                    <TbodyCol alignMiddle label="Fabricante">
                      {medicamento.med_st_fabricante || '-'}
                    </TbodyCol>
                    <TbodyCol alignMiddle label="Recomendação de uso">
                      {medicamento.med_st_recomendacaouso || '-'}
                    </TbodyCol>
                    <TbodyCol alignMiddle label="Posologia">
                      {medicamento.med_st_posologia || '-'}
                    </TbodyCol>
                    <TbodyCol alignMiddle label="Obs">
                      {medicamento.med_st_observacao || '-'}
                    </TbodyCol>
                    <Media query={{ maxWidth: 600 }}>
                      {matches => (
                        <TbodyCol alignMiddle flex={!matches} alignItemsCenter={!matches}>
                          <ButtonView target="#modalViewIndustrializado" onClick={() => {
                            setIndustrializado(medicamento);
                            // setImage(document.getElementById(`img-${medicamento.med_in_codigo}`).src);
                          }}
                            className="mr-1" />

                          {isMedicamentoOnReceita ? null : (
                            <ButtonAdd className="mr-1"
                              onClick={() => handleAddIndustrializadoReceita(medicamento)} />
                          )}

                          <ButtonRemove className="mr-1" onClick={() => handleRemoveLinkMedicamento(medicamento)} />

                          <MdFavorite size={24} color="#f03434"
                            className="cursor-pointer"
                            onClick={() => handleUnfavorite(medicamento)} />
                        </TbodyCol>
                      )}
                    </Media>
                  </TableRow>
                )
              })}
            </Tbody>
          </Table>
        )}

      <ModalViewIndustrializado id="modalViewIndustrializado" industrializado={industrializado}
      // img={image}
      />
    </div>
  )
}

MedicamentoList.propTypes = {
  industrializados: PropTypes.array.isRequired,
  /** All medicamentos on current receita */
  receita: PropTypes.array.isRequired,
  onUnfavorite: PropTypes.func,
  onAddIndustrializadoReceita: PropTypes.func,
  onRemoveLinkMedicamento: PropTypes.func,
}

export default MedicamentoList;
