import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Card, { CardBody, CardTitle } from '../../../components/Card';
import Table, { TableRow, Tbody, TbodyCol } from '../../../components/Table';
import Button from '../../../components/Button';
import { isEqualByProps } from '../../../utils/objects';

function ButtonSelect({ medico, handleSelectMedico }) {
  return (
    <Button
      color='info'
      handleclick={() => handleSelectMedico(medico)}
      size='sm'
    >
      Selecionar
    </Button>
  );
}

function ButtonUnselect({ medico, handleUnselect }) {
  return (
    <Button color='danger' size='sm' handleclick={() => handleUnselect(medico)}>
      Remover
    </Button>
  );
}

function renderRepresentantes(representantes) {
  if (representantes.length === 0)
    return <small className='tetx-muted'>Nenhum representante vinculado</small>;

  return (
    <ul className='list-group list-group-flush'>
      {representantes.map((representante) => (
        <li className='list-group-item' key={representante.usu_in_codigo}>
          <small>{representante.usu_st_nome}</small>
        </li>
      ))}
    </ul>
  );
}

function MedicoList({
  representante,
  medicos,
  selected,
  onUnselect: handleUnselect,
  onSelect: handleSelectMedico,
}) {
  return (
    <div className='d-flex justify-content-center'>
      <Card className='align-items-center'>
        <CardTitle className='text-center'>
          {representante.usu_st_nome}
        </CardTitle>

        <CardBody>
          <Table>
            <Tbody>
              {medicos.length === 0 ? (
                <p>Nenhum médico encontrado.</p>
              ) : (
                medicos.map((medico, index) => (
                  <Fragment key={medico.usu_in_codigo}>
                    <TableRow
                      className='cursor-pointer'
                      data-toggle='collapse'
                      data-target={`#table-row-${index}`}
                    >
                      <TbodyCol alignStart>{medico.usu_st_nome}</TbodyCol>
                      <TbodyCol>
                        {selected?.find((m) =>
                          isEqualByProps(
                            m,
                            medico,
                            'far_in_codigo',
                            'usu_in_codigo',
                            'usu_ch_tipo'
                          )
                        ) ? (
                          handleUnselect ? (
                            <ButtonUnselect
                              handleUnselect={handleUnselect}
                              medico={medico}
                            />
                          ) : (
                            <small className='text-muted'>Selecionado</small>
                          )
                        ) : (
                          <ButtonSelect
                            medico={medico}
                            handleSelectMedico={handleSelectMedico}
                          />
                        )}
                      </TbodyCol>
                    </TableRow>
                    {/* Hidden row */}
                    <TableRow>
                      <td colSpan={2} className='hidden-row text-start'>
                        <div
                          id={`table-row-${index}`}
                          className='accordian-body collapse p-1'
                        >
                          {renderRepresentantes(medico.representantes)}
                        </div>
                      </td>
                    </TableRow>
                  </Fragment>
                ))
              )}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
    </div>
  );
}

MedicoList.propTypes = {
  medicos: PropTypes.array.isRequired,
  onSelect: PropTypes.func,
  onUnselect: PropTypes.func,
  selected: PropTypes.array,
};

export default MedicoList;
