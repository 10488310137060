import api from './api';
import { toast } from 'react-toastify';

export function findAllReceitasByRepresentante(farmaciaId, representanteId, pagination = {
  page: 1,
  itemsPerPage: 15
}) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      const response = await api.get(`farmacias/${farmaciaId}/representantes/${representanteId}/receitas`, {
        headers: { authorization: `Bearer ${token}` },
        params: { ...pagination }
      });

      return resolve(response.data);
    } catch (e) {
      toast.error("Erro ao buscar receitas");
      return reject(e);
    }
  })
}
