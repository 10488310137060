import React, { useRef } from 'react';
import './styles.css';
import PropTypes from 'prop-types';

import Modal from '../../../../components/Modal';
import { Form } from '@unform/web';
import { Input, TextArea } from '../../../../components/Form';
// import defaultImg from '../../../../assets/default-img.png'

function ModalViewIndustrializado({
  id,
  industrializado,
  // img
}) {
  const formRef = useRef(null);

  return (
    <Modal
      id={id}
      title={
        industrializado?.med_st_nome?.toUpperCase() ||
        'Informações do industrializado'
      }
      hideFooter
    >
      {/* <div className="text-center">
        <img src={img || defaultImg} alt={industrializado?.med_st_nome}
          className="img-fluid img-thumbnail" />
      </div> */}

      <Form ref={formRef}>
        <Input
          name='nome'
          id='nome'
          label='Nome'
          className='form-control upper'
          placeholder='Nome'
          defaultValue={industrializado?.med_st_nome}
          disabled
        />

        <Input
          name='fabricante'
          id='fabricante'
          label='Fabricante'
          className='form-control upper'
          placeholder='Fabricante'
          defaultValue={industrializado?.med_st_fabricante}
          disabled
        />

        <TextArea
          name='recomendacaoUso'
          id='recomendacaoUso'
          label='Recomendação de uso'
          className='form-control upper'
          placeholder='Recomendação de uso'
          defaultValue={industrializado?.med_st_recomendacaouso}
          disabled
        />

        <Input
          name='posologia'
          id='posologia'
          label='Posologia'
          className='form-control upper'
          placeholder='Posologia'
          defaultValue={industrializado?.med_st_posologia}
          disabled
        />

        <TextArea
          name='obs'
          id='obs'
          label='Observação'
          className='form-control upper'
          placeholder='Observação'
          defaultValue={industrializado?.med_st_observacao}
          disabled
        />
      </Form>
    </Modal>
  );
}

ModalViewIndustrializado.propTypes = {
  id: PropTypes.string.isRequired,
  industrializado: PropTypes.object,
  // img: PropTypes.string,
};

export default ModalViewIndustrializado;
