import React from 'react';
import PropTypes from 'prop-types';
import {
  BackgroundImage,
  ReceitaDocumentContainer,
  ReceitaContainer,
  DataBox,
} from './styles.js';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import moment from 'moment';
import PacienteInfoField from '../PacienteInfoField'
import MedicamentosInfoField from '../MedicamentosInfoField'
import { useStorageBlobURL } from '../../../hooks'

function Receita({
  dataReceita,
  modelo,
  pacienteFieldValue, setPacienteFieldValue,
  medicamentosFieldValue, setMedicamentosFieldValue }) {
  const { url } = useStorageBlobURL(modelo?.rem_st_caminhoimagem);

  const pdfStyles = StyleSheet.create({
    page: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: parseInt(modelo?.rem_st_margintop || "0") + 41.5,
      paddingLeft: parseInt(modelo?.rem_st_marginleft || "0") + 64.2,
      paddingRight: 64.2,
      paddingBottom: 41.5,
    },
    dataBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      color: "#6c757d",
    },
    pacienteBox: {
      display: 'block',
      width: '100%',
      minHeight: 150,
    },
    pacienteText: {
      whiteSpace: 'pre-wrap',
    },
    medicamentosBox: {
      display: 'block',
      width: '100%',
      minHeight: 300,
      columnCount: 2,
      columnGap: 20,
    },
    medicamentosText: {
      whiteSpace: 'pre-wrap',
    },
  });

  function ReceitaDocument() {
    return (
      <Document>
        <Page size="A4" style={pdfStyles.page}>
          <View style={pdfStyles.dataBox}>
            <Text>DATA: {moment().format("DD/MM/YYYY")}</Text>
            <Text>HORA: {moment().format("HH:mm")}</Text>
          </View>
          <View style={pdfStyles.pacienteBox}>
            <Text style={pdfStyles.pacienteText}>{pacienteFieldValue}</Text>
          </View>
          <View style={pdfStyles.medicamentosBox}>
            <Text style={pdfStyles.medicamentosText}>{medicamentosFieldValue}</Text>
          </View>
        </Page>
      </Document>
    )
  }

  return (
    <div>
      {modelo?.rem_bo_timbrado && <BackgroundImage src={url} alt="Timbrado do médico" />}

      <ReceitaDocumentContainer id="receita_document_container"
        paddingTop={parseInt(modelo?.rem_st_margintop || "0") + 41.5} // add the default page margin to modelo margin
        paddingLeft={parseInt(modelo?.rem_st_marginleft || "0") + 64.2}
        image={url}>
        <ReceitaDocument />
      </ReceitaDocumentContainer>

      <ReceitaContainer>
        <DataBox>
          <span className="text-muted">
            DATA: {dataReceita
              ? moment(dataReceita).format("DD/MM/YYYY")
              : moment().format("DD/MM/YYYY")}
          </span> <br />
        </DataBox>

        <div className="paciente-info-box">
          <PacienteInfoField
            value={pacienteFieldValue}
            setValue={setPacienteFieldValue} />
        </div>

        <div className="medicamentos-info-box">
          <MedicamentosInfoField value={medicamentosFieldValue}
            setValue={setMedicamentosFieldValue} />
        </div>
      </ReceitaContainer>
    </div>
  )
}

Receita.propTypes = {
  modelo: PropTypes.object,
  dataReceita: PropTypes.any,
  pacienteFieldValue: PropTypes.string,
  setPacienteFieldValue: PropTypes.func,
  medicamentosFieldValue: PropTypes.string,
  setMedicamentosFieldValue: PropTypes.func,
}

export default Receita;
