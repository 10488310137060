import React, { useState } from 'react'
import './styles.css'
import PropTypes from 'prop-types'

import Table, { TableRow, Tbody, TbodyCol, Thead } from '../../../components/Table'
import { MdFavorite } from 'react-icons/md'
import ButtonView from '../../../components/ButtonView'
import ModalViewFormula from '../../FormulaMedicoListContainer/FormulaList/ModalViewFormula'
import ButtonEdit from '../../../components/ButtonEdit'
import ButtonRemove from '../../../components/ButtonRemove'
import ButtonAdd from '../../../components/ButtonAdd'

function FormulaList({ formulas,
  receita,
  onEditFormula: handleEditFormula,
  onRemoveLinkFormula: handleRemoveLinkFormula,
  onAddFormulaReceita: handleAddFormulaReceita,
  onUnfavorite: handleUnfavorite }) {
  const [formula, setFormula] = useState(null);

  return (
    <div>
      {formulas.length === 0 ? <p>Nenhuma fórmula encontrada...</p>
        : (
          <Table card>
            <Thead dark
              columns={[
                'Nome',
                'Recomendação de uso',
                'Ativos',
                'Posologia',
                'Observação',
                '*']} />

            <Tbody>
              {formulas.map(formula => {
                const isFormulaOnReceita = receita.find(med => (med.codigo === formula.for_in_codigo && med.tipo === 'FORMULA'));

                return (
                  <TableRow key={formula.atv_in_codigo}>
                    <TbodyCol alignMiddle>{formula.for_st_nome}</TbodyCol>
                    <TbodyCol alignMiddle label="Recomendação de uso">
                      {formula.formulas_ativos.length === 0
                        ? ' - '
                        : formula.formulas_ativos.map(formulaAtivo => `${formulaAtivo.ativo.atv_st_indicacaouso} `)}
                    </TbodyCol>
                    <TbodyCol alignMiddle label="Ativos">
                      {formula.formulas_ativos.length === 0
                        ? ' - '
                        : formula.formulas_ativos.map(formulaAtivo => `${formulaAtivo.ativo.atv_st_descricao}; `)}
                    </TbodyCol>
                    <TbodyCol alignMiddle label="Posologia">{formula.for_st_posologia || '-'}</TbodyCol>
                    <TbodyCol alignMiddle label="Observação">{formula.for_st_observacao || '-'}</TbodyCol>
                    <TbodyCol alignMiddle>
                      <ButtonView target="#modalViewFormula" onClick={() => setFormula(formula)}
                        className="mr-1" />

                      <ButtonEdit className="mr-1"
                        onClick={() => handleEditFormula(formula)} />

                      <ButtonRemove className="mr-1"
                        onClick={() => handleRemoveLinkFormula(formula)} />

                      {isFormulaOnReceita ? null : (
                        <ButtonAdd className="mr-1"
                          onClick={() => handleAddFormulaReceita(formula)} />
                      )}

                      <MdFavorite size={24} color="#f03434"
                        className="cursor-pointer"
                        onClick={() => handleUnfavorite(formula)} />
                    </TbodyCol>
                  </TableRow>
                )
              })}
            </Tbody>
          </Table>
        )}

      <ModalViewFormula id="modalViewFormula" formula={formula} />
    </div>
  )
}

FormulaList.propTypes = {
  formulas: PropTypes.array.isRequired,
  receita: PropTypes.array.isRequired,
  onEditFormula: PropTypes.func,
  onRemoveLinkFormula: PropTypes.func,
  onAddFormulaReceita: PropTypes.func,
  onUnfavorite: PropTypes.func
}

export default FormulaList;
