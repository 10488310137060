import React from 'react';
import './styles.css';
import PropTypes from 'prop-types';

import Table, { TableRow, Tbody, TbodyCol, Thead } from '../Table';
import moment from 'moment';
import Button from '../Button';

function AtivoLogs({ logs, readonly, onClearLogs: handleClearLogs }) {
  return (
    <div className='ativo-logs-container'>
      {readonly ? null : (
        <div className='d-flex justify-content-end mb-1'>
          <Button size='sm' color='danger' handleclick={handleClearLogs}>
            Limpar logs
          </Button>
        </div>
      )}

      <Table small headerSm>
        <Thead dark columns={['Data', 'Mensagem']} />

        <Tbody>
          {logs.map((log, index) => (
            <TableRow key={index}>
              <TbodyCol label='Data'>
                {moment(log.datahora).format('DD/MM/YYYY HH:mm:ss')}
              </TbodyCol>
              <TbodyCol label='Mensagem'>{log.mensagem}</TbodyCol>
            </TableRow>
          ))}
        </Tbody>
      </Table>
    </div>
  );
}

AtivoLogs.propTypes = {
  logs: PropTypes.array,
  readonly: PropTypes.bool,
  onClearLogs: PropTypes.func,
};

export default AtivoLogs;
