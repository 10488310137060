const INITIAL_STATE = {
  hidden: false,
}

export default function navbarReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'NAVBAR_SET_HIDDEN':
      return {
        ...state,
        hidden: action.payload
      }
    case 'NAVBAR_CLEAR_ALL':
      return {
        ...state,
        ...INITIAL_STATE,
      }
    default:
      return state
  }
}
