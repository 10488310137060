import React, { useState, useEffect } from 'react'
import './styles.css'
import PropTypes from 'prop-types'

import defaultImg from '../../../assets/default-img.png'
import { v4 as uuidv4 } from 'uuid'
import { useStorageBlobURL } from '../../../hooks';

function Item({ label, imagePath, selected, onSelect: handleSelect }) {
  const [id, setID] = useState(null);
  const { url } = useStorageBlobURL(imagePath);

  useEffect(() => {
    const id = uuidv4();
    setID(id);
  }, []);

  return (
    <div className={`image-selector-item ${selected ? 'selected' : ''}`}>
      <label>{label}</label>
      <img id={id} src={url || defaultImg} alt="Imagem" className="img-fluid img-thumbnail"
        onClick={handleSelect} />
    </div>
  )
}

Item.propTypes = {
  label: PropTypes.string,
  imagePath: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
}

export default Item;
