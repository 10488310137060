import React, { useState, useEffect, useRef } from 'react';
import './styles.css';

import { useParams, useHistory } from 'react-router-dom';
import Title from '../../components/Title';
import { Form } from '@unform/web';
import { Input, Select } from '../../components/Form';
import Button from '../../components/Button';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { setLoading } from '../../stores/loadingActions';
import {
  fetchById,
  clearVeiculoUtilizacao,
  fetchMedicos,
} from '../../stores/veiculoUtilizacaoEditActions';
import Row from '../../components/Row';
import Grid from '../../components/Grid';
import { update } from '../../services/VeiculoUtilizacaoService';

export default function VeiculoUtilizacaoEdit() {
  const { id } = useParams();
  const history = useHistory();

  const farmaciaId = localStorage.getItem('FORMULARIO_FARMACIA_ID');

  const dispatch = useDispatch();
  const veiculoUtilizacao = useSelector(
    (state) => state.veiculoUtilizacao.edit.veiculoUtilizacao
  );
  const medicos = useSelector((state) => state.veiculoUtilizacao.edit.medicos);

  const formRef = useRef(null);

  const [descricao, setDescricao] = useState('');
  const [tipo, setTipo] = useState('AMBOS');
  const [medico, setMedico] = useState(-1);

  const [isReadonly, setReadonly] = useState(true);

  useEffect(() => {
    // fetch veiculo utilizacao by id
    dispatch([fetchById(farmaciaId, id), fetchMedicos(farmaciaId)]);

    return () => dispatch(clearVeiculoUtilizacao());
  }, []);

  useEffect(() => {
    if (veiculoUtilizacao) {
      setDescricao(veiculoUtilizacao.veu_st_descricao);
      setTipo(veiculoUtilizacao.veu_st_tipo);
      setMedico(veiculoUtilizacao.usu_in_codigo);
    }
  }, [veiculoUtilizacao]);

  async function handleSubmit(data, { reset }) {
    try {
      // set loading
      dispatch(setLoading(true));

      // validation
      const schema = Yup.object().shape({
        veu_st_descricao: Yup.string().required('A descrição é obrigatória'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      if (data['usu_in_codigo'] == -1) data['usu_in_codigo'] = null;

      // save
      await update(farmaciaId, id, data);

      dispatch(setLoading(false));

      // reset form data
      reset();

      // reset errors
      formRef.current.setErrors({});

      // redirect user
      history.push('/veiculosUtilizacao');
    } catch (e) {
      if (e instanceof Yup.ValidationError) {
        const errorMessages = {};

        e.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });

        formRef.current.setErrors(errorMessages);
      }

      dispatch(setLoading(false));
    }
  }

  return (
    <div className='veiculo-container'>
      <Title link='/veiculosUtilizacao'>Editar</Title>

      <Row>
        <Grid cols='12'>
          <div className='form-container'>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <Input
                id='veu_st_descricao'
                label='* Descrição'
                className='form-control upper'
                name='veu_st_descricao'
                value={descricao}
                onChange={(e) => setDescricao(e.target.value)}
                disabled={isReadonly}
                required
              />

              <Select
                id='veu_st_tipo'
                label='* Tipo'
                className='form-control upper'
                name='veu_st_tipo'
                value={tipo}
                onChange={(e) => setTipo(e.target.value)}
                disabled={isReadonly}
                required
              >
                <option value='ORAL'>Oral</option>
                <option value='TOPICO'>Tópico</option>
                <option value='AMBOS'>Ambos</option>
              </Select>

              <Select
                id='usu_in_codigo'
                label='Selecione o médico'
                className='form-control upper'
                name='usu_in_codigo'
                value={medico}
                disabled={isReadonly}
                onChange={(e) => setMedico(e.target.value)}
              >
                <option value={-1}>Todos os médicos</option>
                {medicos.map((medico) => (
                  <option
                    value={medico.usu_in_codigo}
                    key={medico.usu_in_codigo}
                  >
                    {medico.usu_st_nome}
                  </option>
                ))}
              </Select>

              <div className='w-100 mb-3'>
                <small className='text-muted align-end'>
                  Os campos marcados com (*) são obrigatórios
                </small>
              </div>

              {isReadonly ? (
                <Button size='block' handleclick={() => setReadonly(false)}>
                  Habilitar campos
                </Button>
              ) : (
                <Row>
                  <Grid cols='12 6'>
                    <Button
                      color='secondary'
                      size='block'
                      handleclick={() => setReadonly(true)}
                    >
                      Cancelar
                    </Button>
                  </Grid>

                  <Grid cols='12 6'>
                    <Button type='submit' size='block'>
                      Salvar
                    </Button>
                  </Grid>
                </Row>
              )}
            </Form>
          </div>
        </Grid>
      </Row>
    </div>
  );
}
