import { nop } from './baseActions'
import { searchByFarmacia } from '../services/SearchReceitaService'

export const setReceitas = receitas => {
  return {
    type: 'RECEITA_LIST_SET_RECEITAS',
    payload: receitas
  }
}

export const clearAll = () => {
  return {
    type: 'RECEITA_LIST_CLEAR_ALL'
  }
}

export const setMedicos = medicos => {
  return {
    type: 'RECEITA_LIST_SET_MEDICOS_LIST',
    payload: medicos,
  }
}

export const setSelectedMedico = medico => {
  return {
    type: 'RECEITA_LIST_SET_MEDICOS_SELECTED',
    payload: medico,
  }
}

export const setPage = page => {
  return {
    type: 'RECEITA_LIST_SET_PAGINATION_PAGE',
    payload: page,
  }
}

export const setItemsPerpage = itemsPerPage => {
  return {
    type: 'RECEITA_LIST_SET_PAGINATION_ITEMS_PER_PAGE',
    payload: itemsPerPage,
  }
}

export const setTotalItems = totalItems => {
  return {
    type: 'RECEITA_LIST_SET_PAGINATION_TOTAL_ITEMS',
    payload: totalItems,
  }
}

export const setRef = ref => {
  return {
    type: 'RECEITA_LIST_SET_SEARCH_REF',
    payload: ref
  }
}

export const setFrom = from => {
  return {
    type: 'RECEITA_LIST_SET_SEARCH_FROM',
    payload: from
  }
}

export const setTo = to => {
  return {
    type: 'RECEITA_LIST_SET_SEARCH_TO',
    payload: to
  }
}

export const fetchAllReceitasByFarmacia = (farmaciaId, ref, from, to) => {
  return async dispatch => {
    try {
      const receitas = await searchByFarmacia(farmaciaId, ref, from, to);

      return dispatch(setReceitas(receitas));
    } catch (e) {
      return dispatch(nop());
    }
  }
}
