import React from 'react'
import PropTypes from 'prop-types'
import './styles.css'
import { Link } from 'react-router-dom'

function ButtonHead({ to, children }) {
    return (
        <button className="btn btn-sm btn-secondary ml-2">
            <Link  to={to} style={{textDecoration: 'none', color: 'white'}}>
                {children}
            </Link>
        </button>
    )
  }
  
  ButtonHead.propTypes = {
    to: PropTypes.string,
    children: PropTypes.any.isRequired
  }
  
  ButtonHead.defaultProps = {
    color: 'primary',
    size: 'md',
    to: '/'
  }
  
  export default ButtonHead;
  