import React from 'react';
import './styles.css';
import PropTypes from 'prop-types';

import Modal from '../../../../components/Modal';

function ModalDelete({
  onCancelarClick: handleCancelarClick,
  onRemoveLink: handleRemoveLink,
}) {
  return (
    <Modal
      id='modalDelete'
      title='Remover vínculo'
      text='Você tem certeza de que deseja desvincular esse ativo da fórmula?'
      btnColor='danger'
      handleClick={handleRemoveLink}
      handleCancelarClick={handleCancelarClick}
    />
  );
}

ModalDelete.propTypes = {
  onCancelarClick: PropTypes.func,
  onRemoveLink: PropTypes.func,
};

export default ModalDelete;
