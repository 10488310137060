import api from './api';
import { toast } from 'react-toastify';

export function findEspecialidadesByMedico(farmaciaId, usuarioId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      const response = await api.get(
        `farmacias/${farmaciaId}/especialidades/${usuarioId}/medicos`,
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );

      return resolve(response.data.data);
    } catch (e) {
      toast.error('Falha ao buscar especialidades vinculadas ao médico');
      return reject(e);
    }
  });
}

export function linkEspecialidadeToMedico(farmaciaId, usuarioId, especId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      await api.post(
        `farmacias/${farmaciaId}/especialidades/${usuarioId}/medicos/${especId}`,
        {},
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );

      return resolve();
    } catch (e) {
      toast.error('Falha ao vincular especialidade ao médico');
      return reject(e);
    }
  });
}

export function unlinkEspecialidadeMedico(farmaciaId, usuarioId, especId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      await api.delete(
        `farmacias/${farmaciaId}/especialidades/${usuarioId}/medicos/${especId}`,
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );

      return resolve();
    } catch (e) {
      toast.error('Falha ao desvincular especialidade do médico');
      return reject(e);
    }
  });
}
