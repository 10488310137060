import api from './api'
import { toast } from 'react-toastify'

export function findAtivosByRepresentante(farmaciaId, representanteId, pagination = { page: 1, itemsPerPage: 15 }) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      const response = await api.get(`/farmacias/${farmaciaId}/representantes/${representanteId}/ativos`, {
        headers: { authorization: `Bearer ${token}` },
        params: { page: pagination.page, itemsPerPage: pagination.itemsPerPage }
      });

      return resolve(response.data);
    } catch (e) {
      toast.error("Falha ao buscar ativos");
      return reject(e);
    }
  })
}

export function findFormulasByRepresentante(farmaciaId, representanteId, pagination = { page: 1, itemsPerPage: 15 }) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      const response = await api.get(`/farmacias/${farmaciaId}/representantes/${representanteId}/formulas`, {
        headers: { authorization: `Bearer ${token}` },
        params: { page: pagination.page, itemsPerPage: pagination.itemsPerPage }
      });

      return resolve(response.data);
    } catch (e) {
      toast.error("Falha ao buscar formulas");
      return reject(e);
    }
  })
}

export function findIndustrializadosByRepresentante(
  farmaciaId,
  representanteId,
  paginationFilters = {
    page: 1,
    itemsPerPage: 15
  }) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      const response = await api.get(`/farmacias/${farmaciaId}/representantes/${representanteId}/medicamentos_industrializados`, {
        headers: { authorization: `Bearer ${token}` },
        params: { ...paginationFilters }
      });

      return resolve(response.data);
    } catch (e) {
      toast.error("Falha ao buscar industrializados");
      return reject(e);
    }
  })
}
