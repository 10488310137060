import React, { useRef } from 'react';
import './styles.css';

import Title from '../../components/Title';
import { Form } from '@unform/web';
import { Input } from '../../components/Form';
import Row from '../../components/Row';
import Grid from '../../components/Grid';
import Button from '../../components/Button';
import * as Yup from 'yup';
import { recoverPassword } from '../../services/PasswordRecoveryService';

export default function PasswordRecovery() {
  const formRef = useRef(null);

  async function handleSubmit(data, { reset }) {
    try {
      // validation
      const schema = Yup.object().shape({
        email: Yup.string().required('O campo e-mail é obrigatório').email(),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      // send new password e-mail
      await recoverPassword(data.email);

      // clear form
      reset();

      formRef.current.setErrors({});
    } catch (e) {
      if (e instanceof Yup.ValidationError) {
        const errorMessages = {};

        e.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });

        formRef.current.setErrors(errorMessages);
      }
    }
  }

  return (
    <div className='password-container'>
      <Title link='/login'>Esqueceu sua senha?</Title>

      <Row>
        <Grid cols='12'>
          <div className='email-box'>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <Input
                type='email'
                label='Digite seu endereço de e-mail e enviamos uma senha nova a você'
                id='email'
                name='email'
                placeholder='example@mail.com'
                required
              />

              <Button type='submit' size='block'>
                Enviar
              </Button>
            </Form>
          </div>
        </Grid>
      </Row>
    </div>
  );
}
