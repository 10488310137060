import React, { useState } from 'react';
import './styles.css';
import PropTypes from 'prop-types';

import Table, {
  Thead as TableHeader,
  TableRow,
  Tbody,
  TbodyCol as TbCol,
} from '../../../components/Table';
import Modal from '../../../components/Modal';
import ModalButton from '../../../components/ModalButton';
import Button from '../../../components/Button';
import { MdDelete, MdEdit } from 'react-icons/md';
import { insertIf } from '../../../utils/arrays';
import Media from 'react-media';
// import defaultImg from '../../../assets/default-img.png'
// import { storage } from '../../../config/firebase'
// import Media from 'react-media'

function MedicamentoList({
  medicamentos,
  readonly,
  onDelete: handleDelete,
  onEdit: handleEdit,
}) {
  const [medicamento, setMedicamento] = useState(null);

  // useEffect(() => {
  //   medicamentos.forEach(medicamento => {
  //     if (medicamento.med_st_imagem) {
  //       const ref = storage.ref(medicamento.med_st_imagem);

  //       ref.getDownloadURL()
  //         .then(img => document.getElementById(`img-${medicamento.med_in_codigo}`).src = img)
  //         .catch(err => console.log("Erro ao buscar imagem: ", err));
  //     }
  //   })
  // }, []);

  return medicamentos.length > 0 ? (
    <div>
      <Table card>
        <TableHeader
          dark
          columns={[
            'Nome',
            'Fabricante',
            'Recomendação de uso',
            'Posologia',
            'Observação',
            ...insertIf(!readonly, 'Ação'),
          ]}
        />

        <Tbody>
          {medicamentos.map((medicamento) => (
            <TableRow key={medicamento.med_in_codigo} className='upper'>
              <TbCol
                alignMiddle
                // alignStart
              >
                {/* <img id={`img-${medicamento.med_in_codigo}`}
                  className="img-fluid img-thumbnail img-list mr-1"
                  src={defaultImg} alt="Imagem do medicamento" />
                <Media query={{ maxWidth: 600 }}>
                  {matches => matches ? <span className="medicamento-title">{medicamento.med_st_nome}</span> : medicamento.med_st_nome}
                </Media> */}
                {medicamento.med_st_nome}
              </TbCol>
              <TbCol alignMiddle label='Fabricante'>
                {medicamento.med_st_fabricante || '-'}
              </TbCol>
              <TbCol alignMiddle label='Recomendação de uso'>
                {medicamento.med_st_recomendacaouso || '-'}
              </TbCol>
              <TbCol alignMiddle label='Posologia'>
                {medicamento.med_st_posologia || '-'}
              </TbCol>
              <TbCol alignMiddle label='Obs'>
                {medicamento.med_st_observacao || '-'}
              </TbCol>
              {readonly ? null : (
                <Media query={{ maxWidth: 599 }}>
                  {(matches) => (
                    <TbCol alignMiddle flex={!matches} label='Ação'>
                      <ModalButton
                        target='#modalDeleteMedicamento'
                        color='danger'
                        size='sm'
                        handleclick={() => setMedicamento(medicamento)}
                      >
                        <MdDelete size={24} />
                      </ModalButton>

                      <Button
                        className='ml-1'
                        color='warning'
                        size='sm'
                        handleclick={() => handleEdit(medicamento)}
                      >
                        <MdEdit size={24} />
                      </Button>
                    </TbCol>
                  )}
                </Media>
              )}
            </TableRow>
          ))}
        </Tbody>
      </Table>

      <Modal
        id='modalDeleteMedicamento'
        handleClick={() => handleDelete(medicamento)}
        handleCancelarClick={() => setMedicamento(null)}
        btnColor='danger'
        size='sm'
        text='Você tem certeza de que deseja remover este industrializado?'
        title='Remover industrializado'
      />
    </div>
  ) : (
    <p className='not-found'>Nenhum medicamento encontrado</p>
  );
}

MedicamentoList.propTypes = {
  medicamentos: PropTypes.array.isRequired,
  readonly: PropTypes.bool,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
};

MedicamentoList.defaultProps = {
  readonly: false,
};

export default MedicamentoList;
