import React from 'react'
import './styles.css'

export default function HomeTitle({ children }) {
  return (
    <div>
      <h1 className="display-4">{children}</h1>
    </div>
  )
}
