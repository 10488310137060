const INITIAL_STATE = {
  formula: null,
  ativos: [],
  unidades: [],
  veiculosUtilizacao: [],
  veiculoUtilizacao: null,
  isEditing: false,
}

export default function medicoFormulaEditReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'MEDICO_FORMULA_EDIT_SET_FORMULA':
      return {
        ...state,
        formula: action.payload
      }
    case 'MEDICO_FORMULA_EDIT_SET_ATIVOS':
      return {
        ...state,
        ativos: action.payload
      }
    case 'MEDICO_FORMULA_EDIT_SET_UNIDADES':
      return {
        ...state,
        unidades: action.payload
      }
    case 'MEDICO_FORMULA_EDIT_SET_VEICULOS_UTILIZACAO':
      return {
        ...state,
        veiculosUtilizacao: action.payload
      }
    case 'MEDICO_FORMULA_EDIT_SET_VEICULO_UTILIZACAO':
      return {
        ...state,
        veiculoUtilizacao: action.payload
      }
    case 'MEDICO_FORMULA_EDIT_SET_IS_EDITTING':
      return {
        ...state,
        isEditing: action.payload
      }
    case 'MEDICO_FORMULA_EDIT_CLEAR_FORMULA':
      return {
        ...state,
        formula: null,
        ativos: [],
      }
    case 'MEDICO_FORMULA_EDIT_CLEAR_ALL':
      return {
        ...state,
        ...INITIAL_STATE
      }
    default:
      return state
  }
}
