import React, { useState, useEffect } from 'react';
import './styles.css';

import { useSelector, useDispatch } from 'react-redux';
import {
  fetchAllUsers,
  clearUsers,
  removeUser as deleteUser,
} from '../../stores/usuarioListActions';
import { useHistory } from 'react-router-dom';
import Title from '../../components/Title';
import ButtonAdd from '../../components/ButtonAdd';
import UsuarioList from './UsuarioList';
import Skeleton from 'react-loading-skeleton';
import Row from '../../components/Row';
import Grid from '../../components/Grid';

export default function UsuarioListContainer() {
  const [isLoading, setLoading] = useState(true);
  const [isFirst, setFirst] = useState(true);

  const history = useHistory();
  const dispatch = useDispatch();

  const usuarios = useSelector((state) => state.usuario.list.usuarios);

  const farmaciaId = localStorage.getItem('FORMULARIO_FARMACIA_ID');

  useEffect(() => {
    dispatch(fetchAllUsers(farmaciaId));

    return () => dispatch(clearUsers());
  }, []);

  useEffect(() => {
    if (!isFirst) setLoading(false);
    else setFirst(false);
  }, [usuarios]);

  function removeUser(user) {
    dispatch(deleteUser(user));
  }

  function editUser(user) {
    history.push(`/usuarios/${user.usu_in_codigo}/edit`);
  }

  return (
    <div className='usuario-container'>
      <Title
        link='/'
        component={() => (
          <ButtonAdd onClick={() => history.push('/usuarios/new')} />
        )}
      >
        Listagem de usuários
      </Title>

      <Row>
        <Grid cols='12'>
          {isLoading ? (
            <Skeleton count={5} height={30} />
          ) : (
            <UsuarioList
              usuarios={usuarios}
              onDelete={removeUser}
              onEdit={editUser}
            />
          )}
        </Grid>
      </Row>
    </div>
  );
}
