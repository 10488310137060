import React, { useState, useEffect } from 'react'
import './styles.css'
import PropTypes from 'prop-types'

import { CardContainer as Container } from '../../../components/Card'
import { storage } from '../../../config/firebase'
import IndustrializadoCard from './IndustrializadoCard'
import FormulaCard from './FormulaCard'
import AtivoCard from './AtivoCard'
import ModalAddIndustrializado from '../ModalAddIndustrializado'
import ModalAddAtivo from '../ModalAddAtivo'
import Search from './SearchMedicamentos'

function ResultList({ results, medicamentos, role, favorites, ativos,
  onAddMedicamento: handleAddMedicamento,
  onRedirectToMedicamentoPage: handleRedirectToMedicamentoPage,
  onFavorite: handleFavorite,
  onUnfavorite: handleUnfavorite,
  onSetFormula: handleSetFormula,
  onAddAtivo: handleAddAtivo,
  onSearchMedicamentos: handleSearchMedicamentos }) {

  const [medicamento, setMedicamento] = useState(null);

  useEffect(() => {
    const medicamentos = results.filter(r => r.imagem);

    medicamentos.forEach(medicamento => {
      const ref = storage.ref(medicamento.imagem);
      ref.getDownloadURL()
        .then(url => {
          if (document.getElementById(`img-${medicamento.codigo}`))
            document.getElementById(`img-${medicamento.codigo}`).src = url;
        })
        .catch(err => console.log("Erro ao carregar imagem", err));
    });
  }, [results]);

  return (
    <div>
      <Search onSearch={handleSearchMedicamentos} />

      <Container column={role === 'M'}>
        {results.map((result, index) => {
          // check if medicamento is on receita
          const isMedicamentoOnReceita = medicamentos?.find(med => (med.codigo === result.codigo
            && med.tipo === result.tipo));
          const isMedicamentoFavorite = favorites?.find(favorite => {
            if (result.codigo === favorite.atv_in_codigo) return true;
            if (result.codigo === favorite.for_in_codigo) return true;
            if (result.codigo === favorite.med_in_codigo) return true;

            return false;
          });
          const isMedicamentoOnFormula = ativos?.find(ativo => (
            ativo.atv_in_codigo === result.codigo ||
            ativo.codigo === result.codigo
          ));

          if (result.tipo === 'ATIVO')
            return <AtivoCard key={index}
              target="#modalAddAtivo"
              ativo={result}
              role={role}
              onAddMedicamento={setMedicamento}
              isAtivoOnFormula={!!isMedicamentoOnFormula}
              isMedicamentoOnReceita={!!isMedicamentoOnReceita}
              onRedirectToMedicamentoPage={handleRedirectToMedicamentoPage}
              onFavorite={handleFavorite}
              onUnfavorite={handleUnfavorite}
              isFavorite={!!isMedicamentoFavorite} />

          if (result.tipo === 'FORMULA')
            return <FormulaCard key={index}
              formula={result}
              role={role}
              onAddMedicamento={handleSetFormula}
              isMedicamentoOnReceita={!!isMedicamentoOnReceita}
              onRedirectToMedicamentoPage={handleRedirectToMedicamentoPage}
              onFavorite={handleFavorite}
              onUnfavorite={handleUnfavorite}
              isFavorite={!!isMedicamentoFavorite} />

          if (result.tipo === 'INDUSTRIALIZADO')
            return <IndustrializadoCard key={index}
              target="#modalAddIndustrializado"
              industrializado={result}
              role={role}
              onAddMedicamento={setMedicamento}
              isMedicamentoOnReceita={!!isMedicamentoOnReceita}
              onRedirectToMedicamentoPage={handleRedirectToMedicamentoPage}
              onFavorite={handleFavorite}
              onUnfavorite={handleUnfavorite}
              isFavorite={!!isMedicamentoFavorite} />

          return null;
        })}

        <ModalAddIndustrializado id="modalAddIndustrializado"
          industrializado={medicamento}
          onSubmit={data => handleAddMedicamento({ ...medicamento, ...data })} />

        <ModalAddAtivo id="modalAddAtivo"
          ativo={medicamento}
          onSubmit={data => handleAddAtivo({ ...medicamento, ...data })} />
      </Container>
    </div>
  )
}

ResultList.propTypes = {
  /** Array of results */
  results: PropTypes.array.isRequired,
  /** Array of medicamentos on receita */
  medicamentos: PropTypes.array,
  /** Array of ativos on formula */
  ativos: PropTypes.array,
  /** User role */
  role: PropTypes.string,
  /** Function to invoke when redirect to medicamento page */
  onRedirectToMedicamentoPage: PropTypes.func,
  /** Array of medico favorite medicamentos */
  favorites: PropTypes.array,
  /** Function to add medicamento to favorites */
  onFavorite: PropTypes.func,
  /** Function to remove medicamento to favorites */
  onUnfavorite: PropTypes.func,
  /** Function to set the formula that medico is building */
  onSetFormula: PropTypes.func,
  /** Function to add ativo to formula */
  onAddAtivo: PropTypes.func,
  /** Function to search medicamentos */
  onSearchMedicamentos: PropTypes.func,
}

ResultList.defaultProps = {
  role: 'G'
}

export default ResultList;
