import React from 'react';
import './styles.css';
import PropTypes from 'prop-types';

import Card, {
  CardBody as Body,
  CardText as Text,
  CardTitle as Title,
} from '../../../../components/Card';
import Row from '../../../../components/Row';
import Grid from '../../../../components/Grid';
import { Link } from 'react-router-dom';
import ButtonFavorite from '../../../../components/ButtonFavorite';
import { MdAdd } from 'react-icons/md';
import ModalButton from '../../../../components/ModalButton';
import Badge from '../../../../components/Badge';

function AtivoCard({
  ativo,
  role,
  isFavorite,
  target,
  onAddMedicamento: handleAddMedicamento,
  onRedirectToMedicamentoPage: handleRedirectToMedicamentoPage,
  isMedicamentoOnReceita,
  isAtivoOnFormula,
  onFavorite: handleFavorite,
  onUnfavorite: handleUnfavorite,
}) {
  return (
    <Card className='ativo-card'>
      <Row>
        <Grid cols='12'>
          <Body>
            <div className='d-flex align-items-center justify-content-between'>
              <Link
                to={`/ativos/${ativo.codigo}/edit`}
                className='text-dark text-decoration-none'
                onClick={() => handleRedirectToMedicamentoPage(ativo.tipo)}
              >
                <Title className='upper'>
                  {ativo.nome}{' '}
                  <small>
                    <Badge color='info'>Ativo</Badge>
                  </small>
                </Title>
              </Link>

              {role === 'M' ? (
                <div className='card-title-btn-container'>
                  <ButtonFavorite
                    isFavorite={isFavorite}
                    onUnfavorite={() => handleUnfavorite(ativo)}
                    onFavorite={() => handleFavorite(ativo)}
                  />

                  {isMedicamentoOnReceita || isAtivoOnFormula ? (
                    <small className='text-muted'>
                      Medicamento já adicionado
                    </small>
                  ) : (
                    <ModalButton
                      target={target}
                      size='sm'
                      color='light'
                      title='Adicionar a receita'
                      handleclick={() => handleAddMedicamento(ativo)}
                    >
                      <MdAdd className='text-success' size={28} />
                    </ModalButton>
                  )}
                </div>
              ) : null}
            </div>
            <Text>{ativo.observacao}</Text>
            <hr className='divider' />
            <Text>
              <small className='text-muted'>
                <b>Indicação de uso: </b>
                {ativo.uso}
              </small>
            </Text>
          </Body>
        </Grid>
      </Row>
    </Card>
  );
}

AtivoCard.propTypes = {
  ativo: PropTypes.object,
  role: PropTypes.string,
  target: PropTypes.string,
  isFavorite: PropTypes.bool,
  onAddMedicamento: PropTypes.func,
  onRedirectToMedicamentoPage: PropTypes.func,
  isMedicamentoOnReceita: PropTypes.bool,
  isAtivoOnFormula: PropTypes.bool,
  onFavorite: PropTypes.func,
  onUnfavorite: PropTypes.func,
};

export default AtivoCard;
