import React, { useEffect, useState } from 'react'

import Title from '../../components/Title'
import RepresentanteList from './RepresentanteList'
import { useSelector, useDispatch } from 'react-redux'
import {
  fetchRepresentantesByFarmacia,
  fetchMedicosByFarmacia,
  fetchLinksRepresentanteMedico,
  setRepresentante,
  clearAll,
} from '../../stores/linkRepresentanteMedicoActions'
import Skeleton from 'react-loading-skeleton'
import MedicoList from './MedicoList'
import { setLoading as loading } from '../../stores/loadingActions'
import { linkMedicoToRepresentante, unlinkMedicoToRepresentante } from '../../services/LinkMedicoToRepresentanteService'

export default function LinkMedicoRepresentanteContainer() {
  const [isLoading, setLoading] = useState(true);
  const [isFirst, setFirst] = useState(true);

  const [step, setStep] = useState('REPRESENTANTE_LIST');

  const dispatch = useDispatch();
  const representantes = useSelector(state => state.linkRepresentanteMedico.representantes);
  const medicos = useSelector(state => state.linkRepresentanteMedico.medicos);
  const representante = useSelector(state => state.linkRepresentanteMedico.selectedRepresentante);
  const linksRepresentanteMedico = useSelector(state => state.linkRepresentanteMedico.links);

  const farmaciaId = localStorage.getItem('FORMULARIO_FARMACIA_ID');

  useEffect(() => {
    dispatch(fetchRepresentantesByFarmacia(farmaciaId));

    return () => dispatch(clearAll());
  }, []);

  useEffect(() => {
    if (isFirst) setFirst(false);
    else setLoading(false);
  }, [representantes, medicos]);

  function handleSelectRepresentante(representante) {
    setLoading(true);

    dispatch([
      setRepresentante(representante),
      fetchMedicosByFarmacia(farmaciaId),
      fetchLinksRepresentanteMedico(farmaciaId, representante.usu_in_codigo)
    ]);

    setStep('MEDICO_LIST');
  }

  async function handleSelectMedico(medico) {
    dispatch(loading(true));

    await linkMedicoToRepresentante(farmaciaId, medico.usu_in_codigo, representante.usu_in_codigo);

    dispatch(fetchLinksRepresentanteMedico(farmaciaId, representante.usu_in_codigo));
  }

  async function handleUnlinkMedico(medico) {
    dispatch(loading(true));

    await unlinkMedicoToRepresentante(farmaciaId, medico.usu_in_codigo, representante.usu_in_codigo);

    dispatch(fetchLinksRepresentanteMedico(farmaciaId, representante.usu_in_codigo));
  }

  function loadStep(step) {
    return {
      'REPRESENTANTE_LIST':
        <RepresentanteList representantes={representantes}
          onSelect={handleSelectRepresentante} />,
      'MEDICO_LIST':
        <MedicoList representante={representante} medicos={medicos}
          selected={linksRepresentanteMedico}
          onSelect={handleSelectMedico}
          onUnselect={handleUnlinkMedico} />
    }[step];
  }

  function renderTitle(step) {
    return {
      'REPRESENTANTE_LIST': 'Selecione um representante para começar',
      'MEDICO_LIST': 'Selecione o médico para vincular'
    }[step];
  }

  function getGoBackLink(step) {
    return {
      'REPRESENTANTE_LIST': '/',
      'MEDICO_LIST': '/representantes/vincular_medicos'
    }[step];
  }

  function getGoBackFunction(step) {
    return {
      'REPRESENTANTE_LIST': null,
      'MEDICO_LIST': () => {
        dispatch(setRepresentante(null));
        setStep('REPRESENTANTE_LIST');
      }
    }[step];
  }

  return (
    <div className="vinculo-container">
      <Title link={getGoBackLink(step)}
        onClick={getGoBackFunction(step)}>{renderTitle(step)}</Title>

      {isLoading ? <Skeleton count={5} height={30} /> : loadStep(step)}
    </div>
  )
}
