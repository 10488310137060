export const setReceitas = receitas => {
  return {
    type: 'RECEITA_REPRESENTANTE_LIST_SET_RECEITAS',
    payload: receitas
  }
}

export const setPage = page => {
  return {
    type: 'RECEITA_REPRESENTANTE_LIST_PAGINATION_SET_PAGE',
    payload: page,
  }
}

export const setItemsPerPage = itemsPerPage => {
  return {
    type: 'RECEITA_REPRESENTANTE_LIST_PAGINATION_SET_ITEMS_PER_PAGE',
    payload: itemsPerPage,
  }
}

export const setTotalItems = totalItems => {
  return {
    type: 'RECEITA_REPRESENTANTE_LIST_PAGINATION_SET_TOTAL_ITEMS',
    payload: totalItems,
  }
}

export const clearAll = () => {
  return {
    type: 'RECEITA_REPRESENTANTE_LIST_CLEAR_ALL',
  }
}
