const INITIAL_STATE = {
  usuarios: []
}

export default function usuarioListReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'USUARIO_LIST_FETCH_ALL_USERS':
      return {
        ...state,
        usuarios: action.payload
      }
    case 'USUARIO_LIST_CLEAR_ALL_USERS':
      return {
        ...state,
        ...INITIAL_STATE
      }
    default:
      return state;
  }
}
