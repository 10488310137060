import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import './styles.css';

function DropdownItem({ to, children }) {
  return <Link className="dropdown-item" to={to}>{children}</Link>;
}

DropdownItem.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired
}

export default DropdownItem;
