import React from 'react'
import './styles.css'
import PropTypes from 'prop-types'

function TheadCol({ children }) {
  return (
    <th scope="col">{children}</th>
  )
}

TheadCol.propTypes = {
  children: PropTypes.any
}

export default TheadCol;
