import React, { useRef } from 'react';
// import './styles.css';

import { useDispatch } from 'react-redux';
import { setLoading } from '../../stores/loadingActions';
import { useHistory } from 'react-router-dom';

import Title from '../../components/Title';
import { Form } from '@unform/web';
import { Input } from '../../components/Form';
import Button from '../../components/Button';
import * as Yup from 'yup';

import { save } from '../../services/EspecialidadeService';

export default function NewEspecialidadeForm() {
  const formRef = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();

  const farmaciaId = localStorage.getItem('FORMULARIO_FARMACIA_ID');

  async function handleSubmit(data, { reset }) {
    try {
      // set loading
      dispatch(setLoading(true));

      // validation
      const schema = Yup.object().shape({
        esp_st_descricao: Yup.string().required('A descrição é obrigatória'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      // save
      await save(farmaciaId, data);

      dispatch(setLoading(false));

      reset();

      // clear errors
      formRef.current.setErrors({});

      // redirect
      history.push('/especialidades');
    } catch (e) {
      if (e instanceof Yup.ValidationError) {
        const errorMessages = {};

        e.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });

        formRef.current.setErrors(errorMessages);
      }

      dispatch(setLoading(false));
    }
  }

  return (
    <div className='especialidade-container'>
      <Title link='/especialidades'>Cadastro de especialidade</Title>

      <div className='form-container'>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Input
            id='esp_st_descricao'
            label='* Descrição'
            className='form-control upper'
            name='esp_st_descricao'
            required
          />

          <div className='w-100 mb-3'>
            <small className='text-muted align-end'>
              Os campos marcados com (*) são obrigatórios
            </small>
          </div>

          <Button type='submit' color='primary' size='block'>
            Salvar
          </Button>
        </Form>
      </div>
    </div>
  );
}
