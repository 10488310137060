import api from './api'
import { toast } from 'react-toastify'

export function findAtivos(farmaciaId, usuarioId, ref = '', page = 1, itemsPerPage = 15, startsWith = false) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("FORMULARIO_JSONWEBTOKEN");

      const response = await api.get(`farmacias/${farmaciaId}/usuarios/${usuarioId}/ativos`, {
        headers: { authorization: `Bearer ${token}` },
        params: { ref, page, itemsPerPage, startsWith }
      });

      return resolve(response.data);
    } catch (e) {
      toast.error("Falha ao buscar ativos");
      return reject(e);
    }
  })
}

export function findFormulas(farmaciaId, usuarioId, ref = '', page = 1, itemsPerPage = 15, startsWith = false) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("FORMULARIO_JSONWEBTOKEN");

      const response = await api.get(`farmacias/${farmaciaId}/usuarios/${usuarioId}/formulas`, {
        headers: { authorization: `Bearer ${token}` },
        params: { ref, page, itemsPerPage, startsWith }
      });

      return resolve(response.data);
    } catch (e) {
      toast.error("Falha ao buscar fórmulas");
      return reject(e);
    }
  })
}

export function findIndustrializados(farmaciaId, usuarioId, ref = '', page = 1, itemsPerPage = 15, startsWith = false) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("FORMULARIO_JSONWEBTOKEN");

      const response = await api.get(`farmacias/${farmaciaId}/usuarios/${usuarioId}/medicamentos_industrializados`, {
        headers: { authorization: `Bearer ${token}` },
        params: { ref, page, itemsPerPage, startsWith }
      });

      return resolve(response.data);
    } catch (e) {
      toast.error("Falha ao buscar industrializados");
      return reject(e);
    }
  })
}