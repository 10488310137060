import React from 'react'
import './styles.css'
import PropTypes from 'prop-types'

function Button({ children, radio, color, name, id, checked, disabled, onChange: handleChange, ...props }) {
  return (
    <label className={`btn btn-${color} ${checked ? 'active ' : 'unactive'} ${disabled && 'disabled '}`}>
      <input type={radio ? 'radio' : 'checkbox'} disabled={disabled} name={name} id={id} checked={checked}
        onChange={handleChange} {...props} /> {children}
    </label>
  )
}

Button.propTypes = {
  children: PropTypes.any.isRequired,
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  radio: PropTypes.bool,
}

Button.defaultProps = {
  color: 'secondary'
}

export default Button;
