import api from './api';
import { toast } from 'react-toastify';

export function findById(farmaciaId, receitaId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      const response = await api.get(
        `farmacias/${farmaciaId}/receitas/${receitaId}`,
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );

      return resolve(response.data.data);
    } catch (e) {
      toast.error('Falha ao buscar receita');
      return reject(e);
    }
  });
}

export function findAllByFarmacia(farmaciaId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      const response = await api.get(`farmacias/${farmaciaId}/receitas`, {
        headers: { authorization: `Bearer ${token}` },
      });

      return resolve(response.data.data);
    } catch (e) {
      toast.error('Falha ao buscar receitas');
      return reject(e);
    }
  });
}

export function save(farmaciaId, usuarioId, pacienteId, receita) {
  console.log(farmaciaId);
  console.log(usuarioId);
  console.log(pacienteId);
  console.log(receita);
  return new Promise(async (resolve, reject) => {
    // console.log("paciente id no service: ", pacienteId)
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      const response = await api.post(
        `farmacias/${farmaciaId}/usuarios/${usuarioId}/receitas`,
        { pac_in_codigo: pacienteId, ...receita },
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );

      return resolve(response.data.data);
    } catch (e) {
      toast.error('Falha ao salvar receita');
      return reject(e);
    }
  });
}

export function update(farmaciaId, usuarioId, receitaId, receita) {
  console.log('=======================');
  console.log(farmaciaId);
  console.log(usuarioId);
  console.log(receitaId);
  console.log(receita);
  console.log('=======================');
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      await api.put(
        `farmacias/${farmaciaId}/usuarios/${usuarioId}/receitas/${receitaId}`,
        receita,
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );

      return resolve();
    } catch (e) {
      toast.error('Falha ao salvar receita');
      return reject(e);
    }
  });
}

export function saveMedicamentosReceita(
  farmaciaId,
  usuarioId,
  receitaId,
  medicamentos
) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      await api.post(
        `farmacias/${farmaciaId}/usuarios/${usuarioId}/receitas/${receitaId}/medicamentos`,
        {
          medicamentos,
        },
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );

      return resolve();
    } catch (e) {
      toast.error('Falha ao salvar medicamentos da receita');
      return reject(e);
    }
  });
}
