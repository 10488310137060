import React from 'react';
import PropTypes from 'prop-types';

const Modal = ({
  id,
  title,
  text,
  handleClick,
  handleCancelarClick,
  size,
  btnColor,
  btnCancelarColor,
  btnText,
  btnCancelarText,
  style,
  hideFooter,
  children,
}) => {
  return (
    <div
      style={style}
      className='modal'
      id={id}
      tabIndex='-1'
      role='dialog'
      aria-labelledby='exampleModalLabel'
      aria-hidden='true'
    >
      <div className={`modal-dialog modal-${size}`}>
        <div className='modal-dialog' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>{title}</h5>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body'>
              {children ? children : <p>{text}</p>}
            </div>
            {hideFooter ? null : (
              <div className='modal-footer'>
                <button
                  type='button'
                  className={`btn btn-${btnCancelarColor}`}
                  data-dismiss='modal'
                  onClick={handleCancelarClick}
                >
                  {btnCancelarText}
                </button>
                <button
                  type='button'
                  className={`btn btn-${btnColor}`}
                  data-dismiss='modal'
                  onClick={handleClick}
                >
                  {btnText}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  handleClick: PropTypes.func,
  handleCancelarClick: PropTypes.func,
  size: PropTypes.string,
  btnColor: PropTypes.string,
  btnCancelarColor: PropTypes.string,
  btnText: PropTypes.string,
  btnCancelarText: PropTypes.string,
  style: PropTypes.object,
  hideFooter: PropTypes.bool,
  children: PropTypes.element,
};

Modal.defaultProps = {
  id: 'exampleModal',
  title: 'Confirmar ação',
  text: 'Você tem certeza de que deseja realizar esta ação?',
  handleClick: () => {},
  handleCancelarClick: () => {},
  size: 'md',
  btnColor: 'primary',
  btnCancelarColor: 'secondary',
  btnText: 'Confirmar',
  btnCancelarText: 'Cancelar',
  style: null,
};

export default Modal;
