const INITIAL_STATE = {
  usuario: null,
  receitaModelos: []
}

export default function usuarioEditReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'USUARIO_EDIT_SET_USUARIO':
      return {
        ...state,
        usuario: action.payload
      }
    case 'USUARIO_EDIT_SET_RECEITA_MODELOS':
      return {
        ...state,
        receitaModelos: action.payload
      }
    case 'USUARIO_EDIT_CLEAR_USUARIO':
      return {
        ...state,
        ...INITIAL_STATE
      }
    default:
      return state
  }
}
