import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import Button from '@bit/ericktrettel.franchini.button'
import Button from '../Button';
import { MdCameraAlt, MdDone } from 'react-icons/md';

function ImageInput({
  id,
  onLoadImage: handleLoadImage,
  isRequired,
  ...props
}) {
  const [done, setDone] = useState(false);

  return (
    <div className='form-group h-100' {...props}>
      <Button
        type='button'
        className='h-100 border border-dark border-3'
        size='block'
        color={done ? 'secondary' : 'light'}
        handleclick={() => document.getElementById(id).click()}
      >
        {done ? <MdDone size={18} /> : <MdCameraAlt size={18} />}
      </Button>

      <input
        type='file'
        name={id}
        id={id}
        accept='image/*'
        className='d-none'
        onChange={(e) => {
          handleLoadImage(e);
          setDone(true);
        }}
        required={isRequired}
      />
    </div>
  );
}

ImageInput.propTypes = {
  id: PropTypes.string.isRequired,
  handleLoadImage: PropTypes.func,
  isRequired: PropTypes.bool,
};

ImageInput.defaultProps = {
  handleLoadImage: () => {},
  isRequired: false,
};

export default ImageInput;
