import api from './api';

export function signIn(email, password) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await api.post('auth', { email, password });

      return resolve(response);
    } catch (e) {
      return reject(e);
    }
  });
}
