import React, { useState, useEffect, useRef } from 'react';
import './styles.css';

import { useParams, useHistory } from 'react-router-dom';
import Title from '../../components/Title';
import { Form } from '@unform/web';
import { Input } from '../../components/Form';
import Button from '../../components/Button';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { setLoading } from '../../stores/loadingActions';
import {
  fetchById,
  clearEspecialidade,
} from '../../stores/especialidadeEditActions';
import Row from '../../components/Row';
import Grid from '../../components/Grid';
import { update } from '../../services/EspecialidadeService';

export default function UnidadeEdit() {
  const { id } = useParams();
  const history = useHistory();

  const dispatch = useDispatch();
  const especialidade = useSelector(
    (state) => state.especialidades.edit.especialidade
  );

  const farmaciaId = localStorage.getItem('FORMULARIO_FARMACIA_ID');

  const formRef = useRef(null);

  const [descricao, setDescricao] = useState('');

  const [locked, setLocked] = useState(true);

  useEffect(() => {
    // fetch unidade by id
    dispatch(fetchById(id));

    return () => dispatch(clearEspecialidade());
  }, []);

  useEffect(() => {
    if (especialidade) {
      setDescricao(especialidade.esp_st_descricao);
    }
  }, [especialidade]);

  async function handleSubmit(data, { reset }) {
    try {
      // set loading
      dispatch(setLoading(true));

      // validation
      const schema = Yup.object().shape({
        esp_st_descricao: Yup.string().required('A descrição é obrigatória'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      // save
      await update(id, data);

      dispatch(setLoading(false));

      // reset form data
      reset();

      // reset errors
      formRef.current.setErrors({});

      // redirect user
      history.push('/especialidades');
    } catch (e) {
      if (e instanceof Yup.ValidationError) {
        const errorMessages = {};

        e.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });

        formRef.current.setErrors(errorMessages);
      }

      dispatch(setLoading(false));
    }
  }

  function ActionButtons() {
    return locked ? (
      <Button size='block' handleclick={() => setLocked(false)}>
        Habilitar campos
      </Button>
    ) : (
      <Row>
        <Grid cols='12 6'>
          <Button
            color='secondary'
            size='block'
            handleclick={() => setLocked(true)}
          >
            Cancelar
          </Button>
        </Grid>

        <Grid cols='12 6'>
          <Button type='submit' size='block'>
            Salvar
          </Button>
        </Grid>
      </Row>
    );
  }

  return (
    <div className='unidade-container'>
      <Title link='/unidades'>Editar</Title>

      <Row>
        <Grid cols='12'>
          <div className='form-container'>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <Input
                id='esp_st_descricao'
                label='* Descrição'
                className='form-control upper'
                name='esp_st_descricao'
                value={descricao}
                onChange={(e) => setDescricao(e.target.value)}
                disabled={locked}
                required
              />

              <div className='w-100 mb-3'>
                <small className='text-muted align-end'>
                  Os campos marcados com (*) são obrigatórios
                </small>
              </div>

              <ActionButtons />
            </Form>
          </div>
        </Grid>
      </Row>
    </div>
  );
}
