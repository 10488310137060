import { nop } from './baseActions';
import { findAllByFarmacia } from '../services/EspecialidadeService';
// import { findEspecialidadesByAtivo } from '../services/FindAllMedicamentosByEspecialidadeService';
import { searchMedicamentos as search } from '../services/SearchMedicamentosService';
import { findMedicamentosByEspecialidade } from '../services/FindAllMedicamentosByEspecialidadeService';
import {
  findEspecialidadesByAtivo,
  linkEspecialidadeToAtivo,
  unlinkEspecialidadeAtivo,
  findEspecialidadesByIndustrializado,
  linkEspecialidadeToIndustrializado,
  unlinkEspecialidadeIndustrializado,
  findEspecialidadesByFormula,
  linkEspecialidadeToFormula,
  unlinkEspecialidadeFormula,
} from '../services/LinkMedicamentosToEspecialidadeService';
import { setLoading } from './loadingActions';

const setAllMedicamentos = (medicos) => {
  return {
    type: 'LINK_MEDICAMENTO_ESPECIALIDADE_SET_MEDICAMENTOS',
    payload: medicos,
  };
};

export const setSelectedEspecialidade = (especialidade) => {
  return {
    type: 'LINK_MEDICAMENTO_ESPECIALIDADE_SET_ESPECIALIDADE',
    payload: especialidade,
  };
};

const setAllEspecialidades = (especialidades) => {
  return {
    type: 'LINK_MEDICAMENTO_ESPECIALIDADE_SET_ESPECIALIDADES',
    payload: especialidades,
  };
};

const setLinksMedicamentoEspecialidades = (links) => {
  return {
    type: 'LINK_MEDICAMENTO_ESPECIALIDADE_SET_LINKS',
    payload: links,
  };
};

export const setRef = (ref) => {
  return {
    type: 'LINK_MEDICAMENTO_ESPECIALIDADE_SET_REF',
    payload: ref,
  };
};

export const setPage = (page) => {
  return {
    type: 'LINK_MEDICAMENTO_ESPECIALIDADE_SET_PAGE',
    payload: page,
  };
};

const setTotalItems = (totalItems) => {
  return {
    type: 'LINK_MEDICAMENTO_ESPECIALIDADE_SET_TOTAL_ITEMS',
    payload: totalItems,
  };
};

export const clearAll = () => {
  return {
    type: 'LINK_MEDICAMENTO_ESPECIALIDADE_CLEAR_ALL',
  };
};

export const fetchAllEspecialidadesByFarmacia = (farmaciaId) => {
  return async (dispatch) => {
    try {
      const especialidades = await findAllByFarmacia(farmaciaId);

      return dispatch(setAllEspecialidades(especialidades));
    } catch (e) {
      return dispatch(nop());
    }
  };
};

export const fetchLinksEspecialidadeMedicamentos = (
  farmaciaId,
  especialidadeId
) => {
  return async (dispatch) => {
    try {
      const medicamentos = await findMedicamentosByEspecialidade(
        farmaciaId,
        especialidadeId
      );
      console.log('here are the meds: ', medicamentos);
      return dispatch([
        setLinksMedicamentoEspecialidades(medicamentos),
        setLoading(false),
      ]);
    } catch (e) {
      return dispatch(nop());
    }
  };
};

export const searchMedicamentos = (
  farmaciaId,
  searchFilters = { ref: '', favorites: false },
  paginationFilters = { page: 1, itemsPerPage: 15 }
) => {
  return async (dispatch) => {
    try {
      const response = await search(
        farmaciaId,
        searchFilters,
        paginationFilters,
        null,
        'G'
      );

      return dispatch([
        setAllMedicamentos(response.data),
        setTotalItems(response['_metadata']?.totalItems),
      ]);
    } catch (e) {
      return dispatch(nop());
    }
  };
};
