export const setLoading = loading => {
  return {
    type: 'SET_LOADING',
    payload: loading
  }
}

export const clearLoading = () => {
  return {
    type: 'CLEAR_LOADING'
  }
}
