import React, { useEffect } from 'react';
import './styles.css';

import { useSelector, useDispatch } from 'react-redux';
import HomeTitle from './HomeTitle';
import SearchContainer from './SearchContainer';
import Row from '../../components/Row';
import Grid from '../../components/Grid';
import Summary from './Summary';
import {
  removeMedicamento,
  clearMedicamentos,
} from '../../stores/newReceitaActions';
import { getVias } from '../../stores/viaActions';
import logoImg from '../../assets/home/logo_h_white.png';

export default function Home() {
  const dispatch = useDispatch();

  const {
    usu_ch_tipo: role,
    // default checked for paciente aceita receber orçamentos
    usu_bo_pacienterecebeorcamento: pacienteRecebeOrcamentos,
  } = useSelector((state) => state.auth.user);

  // medicamentos on receita
  const medicamentos = useSelector((state) => state.receita.new.medicamentos);

  const farmacia = useSelector((state) => state.auth.farmacia);
  const user = useSelector((state) => state.auth.user);

  function removeMedicamentoReceita(medicamento) {
    dispatch(removeMedicamento(medicamento));
  }

  function handleClearSummary() {
    dispatch(clearMedicamentos());
  }

  useEffect(() => {
    dispatch(getVias());
  }, [dispatch]);

  return (
    <div className='home-container'>
      {process.env.REACT_APP_FIREBASE_PROJECT_ID === 'formularioreact' ? (
        <HomeTitle>
          {farmacia && user.usu_ch_tipo !== 'M'
            ? farmacia?.far_st_nome
            : 'Bem-vindo!'}
        </HomeTitle>
      ) : (
        <img src={logoImg} alt='DoctorLib' />
      )}

      <SearchContainer />

      {role === 'M' && medicamentos.length > 0 ? (
        <Row>
          <Grid cols='12'>
            <p>Receita atual</p>

            <Summary
              medicamentos={medicamentos}
              small
              onRemove={removeMedicamentoReceita}
              onClear={handleClearSummary}
              pacienteRecebeOrcamentos={pacienteRecebeOrcamentos}
              hideTitle
            />
          </Grid>
        </Row>
      ) : null}
    </div>
  );
}
