const INITIAL_STATE = {
  industrializados: [],
  favorites: [],
  pagination: {
    page: 1,
    itemsPerPage: 15,
    totalItems: 0
  },
  search: {
    ref: '',
    startsWith: false,
  }
}

export default function medicoIndustrializadosListReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'MEDICOS_INDUSTRIALIZADOS_SET_INDUSTRIALIZADOS':
      return {
        ...state,
        industrializados: action.payload
      }
    case 'MEDICOS_INDUSTRIALIZADOS_SET_FAVORITES':
      return {
        ...state,
        favorites: action.payload
      }
    case 'MEDICOS_INDUSTRIALIZADOS_SET_PAGE':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.payload
        }
      }
    case 'MEDICOS_INDUSTRIALIZADOS_SET_ITEMS_PER_PAGE':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          itemsPerPage: action.payload
        }
      }
    case 'MEDICOS_INDUSTRIALIZADOS_SET_TOTAL_ITEMS':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          totalItems: action.payload
        }
      }
    case 'MEDICOS_INDUSTRIALIZADOS_SET_REF':
      return {
        ...state,
        search: {
          ...state.search,
          ref: action.payload
        }
      }
    case 'MEDICOS_INDUSTRIALIZADOS_SET_STARTS_WITH':
      return {
        ...state,
        search: {
          ...state.search,
          startsWith: action.payload
        }
      }
    case 'MEDICOS_INDUSTRIALIZADOS_CLEAR_INDUSTRIALIZADOS':
      return {
        ...state,
        ...INITIAL_STATE
      }
    default:
      return state;
  }
}
