import React from 'react'
import './styles.css'
import PropTypes from 'prop-types'

function Container({ children }) {
  return (
    <div className="image-selector-container">
      {children}
    </div>
  )
}

Container.propTypes = {
  children: PropTypes.any
}

export default Container;
