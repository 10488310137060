import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import {
  setLogs,
  setPage,
  setTotalItems,
} from '../../stores/loggerMedicamentosMedicoListActions';
import { fetchAllLogsMedico } from '../../services/MedicoLogsService';
import LogList from '../../components/LogList';
import Title from '../../components/Title';
import Skeleton from 'react-loading-skeleton';
import LogSearch from '../../components/LogSearch';
import Pagination from '../../components/Pagination';

export default function MedicamentoLogsMedicoListContainer() {
  const [isLoading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const logs = useSelector((state) => state.medico.logger.medicamentos.logs);
  const { usu_in_codigo: medicoId } = useSelector((state) => state.auth.user);

  // search
  const ref = useSelector(
    (state) => state.medico.logger.medicamentos.search.ref
  );

  // pagination
  const page = useSelector(
    (state) => state.medico.logger.medicamentos.pagination.page
  );
  const itemsPerPage = useSelector(
    (state) => state.medico.logger.medicamentos.pagination.itemsPerPage
  );
  const totalItems = useSelector(
    (state) => state.medico.logger.medicamentos.pagination.totalItems
  );

  const farmaciaId = localStorage.getItem('FORMULARIO_FARMACIA_ID');

  useEffect(() => {
    fetchLogs();
  }, []);

  async function fetchLogs() {
    const response = await fetchAllLogsMedico(
      farmaciaId,
      medicoId,
      { ref: '' },
      {
        page: 1,
        itemsPerPage,
      }
    );

    dispatch([
      setLogs(response.data),
      setTotalItems(response['_metadata'].totalItems),
    ]);

    setLoading(false);
  }

  async function handleSearch({ ref }) {
    setLoading(true);

    const response = await fetchAllLogsMedico(
      farmaciaId,
      medicoId,
      { ref },
      {
        page: 1,
        itemsPerPage,
      }
    );

    dispatch([
      setLogs(response.data),
      setTotalItems(response['_metadata'].totalItems),
    ]);

    setLoading(false);
  }

  async function handleClearSearch() {
    setLoading(true);

    fetchLogs();
  }

  async function handlePaginate(pgNumber) {
    if (pgNumber === page) return;

    setLoading(true);

    const response = await fetchAllLogsMedico(
      farmaciaId,
      medicoId,
      { ref },
      {
        page: pgNumber,
        itemsPerPage,
      }
    );

    dispatch([
      setLogs(response.data),
      setPage(pgNumber),
      setTotalItems(response['_metadata'].totalItems),
    ]);

    setLoading(false);
  }

  return (
    <div>
      <Title link='/'>Registro de eventos</Title>

      <LogSearch onSubmit={handleSearch} onClear={handleClearSearch} />

      {isLoading ? <Skeleton count={5} height={25} /> : <LogList logs={logs} />}

      {logs.length === 0 ? null : (
        <Pagination
          page={page}
          itemsPerPage={itemsPerPage}
          totalItems={totalItems}
          paginate={handlePaginate}
        />
      )}
    </div>
  );
}
