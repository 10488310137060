import api from './api'
import { toast } from 'react-toastify'

export function recoverPassword(email) {
  return new Promise(async (resolve, reject) => {
    try {
      await api.post('usuarios/passwordRecovery', { email });

      toast.success('Verifique sua caixa de entrada, enviamos um e-mail com sua nova senha');
      
      return resolve();
    } catch (e) {
      if(e.response.data.error && e.response.data.error.status === 'EMAIL_NOT_FOUND') {
        toast.error('E-mail não encontrado');
      }

      return reject(e);
    }
  })
}
