import React, { useState, useEffect } from 'react';
import './styles.css';

import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import {
  fetchMedicoFavoriteFormulas,
  removeLinkFormulaMedico,
  setPage,
  setRef,
  clearAll,
} from '../../stores/favoriteFormulasActions';
import FormulaList from './FormulaList';
import Skeleton from 'react-loading-skeleton';
import Title from '../../components/Title';
import { unfavoriteFormula } from '../../services/FavoriteFormulasService';
import { setLoading as loading } from '../../stores/loadingActions';
import Pagination from '../../components/Pagination';
import Search from '../FormulaListContainer/FormulaSearch';
import Row from '../../components/Row';
import Grid from '../../components/Grid';

export default function FormulaFavoritesListContainer() {
  const [isLoading, setLoading] = useState(true);
  const [isFirst, setFirst] = useState(true);

  const { medicoId } = useParams();
  const history = useHistory();

  const dispatch = useDispatch();
  const formulas = useSelector((state) => state.formula.favorites.formulas);

  // pagination
  const page = useSelector((state) => state.formula.favorites.pagination.page);
  const itemsPerPage = useSelector(
    (state) => state.formula.favorites.pagination.itemsPerPage
  );
  const totalItems = useSelector(
    (state) => state.formula.favorites.pagination.totalItems
  );

  // search
  const ref = useSelector((state) => state.formula.favorites.search.ref);

  const receita = useSelector((state) => state.receita.new.medicamentos);

  const farmaciaId = localStorage.getItem('FORMULARIO_FARMACIA_ID');

  useEffect(() => {
    dispatch(
      fetchMedicoFavoriteFormulas(farmaciaId, medicoId, page, itemsPerPage, ref)
    );

    return () => dispatch(clearAll());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFirst) setFirst(false);
    else setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formulas]);

  async function handleUnfavorite(formula) {
    dispatch(loading(true));

    await unfavoriteFormula(farmaciaId, medicoId, formula.for_in_codigo);

    dispatch([
      fetchMedicoFavoriteFormulas(farmaciaId, medicoId, page, itemsPerPage, ''),
      loading(false),
    ]);
  }

  function handlePaginate(pgNumber) {
    if (pgNumber === page) return;

    setLoading(true);

    dispatch([
      fetchMedicoFavoriteFormulas(
        farmaciaId,
        medicoId,
        pgNumber,
        itemsPerPage,
        ref
      ),
      setPage(pgNumber),
    ]);
  }

  function handleSearch({ ref }) {
    setLoading(true);

    dispatch([
      fetchMedicoFavoriteFormulas(farmaciaId, medicoId, 1, itemsPerPage, ref),
      setPage(1),
      setRef(ref),
    ]);
  }

  function handleClearSearch() {
    setLoading(true);

    dispatch([
      fetchMedicoFavoriteFormulas(farmaciaId, medicoId, 1, itemsPerPage, ''),
      setPage(1),
      setRef(''),
    ]);
  }

  function handleRemoveLinkFormula(formula) {
    setLoading(true);

    dispatch(
      removeLinkFormulaMedico(farmaciaId, medicoId, formula.for_in_codigo)
    );
  }
  function handleAddFormulaReceita(formula) {
    history.push('/search', {
      step: 'NEW_FORMULA',
      formula: {
        codigo: formula.for_in_codigo,
        nome: formula.for_st_nome,
        uso: formula.for_st_recomendacaouso,
        posologia: formula.for_st_posologia,
        observacao: formula.for_st_observacao,
        qsp: formula.for_bo_qsp,
        quantidadeVeiculo: formula.for_in_quantidadeveiculo,
        id_unidade: formula.uni_in_codigo,
        veiculoUtilizacao: formula.veu_in_codigo,
        tipo: 'FORMULA',
      },
    });
  }

  function handleEditFormula(formula) {
    history.push(`/m/formulas/${formula.for_in_codigo}/edit`, {
      origin: `/medicos/${medicoId}/formulas/favoritos`,
      page,
      ref,
    });
  }

  return (
    <div className='formula-container'>
      <Title link='/formulas'>Fórmulas favoritas</Title>

      <Row>
        <Grid cols='12'>
          <Search onSubmit={handleSearch} onClear={handleClearSearch} />
        </Grid>
      </Row>

      <Row>
        <Grid cols='12'>
          {isLoading ? (
            <Skeleton count={5} height={35} />
          ) : (
            <FormulaList
              formulas={formulas}
              onUnfavorite={handleUnfavorite}
              onAddFormulaReceita={handleAddFormulaReceita}
              onEditFormula={handleEditFormula}
              onRemoveLinkFormula={handleRemoveLinkFormula}
              receita={receita}
            />
          )}
        </Grid>
      </Row>

      {formulas.length > 0 ? (
        <Row>
          <Grid cols='12'>
            <Pagination
              page={page}
              itemsPerPage={itemsPerPage}
              totalItems={totalItems}
              paginate={handlePaginate}
            />
          </Grid>
        </Row>
      ) : null}
    </div>
  );
}
