import api from './api'
import { toast } from 'react-toastify'

export function findMedicamentosByMedico(farmaciaId, usuarioId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      const response = await api.get(`farmacias/${farmaciaId}/usuarios/${usuarioId}/medicamentos`, {
        headers: { authorization: `Bearer ${token}` }
      });

      return resolve(response.data.data);
    } catch (e) {
      toast.error("Falha ao buscar medicamentos vinculados ao médico");
      return reject(e);
    }
  })
}

export function linkAtivoToMedico(farmaciaId, usuarioId, ativoId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      await api.post(`farmacias/${farmaciaId}/usuarios/${usuarioId}/ativos/${ativoId}`, {}, {
        headers: { authorization: `Bearer ${token}` }
      });

      return resolve();
    } catch (e) {
      toast.error("Falha ao vincular ativo ao médico");
      return reject(e);
    }
  })
}

export function unlinkAtivoMedico(farmaciaId, usuarioId, ativoId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      await api.delete(`farmacias/${farmaciaId}/usuarios/${usuarioId}/ativos/${ativoId}`, {
        headers: { authorization: `Bearer ${token}` }
      });

      return resolve();
    } catch (e) {
      toast.error("Falha ao desvincular ativo do médico");
      return reject(e);
    }
  })
}

export function linkFormulaMedico(farmaciaId, usuarioId, formulaId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      await api.post(`farmacias/${farmaciaId}/usuarios/${usuarioId}/formulas/${formulaId}`, {}, {
        headers: { authorization: `Bearer ${token}` }
      });

      return resolve();
    } catch (e) {
      toast.error("Falha ao vincular fórmula ao médico");
      return reject(e);
    }
  })
}

export function unlinkFormulaMedico(farmaciaId, usuarioId, formulaId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      await api.delete(`farmacias/${farmaciaId}/usuarios/${usuarioId}/formulas/${formulaId}`, {
        headers: { authorization: `Bearer ${token}` }
      });

      return resolve();
    } catch (e) {
      toast.error("Falha ao remover vínculo");
      return reject(e);
    }
  })
}

export function linkIndustrializadoToMedico(farmaciaId, usuarioId, industrializadoId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      await api.post(`farmacias/${farmaciaId}/usuarios/${usuarioId}/medicamentos_industrializados/${industrializadoId}`, {}, {
        headers: { authorization: `Bearer ${token}` }
      });

      return resolve();
    } catch (e) {
      toast.error("Falha ao vincular industrializado ao médico");
      return reject(e);
    }
  })
}

export function unlinkIndustrializadoMedico(farmaciaId, usuarioId, industrializadoId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      await api.delete(`farmacias/${farmaciaId}/usuarios/${usuarioId}/medicamentos_industrializados/${industrializadoId}`, {
        headers: { authorization: `Bearer ${token}` }
      });

      return resolve();
    } catch (e) {
      toast.error("Falha ao remover vínculo");
      return reject(e);
    }
  })
}

export function linkMultipleToMedico(farmaciaId, usuarioId, medicamentos) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      await api.post(`farmacias/${farmaciaId}/usuarios/${usuarioId}/medicamentos`, {
        medicamentos
      }, {
        headers: { authorization: `Bearer ${token}` }
      });

      return resolve();
    } catch (e) {
      toast.error("Falha ao vincular múltiplos medicamentos ao médico");
      return reject(e);
    }
  })
}

export function linkMultipleToMedicoByFilter(farmaciaId, usuarioId, ref, tipo) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      await api.post(`farmacias/${farmaciaId}/usuarios/${usuarioId}/medicamentos/filter`, {}, {
        headers: { authorization: `Bearer ${token}` },
        params: { ref, tipo }
      });

      return resolve();
    } catch (e) {
      toast.error("Falha ao vincular medicamentos ao médico");
      return reject(e);
    }
  })
}
