import { nop } from './baseActions'
import { findById } from '../services/FormulaService'
import { findFormulasAtivos } from '../services/FormulaAtivoService'
import { findAll as findAllUnidades } from '../services/UnidadeService'
import { findAll as findAllVeiculosUtilizacao, findById as findVeiculoById } from '../services/VeiculoUtilizacaoService'
import { searchByRef as searchVeiculos } from '../services/SearchVeiculoUtilizacaoService'

const setFormula = formula => {
  return {
    type: 'MEDICO_FORMULA_EDIT_SET_FORMULA',
    payload: formula
  }
}

const setAtivos = ativos => {
  return {
    type: 'MEDICO_FORMULA_EDIT_SET_ATIVOS',
    payload: ativos
  }
}

const setUnidades = unidades => {
  return {
    type: 'MEDICO_FORMULA_EDIT_SET_UNIDADES',
    payload: unidades
  }
}

const setVeiculosUtilizacao = veiculosUtilizacao => {
  return {
    type: 'MEDICO_FORMULA_EDIT_SET_VEICULOS_UTILIZACAO',
    payload: veiculosUtilizacao
  }
}

const setVeiculoUtilizacao = veiculoUtilizacao => {
  return {
    type: 'MEDICO_FORMULA_EDIT_SET_VEICULO_UTILIZACAO',
    payload: veiculoUtilizacao
  }
}

export const setGoBack = path => {
  return {
    type: 'MEDICO_FORMULA_EDIT_SET_GO_BACK',
    payload: path
  }
}

export const setEditing = isEditing => {
  return {
    type: 'MEDICO_FORMULA_EDIT_SET_IS_EDITTING',
    payload: isEditing
  }
}

export const clearFormula = () => {
  return {
    type: 'MEDICO_FORMULA_EDIT_CLEAR_FORMULA'
  }
}

export const fetchAtivosByFormula = (farmaciaId, formulaId) => {
  return async dispatch => {
    try {
      const ativos = await findFormulasAtivos(farmaciaId, formulaId);

      return dispatch(setAtivos(ativos));
    } catch (e) {
      return dispatch(nop());
    }
  }
}

export const fetchById = (farmaciaId, id) => {
  return async dispatch => {
    try {
      const formula = await findById(farmaciaId, id);

      return dispatch(setFormula(formula));
    } catch (e) {
      return dispatch(nop());
    }
  }
}

export const fetchUnidades = farmaciaId => {
  return async dispatch => {
    try {
      const unidades = await findAllUnidades(farmaciaId);

      return dispatch(setUnidades(unidades));
    } catch (e) {
      return dispatch(nop());
    }
  }
}

export const fetchVeiculosUtilizacao = farmaciaId => {
  return async dispatch => {
    try {
      const veiculosUtilizacao = await findAllVeiculosUtilizacao(farmaciaId);

      return dispatch(setVeiculosUtilizacao(veiculosUtilizacao));
    } catch (e) {
      return dispatch(nop());
    }
  }
}

export const fetchVeiculoUtilizacaoById = (farmaciaId, id) => {
  return async dispatch => {
    try {
      const veiculoUtilizacao = await findVeiculoById(farmaciaId, id);

      return dispatch(setVeiculoUtilizacao(veiculoUtilizacao));
    } catch (e) {
      return dispatch(nop());
    }
  }
}

export const searchVeiculosUtilizacao = (farmaciaId, ref) => {
  return async dispatch => {
    try {
      const veiculosUtilizacao = await searchVeiculos(farmaciaId, ref);

      return dispatch(setVeiculosUtilizacao(veiculosUtilizacao));
    } catch (e) {
      return dispatch(nop());
    }
  }
}

