import api from './api';
import { toast } from 'react-toastify';

//ATIVOS
export function findMedicamentosByEspecialidade(farmaciaId, especialidadeId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      const response = await api.get(
        `farmacias/${farmaciaId}/especialidades/links/${especialidadeId}`,
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );

      return resolve(response.data.data);
    } catch (e) {
      toast.error('Falha ao buscar especialidades vinculadas ao médico');
      return reject(e);
    }
  });
}
