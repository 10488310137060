import React, { useState, useEffect, useRef } from 'react';
import './styles.css';

import { useParams, useHistory } from 'react-router-dom';
import Title from '../../components/Title';
import { Form } from '@unform/web';
import { Input, TextArea, Select } from '../../components/Form';
import Button from '../../components/Button';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { setLoading } from '../../stores/loadingActions';
import {
  fetchById,
  clearAtivo,
  fetchLogsByAtivo,
} from '../../stores/ativoEditActions';
import Row from '../../components/Row';
import Grid from '../../components/Grid';
import { update } from '../../services/AtivoService';
import AtivoLogs from '../../components/Logs';
import { clearLogs } from '../../services/AtivoLogsService';

export default function AtivoEdit() {
  const { id: ativoId } = useParams();
  const history = useHistory();

  const farmaciaId = localStorage.getItem('FORMULARIO_FARMACIA_ID');

  const dispatch = useDispatch();
  const ativo = useSelector((state) => state.ativo.edit.ativo);
  const { usu_in_codigo: usuarioId } = useSelector((state) => state.auth.user);

  const formRef = useRef(null);

  const [descricao, setDescricao] = useState('');
  const [concentracaoMinimaTopico, setConcentracaoMinimaTopico] = useState('');
  const [concentracaoMaximaTopico, setConcentracaoMaximaTopico] = useState('');
  const [concentracaoDiariaTopico, setConcentracaoDiariaTopico] = useState('');
  const [concentracaoMinimaOral, setConcentracaoMinimaOral] = useState('');
  const [concentracaoMaximaOral, setConcentracaoMaximaOral] = useState('');
  const [concentracaoDiariaOral, setConcentracaoDiariaOral] = useState('');
  const [obs, setObs] = useState('');
  const [indicacaoUso, setIndicacaoUso] = useState('');
  const [uso, setUso] = useState('ORAL');
  const [sinonimos, setSinonimos] = useState(null);

  const [locked, setLocked] = useState(true);

  const role = useSelector((state) => state.auth.user.usu_ch_tipo);
  const readonly = role !== 'G';

  const goBackPath = useSelector((state) => state.ativo.edit.goBack);

  // logs
  const logs = useSelector((state) => state.ativo.edit.logs);

  useEffect(() => {
    // fetch ativo by id
    dispatch([
      fetchById(farmaciaId, ativoId),
      fetchLogsByAtivo(farmaciaId, ativoId),
    ]);

    return () => dispatch(clearAtivo());
  }, []);

  useEffect(() => {
    if (ativo) {
      setDescricao(ativo.atv_st_descricao);
      setConcentracaoMinimaTopico(ativo.atv_st_concentracaominima_topico || '');
      setConcentracaoMaximaTopico(ativo.atv_st_concentracaomaxima_topico || '');
      setConcentracaoDiariaTopico(ativo.atv_st_concentracaodiaria_topico || '');
      setConcentracaoMinimaOral(ativo.atv_st_concentracaominima_oral || '');
      setConcentracaoMaximaOral(ativo.atv_st_concentracaomaxima_oral || '');
      setConcentracaoDiariaOral(ativo.atv_st_concentracaodiaria_oral || '');
      setObs(ativo.atv_st_observacao || '');
      setIndicacaoUso(ativo.atv_st_indicacaouso);
      setUso(ativo.atv_st_uso);
      setSinonimos(ativo.atv_st_sinonimos);
    }
  }, [ativo]);

  async function handleSubmit(data, { reset }) {
    try {
      // set loading
      dispatch(setLoading(true));

      // validation
      const schema = Yup.object().shape({
        atv_st_descricao: Yup.string().required('A descrição é obrigatória'),
        atv_st_concentracaominima_topico: Yup.string(),
        atv_st_concentracaomaxima_topico: Yup.string(),
        atv_st_concentracaodiaria_topico: Yup.string(),
        atv_st_concentracaominima_oral: Yup.string(),
        atv_st_concentracaomaxima_oral: Yup.string(),
        atv_st_concentracaodiaria_oral: Yup.string(),
        atv_st_observacao: Yup.string(),
        atv_st_sinonimos: Yup.string(),
        atv_st_indicacaouso: Yup.string().required(
          'Informe a indicação de uso do ativo'
        ),
        atv_st_uso: Yup.string().required('O campo uso é obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      // save
      await update(farmaciaId, ativoId, data, usuarioId);

      dispatch(setLoading(false));

      // reset form data
      reset();

      // reset errors
      formRef.current.setErrors({});

      // redirect user
      history.push('/ativos');
    } catch (e) {
      if (e instanceof Yup.ValidationError) {
        const errorMessages = {};

        e.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });

        formRef.current.setErrors(errorMessages);
      }

      dispatch(setLoading(false));
    }
  }

  async function handleClearLogs() {
    dispatch(setLoading(true));

    await clearLogs(farmaciaId, ativoId, usuarioId);

    dispatch([fetchLogsByAtivo(farmaciaId, ativoId), setLoading(false)]);
  }

  function ButtonLinkVeiculoUtilizacao() {
    return readonly ? null : (
      <Button
        size='sm'
        color='secondary'
        handleclick={() =>
          history.push(`/ativos/${ativoId}/link/veiculosUtilizacao`)
        }
      >
        Vincular veículos de utilização
      </Button>
    );
  }

  function ButtonLinkFormula() {
    return readonly ? null : (
      <Button
        size='sm'
        color='secondary'
        className='ml-2'
        handleclick={() => history.push(`/ativos/${ativoId}/link/formulas`)}
      >
        Vincular fórmulas
      </Button>
    );
  }

  function ActionButtons() {
    return locked ? (
      <Button size='block' handleclick={() => setLocked(false)}>
        Habilitar campos
      </Button>
    ) : (
      <Row>
        <Grid cols='12 6'>
          <Button
            color='secondary'
            size='block'
            handleclick={() => setLocked(true)}
          >
            Cancelar
          </Button>
        </Grid>

        <Grid cols='12 6'>
          <Button type='submit' size='block'>
            Salvar
          </Button>
        </Grid>
      </Row>
    );
  }

  return (
    <div className='ativo-container'>
      <Title
        link={goBackPath || '/ativos'}
        component={() => [
          <ButtonLinkVeiculoUtilizacao key={ButtonLinkVeiculoUtilizacao} />,
          <ButtonLinkFormula key={ButtonLinkFormula} />,
        ]}
      >
        {readonly ? 'Informações do ativo' : 'Editar'}
      </Title>

      <Row>
        <Grid cols='12 6'>
          <div className='form-container'>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <Input
                id='atv_st_descricao'
                label='* Descrição'
                className='form-control upper'
                name='atv_st_descricao'
                value={descricao}
                onChange={(e) => setDescricao(e.target.value)}
                disabled={locked}
                required
              />

              <Select
                id='atv_st_uso'
                label='* Uso'
                className='form-control upper'
                name='atv_st_uso'
                value={uso}
                onChange={(e) => setUso(e.target.value)}
                disabled={locked}
                required
              >
                <option value='ORAL'>Oral</option>
                <option value='TOPICO'>Topico</option>
                <option value='AMBOS'>Ambos</option>
              </Select>

              {uso === 'TOPICO' || uso === 'AMBOS' ? (
                <fieldset>
                  <Input
                    id='atv_st_concentracaominima_topico'
                    label='Concentração mínima (topico)'
                    className='form-control upper'
                    name='atv_st_concentracaominima_topico'
                    value={concentracaoMinimaTopico}
                    onChange={(e) =>
                      setConcentracaoMinimaTopico(e.target.value)
                    }
                    disabled={locked}
                  />

                  <Input
                    id='atv_st_concentracaomaxima_topico'
                    label='Concentração máxima (topico)'
                    className='form-control upper'
                    name='atv_st_concentracaomaxima_topico'
                    value={concentracaoMaximaTopico}
                    onChange={(e) =>
                      setConcentracaoMaximaTopico(e.target.value)
                    }
                    disabled={locked}
                  />

                  <Input
                    id='atv_st_concentracaodiaria_topico'
                    label='Concentração usual (topico)'
                    className='form-control upper'
                    name='atv_st_concentracaodiaria_topico'
                    value={concentracaoDiariaTopico}
                    onChange={(e) =>
                      setConcentracaoDiariaTopico(e.target.value)
                    }
                    disabled={locked}
                  />
                </fieldset>
              ) : null}

              {uso === 'ORAL' || uso === 'AMBOS' ? (
                <fieldset>
                  <Input
                    id='atv_st_concentracaominima_oral'
                    label='Concentração mínima (oral)'
                    className='form-control upper'
                    name='atv_st_concentracaominima_oral'
                    value={concentracaoMinimaOral}
                    onChange={(e) => setConcentracaoMinimaOral(e.target.value)}
                    disabled={locked}
                  />

                  <Input
                    id='atv_st_concentracaomaxima_oral'
                    label='Concentração máxima (oral)'
                    className='form-control upper'
                    name='atv_st_concentracaomaxima_oral'
                    value={concentracaoMaximaOral}
                    onChange={(e) => setConcentracaoMaximaOral(e.target.value)}
                    disabled={locked}
                  />

                  <Input
                    id='atv_st_concentracaodiaria_oral'
                    label='Concentração usual (oral)'
                    className='form-control upper'
                    name='atv_st_concentracaodiaria_oral'
                    value={concentracaoDiariaOral}
                    onChange={(e) => setConcentracaoDiariaOral(e.target.value)}
                    disabled={locked}
                  />
                </fieldset>
              ) : null}

              <TextArea
                id='atv_st_indicacaouso'
                label='* Indicação de uso'
                className='form-control upper'
                name='atv_st_indicacaouso'
                value={indicacaoUso}
                onChange={(e) => setIndicacaoUso(e.target.value)}
                disabled={locked}
                required
              ></TextArea>

              <Input
                id='atv_st_sinonimos'
                label='Sinônimos'
                className='form-control upper'
                name='atv_st_sinonimos'
                value={sinonimos}
                onChange={(e) => setSinonimos(e.target.value)}
                disabled={locked}
              />

              <TextArea
                id='atv_st_observacao'
                label='Observação'
                className='form-control upper'
                name='atv_st_observacao'
                value={obs}
                onChange={(e) => setObs(e.target.value)}
                disabled={locked}
              ></TextArea>

              {readonly ? null : (
                <div className='w-100 mb-3'>
                  <small className='text-muted align-end'>
                    Os campos marcados com (*) são obrigatórios
                  </small>
                </div>
              )}

              {readonly ? null : <ActionButtons />}
            </Form>
          </div>
        </Grid>

        <Grid cols='12 6'>
          <AtivoLogs
            logs={logs.map((log) => ({
              datahora: log.alo_dt_datahora,
              mensagem: log.alo_st_mensagem,
            }))}
            onClearLogs={handleClearLogs}
          />
        </Grid>
      </Row>
    </div>
  );
}
