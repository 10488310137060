import { nop } from './baseActions'
import { findAtivosByRepresentante } from '../services/RepresentanteMedicamentosService'

const setAtivos = ativos => {
  return {
    type: 'REPRESENTANTE_ATIVO_LIST_SET_ATIVOS',
    payload: ativos
  }
}

export const setPage = page => {
  return {
    type: 'REPRESENTANTE_ATIVO_LIST_PAGINATION_SET_PAGE',
    payload: page
  }
}

export const setItemsPerPage = itemsPerPage => {
  return {
    type: 'REPRESENTANTE_ATIVO_LIST_PAGINATION_SET_ITEMS_PER_PAGE',
    payload: itemsPerPage
  }
}

const setTotalItems = totalItems => {
  return {
    type: 'REPRESENTANTE_ATIVO_LIST_PAGINATION_SET_TOTAL_ITEMS',
    payload: totalItems
  }
}

export const clearAll = () => {
  return {
    type: 'REPRESENTANTE_ATIVO_LIST_CLEAR_ALL'
  }
}

export const fetchAtivosByRepresentante = (farmaciaId, representanteId, pagination = { page: 1, itemsPerPage: 15 }) => {
  return async dispatch => {
    try {
      const response = await findAtivosByRepresentante(farmaciaId, representanteId, pagination);

      return dispatch([
        setAtivos(response.data),
        setTotalItems(response["_metadata"].totalItems)
      ]);
    } catch (e) {
      return dispatch(nop());
    }
  }
}
