import api from './api';
import { toast } from 'react-toastify';

export function findAllMedicosByFarmacia(farmaciaId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      const response = await api.get(`farmacias/${farmaciaId}/medicos`, {
        headers: { authorization: `Bearer ${token}` },
      });

      return resolve(response.data.data);
    } catch (e) {
      toast.error('Falha ao buscar médicos');
      return reject(e);
    }
  });
}

export function findAllMedicosByRepresentante(farmaciaId, representanteId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      const response = await api.get(
        `farmacias/${farmaciaId}/medicos/representante/${representanteId}`,
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );

      return resolve(response.data.data);
    } catch (e) {
      toast.error('Falha ao buscar médicos');
      return reject(e);
    }
  });
}
