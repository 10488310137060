import React, { useState, useEffect } from 'react';
import './styles.css';

import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchAllMedicamentos,
  removeMedicamento as deleteMedicamento,
  clearMedicamentos,
  setPage,
  setRef,
  setStartsWith,
} from '../../stores/medicamentoListActions';
import Title from '../../components/Title';
import ButtonAdd from '../../components/ButtonAdd';
import Button from '../../components/Button';
import Skeleton from 'react-loading-skeleton';
import Row from '../../components/Row';
import Grid from '../../components/Grid';
import MedicamentoList from './MedicamentoList';
import MedicamentoSearch from './MedicamentoSearch';
import Pagination from '../../components/Pagination';

export default function MedicamentoListContainer() {
  const [isLoading, setLoading] = useState(true);
  const [isFirst, setFirst] = useState(true);

  const history = useHistory();
  const dispatch = useDispatch();

  const medicamentos = useSelector(
    (state) => state.medicamentosIndustrializados.list.medicamentos
  );

  const farmaciaId = localStorage.getItem('FORMULARIO_FARMACIA_ID');

  // pagination
  const page = useSelector(
    (state) => state.medicamentosIndustrializados.list.pagination.page
  );
  const itemsPerPage = useSelector(
    (state) => state.medicamentosIndustrializados.list.pagination.itemsPerPage
  );
  const totalItems = useSelector(
    (state) => state.medicamentosIndustrializados.list.pagination.totalItems
  );

  const ref = useSelector(
    (state) => state.medicamentosIndustrializados.list.search.ref
  );
  const startsWith = useSelector(
    (state) => state.medicamentosIndustrializados.list.search.startsWith
  );

  useEffect(() => {
    dispatch(fetchAllMedicamentos(farmaciaId, '', 1, itemsPerPage, false));

    return () => dispatch(clearMedicamentos());
  }, []);

  useEffect(() => {
    if (!isFirst) setLoading(false);
    else setFirst(false);
  }, [medicamentos]);

  function removeMedicamento(medicamento) {
    dispatch(deleteMedicamento(farmaciaId, medicamento));
  }

  function editMedicamento(medicamento) {
    history.push(`/medicamentos/${medicamento.med_in_codigo}/edit`);
  }

  function handlePaginate(pgNumber) {
    if (pgNumber === page) return;

    setLoading(true);

    dispatch([
      setPage(pgNumber),
      fetchAllMedicamentos(farmaciaId, ref, pgNumber, itemsPerPage, startsWith),
    ]);
  }

  function handleSearch({ ref: refText, startsWith: searchStartsWith }) {
    if (refText === ref && searchStartsWith === startsWith) return;

    setLoading(true);

    dispatch([
      setPage(1),
      setRef(refText),
      setStartsWith(searchStartsWith),
      fetchAllMedicamentos(
        farmaciaId,
        refText,
        1,
        itemsPerPage,
        searchStartsWith
      ),
    ]);
  }

  function handleClearSearch() {
    setLoading(true);

    dispatch([
      setPage(1),
      setRef(''),
      setStartsWith(false),
      fetchAllMedicamentos(farmaciaId, '', 1, itemsPerPage, false),
    ]);
  }

  return (
    <div className='medicamento-container'>
      <Title
        link='/'
        component={() => [
          <Button
            key={1}
            color='secondary'
            size='sm'
            className='mr-1'
            handleclick={() => history.push('/medicamentos/pendentes')}
          >
            Industrializados pendentes
          </Button>,
          <ButtonAdd
            key={2}
            onClick={() => history.push('/medicamentos/new')}
          />,
        ]}
      >
        Industrializados
      </Title>

      <Row>
        <Grid cols='12'>
          <MedicamentoSearch
            onSearch={handleSearch}
            onClear={handleClearSearch}
          />
        </Grid>
      </Row>

      <Row>
        <Grid cols='12'>
          {isLoading ? (
            <Skeleton count={5} height={30} />
          ) : (
            <MedicamentoList
              medicamentos={medicamentos}
              onDelete={removeMedicamento}
              onEdit={editMedicamento}
            />
          )}
        </Grid>
      </Row>

      {medicamentos.length > 0 ? (
        <Row>
          <Grid cols='12'>
            <Pagination
              page={page}
              itemsPerPage={itemsPerPage}
              totalItems={totalItems}
              paginate={handlePaginate}
            />
          </Grid>
        </Row>
      ) : null}
    </div>
  );
}
