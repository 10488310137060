import api from './api';
import { toast } from 'react-toastify';

export function findAllModelosReceitaByRepresentante(farmaciaId, representanteId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      const response = await api.get(`farmacias/${farmaciaId}/representantes/${representanteId}/modelos_receitas`, {
        headers: { authorization: `Bearer ${token}` }
      });

      return resolve(response.data.data);
    } catch (e) {
      toast.error("Erro ao buscar modelos da receita");
      return reject(e);
    }
  })
}
