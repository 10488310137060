import firebase from 'firebase/app'
import 'firebase/storage'

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Initialize storage
const storage = firebase.storage();

// Save files on storage
function save(blob, blobName) {
  // create a storage ref
  const storageRef = storage.ref(blobName)

  // upload file
  const task = storageRef.put(blob)

  // upload progress bar
  task.on('state_changed',
    function progress(snapshot) { },
    function error(err) { },
    function complete() { }
  );
}

export {
  firebase as deafult,
  storage,
  save
}
