import React, { useState, useEffect, useRef } from 'react';

import { useParams, useHistory } from 'react-router-dom';
import Title from '../../components/Title';
import { Form } from '@unform/web';
import { Checkbox, Input } from '../../components/Form';
import Button from '../../components/Button';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { setLoading } from '../../stores/loadingActions';
import { fetchById, clearAll } from '../../stores/receitaModeloEditActions';
import Row from '../../components/Row';
import Grid from '../../components/Grid';
import { update } from '../../services/ReceitaModeloService';
import { storage } from '../../config/firebase';
import ImageInput from '../../components/ImageInput';
import defaultImg from '../../assets/default-img.png';
import { save as saveFile } from '../../config/firebase';
import { getFileExtension } from '../../utils/files';
import { v4 as uuidv4 } from 'uuid';

export default function ModeloReceitaEdit() {
  const { id } = useParams();
  const history = useHistory();

  const dispatch = useDispatch();
  const modelo = useSelector((state) => state.receitaModelo.edit.modelo);

  const farmaciaId = localStorage.getItem('FORMULARIO_FARMACIA_ID');

  const formRef = useRef(null);

  const [descricao, setDescricao] = useState('');
  const [marginLeft, setMarginLeft] = useState('');
  const [marginTop, setMarginTop] = useState('');
  const [timbrado, setTimbrado] = useState(false);

  const [image, setImage] = useState({ url: defaultImg, file: null });

  const [locked, setLocked] = useState(true);

  useEffect(() => {
    // fetch modelo by id
    dispatch(fetchById(farmaciaId, id));

    return () => dispatch(clearAll());
  }, []);

  useEffect(() => {
    if (modelo) {
      setDescricao(modelo.rem_st_descricao);
      setMarginLeft(modelo.rem_st_marginleft);
      setMarginTop(modelo.rem_st_margintop);
      setTimbrado(modelo.rem_bo_timbrado);

      // fetch image
      if (modelo.rem_st_caminhoimagem) {
        storage
          .ref(modelo.rem_st_caminhoimagem)
          .getDownloadURL()
          .then((url) => {
            document.getElementById('modelo-img').src = url;
          })
          .catch((err) => console.log('Erro ao carregar imagem', err));
      }
    }
  }, [modelo]);

  async function handleSubmit(data, { reset }) {
    try {
      // set loading
      dispatch(setLoading(true));

      // validation
      const schema = Yup.object().shape({
        rem_st_descricao: Yup.string().required('A descrição é obrigatória'),
        rem_st_caminhoimagem: Yup.string(),
        rem_st_marginleft: Yup.string(),
        rem_st_margintop: Yup.string(),
      });

      let modelo = data;
      const file = image.file;

      if (file) {
        const fileExtension = getFileExtension(file.name);
        const blobName = `receitas/${modelo.med_st_nome}/${
          uuidv4() + fileExtension
        }`;
        saveFile(file, blobName);

        modelo = {
          ...modelo,
          rem_st_caminhoimagem: blobName,
        };
      }

      console.log('modelo', modelo);

      await schema.validate(modelo, {
        abortEarly: false,
      });

      // save
      await update(farmaciaId, id, modelo);

      dispatch(setLoading(false));

      // reset form data
      reset();

      // reset errors
      formRef.current.setErrors({});

      // redirect user
      history.push('/modelos_receita');
    } catch (e) {
      if (e instanceof Yup.ValidationError) {
        const errorMessages = {};

        e.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });

        formRef.current.setErrors(errorMessages);
      }

      dispatch(setLoading(false));
    }
  }

  function handleLoadImage(e) {
    const file = e.target.files[0];
    const url = window.URL.createObjectURL(file);

    setImage({ url, file });
  }

  function ActionButtons() {
    return locked ? (
      <Button size='block' handleclick={() => setLocked(false)}>
        Habilitar campos
      </Button>
    ) : (
      <Row>
        <Grid cols='12 6'>
          <Button
            color='secondary'
            size='block'
            handleclick={() => setLocked(true)}
          >
            Cancelar
          </Button>
        </Grid>

        <Grid cols='12 6'>
          <Button type='submit' size='block'>
            Salvar
          </Button>
        </Grid>
      </Row>
    );
  }

  return (
    <div className='receita-container'>
      <Title link='/modelos_receita'>Editar</Title>

      <Row>
        <Grid cols='12 6'>
          <div className='form-container'>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <Input
                id='rem_st_descricao'
                label='* Descrição'
                className='form-control'
                name='rem_st_descricao'
                value={descricao}
                onChange={(e) => setDescricao(e.target.value)}
                disabled={locked}
                required
              />

              <Input
                id='rem_st_marginleft'
                label='Margem da esquerda (número em pixels)'
                placeholder='Ex: 25'
                className='form-control'
                name='rem_st_marginleft'
                value={marginLeft}
                onChange={(e) => setMarginLeft(e.target.value)}
                disabled={locked}
              />

              <Input
                id='rem_st_margintop'
                label='Margem do topo (número em pixels)'
                placeholder='Ex: 25'
                className='form-control'
                name='rem_st_margintop'
                value={marginTop}
                onChange={(e) => setMarginTop(e.target.value)}
                disabled={locked}
              />

              <Checkbox
                id='rem_bo_timbrado'
                label='Imprimir timbrado?'
                name='rem_bo_timbrado'
                checked={timbrado}
                value={timbrado}
                onChange={(e) => setTimbrado(e.target.checked)}
                disabled={locked}
              />

              {!locked && (
                <ImageInput
                  className='form-group'
                  id='med_st_imagem'
                  name='med_st_imagem'
                  onLoadImage={handleLoadImage}
                  isRequired={false}
                />
              )}

              <div className='w-100 mb-3'>
                <small className='text-muted align-end'>
                  Os campos marcados com (*) são obrigatórios
                </small>
              </div>

              <ActionButtons />
            </Form>
          </div>
        </Grid>

        <Grid cols='12 6'>
          <img
            id='modelo-img'
            className='img-fluid img-view'
            src={image.url}
            alt='Layout do modelo'
          />
        </Grid>
      </Row>
    </div>
  );
}
