import { isEqualByProps } from "../utils/objects";
import { v4 as uuidv4 } from "uuid";

const INITIAL_STATE = {
  medicamentos: [],
  paciente: null,
  receita: null,
  modelo: null,
  orcamento: false,
};

export default function newReceitaReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "NEW_RECEITA_SET_MEDICAMENTOS":
      return {
        ...state,
        medicamentos: action.payload,
      };
    case "NEW_RECEITA_ADD_MEDICAMENTO":
      const isMedicamentoOnReceita = state.medicamentos.find((med) =>
        isEqualByProps(med, action.payload, "codigo", "tipo")
      );
      let newMedicamentos = [];

      // replace medicamento if it is already on receita
      // otherwise, just add medicamento
      if (isMedicamentoOnReceita) {
        newMedicamentos = state.medicamentos.map((med) => {
          if (isEqualByProps(med, action.payload, "codigo", "tipo")) {
            return action.payload;
          }

          return med;
        });
      } else {
        newMedicamentos = [
          ...state.medicamentos,
          {
            ...action.payload,
            codigo: action.payload.codigo || uuidv4(),
          },
        ];
      }

      return {
        ...state,
        medicamentos: newMedicamentos,
      };
    case "NEW_RECEITA_REMOVE_MEDICAMENTO":
      return {
        ...state,
        medicamentos: state.medicamentos.filter((med) => {
          if (
            med.codigo === action.payload.codigo &&
            med.tipo === action.payload.tipo
          ) {
            return false;
          }

          return true;
        }),
      };
    case "NEW_RECEITA_SET_PACIENTE":
      return {
        ...state,
        paciente: action.payload,
      };
    case "NEW_RECEITA_SET_RECEITA":
      return {
        ...state,
        receita: action.payload,
      };
    case "NEW_RECEITA_SET_MODELO":
      return {
        ...state,
        modelo: action.payload,
      };
    case "NEW_RECEITA_CLEAR_MEDICAMENTOS":
      return {
        ...state,
        ...INITIAL_STATE,
      };
    case "NEW_RECEITA_SET_RECEBER_ORCAMENTO":
      return {
        ...state,
        orcamento: true,
      };
    default:
      return state;
  }
}
