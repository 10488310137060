import React, { useState } from 'react';
// import './styles.css'
import PropTypes from 'prop-types';

import Table, {
  Thead as TableHeader,
  TableRow,
  Tbody,
  TbodyCol as TbCol,
} from '../../../components/Table';
import Modal from '../../../components/Modal';
import ModalButton from '../../../components/ModalButton';
import Button from '../../../components/Button';
import { MdDelete, MdEdit } from 'react-icons/md';
import { insertIf } from '../../../utils/arrays';

function UnidadeList({
  especialidades,
  onLinkEspecialidade: handleLinkEspecialidade,
  onUnlinkEspecialidade: handleUnlinkEspecialidade,
  readonly,
  search: searchRef,
}) {
  return especialidades.length > 0 ? (
    <div>
      <Table card>
        <TableHeader
          dark
          columns={['Especialidade', ...insertIf(!readonly, 'Ação')]}
        />
        <Tbody>
          {especialidades.map((especialidade) =>
            especialidade.esp_st_descricao
              .toUpperCase()
              .includes(searchRef.toUpperCase()) ? (
              <TableRow className='upper' key={especialidade.esp_in_codigo}>
                <TbCol alignMiddle label='Descrição'>
                  {especialidade.esp_st_descricao}
                </TbCol>
                {readonly ? null : (
                  <TbCol alignMiddle label='Ação'>
                    {especialidade.hasOwnProperty('linked') ? (
                      <Button
                        className='ml-1'
                        color='danger'
                        size='sm'
                        handleclick={() =>
                          handleUnlinkEspecialidade(especialidade)
                        }
                      >
                        Desvincular especialidade
                      </Button>
                    ) : (
                      <Button
                        className='ml-1'
                        color='success'
                        size='sm'
                        handleclick={() =>
                          handleLinkEspecialidade(especialidade)
                        }
                      >
                        Vincular especialidade
                      </Button>
                    )}
                  </TbCol>
                )}
              </TableRow>
            ) : null
          )}
        </Tbody>
      </Table>
    </div>
  ) : (
    <p className='not-found'>Nenhuma especialidade encontrada</p>
  );
}

UnidadeList.propTypes = {
  especialidades: PropTypes.array.isRequired,
  readonly: PropTypes.bool,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
};

UnidadeList.defaultProps = {
  readonly: false,
};

export default UnidadeList;
