import api from './api';
import { toast } from 'react-toastify';

export function findAll() {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      const response = await api.get('especialidades', {
        headers: { authorization: `bearer ${token}` },
      });

      return resolve(response.data.data);
    } catch (e) {
      toast.error('Erro ao buscar especialidades');
      return reject(e);
    }
  });
}

export function findAllByFarmacia(farmaciaId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      const response = await api.get(`especialidades/farmacia/${farmaciaId}`, {
        headers: { authorization: `bearer ${token}` },
      });

      return resolve(response.data.data);
    } catch (e) {
      toast.error('Erro ao buscar especialidades');
      return reject(e);
    }
  });
}

export function findById(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      const response = await api.get(`especialidades/${id}`, {
        headers: { authorization: `bearer ${token}` },
      });

      return resolve(response.data.data);
    } catch (e) {
      toast.error('Erro ao buscar especialidade');
      return reject(e);
    }
  });
}

export function save(farmaciaId, espec) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      const response = await api.post(
        `especialidades/farmacia/${farmaciaId}`,
        espec,
        {
          headers: { authorization: `bearer ${token}` },
        }
      );

      return resolve(response.data.data);
    } catch (e) {
      if (
        e.response.data.error &&
        e.response.data.error.status === 'ESP_ALREADY_REGISTERED'
      ) {
        toast.error('Esta especialidade já foi cadastrada');
      } else {
        toast.error('Falha ao cadastrar especialidade');
      }

      return reject(e);
    }
  });
}

export function update(id, espec) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      await api.put(`especialidades/${id}`, espec, {
        headers: { authorization: `bearer ${token}` },
      });

      return resolve();
    } catch (e) {
      toast.error('Falha ao salvar especialidade');
      return reject(e);
    }
  });
}

export function remove(espec) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      await api.delete(`especialidades/${espec.esp_in_codigo}`, {
        headers: { authorization: `bearer ${token}` },
      });

      return resolve();
    } catch (e) {
      toast.error('Falha ao remover especialidade');
      return reject(e);
    }
  });
}
