const INITIAL_STATE = {
  receitaModelos: []
}

export default function newUsuarioReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'NEW_USUARIO_SET_RECEITA_MODELOS':
      return {
        ...state,
        receitaModelos: action.payload
      }
    case 'NEW_USUARIO_CLEAR_ALL':
      return {
        ...state,
        ...INITIAL_STATE
      }
    default:
      return state;
  }
}
