import React from 'react';
import './styles.css';
import PropTypes from 'prop-types';

import Card, {
  CardContainer as Container,
  CardBody as Body,
  CardText as Text,
  CardTitle as Title,
} from '../../../components/Card';
import Table, {
  Thead as TableHeader,   
  TableRow,
  Tbody,
  TbodyCol as TbCol
} from '../../../components/Table';
import Row from '../../../components/Row';
import Grid from '../../../components/Grid';
// import defaultImg from '../../../assets/default-img.png'
// import { storage } from '../../../config/firebase'
import Button from '../../../components/Button';

function ButtonVincular({ onClick }) {
  return (
    <Button color='info' size='sm' handleclick={onClick}>
      Vincular
    </Button>
  );
}

function ButtonDesvincular({ onClick }) {
  return (
    <Button color='danger' size='sm' handleclick={onClick}>
      Desvincular
    </Button>
  );
}

function IndustrializadoCard({
  industrializado,
  onLinkMedicamento,
  onUnlinkMedicamento,
}) {
  return (
    <Card className='medicamento-card'>
      <Row>
        {/* <Grid cols="12 4">
          <img id={`img-${industrializado.codigo}`}
            src={defaultImg}
            className="card-img"
            alt={industrializado.nome} />
        </Grid> */}
        <Grid cols='12'>
          <Body pl0>
            <div className='d-flex align-items-center justify-content-between'>
              <Title className='upper'>{industrializado.nome}</Title>

              {industrializado.linked ? (
                <ButtonDesvincular
                  onClick={() => onUnlinkMedicamento(industrializado)}
                />
              ) : (
                <ButtonVincular
                  onClick={() => onLinkMedicamento(industrializado)}
                />
              )}
            </div>
            <Text>{industrializado.observacao}</Text>
            <Text>
              <b>Posologia: </b>
              {industrializado.posologia}
            </Text>
            <hr className='divider' />
            <Text>
              <small className='text-muted'>
                <b>Recomendação de uso: </b>
                {industrializado.uso}
              </small>
            </Text>
          </Body>
        </Grid>
      </Row>
    </Card>
  );
}

function FormulaCard({ formula, onLinkMedicamento, onUnlinkMedicamento }) {
  return (
    <Card className='formula-card'>
      <Row>
        <Grid cols='12'>
          <Body pl0>
            <div className='d-flex align-items-center justify-content-between'>
              <Title className='upper'>{formula.nome}</Title>

              {formula.linked ? (
                <ButtonDesvincular
                  onClick={() => onUnlinkMedicamento(formula)}
                />
              ) : (
                <ButtonVincular onClick={() => onLinkMedicamento(formula)} />
              )}
            </div>
            <Text>{formula.observacao}</Text>
            <Text>
              <b>Posologia: </b>
              {formula.posologia}
            </Text>
          </Body>
        </Grid>
      </Row>
    </Card>
  );
}

function AtivoCard({ ativo, onLinkMedicamento, onUnlinkMedicamento }) {
  return (
    <Card className='ativo-card'>
      <Row>
        <Grid cols='12'>
          <Body>
            <div className='d-flex align-items-center justify-content-between'>
              <Title className='upper'>{ativo.nome}</Title>

              {ativo.linked ? (
                <ButtonDesvincular onClick={() => onUnlinkMedicamento(ativo)} />
              ) : (
                <ButtonVincular onClick={() => onLinkMedicamento(ativo)} />
              )}
            </div>
            <Text>{ativo.observacao}</Text>
            <hr className='divider' />
            <Text>
              <small className='text-muted'>
                <b>Indicação de uso: </b>
                {ativo.uso}
              </small>
            </Text>
          </Body>
        </Grid>
      </Row>
    </Card>
  );
}

function MedicamentoList({
  results,
  onLinkMedicamento: handleLinkMedicamento,
  onUnlinkMedicamento: handleUnlinkMedicamento,
}) {
  // useEffect(() => {
  //   const medicamentos = results.filter(r => r.imagem);

  //   // load images
  //   medicamentos.forEach(medicamento => {
  //     const ref = storage.ref(medicamento.imagem);
  //     ref.getDownloadURL()
  //       .then(url => document.getElementById(`img-${medicamento.codigo}`).src = url)
  //       .catch(err => console.log("Erro ao carregar imagem", err));
  //   });
  // }, [results]);
  console.log(results);
  return ( // man
    <Table card>
    <TableHeader
        dark
        columns={['Tipo', 'Nome', 'Uso', 'Posologia', 'Ação']}
      />
    <Tbody>
      {results.map((result, index) => {
        return (result.tipo ?
          <TableRow key={index}>
            <TbCol>
              {result.tipo}
            </TbCol>
            <TbCol>
              {result.nome}
            </TbCol>
            <TbCol>
              {result.uso}
            </TbCol>
            <TbCol>
              {result.posologia}
            </TbCol>
            <TbCol>
            {result.linked ? (
                <ButtonDesvincular 
                  onClick={() => handleUnlinkMedicamento(result)} 
                />
              ) : (
                <ButtonVincular 
                  onClick={() => handleLinkMedicamento(result)} 
                />
              )}
            </TbCol>
          </TableRow> 
        : null)
        })}
    </Tbody>
  </Table>
    // troca de card button para table
    // <Container> 
    //   {results.map((result, index) => {
    //     if (result.tipo === 'ATIVO')
    //       return (
    //         <AtivoCard
    //           key={index}
    //           ativo={result}
    //           onLinkMedicamento={handleLinkMedicamento}
    //           onUnlinkMedicamento={handleUnlinkMedicamento}
    //         />
    //       );

    //     if (result.tipo === 'FORMULA')
    //       return (
    //         <FormulaCard
    //           key={index}
    //           formula={result}
    //           onLinkMedicamento={handleLinkMedicamento}
    //           onUnlinkMedicamento={handleUnlinkMedicamento}
    //         />
    //       );

    //     if (result.tipo === 'INDUSTRIALIZADO')
    //       return (
    //         <IndustrializadoCard
    //           key={index}
    //           industrializado={result}
    //           onLinkMedicamento={handleLinkMedicamento}
    //           onUnlinkMedicamento={handleUnlinkMedicamento}
    //         />
    //       );

    //     return null;
    //   })}
    // </Container>
  );
}

MedicamentoList.propTypes = {
  /** Array of results */
  results: PropTypes.array.isRequired,
  /** Link medico to medicamento function */
  onLinkMedicamento: PropTypes.func,
  /** Unlink medico to medicamento function */
  onUnlinkMedicamento: PropTypes.func,
};

export default MedicamentoList;
