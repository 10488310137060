import styled from 'styled-components';

export const BackgroundImage = styled.img`
  display: none;
  
  @media print {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  } 
`;

export const ReceitaDocumentContainer = styled.div`
  display: none;

  @media print {
    width: 100%;
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    @page {
      size: A4;
      margin: 0;
      // margin: ${props => `${props.paddingTop}px` || '11mm'} 17mm 17mm ${props => `${props.paddingLeft}px` || '17mm'};
    }
  }
`;

export const ReceitaContainer = styled.div`
  @media print {
    display: none;
  }
`;

export const DataBox = styled.div`
  text-align: right;
`;
