import React from 'react';
import './styles.css';
import PropTypes from 'prop-types';

import Table, {
  Thead as TableHeader,
  TableRow,
  Tbody,
  TbodyCol as TbCol,
} from '../../../components/Table';
import Button from '../../../components/Button';
import { MdDone } from 'react-icons/md';
// import defaultImg from '../../../assets/default-img.png'
// import { storage } from '../../../config/firebase'
// import Media from 'react-media'

function MedicamentoList({ medicamentos, onValidate: handleValidate }) {
  // useEffect(() => {
  //   medicamentos.forEach(medicamento => {
  //     if (medicamento.med_st_imagem) {
  //       const ref = storage.ref(medicamento.med_st_imagem);

  //       ref.getDownloadURL()
  //         .then(img => document.getElementById(`img-${medicamento.med_in_codigo}`).src = img)
  //         .catch(err => console.log("Erro ao buscar imagem: ", err));
  //     }
  //   })
  // }, []);

  return medicamentos.length > 0 ? (
    <Table card>
      <TableHeader
        dark
        columns={[
          'Nome',
          'Fabricante',
          'Recomendação de uso',
          'Posologia',
          'Observação',
          'Validar',
        ]}
      />

      <Tbody>
        {medicamentos.map((medicamento) => (
          <TableRow className='upper' key={medicamento.med_in_codigo}>
            <TbCol
              alignMiddle
              // alignStart
            >
              {/* <img id={`img-${medicamento.med_in_codigo}`}
                className="img-fluid img-thumbnail img-list mr-1"
                src={defaultImg} alt="Imagem do medicamento" />
              <Media query={{ maxWidth: 600 }}>
                {matches => matches ? <span className="medicamento-title">{medicamento.med_st_nome}</span> : medicamento.med_st_nome}
              </Media> */}
              {medicamento.med_st_nome}
            </TbCol>
            <TbCol alignMiddle label='Fabricante'>
              {medicamento.med_st_fabricante || '-'}
            </TbCol>
            <TbCol alignMiddle label='Recomendação de uso'>
              {medicamento.med_st_recomendacaouso || '-'}
            </TbCol>
            <TbCol alignMiddle label='Posologia'>
              {medicamento.med_st_posologia || '-'}
            </TbCol>
            <TbCol alignMiddle label='Obs'>
              {medicamento.med_st_observacao || '-'}
            </TbCol>
            <TbCol alignMiddle label='Ação'>
              <Button
                className='ml-1'
                color='info'
                size='sm'
                handleclick={() => handleValidate(medicamento)}
              >
                <MdDone size={24} />
              </Button>
            </TbCol>
          </TableRow>
        ))}
      </Tbody>
    </Table>
  ) : (
    <p className='not-found'>Nenhum medicamento encontrado</p>
  );
}

MedicamentoList.propTypes = {
  medicamentos: PropTypes.array.isRequired,
  onValidate: PropTypes.func,
};

export default MedicamentoList;
