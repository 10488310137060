import React from 'react';
import './styles.css';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Row from '../../components/Row';
import Grid from '../../components/Grid';
import { MdKeyboardArrowLeft } from 'react-icons/md';

function Text({ level, children }) {
  if (level === 1) return <h1 className='title'>{children}</h1>;
  if (level === 2) return <h2 className='title'>{children}</h2>;
  if (level === 3) return <h3 className='title'>{children}</h3>;
  if (level === 4) return <h4 className='title'>{children}</h4>;
  if (level === 5) return <h5 className='title'>{children}</h5>;

  return <h6 className='title'>{children}</h6>;
}

function Title({
  component: Component,
  hideButton,
  link,
  level,
  children,
  onClick: handleClick,
}) {
  return (
    <div className='p-2'>
      <Row>
        <Grid cols='12'>
          <div className='d-flex align-items-center'>
            {hideButton ? null : (
              <Link to={link} onClick={handleClick} className='mr-2'>
                <MdKeyboardArrowLeft size={32} />
              </Link>
            )}

            <Text level={level}>{children}</Text>

            {Component ? (
              <div className='d-flex justify-content-end flex-2'>
                <Component />
              </div>
            ) : null}
          </div>

          <hr className='divider' />
        </Grid>
      </Row>
    </div>
  );
}

Title.propTypes = {
  component: PropTypes.any,
  hideButton: PropTypes.bool,
  link: PropTypes.string,
  level: PropTypes.number,
  children: PropTypes.any,
  onClick: PropTypes.func,
};

Title.defaultProps = {
  hideButton: false,
  level: 4,
};

export default Title;
