import React, { useState, useEffect } from 'react'
import './styles.css'

import FormulaList from './FormulaList'
import Title from '../../components/Title'
import Skeleton from 'react-loading-skeleton'
import { fetchFormulasPendentes, clearFormulas } from '../../stores/formulasPendentesActions'
import { useSelector, useDispatch } from 'react-redux'
import { validateFormula } from "../../services/MedicamentosPendentesService";

export default function FormulasPendentesListContainer() {
  const [isFirst, setFirst] = useState(true);
  const [isLoading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const formulas = useSelector(state => state.formula.pendentes.formulas);

  const farmaciaId = localStorage.getItem('FORMULARIO_FARMACIA_ID');

  // fetch all ativos pendentes
  useEffect(() => {
    dispatch(fetchFormulasPendentes(farmaciaId));

    return () => dispatch(clearFormulas());
  }, []);

  // set loading
  useEffect(() => {
    if (isFirst) setFirst(false);
    else setLoading(false);
  }, [formulas]);

  async function handleValidate(formula) {
    setLoading(true);

    await validateFormula(farmaciaId, formula.for_in_codigo);

    dispatch(fetchFormulasPendentes(farmaciaId));
  }

  return (
    <div className="ativo-container">
      <Title link="/formulas">Fórmulas pendentes</Title>

      {isLoading
        ? <Skeleton count={5} height={30} />
        : <FormulaList formulas={formulas}
          onValidate={handleValidate} />}
    </div>
  )
}
