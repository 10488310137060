import React, { useState, useRef, useEffect } from 'react';
import './styles.css';
import PropTypes from 'prop-types';

import Modal from '../../../components/Modal';
import { Form } from '@unform/web';
import { Input, TextArea } from '../../../components/Form';
import Button from '../../../components/Button';
import CloseModalButton from '../../../components/CloseModalButton';

function ModalAddIndustrializado({
  id,
  industrializado,
  onSubmit: handleSubmit,
}) {
  const formRef = useRef(null);

  const [uso, setUso] = useState('');
  const [fabricante, setFabricante] = useState('');
  const [posologia, setPosologia] = useState('');
  const [observacao, setObservacao] = useState('');
  const [resumo, setResumo] = useState('');

  useEffect(() => {
    if (industrializado) {
      setUso(industrializado.uso);
      setFabricante(industrializado.fabricante);
      setPosologia(industrializado.posologia);
      setObservacao(industrializado.observacao);

      buildResumo(industrializado.nome, industrializado.posologia);
    }
  }, [industrializado]);

  function buildResumo(nome, posologia) {
    setResumo(nome + '\n' + posologia);
  }

  return (
    <Modal
      id={id}
      title={`Adicionar na receita o industrializado ${industrializado?.nome}`}
      hideFooter
    >
      {industrializado?.tipo === 'INDUSTRIALIZADO' ? (
        <Form ref={formRef} onSubmit={handleSubmit}>
          <TextArea
            id='recomendacaoUso'
            name='recomendacaoUso'
            label='Recomendação de uso'
            placeholder='Recomendação de uso'
            value={uso}
            onChange={(e) => setUso(e.target.value)}
          />

          <Input
            id='fabricante'
            name='fabricante'
            label='Fabricante'
            placeholder='Fabricante'
            value={fabricante}
            onChange={(e) => setFabricante(e.target.value)}
          />

          <Input
            id='posologia'
            name='posologia'
            label='Posologia'
            placeholder='Posologia'
            value={posologia}
            onChange={(e) => {
              setPosologia(e.target.value);
              buildResumo(industrializado.nome, e.target.value);
            }}
          />

          <TextArea
            id='observacao'
            name='observacao'
            label='Observação'
            placeholder='Observação'
            value={observacao}
            onChange={(e) => setObservacao(e.target.value)}
          />

          <TextArea
            id='resumo'
            name='resumo'
            label='Resumo'
            value={resumo}
            onChange={(e) => setResumo(e.target.value)}
          />

          <div className='text-right'>
            {/* NOTE: for some reason, when the button has the property 'data-dismiss' it cannot submit */}
            <CloseModalButton
              id='closeModalButton'
              color='secondary'
              className='mr-1'
            >
              Cancelar
            </CloseModalButton>

            <Button
              type='submit'
              handleclick={() =>
                document.getElementById('closeModalButton').click()
              }
            >
              Vincular
            </Button>
          </div>
        </Form>
      ) : (
        <p>Medicamento inválido</p>
      )}
    </Modal>
  );
}

ModalAddIndustrializado.propTypes = {
  id: PropTypes.string.isRequired,
  formRef: PropTypes.any,
  industrializado: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default ModalAddIndustrializado;
