import React, { useState } from 'react';
// import './styles.css'
import PropTypes from 'prop-types';

import Table, {
  Thead as TableHeader,
  TableRow,
  Tbody,
  TbodyCol as TbCol,
} from '../../../components/Table';
import Modal from '../../../components/Modal';
import ModalButton from '../../../components/ModalButton';
import Button from '../../../components/Button';
import { MdDelete, MdEdit } from 'react-icons/md';
import { insertIf } from '../../../utils/arrays';

function UnidadeList({ especialidades, handleEdit, handleDelete, readonly }) {
  const [especialidade, setEspecialidade] = useState(null);

  return especialidades.length > 0 ? (
    <div>
      <Table card>
        <TableHeader
          dark
          columns={['Especialidade', ...insertIf(!readonly, 'Ação')]}
        />

        <Tbody>
          {especialidades.map((especialidade) => (
            <TableRow className='upper' key={especialidade.esp_in_codigo}>
              <TbCol alignMiddle label='Descrição'>
                {especialidade.esp_st_descricao}
              </TbCol>
              {readonly ? null : (
                <TbCol alignMiddle label='Ação'>
                  <ModalButton
                    target='#modalDeleteUnidade'
                    color='danger'
                    size='sm'
                    handleclick={() => setEspecialidade(especialidade)}
                  >
                    <MdDelete size={24} />
                  </ModalButton>

                  <Button
                    className='ml-1'
                    color='warning'
                    size='sm'
                    handleclick={() => handleEdit(especialidade)}
                  >
                    <MdEdit size={24} />
                  </Button>
                </TbCol>
              )}
            </TableRow>
          ))}
        </Tbody>
      </Table>

      <Modal
        id='modalDeleteUnidade'
        handleClick={() => handleDelete(especialidade)}
        handleCancelarClick={() => setEspecialidade(null)}
        btnColor='danger'
        size='sm'
        text='Você tem certeza de que deseja remover a especialidade?'
        title='Remover especialidade'
      />
    </div>
  ) : (
    <p className='not-found'>Nenhuma especialidade encontrada</p>
  );
}

UnidadeList.propTypes = {
  especialidades: PropTypes.array.isRequired,
  readonly: PropTypes.bool,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
};

UnidadeList.defaultProps = {
  readonly: false,
};

export default UnidadeList;
