import React from 'react'
import './styles.css'
import PropTypes from 'prop-types'

import AutoResizeTextArea from '../../../components/AutoResizeTextArea'

function MedicamentosInfoField({ value, setValue }) {
  return (
    <div className="w600px receita-field-container">
      <AutoResizeTextArea className="form-control receita-field min-h650px" value={value}
        onChange={e => setValue(e.target.value)} />
    </div>
  )
}

MedicamentosInfoField.propTypes = {
  medicamentos: PropTypes.array
}

export default MedicamentosInfoField;
