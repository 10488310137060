import { nop } from './baseActions'
import { findFavoriteIndustrializados } from '../services/FavoriteIndustrializadosService'
import { unlinkIndustrializadoMedico } from '../services/LinkMedicoToMedicamentoService'

const setIndustrializados = industrializados => {
  return {
    type: 'FAVORITES_INDUSTRIALIZADOS_SET_INDUSTRIALIZADOS',
    payload: industrializados
  }
}

export const setPage = page => {
  return {
    type: 'FAVORITES_INDUSTRIALIZADOS_SET_PAGE',
    payload: page
  }
}

export const setItemsPerPage = itemsPerPage => {
  return {
    type: 'FAVORITES_INDUSTRIALIZADOS_SET_ITEMS_PER_PAGE',
    payload: itemsPerPage
  }
}

const setTotalItems = totalItems => {
  return {
    type: 'FAVORITES_INDUSTRIALIZADOS_SET_TOTAL_ITEMS',
    payload: totalItems
  }
}

export const setRef = ref => {
  return {
    type: 'FAVORITES_INDUSTRIALIZADOS_SET_REF',
    payload: ref
  }
}

export const clearAll = () => {
  return {
    type: 'FAVORITES_INDUSTRIALIZADOS_CLEAR_ALL'
  }
}

export const fetchMedicoFavoriteIndustrializados = (farmaciaId, usuarioId, page = 1, itemsPerPage = 15, ref = '') => {
  return async dispatch => {
    try {
      const response = await findFavoriteIndustrializados(farmaciaId, usuarioId, page, itemsPerPage, ref);

      return dispatch([
        setIndustrializados(response.data),
        setTotalItems(response["_metadata"].totalItems)
      ]);
    } catch (e) {
      return dispatch(nop());
    }
  }
}

export const removeLinkMedicamentoMedico = (farmaciaId, usuarioId, industrializadoId) => {
  return async (dispatch, getState) => {
    try {
      await unlinkIndustrializadoMedico(farmaciaId, usuarioId, industrializadoId);

      const { search, pagination } = getState().medico.industrializado;

      return dispatch(fetchMedicoFavoriteIndustrializados(
        farmaciaId,
        usuarioId,
        pagination.page,
        pagination.itemsPerPage,
        search.ref,
      ));
    } catch (e) {
      return dispatch(nop());
    }
  }
}
