import React, { useRef, useEffect, useState } from 'react';
import './styles.css';

import { useDispatch } from 'react-redux';
import { setLoading } from '../../stores/loadingActions';
import { useHistory } from 'react-router-dom';

import Title from '../../components/Title';
import { Form } from '@unform/web';
import { Input, Select } from '../../components/Form';
import Button from '../../components/Button';
import * as Yup from 'yup';

import { save } from '../../services/VeiculoUtilizacaoService';
import { findAllMedicosByFarmacia } from '../../services/FindMedicosService';

export default function NewUsuarioForm() {
  const formRef = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();

  const [medicos, setMedicos] = useState([]);

  const farmaciaId = localStorage.getItem('FORMULARIO_FARMACIA_ID');

  useEffect(() => {
    async function fetchMedicos() {
      const response = await findAllMedicosByFarmacia(farmaciaId);

      setMedicos(response);
    }

    fetchMedicos();
  }, []);

  async function handleSubmit(data, { reset }) {
    try {
      // set loading
      dispatch(setLoading(true));

      // validation
      const schema = Yup.object().shape({
        veu_st_descricao: Yup.string().required('A descrição é obrigatória'),
        veu_st_tipo: Yup.string().required('Selecione o tipo'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      // save
      await save(farmaciaId, {
        ...data,
        usu_in_codigo: data.usu_in_codigo == -1 ? null : data.usu_in_codigo,
      });

      dispatch(setLoading(false));

      reset();

      // clear errors
      formRef.current.setErrors({});

      // redirect
      history.push('/veiculosUtilizacao');
    } catch (e) {
      if (e instanceof Yup.ValidationError) {
        const errorMessages = {};

        e.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });

        formRef.current.setErrors(errorMessages);
      }

      dispatch(setLoading(false));
    }
  }

  return (
    <div className='veiculo-container'>
      <Title link='/veiculosUtilizacao'>
        Cadastro veic. utilização/forma farmacêutica
      </Title>

      <div className='form-container'>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Input
            id='veu_st_descricao'
            label='* Descrição'
            className='form-control upper'
            name='veu_st_descricao'
            required
          />

          <Select
            id='veu_st_tipo'
            label='* Tipo'
            className='form-control upper'
            name='veu_st_tipo'
            required
          >
            <option value='ORAL'>Oral</option>
            <option value='TOPICO'>Tópico</option>
            <option value='AMBOS'>Ambos</option>
          </Select>

          <Select
            id='usu_in_codigo'
            label='Selecione o médico'
            className='form-control upper'
            name='usu_in_codigo'
          >
            <option value={-1}>Todos os médicos</option>
            {medicos.map((medico) => (
              <option value={medico.usu_in_codigo} key={medico.usu_in_codigo}>
                {medico.usu_st_nome}
              </option>
            ))}
          </Select>

          <div className='w-100 mb-3'>
            <small className='text-muted align-end'>
              Os campos marcados com (*) são obrigatórios
            </small>
          </div>

          <Button type='submit' color='primary' size='block'>
            Salvar
          </Button>
        </Form>
      </div>
    </div>
  );
}
