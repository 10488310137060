import React from 'react'
import PropTypes from 'prop-types'

function RadioButtonContainer({ children }) {
  return (
    <div className="btn-group btn-group-toggle">
      {children}
    </div>
  )
}

RadioButtonContainer.propTypes = {
  children: PropTypes.any,
}

export default RadioButtonContainer;
