import React, { useState, useEffect } from 'react';
import './styles.css';

import Title from '../../components/Title';
import { useParams } from 'react-router-dom';
import Row from '../../components/Row';
import Grid from '../../components/Grid';
import { useSelector, useDispatch } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import {
  fetchAtivoById,
  fetchFormulasByFarmacia,
  fetchAllLinksAtivoFormula,
  clearAtivosFormulas,
  setPage,
  setRef,
} from '../../stores/linkAtivoFormulaActions';
import {
  linkAtivoToFormula,
  removeLinkAtivoFormula,
} from '../../services/AtivoFormulaService';
import { setLoading as loading } from '../../stores/loadingActions';
import Pagination from '../../components/Pagination';
import FormulaSearch from '../FormulaListContainer/FormulaSearch';
import FormulaList from './FormulaList';

export default function LinkAtivoFormulas() {
  const { id: ativoId } = useParams();
  const farmaciaId = localStorage.getItem('FORMULARIO_FARMACIA_ID');

  const [isLoading, setLoading] = useState(true);
  const [isFirst, setFirst] = useState(true);

  const dispatch = useDispatch();

  // ativo
  const ativo = useSelector((state) => state.linkAtivoFormula.ativo);

  // formulas already linked to ativo
  const linksAtivoFormulas = useSelector(
    (state) => state.linkAtivoFormula.ativosFormulas
  );

  // all formulas by farmacia
  // add linked property
  const results = useSelector((state) => state.linkAtivoFormula.formulas);

  const formulas = results.map((form) => ({
    ...form,
    linked: linksAtivoFormulas.find(
      (link) => link.for_in_codigo === form.for_in_codigo
    ),
  }));

  // pagination
  const page = useSelector((state) => state.linkAtivoFormula.pagination.page);
  const itemsPerPage = useSelector(
    (state) => state.linkAtivoFormula.pagination.itemsPerPage
  );
  const totalItems = useSelector(
    (state) => state.linkAtivoFormula.pagination.totalItems
  );

  // search
  const ref = useSelector((state) => state.linkAtivoFormula.search.ref);

  useEffect(() => {
    dispatch([
      fetchAtivoById(farmaciaId, ativoId),
      fetchFormulasByFarmacia(farmaciaId, '', 1, itemsPerPage),
      fetchAllLinksAtivoFormula(farmaciaId, ativoId),
    ]);

    return () => dispatch(clearAtivosFormulas());
  }, []);

  useEffect(() => {
    if (isFirst) setFirst(false);
    else setLoading(false);
  }, [results]);

  function handlePaginate(pgNumber) {
    if (pgNumber === page) return;

    setLoading(true);

    dispatch([
      setPage(pgNumber),
      fetchFormulasByFarmacia(farmaciaId, ref, pgNumber, itemsPerPage),
    ]);
  }

  function handleSearch({ ref }) {
    setLoading(true);

    dispatch([
      setPage(1),
      setRef(ref),
      fetchFormulasByFarmacia(farmaciaId, ref, 1, itemsPerPage),
    ]);
  }

  function handleClearSearch() {
    setLoading(true);

    dispatch([
      setPage(1),
      setRef(''),
      fetchFormulasByFarmacia(farmaciaId, '', 1, itemsPerPage),
    ]);
  }

  async function handleUnlinkFormulaAtivo(formula) {
    dispatch(loading(true));

    await removeLinkAtivoFormula(farmaciaId, ativoId, formula.for_in_codigo);

    dispatch([loading(false), fetchAllLinksAtivoFormula(farmaciaId, ativoId)]);
  }

  async function handleLinkFormulaAtivo(
    formula,
    doseMinima,
    doseMaxima,
    doseDiaria,
    dosePrescrita,
    uso
  ) {
    try {
      dispatch(loading(true));

      await linkAtivoToFormula(
        farmaciaId,
        ativoId,
        formula.for_in_codigo,
        doseMinima,
        doseMaxima,
        doseDiaria,
        dosePrescrita,
        uso
      );

      dispatch([
        loading(false),
        fetchAllLinksAtivoFormula(farmaciaId, ativoId),
      ]);
    } catch (e) {
      dispatch(loading(false));
    }
  }

  return (
    <div className='ativo-container'>
      <Title link={`/ativos/${ativoId}/edit`}>
        Selecione uma fórmula para vincular
      </Title>

      <Row>
        <Grid cols='12'>
          <FormulaSearch onSubmit={handleSearch} onClear={handleClearSearch} />
        </Grid>
      </Row>

      <Row>
        <Grid cols='12'>
          {isLoading ? (
            <Skeleton count={4} width={500} height={60} />
          ) : (
            <FormulaList
              formulas={formulas}
              ativo={ativo}
              onUnlinkFormulaAtivo={handleUnlinkFormulaAtivo}
              onLinkFormulaAtivo={handleLinkFormulaAtivo}
            />
          )}
        </Grid>
      </Row>

      <Row>
        <Grid cols='12'>
          <Pagination
            page={page}
            itemsPerPage={itemsPerPage}
            totalItems={totalItems}
            paginate={handlePaginate}
          />
        </Grid>
      </Row>
    </div>
  );
}
