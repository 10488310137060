import api from './api'
import { toast } from 'react-toastify'
import { toUpperCase } from '../utils/objects'

export function findAll(farmaciaId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      const response = await api.get(`/farmacias/${farmaciaId}/unidades`, {
        headers: { authorization: `Bearer ${token}` }
      });

      return resolve(response.data.data);
    } catch (e) {
      toast.error('Falha ao buscar unidades');
      return reject(e);
    }
  })
}

export function findById(farmaciaId, id) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      const response = await api.get(`/farmacias/${farmaciaId}/unidades/${id}`, {
        headers: { authorization: `Bearer ${token}` }
      });

      return resolve(response.data.data);
    } catch (e) {
      toast.error("Falha ao buscar unidade");
      return reject(e);
    }
  })
}

export function save(farmaciaId, unidade) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      const response = await api.post(`/farmacias/${farmaciaId}/unidades`, toUpperCase(unidade), {
        headers: { authorization: `Bearer ${token}` }
      });

      return resolve(response.data.data);
    } catch (e) {
      toast.error("Falha ao salvar unidade");
      return reject(e);
    }
  })
}

export function update(farmaciaId, id, unidade) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      await api.put(`/farmacias/${farmaciaId}/unidades/${id}`, toUpperCase(unidade), {
        headers: { authorization: `Bearer ${token}` }
      });

      return resolve();
    } catch (e) {
      toast.error("Falha ao salvar unidade");
      return reject(e);
    }
  })
}

export function remove(farmaciaId, unidade) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      await api.delete(`/farmacias/${farmaciaId}/unidades/${unidade.uni_in_codigo}`, {
        headers: { authorization: `Bearer ${token}` }
      });

      return resolve();
    } catch (e) {
      toast.error("Falha ao remover unidade");
      return reject(e);
    }
  })
}
