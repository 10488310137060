import React, { useState } from 'react';
import './styles.css';
import PropTypes from 'prop-types';

import Table, {
  Thead as TableHeader,
  TableRow,
  Tbody,
  TbodyCol as TbCol,
} from '../../../components/Table';
import Modal from '../../../components/Modal';
import ModalButton from '../../../components/ModalButton';
import Button from '../../../components/Button';
import { MdDelete, MdEdit } from 'react-icons/md';
import { insertIf } from '../../../utils/arrays';

function getTipo(tipo) {
  if (tipo === 'M') return 'Prescritor';
  if (tipo === 'G') return 'Gestor';
  if (tipo === 'R') return 'Representante';

  return '';
}

function UsuarioList({
  usuarios,
  readonly,
  onDelete: handleDelete,
  onEdit: handleEdit,
}) {
  const [user, setUser] = useState(null);

  return usuarios.length > 0 ? (
    <div>
      <Table card>
        <TableHeader
          dark
          columns={[
            'Nome',
            'E-mail',
            'Tipo',
            'Telefone',
            'Especialidade',
            ...insertIf(!readonly, 'Ação'),
          ]}
        />

        <Tbody>
          {usuarios.map((usuario) => (
            <TableRow key={usuario.usu_in_codigo}>
              <TbCol alignMiddle label='Nome'>
                {usuario.usu_st_nome}
              </TbCol>
              <TbCol alignMiddle label='E-mail'>
                {usuario.usu_st_email}
              </TbCol>
              <TbCol alignMiddle label='Tipo'>
                {getTipo(usuario.usu_ch_tipo)}
              </TbCol>
              <TbCol alignMiddle label='Telefone'>
                {usuario.usu_st_telefone || ' - '}
              </TbCol>
              <TbCol alignMiddle label='Especialidade'>
                {usuario.usu_ch_tipo === 'M'
                  ? usuario.usu_st_especialidade
                  : ' - '}
              </TbCol>
              {readonly ? null : (
                <TbCol alignMiddle label='Ação'>
                  <ModalButton
                    target='#modalDeletarUsuario'
                    color='danger'
                    size='sm'
                    handleclick={() => setUser(usuario)}
                  >
                    <MdDelete size={24} />
                  </ModalButton>

                  <Button
                    className='ml-1'
                    color='warning'
                    size='sm'
                    handleclick={() => handleEdit(usuario)}
                  >
                    <MdEdit size={24} />
                  </Button>
                </TbCol>
              )}
            </TableRow>
          ))}
        </Tbody>
      </Table>

      <Modal
        id='modalDeletarUsuario'
        handleClick={() => handleDelete(user)}
        handleCancelarClick={() => setUser(null)}
        btnColor='danger'
        size='sm'
        text='Você tem certeza de que deseja remover este usuário?'
        title='Remover usuário'
      />
    </div>
  ) : (
    <p className='not-found'>Nenhum usuário encontrado</p>
  );
}

UsuarioList.propTypes = {
  usuarios: PropTypes.array.isRequired,
  readonly: PropTypes.bool,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
};

UsuarioList.defaultProps = {
  readonly: false,
};

export default UsuarioList;
