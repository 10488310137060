import React, { useState } from 'react';
import './styles.css';
import PropTypes from 'prop-types';

import Table, {
  TableRow,
  Tbody,
  TbodyCol,
  Thead,
} from '../../../components/Table';
import Button from '../../../components/Button';
import ModalButton from '../../../components/ModalButton';
import ModalVincular from './ModalVincular';

function AtivoList({
  ativos,
  onUnlinkFormulaAtivo: handleUnlinkFormulaAtivo,
  onLinkFormulaAtivo: handleLinkFormulaAtivo,
}) {
  const [ativo, setAtivo] = useState(null);
  const [concentracaoPrescrita, setConcentracaoPrescrita] = useState('');

  function clearState() {
    setAtivo(null);
    setConcentracaoPrescrita('');
  }

  function handleModalConfirm(uso) {
    handleLinkFormulaAtivo(ativo, concentracaoPrescrita, uso);
    clearState();
  }

  if (ativos.length === 0) return <p>Nenhum ativo encontrado.</p>;

  return (
    <>
      <Table>
        <Thead
          dark
          columns={['Descrição', 'Uso', 'Indicação de uso', 'Ação']}
        />

        <Tbody>
          {ativos.map((ativo) => (
            <TableRow className='upper' key={ativo.atv_in_codigo}>
              <TbodyCol label='Descrição'>{ativo.atv_st_descricao}</TbodyCol>
              <TbodyCol label='Uso'>{ativo.atv_st_uso}</TbodyCol>
              <TbodyCol label='Indicação de uso'>
                {ativo.atv_st_indicacaouso}
              </TbodyCol>
              <TbodyCol label='Ação'>
                {ativo.linked ? (
                  <Button
                    className='float-right'
                    size='sm'
                    color='danger'
                    handleclick={() =>
                      handleUnlinkFormulaAtivo(ativo.atv_in_codigo)
                    }
                  >
                    Desvincular
                  </Button>
                ) : (
                  <ModalButton
                    className='float-right'
                    size='sm'
                    color='info'
                    target='#modalVincular'
                    handleclick={() => setAtivo(ativo)}
                  >
                    Vincular
                  </ModalButton>
                )}
              </TbodyCol>
            </TableRow>
          ))}
        </Tbody>
      </Table>

      <ModalVincular
        ativo={ativo}
        dosePrescrita={concentracaoPrescrita}
        setDosePrescrita={setConcentracaoPrescrita}
        onCancelarClick={clearState}
        onModalConfirm={handleModalConfirm}
      />
    </>
  );
}

AtivoList.propTypes = {
  ativos: PropTypes.array.isRequired,
  onUnlinkFormulaAtivo: PropTypes.func,
  onLinkFormulaAtivo: PropTypes.func,
};

export default AtivoList;
