import React, { useEffect, useState } from 'react';

import Title from '../../components/Title';
import { useParams, useHistory } from 'react-router-dom';
import MedicoList from '../LinkMedicamentosToMedicoContainer/MedicoList';
import {
  fetchMedicosByFarmacia,
  clearAll,
} from '../../stores/buildUserProfileActions';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { isEqual } from '../../utils/objects';
import Row from '../../components/Row';
import Grid from '../../components/Grid';
import Button from '../../components/Button';
import { buildProfile } from '../../services/BuildUserProfileService';
import { setLoading as loading } from '../../stores/loadingActions';

export default function BuildUserProfileContainer() {
  const { id: usuarioId } = useParams();

  const history = useHistory();

  const farmaciaId = localStorage.getItem('FORMULARIO_FARMACIA_ID');

  const dispatch = useDispatch();
  const medicos = useSelector((state) => state.buildUserProfile.medicos).filter(
    (medico) => medico.usu_in_codigo != usuarioId
  );

  const [profiles, setProfiles] = useState([]);
  const [isFirst, setFirst] = useState(true);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    // fetch all medicos
    dispatch(fetchMedicosByFarmacia(farmaciaId));

    return () => dispatch(clearAll());
  }, []);

  useEffect(() => {
    if (isFirst) setFirst(false);
    else setLoading(false);
  }, [medicos]);

  function handleSelectMedico(medico) {
    setProfiles([...profiles, medico]);
  }

  function handleUnselectMedico(medico) {
    setProfiles(profiles.filter((profile) => !isEqual(profile, medico)));
  }

  async function handleBuildProfile() {
    dispatch(loading(true));

    await buildProfile(farmaciaId, usuarioId, profiles);

    dispatch(loading(false));

    handleGoBack();
  }

  function handleGoBack() {
    history.push(`/usuarios/${usuarioId}/edit`);
  }

  return (
    <div className='profile-container'>
      <Title link={`/usuarios/${usuarioId}/edit`}>
        Montar perfil do médico
      </Title>

      {isLoading ? (
        <Skeleton count={5} height={30} />
      ) : (
        <>
          <p>Selecione os perfis que deseja copiar e clique em continuar...</p>

          <MedicoList
            medicos={medicos}
            selected={profiles}
            onSelect={handleSelectMedico}
            onUnselect={handleUnselectMedico}
          />
        </>
      )}

      <Row>
        <Grid cols='12 6'>
          <Button
            size='sm btn-block'
            color='secondary'
            handleclick={handleGoBack}
          >
            Cancelar
          </Button>
        </Grid>
        <Grid cols='12 6'>
          <Button size='sm btn-block' handleclick={handleBuildProfile}>
            Continuar
          </Button>
        </Grid>
      </Row>
    </div>
  );
}
