import { nop } from './baseActions'
import { findAtivosPendentes } from '../services/MedicamentosPendentesService'

const setAtivos = ativos => {
  return {
    type: 'ATIVOS_PENDENTES_SET_ATIVOS',
    payload: ativos
  }
}

export const clearAtivos = () => {
  return {
    type: 'ATIVOS_PENDENTES_CLEAR_ATIVOS'
  }
}

export const fetchAtivosPendentes = farmaciaId => {
  return async dispatch => {
    try {
      const ativos = await findAtivosPendentes(farmaciaId);

      return dispatch(setAtivos(ativos));
    } catch (e) {
      return dispatch(nop());
    }
  }
}
