const INITIAL_STATE = {
  veiculosUtilizacao: [],
  pagination: {
    page: 1,
    itemsPerPage: 15,
    totalItems: 0,
  }
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'VEICULO_UTILIZACAO_LIST_FETCH_VEICULOS_UTILIZACAO':
      return {
        ...state,
        veiculosUtilizacao: action.payload
      }
    case 'VEICULO_UTILIZACAO_LIST_PAGINATION_SET_PAGE':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.payload,
        }
      }
    case 'VEICULO_UTILIZACAO_LIST_PAGINATION_SET_ITEMS_PER_PAGE':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          itemsPerPage: action.payload,
        }
      }
    case 'VEICULO_UTILIZACAO_LIST_PAGINATION_SET_TOTAL_ITEMS':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          totalItems: action.payload,
        }
      }
    case 'VEICULO_UTILIZACAO_LIST_CLEAR_VEICULOS_UTILIZACAO':
      return {
        ...state,
        ...INITIAL_STATE
      }
    default:
      return state;
  }
}
