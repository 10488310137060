import React, { useState, useEffect, useRef } from 'react';

function AutoTextArea({ onChange, ...props }) {
  const textAreaRef = useRef(null);

  const [text, setText] = useState('');
  const [textAreaHeight, setTextAreaHeight] = useState('auto');
  const [parentHeight, setParentHeight] = useState('auto');

  useEffect(() => {
    setParentHeight(`${textAreaRef.current?.scrollHeight || 200}px`);
    setTextAreaHeight(`${textAreaRef.current?.scrollHeight || 200}px`);
  }, [text]);

  function onChangeHandler(event) {
    setTextAreaHeight('auto');
    setParentHeight(`${textAreaRef.current?.scrollHeight || 200}px`);
    setText(event.target.value);

    if (onChange) onChange(event);
  }

  return (
    <div style={{ minHeight: parentHeight }}>
      <textarea {...props}
        ref={textAreaRef}
        rows={1}
        style={{ height: textAreaHeight }}
        onChange={onChangeHandler} />
    </div>
  );
};

export default AutoTextArea;