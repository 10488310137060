import React from 'react'
import './styles.css'
import PropTypes from 'prop-types'

function CardContainer({ children, column }) {
  let classes = 'card-container ';
  classes += column ? 'container-column ' : 'container-row ';

  return (
    <div className={classes}>
      {children}
    </div>
  )
}

CardContainer.propTypes = {
  children: PropTypes.any.isRequired,
  column: PropTypes.bool,
}

export default CardContainer;
