import React from 'react';
import './styles.css';
import PropTypes from 'prop-types';

import Card, { CardContainer, CardTitle } from '../../../components/Card';
import Button from '../../../components/Button';

function VeiculosUtilizacaoList({
  veiculosUtilizacao,
  onUnlinkAtivoVeiculo: handleUnlinkAtivoVeiculo,
  onLinkAtivoVeiculo: handleLinkAtivoVeiculo,
}) {
  return (
    <CardContainer>
      {veiculosUtilizacao.length === 0 ? (
        <p>Nenhum veic. utilização/forma farmacêutica encontrado</p>
      ) : (
        veiculosUtilizacao.map((veu) => (
          <Card className='align-items-center' key={veu.veu_in_codigo}>
            <CardTitle className='upper'>{veu.veu_st_descricao}</CardTitle>
            {veu.linked ? (
              <Button
                size='sm'
                color='danger'
                handleclick={() => handleUnlinkAtivoVeiculo(veu)}
              >
                Desvincular
              </Button>
            ) : (
              <Button
                size='sm'
                color='info'
                handleclick={() => handleLinkAtivoVeiculo(veu)}
              >
                Vincular
              </Button>
            )}
          </Card>
        ))
      )}
    </CardContainer>
  );
}

VeiculosUtilizacaoList.propTypes = {
  veiculosUtilizacao: PropTypes.array.isRequired,
};

export default VeiculosUtilizacaoList;
