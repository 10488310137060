const INITIAL_STATE = {
  ativo: null,
  formulas: [],
  ativosFormulas: [],
  pagination: {
    page: 1,
    itemsPerPage: 20,
    totalItems: 0
  },
  search: {
    ref: ''
  }
}

export default function linkAtivoFormulaReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'LINK_ATIVO_FORMULA_SET_ATIVO':
      return {
        ...state,
        ativo: action.payload
      }
    case 'LINK_ATIVO_FORMULA_SET_FORMULAS':
      return {
        ...state,
        formulas: action.payload
      }
    case 'LINK_ATIVO_FORMULA_SET_ATIVOS_FORMULAS':
      return {
        ...state,
        ativosFormulas: action.payload
      }
    case 'LINK_ATIVO_FORMULA_SET_PAGE':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.payload
        }
      }
    case 'LINK_ATIVO_FORMULA_SET_ITEMS_PER_PAGE':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          itemsPerPage: action.payload
        }
      }
    case 'LINK_ATIVO_FORMULA_SET_TOTAL_ITEMS':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          totalItems: action.payload
        }
      }
    case 'LINK_ATIVO_FORMULA_SET_REF':
      return {
        ...state,
        search: {
          ...state.search,
          ref: action.payload
        }
      }
    case 'LINK_ATIVO_FORMULA_CLEAR_SEARCH':
      return {
        ...state,
        search: {
          ref: ''
        }
      }
    case 'LINK_ATIVO_FORMULA_CLEAR_ATIVOS_FORMULAS':
      return {
        ...state,
        ...INITIAL_STATE
      }
    default:
      return state
  }
}
