import React from 'react'
import PropTypes from 'prop-types'
import './styles.css'

import { Link } from 'react-router-dom'

function Brand({ to, children }) {
  return (
    <Link className="navbar-brand" to={to} >
      {children}
    </Link>
  )
}

Brand.propTypes = {
  to: PropTypes.string,
  children: PropTypes.any.isRequired
}

Brand.defaultProps = {
  to: '/'
}

export default Brand;
