import { nop } from './baseActions'
import { toast } from 'react-toastify'
import { findFormulasAtivos } from '../services/FormulaAtivoService'
import { searchAtivos } from '../services/SearchAtivoService'

const setAtivos = data => {
  return {
    type: 'LINK_FORMULA_ATIVO_SET_ATIVOS',
    payload: data
  }
}

const setFormulasAtivos = data => {
  return {
    type: 'LINK_FORMULA_ATIVO_SET_FORMULAS_ATIVOS',
    payload: data
  }
}

export const setGoBackPath = path => {
  return {
    type: 'LINK_FORMULA_ATIVO_SET_GO_BACK_PATH',
    payload: path
  }
}

export const setRef = ref => {
  return {
    type: 'LINK_FORMULA_ATIVO_SET_REF',
    payload: ref
  }
}

export const clearSearch = () => {
  return {
    type: 'LINK_FORMULA_ATIVO_CLEAR_SEARCH'
  }
}

export const setPage = page => {
  return {
    type: 'LINK_FORMULA_ATIVO_SET_PAGE',
    payload: page
  }
}

export const setItemsPerPage = itemsPerPage => {
  return {
    type: 'LINK_FORMULA_ATIVO_SET_ITEMS_PER_PAGE',
    payload: itemsPerPage
  }
}
export const setTotalItems = totalItems => {
  return {
    type: 'LINK_FORMULA_ATIVO_SET_TOTAL_ITEMS',
    payload: totalItems
  }
}

export const clearFormulasAtivos = () => {
  return {
    type: 'LINK_FORMULA_ATIVO_CLEAR_FORMULAS_ATIVOS'
  }
}

export const fetchAtivosByFarmacia = (farmaciaId, ref, page, itemsPerPage) => {
  return async dispatch => {
    try {
      const response = await searchAtivos(farmaciaId, ref, page, itemsPerPage);

      return dispatch([
        setAtivos(response.data),
        setTotalItems(response["_metadata"].totalItems)
      ]);
    } catch (e) {
      toast.error("Erro ao buscar ativos");
      return dispatch(nop());
    }
  }
}

export const fetchAllLinksFormulaAtivo = (farmaciaId, formulaId) => {
  return async dispatch => {
    try {
      const data = await findFormulasAtivos(farmaciaId, formulaId);

      return dispatch(setFormulasAtivos(data));
    } catch (e) {
      toast.error("Erro ao buscar os vínculos entre ativos e fórmulas");
      return dispatch(nop());
    }
  }
}
