export const setLogs = logs => {
  return {
    type: 'REPRESENTANTE_LOGS_LIST_SET_LOGS',
    payload: logs
  }
}

export const clearAll = () => {
  return {
    type: 'REPRESENTANTE_LOGS_LIST_CLEAR_ALL',
  }
}

export const setRef = ref => {
  return {
    type: 'REPRESENTANTE_LOGS_LIST_SEARCH_SET_REF',
    payload: ref
  }
}

export const setPage = page => {
  return {
    type: 'REPRESENTANTE_LOGS_LIST_PAGINATION_SET_PAGE',
    payload: page
  }
}

export const setItemsPerPage = itemsPerPage => {
  return {
    type: 'REPRESENTANTE_LOGS_LIST_PAGINATION_SET_ITEMS_PER_PAGE',
    payload: itemsPerPage
  }
}

export const setTotalItems = totalItems => {
  return {
    type: 'REPRESENTANTE_LOGS_LIST_PAGINATION_SET_TOTAL_ITEMS',
    payload: totalItems
  }
}
