import React from 'react'
import './styles.css'

import PropTypes from 'prop-types'

function CardText({ children }) {
  return <p className="card-text text-dark">{children}</p>;
}

CardText.propTypes = {
  children: PropTypes.any.isRequired
}

export default CardText;
