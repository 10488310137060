import React from 'react'
import PropTypes from 'prop-types'
import './styles.css'

import { Link } from 'react-router-dom'

function NavItem({ to, children }) {
  return (
    <Link to={to} className="nav-item nav-link">
      {children}
    </Link>
  )
}

NavItem.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired
}

export default NavItem;
