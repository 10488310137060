import api from './api'
import { toast } from 'react-toastify'

export function findAtivosPendentes(farmaciaId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      const response = await api.get(`farmacias/${farmaciaId}/ativos/pending`, {
        headers: { authorization: `Bearer ${token}` }
      });

      return resolve(response.data.data);
    } catch (e) {
      toast.error("Falha ao buscar ativos pendentes");
      return reject(e);
    }
  })
}

export function validateAtivo(farmaciaId, ativoId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      await api.post(`farmacias/${farmaciaId}/ativos/${ativoId}/validate`, {}, {
        headers: { authorization: `Bearer ${token}` }
      });

      return resolve();
    } catch (e) {
      toast.error("Falha ao validar ativo");
      return reject(e);
    }
  })
}

export function findFormulasPendentes(farmaciaId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      const response = await api.get(`farmacias/${farmaciaId}/formulas/pending`, {
        headers: { authorization: `Bearer ${token}` }
      });

      return resolve(response.data.data);
    } catch (e) {
      toast.error("Falha ao buscar fórmulas pendentes");
      return reject(e);
    }
  })
}

export function validateFormula(farmaciaId, formulaId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      await api.post(`farmacias/${farmaciaId}/formulas/${formulaId}/validate`, {}, {
        headers: { authorization: `Bearer ${token}` }
      });

      return resolve();
    } catch (e) {
      toast.error("Falha ao validar fórmula");
      return reject(e);
    }
  })
}

export function findIndustrializadosPendentes(farmaciaId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      const response =
        await api.get(`farmacias/${farmaciaId}/medicamentos_industrializados/pending`, {
          headers: { authorization: `Bearer ${token}` }
        });

      return resolve(response.data.data);
    } catch (e) {
      toast.error("Falha ao buscar industrializados pendentes");
      return reject(e);
    }
  })
}

export function validateIndustrializado(farmaciaId, industrializadoId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      await api.post(`farmacias/${farmaciaId}/medicamentos_industrializados/${industrializadoId}/validate`, {}, {
        headers: { authorization: `Bearer ${token}` }
      });

      return resolve();
    } catch (e) {
      toast.error("Falha ao validar industrializado");
      return reject(e);
    }
  })
}
