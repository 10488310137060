import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import {
  fetchAtivosByRepresentante,
  clearAll,
  setPage,
} from '../../stores/representanteAtivoListActions';
import Title from '../../components/Title';
import Skeleton from 'react-loading-skeleton';
import Row from '../../components/Row';
import Grid from '../../components/Grid';
import AtivoList from './AtivoList';
import Pagination from '../../components/Pagination';

export default function AtivoRepresentanteListContainer() {
  const [isLoading, setLoading] = useState(true);
  const [isFirst, setFirst] = useState(true);

  const dispatch = useDispatch();
  const ativos = useSelector((state) => state.representante.ativo.ativos);
  const usuarioId = useSelector((state) => state.auth.user.usu_in_codigo);

  const page = useSelector(
    (state) => state.representante.ativo.pagination.page
  );
  const itemsPerPage = useSelector(
    (state) => state.representante.ativo.pagination.itemsPerPage
  );
  const totalItems = useSelector(
    (state) => state.representante.ativo.pagination.totalItems
  );

  const farmaciaId = localStorage.getItem('FORMULARIO_FARMACIA_ID');

  useEffect(() => {
    dispatch(
      fetchAtivosByRepresentante(farmaciaId, usuarioId, {
        page: 1,
        itemsPerPage,
      })
    );

    return () => dispatch(clearAll());
  }, []);

  useEffect(() => {
    if (!isFirst) setLoading(false);
    else setFirst(false);
  }, [ativos]);

  function handlePaginate(pgNumber) {
    if (pgNumber === page) return;

    setLoading(true);

    dispatch([
      setPage(pgNumber),
      fetchAtivosByRepresentante(farmaciaId, usuarioId, {
        page: pgNumber,
        itemsPerPage,
      }),
    ]);
  }

  return (
    <div className='ativo-container'>
      <Title link='/'>Listagem de ativos</Title>

      <Row>
        <Grid cols='12'>
          {isLoading ? (
            <Skeleton count={5} height={30} />
          ) : (
            <AtivoList ativos={ativos} readonly />
          )}
        </Grid>
      </Row>

      {ativos.length > 0 ? (
        <Row>
          <Grid cols='12'>
            <Pagination
              page={page}
              itemsPerPage={itemsPerPage}
              totalItems={totalItems}
              paginate={handlePaginate}
            />
          </Grid>
        </Row>
      ) : null}
    </div>
  );
}
