import React from 'react'
import './styles.css'
import PropTypes from 'prop-types'

function CardBody({ children, pl0 }) {
  let classes = 'card-body';
  
  if(pl0) {
    classes = classes.concat(' pl-0');
  }

  return (
    <div className={classes}>
      {children}
    </div>
  )
}

CardBody.propTypes = {
  children: PropTypes.any.isRequired
}

export default CardBody;
