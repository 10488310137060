import React, { useState } from 'react';
import './styles.css';
import PropTypes from 'prop-types';

import Table, {
  Thead as TableHeader,
  Tbody,
  TableRow,
  TbodyCol,
} from '../../../components/Table';
import Button from '../../../components/Button';
import { MdClear, MdSearch } from 'react-icons/md';
import ModalViewMedicamento from '../ModalViewMedicamento';
import ModalButton from '../../../components/ModalButton';
import ButtonEdit from '../../../components/ButtonEdit';

function MedicamentoList({
  medicamentos,
  onRemoveMedicamento: handleRemoveMedicamento,
  onEditFormula: handleEditFormula,
}) {
  const [medicamento, setMedicamento] = useState(null);

  return medicamentos.length > 0 ? (
    <>
      <Table borderless>
        <TableHeader columns={['Nome', 'Uso', 'Tipo', 'Ação']} />

        <Tbody>
          {medicamentos.map((med) => (
            <TableRow key={med.codigo}>
              <TbodyCol alignMiddle>{med.nome}</TbodyCol>
              <TbodyCol alignMiddle label='Uso'>
                {med.uso}
              </TbodyCol>
              <TbodyCol alignMiddle label='Tipo'>
                {med.tipo === 'ATIVO'
                  ? 'Ativo'
                  : med.tipo === 'FORMULA'
                  ? 'Fórmula'
                  : med.tipo === 'INDUSTRIALIZADO'
                  ? 'Industrializado'
                  : ' - '}
              </TbodyCol>
              <TbodyCol alignMiddle>
                <ModalButton
                  target='#modalViewMedicamento'
                  size='sm'
                  color='info'
                  className='mr-1'
                  title='Visualizar medicamento'
                  handleclick={() => setMedicamento(med)}
                >
                  <MdSearch size={18} />
                </ModalButton>

                {med.tipo === 'FORMULA' ? (
                  <ButtonEdit onClick={() => handleEditFormula(med)} />
                ) : null}

                <Button
                  size='sm'
                  color='danger'
                  title='Remover da receita'
                  handleclick={() => handleRemoveMedicamento(med)}
                >
                  <MdClear size={18} />
                </Button>
              </TbodyCol>
            </TableRow>
          ))}
        </Tbody>
      </Table>

      <ModalViewMedicamento
        id='modalViewMedicamento'
        medicamento={medicamento}
      />
    </>
  ) : (
    <p>Nenhum medicamento adicionado</p>
  );
}

MedicamentoList.propTypes = {
  medicamentos: PropTypes.array.isRequired,
  onRemoveMedicamento: PropTypes.func,
  onEditFormula: PropTypes.func,
};

export default MedicamentoList;
