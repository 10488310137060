import React, { useRef, useState } from 'react';
import './styles.css';

import PropTypes from 'prop-types';
import { Form } from '@unform/web';
import { Input, Select } from '../../../components/Form';
import Button from '../../../components/Button';
import { MdClear, MdSearch } from 'react-icons/md';
import Row from '../../../components/Row';
import Grid from '../../../components/Grid';

function MedicamentoSearch({ onSubmit: handleSubmit, onClear: handleClear }) {
  const formRef = useRef(null);

  const [ref, setRef] = useState('');
  const [tipo, setTipo] = useState('ALL');

  function resetForm() {
    setRef('');
    setTipo('');
  }

  return (
    <div className='search-container'>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Row>
          <Grid cols='12 4'>
            <Select
              id='tipo'
              name='tipo'
              label='Tipo'
              value={tipo}
              onChange={(e) => setTipo(e.target.value)}
            >
              <option value='ALL'>Todos</option>
              <option value='ORAL'>Oral</option>
              <option value='TOPICO'>Tópico</option>
              <option value='AMBOS'>Ambos</option>
            </Select>
          </Grid>
        </Row>

        <Row>
          <Grid cols='12 10'>
            <Input
              id='ref'
              name='ref'
              value={ref}
              onChange={(e) => setRef(e.target.value)}
              placeholder='Pesquise por ativos, fórmulas ou industrializados'
            />
          </Grid>

          <Grid cols='12 2'>
            <Button color='info' type='submit'>
              <MdSearch size={18} />
            </Button>

            <Button
              type='button'
              className='ml-2'
              color='info'
              handleclick={() => {
                handleClear();
                resetForm();
              }}
            >
              <MdClear size={18} />
            </Button>
          </Grid>
        </Row>
      </Form>

      <hr className='divider' />
    </div>
  );
}

MedicamentoSearch.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClear: PropTypes.func,
};

export default MedicamentoSearch;
