import React from 'react';
import './styles.css';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { MdExitToApp, MdReceipt } from 'react-icons/md';
import { logout } from '../../stores/authActions';
import NavItem from './NavItem';
import Brand from './Brand';
import UserCard from './UserCard';
import ModalButton from '../../components/ModalButton';
import ModalNewSugestion from '../../pages/ResultListPage/ModalNewSugestion';
import { Dropdown, DropdownItem } from './Dropdown';
import logoImg from '../../assets/navbar/logo_h.png';

import ModalNewVia from '../../pages/NewViaModal';
import { save as saveVia } from '../../services/ViaService';
import { toast } from 'react-toastify';
import { getVias } from '../../stores/viaActions';
import ButtonHead from './ButtonHead';

function NavItems({ role, userId }) {
  // Gestor
  if (role === 'G') {
    return (
      <>
        <NavItem to='/receitas'>Receitas</NavItem>
        <Dropdown label='Medicamentos'>
          <DropdownItem to='/formulas'>Fórmulas</DropdownItem>
          <DropdownItem to='/ativos'>Ativos</DropdownItem>
          <DropdownItem to='/medicamentos'>Industrializados</DropdownItem>
        </Dropdown>
        <Dropdown label='Cadastros'>
          <DropdownItem to='/veiculosUtilizacao'>
            Veic. utilização/Forma farmacêutica
          </DropdownItem>
          <DropdownItem to='/unidades'>Unidades</DropdownItem>
          <DropdownItem to='/usuarios'>Usuários</DropdownItem>
          <DropdownItem to='/modelos_receita'>Modelos de receita</DropdownItem>
          <DropdownItem to='/especialidades'>Especialidades</DropdownItem>
        </Dropdown>
        <Dropdown label='Associações'>
          <DropdownItem to='/medicamentos/link'>
            Vínculo de medicamentos
          </DropdownItem>
          <DropdownItem to='/representantes/vincular_medicos'>
            Vínculo representante - médico
          </DropdownItem>
          <DropdownItem to='/especialidades/medicos/link'>
            Vínculo especialidades - médico
          </DropdownItem>
          <DropdownItem to='/especialidades/medicamentos/link'>
            Vínculo especialidades - medicamentos
          </DropdownItem>
        </Dropdown>
        <Dropdown label='Atividades'>
          <DropdownItem to='/medicamentos/logs'>
            Registro de eventos
          </DropdownItem>
        </Dropdown>
      </>
    );
  }

  // Medico
  if (role === 'M') {
    return (
      <>
        <NavItem to={`/medicos/${userId}/receitas`}>Receitas</NavItem>
        <Dropdown label='Medicamentos'>
          <DropdownItem to='/formulas'>Fórmulas</DropdownItem>
          <DropdownItem to='/ativos'>Ativos</DropdownItem>
          <DropdownItem to='/medicamentos'>Industrializados</DropdownItem>
        </Dropdown>
        <Dropdown label='Atividades'>
          <DropdownItem to='/medicamentos/logs'>
            Registro de eventos
          </DropdownItem>
        </Dropdown>
        <ModalButton target='#modalSugestMed' size='sm' color='secondary'>
          Adicionar fórmula/ativo/industrializado
        </ModalButton>
        <ModalButton
          className='ml-2'
          target='#modalNewVia'
          size='sm'
          color='secondary'
        >
          Gerenciar Vias
        </ModalButton>
        <ButtonHead to='/receita' title='Receita atual'>
          Receita atual
        </ButtonHead>
        <ButtonHead to='/receita/manual' title='Receita manual'>
          Receita manual
        </ButtonHead>
        {/* <ButtonHead to='/receita' title='Receita atual' >
          Receita manual
        </ButtonHead> */}
      </>
    );
  }

  // Representante
  if (role === 'R') {
    return (
      <>
        {/* <NavItem to='/receitas'>Receitas</NavItem> */}
        <Dropdown label='Medicamentos'>
          <DropdownItem to='/formulas'>Fórmulas</DropdownItem>
          <DropdownItem to='/ativos'>Ativos</DropdownItem>
          <DropdownItem to='/medicamentos'>Industrializados</DropdownItem>
        </Dropdown>
        <Dropdown label='Cadastros'>
          <DropdownItem to='/veiculosUtilizacao'>
            Veic. utilização/Forma farmacêutica
          </DropdownItem>
          <DropdownItem to='/unidades'>Unidades</DropdownItem>
          <DropdownItem to='/modelos_receita'>Modelos de receita</DropdownItem>
        </Dropdown>
        <Dropdown label='Associações'>
          <DropdownItem to='/medicamentos/link'>
            Vínculo de medicamentos
          </DropdownItem>
        </Dropdown>
        {/* <Dropdown label='Atividades'>
          <DropdownItem to='/medicamentos/logs'>
            Registro de eventos
          </DropdownItem>
        </Dropdown> */}
      </>
    );
  }

  return null;
}

export default function Navbar() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const user = useSelector((state) => state.auth.user);
  const farmacia = useSelector((state) => state.auth.farmacia);
  const hidden = useSelector((state) => state.navbar.hidden);
  const vias = useSelector((state) => state.vias.vias);

  const dispatch = useDispatch();
  const history = useHistory();

  function sugestNewAtivo() {
    history.push('/ativos/new', { status: 'P', usuarioId: user.usu_in_codigo });
  }

  function sugestNewFormula() {
    history.push('/formulas/new', {
      status: 'P',
      usuarioId: user.usu_in_codigo,
    });
  }

  function sugestNewMedicamentoIndustrializado() {
    history.push('/medicamentos/new', {
      status: 'P',
      usuarioId: user.usu_in_codigo,
    });
  }

  async function createVia(via) {
    try {
      await saveVia(via);

      toast.success('Via cadastrada com sucesso!');
      dispatch(getVias());
    } catch (error) {
      console.log('erro save via: ', error);
      toast.error('Erro ao cadastrar via');
    }
  }

  return (
    <>
      {hidden ? null : (
        <nav className='navbar navbar-expand-lg navbar-dark'>
          {process.env.REACT_APP_FIREBASE_PROJECT_ID === 'formularioreact' ? (
            <Brand to='/'>
              {farmacia && user.usu_ch_tipo !== 'M'
                ? farmacia.far_st_nome
                : 'Bem-vindo!'}
            </Brand>
          ) : (
            <Brand to='/'>
              <img
                className='navbar-brand-logo'
                src={logoImg}
                alt='DoctorLib'
              />
            </Brand>
          )}

          <button
            className='navbar-toggler'
            type='button'
            data-toggle='collapse'
            data-target='#navbarNavAltMarkup'
            aria-controls='navbarNavAltMarkup'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <span className='navbar-toggler-icon'></span>
          </button>

          <div className='collapse navbar-collapse' id='navbarNavAltMarkup'>
            <div className='navbar-nav'>
              <NavItems role={user?.usu_ch_tipo} userId={user?.usu_in_codigo} />
            </div>
          </div>

          <div
            className='collapse navbar-collapse justify-content-end'
            id='navbarNavAltMarkup'
          >
            <div className='navbar-nav flex-row justify-content-between'>
              {isAuthenticated ? (
                <>
                  <UserCard user={user} />
                  {/* 
                    // man
                  {user?.usu_ch_tipo === 'M' ? (
                    <Link
                      to='/receita'
                      className='nav-item nav-link d-flex'
                      title='Receita atual'
                    >
                      <MdReceipt size={24} />
                    </Link>
                  ) : null} 
                  */}

                  <Link
                    to='/login'
                    onClick={() => dispatch(logout())}
                    className='nav-item nav-link d-flex'
                    title='Sair'
                  >
                    <MdExitToApp size={24} />
                  </Link>
                </>
              ) : (
                <NavItem to='/login'>Entrar</NavItem>
              )}
            </div>
          </div>
        </nav>
      )}

      <ModalNewSugestion
        id='modalSugestMed'
        onSugestNewAtivo={sugestNewAtivo}
        onSugestNewFormula={sugestNewFormula}
        onSugestNewMedicamentoIndustrializado={
          sugestNewMedicamentoIndustrializado
        }
      />

      <ModalNewVia id='modalNewVia' vias={vias} saveNewVia={createVia} />
    </>
  );
}
