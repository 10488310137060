const INITIAL_STATE = {
  isLoading: false
}

export default function loadingReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SET_LOADING':
      return {
        ...state,
        isLoading: action.payload
      }
    case 'CLEAR_LOADING':
      return {
        ...state,
        ...INITIAL_STATE
      }
    default:
      return state
  }
}
