import React, { useState, useEffect, useRef } from 'react';
import './styles.css';

import { useParams, useHistory } from 'react-router-dom';
import Title from '../../components/Title';
import { Form } from '@unform/web';
import {
  Input,
  Select,
  Checkbox,
  TextArea as Textarea,
  MaskInput,
} from '../../components/Form';
import Button from '../../components/Button';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { setLoading } from '../../stores/loadingActions';
import {
  fetchById,
  clearUsuario,
  fetchAllModelosReceita,
} from '../../stores/usuarioEditActions';
import Row from '../../components/Row';
import Grid from '../../components/Grid';
import { update } from '../../services/UsuarioService';
import {
  ImageSelectorContainer,
  ImageSelectorItem,
} from '../../components/ImageSelector';
import { toast } from 'react-toastify';

export default function UsuarioEdit() {
  const { id } = useParams();
  const history = useHistory();

  const dispatch = useDispatch();
  const usuario = useSelector((state) => state.usuario.edit.usuario);

  const farmaciaId = localStorage.getItem('FORMULARIO_FARMACIA_ID');

  const formRef = useRef(null);

  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [telefone, setTelefone] = useState('');
  const [tipo, setTipo] = useState('G');
  const [crm, setCrm] = useState(null);
  const [especialidade, setEspecialidade] = useState(null);
  const [rua, setRua] = useState(null);
  const [bairro, setBairro] = useState(null);
  const [cidade, setCidade] = useState(null);
  const [estado, setEstado] = useState(null);
  const [cep, setCep] = useState(null);
  const [atendeConvenio, setAtendeConvenio] = useState(null);
  const [composicaoFormula, setComposicaoFormula] = useState(null);
  const [nomeFormula, setNomeFormula] = useState(null);
  const [pacienteRecebeOrcamentos, setPacienteRecebeOrcamentos] = useState(
    null
  );
  const [convenios, setConvenios] = useState(null);
  const [emailReceita, setEmailReceita] = useState(null);
  const [obs, setObs] = useState(null);

  const [isReadonly, setReadonly] = useState(true);

  // modelos receita
  const receitaModelos = useSelector(
    (state) => state.usuario.edit.receitaModelos
  );
  const currentUser = useSelector((state) => state.usuario.edit.usuario);

  const [selectedModelo, setSelectedModelo] = useState(null);

  useEffect(() => {
    // fetch user by id
    dispatch([fetchById(id), fetchAllModelosReceita(farmaciaId)]);

    return () => dispatch(clearUsuario());
  }, []);

  useEffect(() => {
    if (usuario) {
      setNome(usuario.usu_st_nome);
      setEmail(usuario.usu_st_email);
      setTelefone(usuario.usu_st_telefone);
      setTipo(usuario.usu_ch_tipo);
      setCrm(usuario.usu_st_crm);
      setEspecialidade(usuario.usu_st_especialidade);
      setRua(usuario.usu_st_rua);
      setBairro(usuario.usu_st_bairro);
      setCidade(usuario.usu_st_cidade);
      setEstado(usuario.usu_st_estado);
      setCep(usuario.usu_st_cep);
      setAtendeConvenio(usuario.usu_bo_atendeconvenio);
      setComposicaoFormula(usuario.usu_bo_composicaoformula);
      setNomeFormula(usuario.usu_bo_nomeformula);
      setPacienteRecebeOrcamentos(usuario.usu_bo_pacienterecebeorcamento);
      setConvenios(usuario.usu_st_convenios);
      setEmailReceita(usuario.usu_st_emailreceita);
      setObs(usuario.usu_st_observacao);
      setSelectedModelo({ rem_in_codigo: usuario.rem_in_codigo });
    }
  }, [usuario]);

  async function handleSubmit(data, { reset }) {
    try {
      // set loading
      dispatch(setLoading(true));

      // validation
      const schema = Yup.object().shape({
        usu_st_nome: Yup.string().required('O nome é obrigatório'),
        usu_st_email: Yup.string()
          .required('O e-mail é obrigatório')
          .email('Informe um e-mail válido'),
        usu_st_telefone: Yup.string(),
        usu_ch_tipo: Yup.string().oneOf(
          ['G', 'M', 'R'],
          'O tipo deve ser Médico ou Gestor'
        ),
        usu_st_especialidade: Yup.string(),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      // check if usuario selected an modelo for receita
      if (!selectedModelo) {
        toast.warning('Selecione um modelo para suas receitas');
        return;
      }

      // save
      await update(id, {
        ...data,
        rem_in_codigo: selectedModelo.rem_in_codigo,
      });

      dispatch(setLoading(false));

      // reset form data
      reset();

      // reset errors
      formRef.current.setErrors({});

      // redirect user
      history.push('/usuarios');
    } catch (e) {
      if (e instanceof Yup.ValidationError) {
        const errorMessages = {};

        e.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });

        formRef.current.setErrors(errorMessages);
      }

      dispatch(setLoading(false));
    }
  }

  async function resetPassword() {
    dispatch(setLoading(true));

    try {
      const usuario = currentUser;
      delete usuario.usu_in_codigo;
      delete usuario.usu_ch_tipo;
      delete usuario.far_in_codigo;
      delete usuario.createdAt;
      delete usuario.updatedAt;

      await update(id, { ...usuario, usu_st_senha: '123456' });
      toast.success(
        `Senha do usuário '${currentUser.usu_st_nome}' alterada para '123345'`
      );
    } catch (error) {
      toast.error(
        `Erro ao redefinir a senha do usuário '${currentUser.usu_st_nome}'`
      );
    }

    dispatch(setLoading(false));
  }

  function BuildUserProfileButton() {
    if (tipo !== 'M') return null;

    return (
      <>
        <Button size='sm' color='secondary' handleclick={() => resetPassword()}>
          Redefinir senha
        </Button>
        &nbsp;
        <Button
          size='sm'
          color='secondary'
          handleclick={() => history.push(`/usuarios/${id}/montar_perfil`)}
        >
          Montar perfil do prescritor
        </Button>
      </>
    );
  }

  return (
    <div className='usuario-container'>
      <Title link='/usuarios' component={() => <BuildUserProfileButton />}>
        Editar
      </Title>

      <Row>
        <Grid cols='12'>
          <div className='form-container'>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <Input
                id='usu_st_nome'
                label='* Nome'
                name='usu_st_nome'
                value={nome}
                onChange={(e) => setNome(e.target.value)}
                disabled={isReadonly}
                required
              />

              <Input
                id='usu_st_email'
                label='* E-mail'
                name='usu_st_email'
                type='email'
                placeholder='exemplo@mail.com'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={isReadonly}
                required
              />

              <MaskInput
                id='usu_st_telefone'
                mask='(99) 99999 9999'
                maskChar=' '
                label='Telefone'
                name='usu_st_telefone'
                value={telefone}
                onChange={(e) => setTelefone(e.target.value)}
                disabled={isReadonly}
                placeholder='(99) 99999-9999'
              />

              {tipo === 'M' ? (
                <>
                  <Input
                    id='usu_st_crm'
                    label='* Código do conselho de registro'
                    name='usu_st_crm'
                    value={crm}
                    onChange={(e) => setCrm(e.target.value)}
                    disabled={isReadonly}
                    required
                  />

                  <Input
                    id='usu_st_especialidade'
                    label='Especialidade'
                    name='usu_st_especialidade'
                    value={especialidade}
                    onChange={(e) => setEspecialidade(e.target.value)}
                    disabled={isReadonly}
                    required
                  />

                  <fieldset>
                    <legend>Endereço do consultório</legend>

                    <Input
                      id='usu_st_rua'
                      label='Rua'
                      name='usu_st_rua'
                      value={rua}
                      onChange={(e) => setRua(e.target.value)}
                      disabled={isReadonly}
                      required
                    />

                    <Input
                      id='usu_st_bairro'
                      label='Bairro'
                      name='usu_st_bairro'
                      value={bairro}
                      onChange={(e) => setBairro(e.target.value)}
                      disabled={isReadonly}
                      required
                    />

                    <Input
                      id='usu_st_cidade'
                      label='Cidade'
                      name='usu_st_cidade'
                      value={cidade}
                      onChange={(e) => setCidade(e.target.value)}
                      disabled={isReadonly}
                      required
                    />

                    <Select
                      id='usu_st_estado'
                      name='usu_st_estado'
                      label='Estado'
                      value={estado}
                      onChange={(e) => setEstado(e.target.value)}
                      disabled={isReadonly}
                    >
                      <option value='AC'>Acre</option>
                      <option value='AL'>Alagoas</option>
                      <option value='AP'>Amapá</option>
                      <option value='AM'>Amazonas</option>
                      <option value='BA'>Bahia</option>
                      <option value='CE'>Ceará</option>
                      <option value='DF'>Distrito Federal</option>
                      <option value='ES'>Espírito Santo</option>
                      <option value='GO'>Goiás</option>
                      <option value='MA'>Maranhão</option>
                      <option value='MT'>Mato Grosso</option>
                      <option value='MS'>Mato Grosso do Sul</option>
                      <option value='MG'>Minas Gerais</option>
                      <option value='PA'>Pará</option>
                      <option value='PB'>Paraíba</option>
                      <option value='PR'>Paraná</option>
                      <option value='PE'>Pernambuco</option>
                      <option value='PI'>Piauí</option>
                      <option value='RJ'>Rio de Janeiro</option>
                      <option value='RN'>Rio Grande do Norte</option>
                      <option value='RS'>Rio Grande do Sul</option>
                      <option value='RO'>Rondônia</option>
                      <option value='RR'>Roraima</option>
                      <option value='SC'>Santa Catarina</option>
                      <option value='SP'>São Paulo</option>
                      <option value='SE'>Sergipe</option>
                      <option value='TO'>Tocantins</option>
                    </Select>

                    <MaskInput
                      id='usu_st_cep'
                      label='CEP'
                      name='usu_st_cep'
                      mask='99999-999'
                      placeholder='99999-999'
                      maskChar=' '
                      value={cep}
                      onChange={(e) => setCep(e.target.value)}
                      disabled={isReadonly}
                      required
                    />
                  </fieldset>

                  <fieldset>
                    <legend>Convênio</legend>

                    <Checkbox
                      id='usu_bo_atendeconvenio'
                      label='Atende convênio?'
                      name='usu_bo_atendeconvenio'
                      value={atendeConvenio}
                      checked={atendeConvenio}
                      onChange={(e) => {
                        setAtendeConvenio(e.target.checked);

                        if (!e.target.checked) {
                          // reset convenio value
                          setConvenios(null);
                        }
                      }}
                      disabled={isReadonly}
                    />

                    {atendeConvenio ? (
                      <Textarea
                        id='usu_st_convenios'
                        label='Quais convênios?'
                        name='usu_st_convenios'
                        placeholder='UNIMED, Intermedica, Bradesco, Amil...'
                        value={convenios}
                        onChange={(e) => setConvenios(e.target.value)}
                        disabled={isReadonly}
                        required
                      ></Textarea>
                    ) : null}
                  </fieldset>

                  <fieldset>
                    <legend>Receita</legend>

                    {/* componente seletor de imagens */}
                    <ImageSelectorContainer>
                      {receitaModelos.map((modelo) => (
                        <ImageSelectorItem
                          key={modelo.rem_in_codigo}
                          selected={
                            selectedModelo?.rem_in_codigo ===
                            modelo.rem_in_codigo
                          }
                          onSelect={
                            isReadonly ? null : () => setSelectedModelo(modelo)
                          }
                          label={modelo.rem_st_descricao}
                          imagePath={modelo.rem_st_caminhoimagem}
                        />
                      ))}
                    </ImageSelectorContainer>

                    <br />

                    <Checkbox
                      id='usu_bo_composicaoformula'
                      label='Permitir exibir a composição da fórmula no resumo da receita?'
                      name='usu_bo_composicaoformula'
                      value={composicaoFormula}
                      checked={composicaoFormula}
                      onChange={(e) => setComposicaoFormula(e.target.checked)}
                      disabled={isReadonly}
                    />

                    <Checkbox
                      id='usu_bo_nomeformula'
                      label='Permitir exibir o nome da fórmula no resumo da receita?'
                      name='usu_bo_nomeformula'
                      value={nomeFormula}
                      checked={nomeFormula}
                      onChange={(e) => setNomeFormula(e.target.checked)}
                      disabled={isReadonly}
                    />

                    <Checkbox
                      id='usu_bo_pacienterecebeorcamento'
                      label='Paciente aceita receber orçamentos?'
                      name='usu_bo_pacienterecebeorcamento'
                      value={pacienteRecebeOrcamentos}
                      checked={pacienteRecebeOrcamentos}
                      onChange={(e) =>
                        setPacienteRecebeOrcamentos(e.target.checked)
                      }
                      disabled={isReadonly}
                    />
                  </fieldset>

                  <Input
                    id='usu_st_emailreceita'
                    label='Endereço para envio de receitas'
                    placeholder='contato_farmacia@gmail.com'
                    name='usu_st_emailreceita'
                    value={emailReceita}
                    onChange={(e) => setEmailReceita(e.target.value)}
                    disabled={isReadonly}
                  />

                  <Textarea
                    id='usu_st_observacao'
                    label='Observação'
                    name='usu_st_observacao'
                    value={obs}
                    onChange={(e) => setObs(e.target.value)}
                    disabled={isReadonly}
                  ></Textarea>
                </>
              ) : null}

              <div className='w-100 mb-3'>
                <small className='text-muted align-end'>
                  Os campos marcados com (*) são obrigatórios
                </small>
              </div>

              {isReadonly ? (
                <Button size='block' handleclick={() => setReadonly(false)}>
                  Habilitar campos
                </Button>
              ) : (
                <Row>
                  <Grid cols='12 6'>
                    <Button
                      color='secondary'
                      size='block'
                      handleclick={() => setReadonly(true)}
                    >
                      Cancelar
                    </Button>
                  </Grid>

                  <Grid cols='12 6'>
                    <Button type='submit' size='block'>
                      Salvar
                    </Button>
                  </Grid>
                </Row>
              )}
            </Form>
          </div>
        </Grid>
      </Row>
    </div>
  );
}
