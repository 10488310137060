const INITIAL_STATE = {
  unidades: [],
  veiculosUtilizacao: [],
  medicos: [],
}

export default function newFormulaReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'NEW_FORMULA_SET_UNIDADES':
      return {
        ...state,
        unidades: action.payload
      }
    case 'NEW_FORMULA_SET_VEICULOS_UTILIZACAO':
      return {
        ...state,
        veiculosUtilizacao: action.payload
      }
    case 'NEW_FORMULA_SET_MEDICOS':
      return {
        ...state,
        medicos: action.payload
      }
    case 'NEW_FORMULA_CLEAR_ALL':
      return {
        ...state,
        ...INITIAL_STATE
      }
    default:
      return state;
  }
}
