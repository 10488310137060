const INITIAL_STATE = {
  receitas: []
}

export default function medicoReceitaListReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'MEDICO_RECEITA_LIST_SET_RECEITAS':
      return {
        ...state,
        receitas: action.payload
      }
    case 'MEDICO_RECEITA_LIST_CLEAR_ALL':
      return {
        ...state,
        ...INITIAL_STATE
      }
    default:
      return state;
  }
}
