import React, { useRef } from 'react';
import './styles.css';

import { useSelector, useDispatch } from 'react-redux';
import { setLoading } from '../../stores/loadingActions';
import { useHistory, useLocation } from 'react-router-dom';

import Title from '../../components/Title';
import { Form } from '@unform/web';
import { Input, TextArea } from '../../components/Form';
import Button from '../../components/Button';
import Row from '../../components/Row';
import Grid from '../../components/Grid';
// import ImageInput from '@bit/ericktrettel.franchini.image-input'
import * as Yup from 'yup';
// import defaultImg from '../../assets/default-img.png'
import { save } from '../../services/MedicamentoService';
// import { save as saveFile } from '../../config/firebase'
// import { getFileExtension } from '../../utils/files'
// import { v4 as uuidv4 } from 'uuid'

export default function NewMedicamentoForm() {
  const formRef = useRef(null);
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();

  // const [image, setImage] = useState({ url: defaultImg, file: null });

  const farmaciaId = localStorage.getItem('FORMULARIO_FARMACIA_ID');

  const role = useSelector((state) => state.auth.user.usu_ch_tipo);

  const status = location.state?.status;
  const usuarioId = location.state?.usuarioId;

  // if role === M, then check for status props on location
  if (role === 'M' && !status) history.push('/');

  async function handleSubmit(data, { reset }) {
    try {
      // set loading
      dispatch(setLoading(true));

      // validation
      const schema = Yup.object().shape({
        med_st_nome: Yup.string().required('O nome é obrigatório'),
        med_st_fabricante: Yup.string().required('O fabricante é obrigatório'),
        med_st_recomendacaouso: Yup.string().required(
          'Informe a recomendação de uso'
        ),
        med_st_posologia: Yup.string().required('Informe a posologia'),
        for_st_observacao: Yup.string(),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      let medicamento = {
        ...data,
        med_ch_status: status ? status : 'A',
        usu_in_codigo: usuarioId,
      };

      // const file = image.file;

      // if (file) {
      //   const fileExtension = getFileExtension(file.name);
      //   const blobName = `medicamentos/${medicamento.med_st_nome}/${uuidv4() + fileExtension}`;
      //   saveFile(image.file, blobName);

      //   medicamento = {
      //     ...medicamento,
      //     med_st_imagem: blobName
      //   }
      // }

      // save
      await save(farmaciaId, medicamento);

      dispatch(setLoading(false));

      reset();

      // clear errors
      formRef.current.setErrors({});

      // redirect
      history.push('/medicamentos');
    } catch (e) {
      if (e instanceof Yup.ValidationError) {
        const errorMessages = {};

        e.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });

        formRef.current.setErrors(errorMessages);
      }

      dispatch(setLoading(false));
    }
  }

  return (
    <div className='medicamento-container'>
      <Title link='/medicamentos'>Cadastro de industrializado</Title>

      <Row>
        <Grid cols='12'>
          <div className='form-container'>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <Input
                id='med_st_nome'
                label='* Nome'
                className='form-control upper'
                name='med_st_nome'
                required
              />

              <Input
                id='med_st_fabricante'
                label='* Fabricante'
                className='form-control upper'
                name='med_st_fabricante'
                required
              />

              <TextArea
                id='med_st_recomendacaouso'
                label='* Recomendação de uso'
                className='form-control upper'
                name='med_st_recomendacaouso'
                required
              ></TextArea>

              <Input
                id='med_st_posologia'
                label='* Posologia'
                className='form-control upper'
                name='med_st_posologia'
                required
              />

              <TextArea
                id='med_st_observacao'
                label='Observação'
                className='form-control upper'
                name='med_st_observacao'
              ></TextArea>

              {/* <ImageInput className="form-group"
                id="med_st_imagem"
                name="med_st_imagem"
                onLoadImage={handleLoadImage}
                isRequired={false} /> */}

              <div className='w-100 mb-3'>
                <small className='text-muted align-end'>
                  Os campos marcados com (*) são obrigatórios
                </small>
              </div>

              <Button type='submit' color='primary' size='block'>
                Salvar
              </Button>
            </Form>
          </div>
        </Grid>

        {/* <Grid cols="12 6">
          <img className="img-fluid img-view" src={image.url} alt="Imagem selecionada" />
        </Grid> */}
      </Row>
    </div>
  );
}
