import { nop } from './baseActions'
import { findById } from '../services/ReceitaService'
import { findById as findModeloById } from '../services/ReceitaModeloService'

const setReceita = receita => {
  return {
    type: 'RECEITA_PAGE_SET_RECEITA',
    payload: receita
  }
}

export const setGoBack = link => {
  return {
    type: 'RECEITA_PAGE_SET_GO_BACK',
    payload: link
  }
}

export const clearAll = () => {
  return {
    type: 'RECEITA_PAGE_CLEAR_ALL'
  }
}

const setModelo = modelo => {
  return {
    type: 'RECEITA_PAGE_SET_MODELO',
    payload: modelo
  }
}

export const fetchReceitaById = (farmaciaId, receitaId) => {
  return async dispatch => {
    try {
      const receita = await findById(farmaciaId, receitaId);

      return dispatch(setReceita(receita));
    } catch (e) {
      return dispatch(nop());
    }
  }
}

export const fetchModeloReceitaById = (farmaciaId, modeloId) => {
  return async dispatch => {
    try {
      const modelo = await findModeloById(farmaciaId, modeloId);

      return dispatch(setModelo(modelo));
    } catch (e) {
      return dispatch(nop());
    }
  }
}
