import React from 'react';
import './styles.css';
import PropTypes from 'prop-types';

import Button from '../Button';
import { MdAdd } from 'react-icons/md';

function ButtonAdd({ onClick: handleClick, className, ...props }) {
  return (
    <Button
      color='success'
      size='sm'
      className={className}
      handleclick={handleClick}
      {...props}
    >
      <MdAdd size={18} />
    </Button>
  );
}

ButtonAdd.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default ButtonAdd;
