import { nop } from './baseActions'
import { findAll, remove } from '../services/ReceitaModeloService'
import { setLoading } from './loadingActions'

export const clearAll = () => {
  return {
    type: 'RECEITA_MODELO_LIST_CLEAR_ALL'
  }
}

const setModelos = modelos => {
  return {
    type: 'RECEITA_MODELO_LIST_SET_MODELOS',
    payload: modelos
  }
}

export const fetchAllModelos = farmaciaId => {
  return async dispatch => {
    try {
      const modelos = await findAll(farmaciaId);

      return dispatch(setModelos(modelos));
    } catch (e) {
      return dispatch(nop());
    }
  }
}

export const removeModelo = (farmaciaId, modelo) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));

      await remove(farmaciaId, modelo);

      const modelos = await findAll(farmaciaId);

      return dispatch([
        setModelos(modelos),
        setLoading(false)
      ]);
    } catch (e) {
      return dispatch(nop());
    }
  }
}
