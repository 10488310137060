import { nop } from './baseActions'
import { findById } from '../services/MedicamentoService'
import { toast } from 'react-toastify'
import { findLogsByIndustrializado } from '../services/IndustrializadoLogsService'

const setMedicamento = medicamento => {
  return {
    type: 'MEDICAMENTO_EDIT_SET_MEDICAMENTO',
    payload: medicamento
  }
}

export const setGoBack = path => {
  return {
    type: 'MEDICAMENTO_EDIT_SET_GO_BACK',
    payload: path
  }
}

const setLogs = logs => {
  return {
    type: 'MEDICAMENTO_EDIT_SET_LOGS',
    payload: logs
  }
}

export const clearMedicamento = () => {
  return {
    type: 'MEDICAMENTO_EDIT_CLEAR_MEDICAMENTO'
  }
}

export const fetchById = (farmaciaId, id) => {
  return async dispatch => {
    try {
      const medicamento = await findById(farmaciaId, id);

      return dispatch(setMedicamento(medicamento));
    } catch (e) {
      toast.error('Erro ao buscar medicamento');
      return dispatch(nop());
    }
  }
}

export const fetchLogsByIndustrializado = (farmaciaId, industrializadoId) => {
  return async dispatch => {
    try {
      const logs = await findLogsByIndustrializado(farmaciaId, industrializadoId);

      return dispatch(setLogs(logs));
    } catch (e) {
      return dispatch(nop());
    }
  }
}
