import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import './styles.css';
import PropTypes from 'prop-types';

import { Form } from '@unform/web';
import {
  Input,
  MaskInput,
  Checkbox,
  RadioInput as Radio,
} from '../../../../components/Form';
import Table, {
  TableRow,
  Tbody,
  TbodyCol,
  Thead,
} from '../../../../components/Table';
import Button from '../../../../components/Button';
import Modal from '../../../../components/Modal';
import CloseModalButton from '../../../../components/CloseModalButton';
import Row from '../../../../components/Row';
import Grid from '../../../../components/Grid';
import { toast } from 'react-toastify';

import { save as savePaciente } from '../../../../services/PacienteService';

function ModalPreImpressao({
  id,
  pacienteRecebeOrcamentos,
  onSetPaciente: handleSetPaciente,
  onSkipSetPaciente: handleSkipSetPaciente,
  pacientesList,
}) {
  const [nome, setNome] = useState('');
  const [sexo, setSexo] = useState('');
  const [dataNascimento, setDataNascimento] = useState('');
  const [telefone, setTelefone] = useState('');
  const [email, setEmail] = useState('');
  const [receberOrcamentos, setReceberOrcamentos] = useState(
    pacienteRecebeOrcamentos
  );

  const [newUser, setNewUser] = useState(false);

  const [selectedUser, setSelectedUser] = useState(null);

  const [pacSearch, setPacSearch] = useState('');

  const farmaciaId = useSelector((state) => state.auth.farmacia.far_in_codigo);
  const medicoId = useSelector((state) => state.auth.user.usu_in_codigo);

  const formRef = useRef(null);

  function getPacienteById(id) {
    const codigo = parseInt(id);
    const paciente = pacientesList.find((pac) => pac.pac_in_codigo === codigo);
    return paciente;
  }

  async function handleSubmit() {
    // console.log('receber orcamentos: ', receberOrcamentos);
    if (receberOrcamentos) {
      if (!selectedUser && !nome) {
        toast.warning('Informe o nome do paciente');
        return;
      }

      if (!selectedUser && !telefone) {
        toast.warning('Informe o telefone do paciente');
        return;
      }
    }

    if (newUser) {
      if (email.includes('.') === false)
        return toast.error('Insira um email válido');
      const paciente = await savePaciente({
        pac_st_nome: nome,
        pac_st_emailcontato: email,
        pac_st_telefone: telefone,
        far_in_codigo: farmaciaId,
        usu_in_codigo: medicoId,
      });
      handleSetPaciente(paciente, receberOrcamentos);
    } else if (!newUser) {
      handleSetPaciente(getPacienteById(selectedUser), receberOrcamentos);
    }

    document.getElementById('close_modal_paciente').click();
  }

  return (
    <Modal id={id} title='Paciente' hideFooter>
      <Form ref={formRef} onSubmit={handleSubmit}>
        {!newUser ? (
          <div style={{ textAlign: 'center' }}>
            <Row>
              <Grid cols='12 12'>
                <Input
                  id='ref'
                  name='ref'
                  value={pacSearch}
                  onChange={(e) => setPacSearch(e.target.value)}
                  placeholder='Pesquisar pacientes'
                />
              </Grid>
            </Row>
            <Table small headerSm>
              <Thead dark columns={['Nome', 'Email', 'Ação']} />
              <Tbody>
                {pacientesList?.map((pac) =>
                  pac.pac_st_nome.includes(pacSearch) ||
                  pac.pac_st_emailcontato.includes(pacSearch) ? (
                    <TableRow>
                      <TbodyCol>{pac.pac_st_nome}</TbodyCol>
                      <TbodyCol>{pac.pac_st_emailcontato}</TbodyCol>
                      <TbodyCol>
                        <Button
                          color='success'
                          size='sm'
                          handleclick={() => setSelectedUser(pac.pac_in_codigo)}
                        >
                          Selecionar
                        </Button>
                      </TbodyCol>
                    </TableRow>
                  ) : null
                )}
              </Tbody>
            </Table>
            <br />
            <Button
              className='mb-2'
              color='warning'
              size='sm'
              type='button'
              handleclick={() => setNewUser(true)}
            >
              Criar novo paciente?
            </Button>
          </div>
        ) : (
          <div>
            <Input
              id='nome'
              label='Nome *'
              name='nome'
              placeholder='Nome do paciente'
              value={nome}
              onChange={(e) => setNome(e.target.value)}
              required={receberOrcamentos}
            />
            <div className='form-group'>
              <label>Sexo *</label>
              <br />

              <Radio
                name='sexo'
                id='masculino'
                label='Masculino'
                value='M'
                onChange={(e) => setSexo(e.target.value)}
              />

              <Radio
                name='sexo'
                id='feminino'
                label='Feminino'
                value='F'
                onChange={(e) => setSexo(e.target.value)}
              />

              <Radio
                name='sexo'
                id='outro'
                label='Não deseja informar'
                value='O'
                onChange={(e) => setSexo(e.target.value)}
              />
            </div>

            <Input
              type='date'
              id='dataNascimento'
              label='Data de nascimento *'
              name='dataNascimento'
              value={dataNascimento}
              onChange={(e) => setDataNascimento(e.target.value)}
            />

            <MaskInput
              id='telefone'
              label='Telefone *'
              name='telefone'
              mask='99 99999 9999'
              maskChar='_'
              value={telefone}
              onChange={(e) => setTelefone(e.target.value)}
              required={receberOrcamentos}
            />

            <Input
              id='email'
              type='email'
              label='E-mail *'
              name='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <div style={{ textAlign: 'center', marginBottom: '16px' }}>
              <Button
                color='warning'
                size='sm'
                type='button'
                handleclick={() => setNewUser(false)}
              >
                Selecionar um paciente já existente?
              </Button>
            </div>
          </div>
        )}

        <Checkbox
          id='receberOrcamentos'
          name='receberOrcamentos'
          label='Para facilitar, o paciente aceita receber orçamentos dos medicamentos sem compromisso'
          onChange={(e) => setReceberOrcamentos(e.target.checked)}
          defaultChecked={pacienteRecebeOrcamentos}
        />

        <CloseModalButton
          type='button'
          className='d-none'
          id='close_modal_paciente'
        >
          Fechar modal
        </CloseModalButton>
        <Row>
          <Grid cols='12 6'>
            <CloseModalButton
              type='button'
              size='sm btn-block'
              color='secondary'
              handleclick={handleSkipSetPaciente}
            >
              Pular
            </CloseModalButton>
          </Grid>
          <Grid cols='12 6'>
            <Button type='submit' size='sm btn-block'>
              Vincular
            </Button>
          </Grid>
        </Row>
      </Form>
    </Modal>
  );
}

ModalPreImpressao.propTypes = {
  id: PropTypes.string.isRequired,
  /** Default checked for if paciente aceita receber orçamentos */
  pacienteRecebeOrcamentos: PropTypes.bool,
  onSetPaciente: PropTypes.func,
  onSkipSetPaciente: PropTypes.func,
};

export default ModalPreImpressao;
