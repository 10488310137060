import { nop } from './baseActions'
import { findAll as findAllUnidades } from '../services/UnidadeService'
import { findAll as findAllVeiculosUtilizacao } from '../services/VeiculoUtilizacaoService'
import { searchByRef as searchVeiculos } from '../services/SearchVeiculoUtilizacaoService'
import { searchByRefAndMedico as searchVeiculosByMedico } from '../services/MedicoVeiculoUtilizacaoService'
import { findAllMedicosByFarmacia } from '../services/FindMedicosService'

const setUnidades = unidades => {
  return {
    type: 'NEW_FORMULA_SET_UNIDADES',
    payload: unidades
  }
}

const setVeiculosUtilizacao = veiculosUtilizacao => {
  return {
    type: 'NEW_FORMULA_SET_VEICULOS_UTILIZACAO',
    payload: veiculosUtilizacao
  }
}

const setMedicos = medicos => {
  return {
    type: 'NEW_FORMULA_SET_MEDICOS',
    payload: medicos
  }
}

export const clearAll = () => {
  return {
    type: 'NEW_FORMULA_CLEAR_ALL'
  }
}

export const fetchUnidades = farmaciaId => {
  return async dispatch => {
    try {
      const unidades = await findAllUnidades(farmaciaId);

      return dispatch(setUnidades(unidades));
    } catch (e) {
      return dispatch(nop());
    }
  }
}

export const fetchMedicos = farmaciaId => {
  return async dispatch => {
    try {
      const medicos = await findAllMedicosByFarmacia(farmaciaId);

      return dispatch(setMedicos(medicos));
    } catch (e) {
      return dispatch(nop());
    }
  }
}

export const fetchVeiculosUtilizacao = farmaciaId => {
  return async dispatch => {
    try {
      const veiculosUtilizacao = await findAllVeiculosUtilizacao(farmaciaId);

      return dispatch(setVeiculosUtilizacao(veiculosUtilizacao));
    } catch (e) {
      return dispatch(nop());
    }
  }
}

export const searchVeiculosUtilizacao = (farmaciaId, ref, role, medicoId) => {
  return async dispatch => {
    try {
      let veiculosUtilizacao = [];

      if (role === 'M') veiculosUtilizacao = await searchVeiculosByMedico(farmaciaId, ref, null, medicoId);
      else veiculosUtilizacao = await searchVeiculos(farmaciaId, ref);

      return dispatch(setVeiculosUtilizacao(veiculosUtilizacao));
    } catch (e) {
      return dispatch(nop());
    }
  }
}
