import { nop } from './baseActions'
import { findById } from '../services/VeiculoUtilizacaoService'
import { toast } from 'react-toastify'
import { findAllMedicosByFarmacia } from '../services/FindMedicosService'

const setVeiculoUtilizacao = veiculoUtilizacao => {
  return {
    type: 'VEICULO_UTILIZACAO_EDIT_SET_VEICULO_UTILIZACAO',
    payload: veiculoUtilizacao
  }
}

const setMedicos = medicos => {
  return {
    type: 'VEICULO_UTILIZACAO_EDIT_SET_MEDICOS',
    payload: medicos
  }
}

export const clearVeiculoUtilizacao = () => {
  return {
    type: 'VEICULO_UTILIZACAO_EDIT_CLEAR_VEICULO_UTILIZACAO'
  }
}

export const fetchById = (farmaciaId, veiculoId) => {
  return async dispatch => {
    try {
      const ativo = await findById(farmaciaId, veiculoId);

      return dispatch(setVeiculoUtilizacao(ativo));
    } catch (e) {
      toast.error('Erro ao buscar veículo utilização');
      return dispatch(nop());
    }
  }
}

export const fetchMedicos = farmaciaId => {
  return async dispatch => {
    try {
      const medicos = findAllMedicosByFarmacia(farmaciaId);

      return dispatch(setMedicos(medicos));
    } catch (e) {
      return dispatch(nop());
    }
  }
}
