import React from 'react';
import PropTypes from 'prop-types';

import Table, { TableRow, Tbody, TbodyCol, Thead } from '../Table';
import moment from 'moment';

function LogList({ logs }) {
  return logs.length === 0 ? <p>Nenhum log foi registrado</p> : (
    <Table card headerSm>
      <Thead dark columns={['Medicamento', 'Mensagem', 'Data/hora']} />

      <Tbody>
        {logs.map((log, index) => (
          <TableRow key={index}>
            <TbodyCol label="Medicamento">{log.nome_medicamento}</TbodyCol>
            <TbodyCol label="Mensagem">{log.mensagem}</TbodyCol>
            <TbodyCol label="Data/hora">{moment(log.datahora).format("DD/MM/YYYY HH:mm:ss")}</TbodyCol>
          </TableRow>
        ))}
      </Tbody>
    </Table>
  )
}

LogList.propTypes = {
  logs: PropTypes.array,
}

export default LogList;
