import { nop } from './baseActions'
import { setLoading } from './loadingActions'
import { findAll, remove } from '../services/UsuarioService'
import { toast } from 'react-toastify'

const setUsers = users => {
  return {
    type: 'USUARIO_LIST_FETCH_ALL_USERS',
    payload: users
  }
}

export const clearUsers = () => {
  return {
    type: 'USUARIO_LIST_CLEAR_ALL_USERS'
  }
}

export const fetchAllUsers = farmaciaId => {
  return async dispatch => {
    try {
      const users = await findAll(farmaciaId);

      return dispatch(setUsers(users));
    } catch (e) {
      toast.error('Erro ao buscar usuários');
      return dispatch(nop());
    }
  }
}

export const removeUser = user => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      await remove(user);

      return dispatch([
        fetchAllUsers(user.far_in_codigo),
        setLoading(false)
      ]);
    } catch (e) {
      toast.error('Erro ao remover usuário');
      return dispatch(setLoading(false));
    }
  }
}
