import { nop } from './baseActions'
import { findFormulasPendentes } from '../services/MedicamentosPendentesService'

const setFormulas = formulas => {
  return {
    type: 'FORMULAS_PENDENTES_SET_FORMULAS',
    payload: formulas
  }
}

export const clearFormulas = () => {
  return {
    type: 'FORMULAS_PENDENTES_CLEAR_FORMULAS'
  }
}

export const fetchFormulasPendentes = farmaciaId => {
  return async dispatch => {
    try {
      const formulas = await findFormulasPendentes(farmaciaId);

      return dispatch(setFormulas(formulas));
    } catch (e) {
      return dispatch(nop());
    }
  }
}
