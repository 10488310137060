import React, { useState, useEffect } from 'react';
import './styles.css';

import { useSelector, useDispatch } from 'react-redux';
import {
  fetchAllMedicosByFarmacia,
  fetchAllMedicosByFarmaciaAndReprentante,
  searchMedicamentos,
  setSelectedMedico,
  fetchLinksMedicoMedicamentos,
  setRef,
  setTipo,
  setPage,
  clearAll,
} from '../../stores/linkMedicoToMedicamentosActions';
import Title from '../../components/Title';
import MedicoList from './MedicoList';
import Skeleton from 'react-loading-skeleton';
import MedicamentoList from './MedicamentoList';
import {
  linkAtivoToMedico,
  unlinkAtivoMedico,
  linkFormulaMedico,
  unlinkFormulaMedico,
  linkIndustrializadoToMedico,
  unlinkIndustrializadoMedico,
  linkMultipleToMedicoByFilter,
} from '../../services/LinkMedicoToMedicamentoService';
import MedicamentoSearch from './MedicamentoSearch';
import Pagination from '../../components/Pagination';
import Button from '../../components/Button';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setLoading as loading } from '../../stores/loadingActions';
import Grid from '../../components/Grid';
import UnidadeList from '../LinkEspecialidadeToMedicoContainer/EspecialidadesList';
import Row from '../../components/Row';

export default function LinkMedicamentoToMedicoContainer() {
  const dispatch = useDispatch();
  // search
  const ref = useSelector((state) => state.linkMedicoMedicamento.search.ref);
  const tipo = useSelector((state) => state.linkMedicoMedicamento.search.tipo);

  // pagination
  const page = useSelector(
    (state) => state.linkMedicoMedicamento.pagination.page
  );
  const itemsPerPage = useSelector(
    (state) => state.linkMedicoMedicamento.pagination.itemsPerPage
  );
  const totalItems = useSelector(
    (state) => state.linkMedicoMedicamento.pagination.totalItems
  );

  const allMedicos = useSelector(
    (state) => state.linkMedicoMedicamento.allMedicos
  );
  const selectedMedico = useSelector(
    (state) => state.linkMedicoMedicamento.selectedMedico
  );

  const history = useHistory();

  // medicamentos
  const results = useSelector(
    (state) => state.linkMedicoMedicamento.allMedicamentos
  );

  // medicamentos linked to selected medico
  const linksMedicoMedicamentos = useSelector(
    (state) => state.linkMedicoMedicamento.linksMedicoMedicamentos
  );

  const currentUser = useSelector((state) => state.auth.user);

  const searchRepId =
    currentUser.usu_ch_tipo === 'R' ? currentUser.usu_in_codigo : null;

  const medicamentos = results.map((result) => {
    const isLinked = linksMedicoMedicamentos.find((link) => {
      // ativo
      if (result.codigo === link.atv_in_codigo && result.tipo === 'ATIVO')
        return true;

      // formula
      if (result.codigo === link.for_in_codigo && result.tipo === 'FORMULA')
        return true;

      // industrializado
      if (
        result.codigo === link.med_in_codigo &&
        result.tipo === 'INDUSTRIALIZADO'
      )
        return true;

      return false;
    });

    if (isLinked) return { ...result, linked: true };
    else return result;
  });

  const [isFirst, setFirst] = useState(true);
  const [isLoading, setLoading] = useState(true);

  const [step, setStep] = useState(1);

  const farmaciaId = localStorage.getItem('FORMULARIO_FARMACIA_ID');

  useEffect(() => {
    if (currentUser.usu_ch_tipo === 'G')
      dispatch(fetchAllMedicosByFarmacia(farmaciaId));
    if (currentUser.usu_ch_tipo === 'R')
      dispatch(
        fetchAllMedicosByFarmaciaAndReprentante(
          farmaciaId,
          currentUser.usu_in_codigo
        )
      );

    return () => dispatch(clearAll());
  }, []);

  // loading medicos
  useEffect(() => {
    if (isFirst) setFirst(false);
    else setLoading(false);
  }, [results, allMedicos]);

  function handleSelectMedico(medico) {
    setLoading(true);

    dispatch([
      loading(true),
      setSelectedMedico(medico),
      searchMedicamentos(
        farmaciaId,
        { ref: '', activeOnly: true, excludeExclusive: false },
        { page: 1, itemsPerPage },
        searchRepId
      ),
      fetchLinksMedicoMedicamentos(farmaciaId, medico.usu_in_codigo),
    ]);

    setStep(2);
  }

  async function handleLinkMedicamento(medicamento) {
    dispatch(loading(true));

    if (medicamento.tipo === 'ATIVO') {
      await linkAtivoToMedico(
        farmaciaId,
        selectedMedico.usu_in_codigo,
        medicamento.codigo
      );
    }

    if (medicamento.tipo === 'FORMULA') {
      await linkFormulaMedico(
        farmaciaId,
        selectedMedico.usu_in_codigo,
        medicamento.codigo
      );
    }

    if (medicamento.tipo === 'INDUSTRIALIZADO') {
      await linkIndustrializadoToMedico(
        farmaciaId,
        selectedMedico.usu_in_codigo,
        medicamento.codigo
      );
    }

    dispatch(
      fetchLinksMedicoMedicamentos(farmaciaId, selectedMedico.usu_in_codigo)
    );
  }

  async function handleUnlinkMedicamento(medicamento) {
    dispatch(loading(true));

    if (medicamento.tipo === 'ATIVO') {
      await unlinkAtivoMedico(
        farmaciaId,
        selectedMedico.usu_in_codigo,
        medicamento.codigo
      );
    }

    if (medicamento.tipo === 'FORMULA') {
      await unlinkFormulaMedico(
        farmaciaId,
        selectedMedico.usu_in_codigo,
        medicamento.codigo
      );
    }

    if (medicamento.tipo === 'INDUSTRIALIZADO') {
      await unlinkIndustrializadoMedico(
        farmaciaId,
        selectedMedico.usu_in_codigo,
        medicamento.codigo
      );
    }

    dispatch(
      fetchLinksMedicoMedicamentos(farmaciaId, selectedMedico.usu_in_codigo)
    );
  }

  function handlePaginate(page) {
    setLoading(true);

    dispatch([
      setPage(page),
      searchMedicamentos(
        farmaciaId,
        { ref, tipo, activeOnly: true, excludeExclusive: true },
        { page, itemsPerPage },
        searchRepId
      ),
    ]);
  }

  function handleSearch({ ref, tipo }) {
    setLoading(true);

    dispatch([
      setPage(1),
      searchMedicamentos(
        farmaciaId,
        { ref, tipo, activeOnly: true, excludeExclusive: true },
        { page: 1, itemsPerPage },
        searchRepId
      ),
      setRef(ref),
    ]);
  }

  function handleClearSearch() {
    setLoading(true);

    dispatch([
      setPage(1),
      searchMedicamentos(
        farmaciaId,
        { ref: '', activeOnly: true, excludeExclusive: true },
        { page: 1, itemsPerPage },
        searchRepId
      ),
      setRef(''),
      setTipo(null),
    ]);
  }

  async function handleLinkAllMedicamentos() {
    setLoading(true);

    try {
      await linkMultipleToMedicoByFilter(
        farmaciaId,
        selectedMedico.usu_in_codigo,
        ref,
        tipo
      );

      toast.info(
        'Sua requisição foi enviada e deve finalizar dentro de alguns minutos...'
      );

      history.push('/');
    } catch (e) {
      console.log('Erro ao vincular múltiplos medicamentos ao médico', e);
    } finally {
      setLoading(false);
    }
  }

  function ButtonLinkAll() {
    return (
      <Button
        color='secondary'
        size='sm'
        handleclick={handleLinkAllMedicamentos}
      >
        Vincular todos
      </Button>
    );
  }

  function goBack() {
    if (step === 2) setStep(1);
    if (step === 1) history.push('/');
  }

  return (
    <div className='link-container'>
      <Title
        onClick={goBack}
        component={step === 2 ? () => <ButtonLinkAll /> : null}
      >
        Vinculo médico - medicamento
      </Title>

      {
        {
          1: (
            <div>
              <p>Selecione o médico:</p>

              {isLoading ? (
                <Skeleton count={3} height={60} width={200} />
              ) : (
                // man
                <MedicoList
                  medicos={allMedicos}
                  onSelect={handleSelectMedico}
                />
              )}
            </div>
          ),

          2: (
            <div>
              <MedicamentoSearch
                onSubmit={handleSearch}
                onClear={handleClearSearch}
              />

              {isLoading ? (
                <Skeleton count={3} height={60} width={200} />
              ) : (
                <MedicamentoList
                  results={medicamentos}
                  onLinkMedicamento={handleLinkMedicamento}
                  onUnlinkMedicamento={handleUnlinkMedicamento}
                />
              )}

              {medicamentos.length === 0 ? null : (
                <Pagination
                  page={page}
                  itemsPerPage={itemsPerPage}
                  totalItems={totalItems}
                  paginate={handlePaginate}
                />
              )}
            </div>
          ),
        }[step]
      }
    </div>
  );
}
