import { nop } from './baseActions'
import { findAll, findAllByFarmacia, remove } from '../services/EspecialidadeService'
import { setLoading } from './loadingActions'

const setEspecialidades = unidades => {
  return {
    type: 'ESPECIALIDADE_LIST_SET_ESPECIALIDADES',
    payload: unidades
  }
}

export const clearEspecialidades = () => {
  return {
    type: 'ESPECIALIDADE_LIST_CLEAR_ALL'
  }
}

export const fetchAllEspecialidades = (farmaciaId) => {
  return async dispatch => {
    try {
      const unidades = await findAllByFarmacia(farmaciaId);

      return dispatch(setEspecialidades(unidades));
    } catch (e) {
      return dispatch(nop());
    }
  }
}

export const removeEspecialidade = (farmaciaId, unidade) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));

      await remove(unidade);

      const unidades = await findAll(farmaciaId);

      return dispatch([
        setEspecialidades(unidades),
        setLoading(false)
      ]);
    } catch (e) {
      return dispatch(nop());
    }
  }
}
