import React from 'react';
import './styles.css';
import PropTypes from 'prop-types';

import Modal from '../.././../../components/Modal';
import ButtonGroup, { Button } from '../../../../components/ButtonGroup';

function ModalLink({
  formula,
  doseMinima,
  doseMaxima,
  doseDiaria,
  dosePrescrita,
  setDosePrescrita,
  uso,
  setUso,
  ativo,
  onClick: handleClick,
  onCancelarClick: handleCancelarClick,
}) {
  return (
    <Modal
      id='modalVincular'
      btnText='Vincular'
      title='Vincular esta fórmula ao ativo'
      handleCancelarClick={handleCancelarClick}
      handleClick={handleClick}
    >
      <p className='mb-4'>
        Vincular <b>{formula && formula.for_st_nome}</b> ao ativo?
      </p>

      <div className='d-flex justify-content-center mb-4'>
        <ButtonGroup>
          {ativo?.atv_st_uso === 'ORAL' ? null : (
            <Button
              radio
              name='uso'
              id='TOPICO'
              checked={uso === 'TOPICO'}
              disabled={ativo?.atv_st_uso === 'ORAL'}
              onChange={(e) => setUso(e.target.checked ? 'TOPICO' : '')}
            >
              TÓPICO
            </Button>
          )}

          {ativo?.atv_st_uso === 'TOPICO' ? null : (
            <Button
              radio
              name='uso'
              id='ORAL'
              checked={uso === 'ORAL'}
              disabled={ativo?.atv_st_uso === 'TOPICO'}
              onChange={(e) => setUso(e.target.checked ? 'ORAL' : '')}
            >
              ORAL
            </Button>
          )}
        </ButtonGroup>
      </div>

      <div className='form-group mb-2'>
        <label htmlFor='doseMinima'>Concentração mínima</label>

        <input
          className='form-control'
          id='doseMinima'
          name='doseMinima'
          placeholder='Concentração mínima'
          value={doseMinima}
          disabled
        />
      </div>

      <div className='form-group mb-2'>
        <label htmlFor='doseMaxima'>Concentração máxima</label>

        <input
          className='form-control mb-2'
          id='doseMaxima'
          name='doseMaxima'
          placeholder='Concentração máxima'
          value={doseMaxima}
          disabled
        />
      </div>

      <div className='form-group mb-2'>
        <label htmlFor='doseDiaria'>Concentração usual</label>

        <input
          className='form-control mb-2'
          id='doseDiaria'
          name='doseDiaria'
          placeholder='Concentração usual'
          value={doseDiaria}
          disabled
        />
      </div>

      <div className='form-group mb-2'>
        <label htmlFor='dosePrescrita'>Concentração prescrita</label>

        <input
          className='form-control'
          id='dosePrescrita'
          name='dosePrescrita'
          placeholder='Concentração prescrita'
          value={dosePrescrita}
          onChange={(e) => setDosePrescrita(e.target.value)}
        />
      </div>
    </Modal>
  );
}

ModalLink.propTypes = {
  formula: PropTypes.object.isRequired,
  doseMinima: PropTypes.object.isRequired,
  doseMaxima: PropTypes.object.isRequired,
  doseDiaria: PropTypes.object.isRequired,
  dosePrescrita: PropTypes.object.isRequired,
  setDosePrescrita: PropTypes.func.isRequired,
  uso: PropTypes.string.isRequired,
  setUso: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  onCancelarClick: PropTypes.func,
};

export default ModalLink;
