import { nop } from './baseActions'
import { findAllMedicosByFarmacia } from '../services/FindMedicosService'

const setMedicos = medicos => {
  return {
    type: 'BUILD_USER_PROFILE_SET_MEDICOS',
    payload: medicos
  }
}

export const clearAll = () => {
  return {
    type: 'BUILD_USER_PROFILE_CLEAR_ALL',
  }
}

export const fetchMedicosByFarmacia = farmaciaId => {
  return async dispatch => {
    try {
      const medicos = await findAllMedicosByFarmacia(farmaciaId);

      return dispatch(setMedicos(medicos));
    } catch (e) {
      return dispatch(nop());
    }
  }
}
