const INITIAL_STATE = {
  usuario: null
}

export default function newPasswordReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'NEW_PASSWORD_SET_USER':
      return {
        ...state,
        usuario: action.payload
      }
    case 'NEW_PASSWORD_CLEAR_USER':
      return {
        ...state,
        ...INITIAL_STATE
      }
    default: 
      return state;
  }
}
