import { nop } from './baseActions'
import { toast } from 'react-toastify'
import { findAtivosVeiculosUtilizacao } from '../services/AtivoVeiculoService'
import { searchByRef } from '../services/SearchVeiculoUtilizacaoService'

const setVeiculosUtilizacao = data => {
  return {
    type: 'LINK_ATIVO_VEICULO_SET_VEICULOS_UTILIZACAO',
    payload: data
  }
}

const setAtivosVeiculosUtilizacao = data => {
  return {
    type: 'LINK_ATIVO_VEICULO_SET_ATIVOS_VEICULOS',
    payload: data
  }
}

export const clearAtivosVeiculosUtilizacao = () => {
  return {
    type: 'LINK_ATIVO_VEICULO_CLEAR_ATIVOS_VEICULOS'
  }
}

export const fetchVeiculosUtilizacaoByFarmacia = (farmaciaId, ref, tipo) => {
  return async dispatch => {
    try {
      const data = await searchByRef(farmaciaId, ref, tipo);

      return dispatch(setVeiculosUtilizacao(data));
    } catch(e) {
      toast.error("Erro ao buscar os veículos de utilização");
      return dispatch(nop());
    }
  }
}

export const fetchAllLinksAtivoVeiculoUtilizacao = (farmaciaId, ativoId) => {
  return async dispatch => {
    try {
      const data = await findAtivosVeiculosUtilizacao(farmaciaId, ativoId);

      return dispatch(setAtivosVeiculosUtilizacao(data));
    } catch (e) {
      toast.error("Erro ao buscar os vínculos entre ativos e veículos de utilização");
      return dispatch(nop());
    }
  }
}
