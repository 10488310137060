import React, { useState, Fragment } from 'react';
import './styles.css';
import PropTypes from 'prop-types';

import Table, {
  Thead as TableHeader,
  TableRow,
  Tbody,
  TbodyCol as TbCol,
} from '../../../components/Table';
import Modal from '../../../components/Modal';
import ModalButton from '../../../components/ModalButton';
import Button from '../../../components/Button';
import { MdDelete, MdEdit } from 'react-icons/md';
import { insertIf } from '../../../utils/arrays';

function renderMedicos(medicosFormulas, handleRemoveLinkFormulaMedico) {
  if (medicosFormulas.length > 0)
    return (
      <>
        <p>
          <span className='font-weight-bold'>Médicos</span>
        </p>
        <ul className='list-group list-group-flush'>
          {medicosFormulas.map((medicoFormula, i) => (
            <li
              key={`medico-${i}`}
              className='list-group-item d-flex justify-content-between'
            >
              {medicoFormula.usuario.usu_st_nome}

              <Button
                size='sm'
                color='danger'
                handleclick={() => handleRemoveLinkFormulaMedico(medicoFormula)}
              >
                <MdDelete size={18} />
              </Button>
            </li>
          ))}
        </ul>
      </>
    );

  return <p>Nenhum médico vinculado...</p>;
}

function FormulaList({
  formulas,
  readonly,
  onDelete: handleDelete,
  onEdit: handleEdit,
  onRemoveLinkFormulaMedico: handleRemoveLinkFormulaMedico,
}) {
  const [formula, setFormula] = useState(null);

  const columns = [
    'Nome',
    'Posologia',
    'Observação',
    ...insertIf(!readonly, 'Ação'),
  ];

  return formulas.length > 0 ? (
    <div>
      <Table card>
        <TableHeader dark columns={columns} />

        <Tbody>
          {formulas.map((formula, index) => (
            <Fragment key={`formula-row-${index}`}>
              <TableRow
                className='clickable-row upper'
                data-toggle='collapse'
                data-target={`#table-row-${index}`}
              >
                <TbCol alignMiddle>{formula.for_st_nome}</TbCol>
                <TbCol alignMiddle label='Posologia'>
                  {formula.for_st_posologia || '-'}
                </TbCol>
                <TbCol alignMiddle label='Observação'>
                  {formula.for_st_observacao || '-'}
                </TbCol>
                {readonly ? null : (
                  <TbCol alignMiddle label='Ação'>
                    <ModalButton
                      target='#modalDeleteFormula'
                      color='danger'
                      size='sm'
                      handleclick={() => setFormula(formula)}
                    >
                      <MdDelete size={24} />
                    </ModalButton>

                    <Button
                      className='ml-1'
                      color='warning'
                      size='sm'
                      handleclick={() => handleEdit(formula)}
                    >
                      <MdEdit size={24} />
                    </Button>
                  </TbCol>
                )}
              </TableRow>

              {/* Hidden row */}
              <TableRow className='upper'>
                <td colSpan={columns.length} className='hidden-row text-start'>
                  <div
                    id={`table-row-${index}`}
                    className='accordian-body collapse p-1'
                  >
                    {renderMedicos(
                      formula.medicos_formulas,
                      handleRemoveLinkFormulaMedico
                    )}
                  </div>
                </td>
              </TableRow>
            </Fragment>
          ))}
        </Tbody>
      </Table>

      <Modal
        id='modalDeleteFormula'
        handleClick={() => handleDelete(formula)}
        handleCancelarClick={() => setFormula(null)}
        btnColor='danger'
        size='sm'
        text={`Você tem certeza de que deseja remover esta fórmula? ${
          formula?.formula_ativos?.length > 0
            ? 'OBS: Existem ativos vinculados, deseja continuar?'
            : ''
        }`}
        title='Remover fórmula'
      />
    </div>
  ) : (
    <p className='not-found'>Nenhuma fórmula encontrada</p>
  );
}

FormulaList.propTypes = {
  formulas: PropTypes.array.isRequired,
  readonly: PropTypes.bool,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onRemoveLinkFormulaMedico: PropTypes.func,
};

FormulaList.defaultProps = {
  readonly: false,
};

export default FormulaList;
