const INITIAL_STATE = {
  ativos: []
}

export default function ativosPendentesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'ATIVOS_PENDENTES_SET_ATIVOS':
      return {
        ...state,
        ativos: action.payload
      }
    case 'ATIVOS_PENDENTES_CLEAR_ATIVOS':
      return {
        ...state,
        ...INITIAL_STATE
      }
    default:
      return state
  }
}
