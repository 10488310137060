import React, { useState, useEffect } from 'react';
import './styles.css';

import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchAllUnidades,
  removeUnidade as deleteUnidade,
  clearUnidades,
} from '../../stores/unidadeListActions';
import Title from '../../components/Title';
import ButtonAdd from '../../components/ButtonAdd';
import Skeleton from 'react-loading-skeleton';
import Row from '../../components/Row';
import Grid from '../../components/Grid';
import UnidadeList from './UnidadeList';

export default function UnidadeListContainer() {
  const [isFirst, setFirst] = useState(true);
  const [isLoading, setLoading] = useState(true);

  const history = useHistory();
  const dispatch = useDispatch();

  const unidades = useSelector((state) => state.unidade.list.unidades);

  const farmaciaId = localStorage.getItem('FORMULARIO_FARMACIA_ID');

  useEffect(() => {
    dispatch(fetchAllUnidades(farmaciaId));

    return () => dispatch(clearUnidades());
  }, []);

  useEffect(() => {
    if (!isFirst) setLoading(false);
    else setFirst(false);
  }, [unidades]);

  function removeUnidade(unidade) {
    dispatch(deleteUnidade(farmaciaId, unidade));
  }

  function editUnidade(unidade) {
    history.push(`/unidades/${unidade.uni_in_codigo}/edit`);
  }

  return (
    <div className='unidade-container'>
      <Title
        link='/'
        component={() => (
          <ButtonAdd onClick={() => history.push('/unidades/new')} />
        )}
      >
        Listagem de unidades
      </Title>

      <Row>
        <Grid cols='12'>
          {isLoading ? (
            <Skeleton count={5} height={30} />
          ) : (
            <UnidadeList
              unidades={unidades}
              onDelete={removeUnidade}
              onEdit={editUnidade}
            />
          )}
        </Grid>
      </Row>
    </div>
  );
}
