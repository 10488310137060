const INITIAL_STATE = {
  vias: []
}

export default function viaRedcuer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SET_VIAS':
      return {
        ...state,
        vias: action.payload
      }
    default:
      return state
  }
}
