import React from 'react'
import './styles.css'
import PropTypes from 'prop-types'

function TbodyCol({ children, label, alignMiddle, alignStart, alignItemsCenter, flex, colspan,
  onClick: handleClick }) {
  let className = '';

  if (alignMiddle) className = className.concat('align-middle ');
  if (alignStart) className = className.concat('text-start ');
  if (flex) className = className.concat('d-flex ');
  if (alignItemsCenter) className = className.concat('align-items-center ');
  if (handleClick) className = className.concat('cursor-pointer ');

  return (
    <td data-label={label} className={className}
      colSpan={colspan} onClick={handleClick}>{children}</td>
  )
}

TbodyCol.propTypes = {
  children: PropTypes.any,
  label: PropTypes.string,
  alignMiddle: PropTypes.bool,
  alignStart: PropTypes.bool,
  alignItemsCenter: PropTypes.bool,
  flex: PropTypes.bool,
  colspan: PropTypes.number,
  onClick: PropTypes.func,
}

export default TbodyCol;
