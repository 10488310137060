import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchAllModelos,
  removeModelo as deleteModelo,
  clearAll,
} from '../../stores/receitaModeloListActions';
import Title from '../../components/Title';
import ButtonAdd from '../../components/ButtonAdd';
import Skeleton from 'react-loading-skeleton';
import Row from '../../components/Row';
import Grid from '../../components/Grid';
import ReceitaModeloList from './ReceitaModeloList';

export default function ReceitaModeloListContainer() {
  const [isFirst, setFirst] = useState(true);
  const [isLoading, setLoading] = useState(true);

  const history = useHistory();
  const dispatch = useDispatch();

  const modelos = useSelector((state) => state.receitaModelo.list.modelos);

  const farmaciaId = localStorage.getItem('FORMULARIO_FARMACIA_ID');

  useEffect(() => {
    dispatch(fetchAllModelos(farmaciaId));

    return () => dispatch(clearAll());
  }, []);

  useEffect(() => {
    if (!isFirst) setLoading(false);
    else setFirst(false);
  }, [modelos]);

  function removeModelo(modelo) {
    dispatch(deleteModelo(farmaciaId, modelo));
  }

  function editModelo(modelo) {
    history.push(`/modelos_receita/${modelo.rem_in_codigo}/edit`);
  }

  return (
    <div className='receita-container'>
      <Title
        link='/'
        component={() => (
          <ButtonAdd onClick={() => history.push('/modelos_receita/new')} />
        )}
      >
        Listagem de modelos
      </Title>

      <Row>
        <Grid cols='12'>
          {isLoading ? (
            <Skeleton count={5} height={30} />
          ) : (
            <ReceitaModeloList
              modelos={modelos}
              onDelete={removeModelo}
              onEdit={editModelo}
            />
          )}
        </Grid>
      </Row>
    </div>
  );
}
