import React, { useRef, useState } from 'react';
import './styles.css';
import PropTypes from 'prop-types';

import { Form } from '@unform/web';
import { Input, Checkbox } from '../../../../components/Form';
import Row from '../../../../components/Row';
import Grid from '../../../../components/Grid';
import Button from '../../../../components/Button';
import { MdSearch } from 'react-icons/md';

function SearchMedicamentos({ onSearch: handleSearch }) {
  const formRef = useRef(null);

  const [ref, setRef] = useState('');
  const [startsWith, setStartsWith] = useState(false);

  return (
    <div className='search-container'>
      <Form
        ref={formRef}
        onSubmit={({ ref, startsWith }) => handleSearch(ref, startsWith)}
      >
        <Row>
          <Grid cols='12'>
            <Checkbox
              id='startsWith'
              name='startsWith'
              label='Iniciar com...'
              checked={startsWith}
              value={startsWith}
              onChange={(e) => setStartsWith(e.target.checked)}
            />
          </Grid>
        </Row>

        <Row>
          <Grid cols='12 10'>
            <Input
              id='ref'
              name='ref'
              value={ref}
              onChange={(e) => setRef(e.target.value)}
              placeholder='Pesquisar medicamentos'
            />
          </Grid>

          <Grid cols='12 2'>
            <Button color='info' type='submit'>
              <MdSearch size={18} />
            </Button>
          </Grid>
        </Row>
      </Form>

      <hr className='divider' />
    </div>
  );
}

SearchMedicamentos.propTypes = {
  onSearch: PropTypes.func,
};

export default SearchMedicamentos;
