import React, { useState } from 'react';
import './styles.css';
import PropTypes from 'prop-types';

import Table, {
  Thead as TableHeader,
  TableRow,
  Tbody,
  TbodyCol as TbCol,
} from '../../../components/Table';
import Modal from '../../../components/Modal';
import ModalButton from '../../../components/ModalButton';
import Button from '../../../components/Button';
import { MdDelete, MdEdit } from 'react-icons/md';
import { insertIf } from '../../../utils/arrays';

function VeiculoUtilizacaoList({
  veiculosUtilizacao,
  readonly,
  onDelete: handleDelete,
  onEdit: handleEdit,
}) {
  const [veiculoUtilizacao, setVeiculoUtilizacao] = useState(null);

  return veiculosUtilizacao.length > 0 ? (
    <div>
      <Table card>
        <TableHeader
          dark
          columns={['Descrição', 'Tipo', ...insertIf(!readonly, 'Ação')]}
        />

        <Tbody>
          {veiculosUtilizacao.map((vu) => (
            <TableRow className='upper' key={vu.veu_in_codigo}>
              <TbCol alignMiddle label='Descrição'>
                {vu.veu_st_descricao}
              </TbCol>
              <TbCol alignMiddle label='Tipo'>
                {vu.veu_st_tipo}
              </TbCol>
              {readonly ? null : (
                <TbCol alignMiddle label='Ação'>
                  <ModalButton
                    target='#modalDeleteVeiculoUtilizacao'
                    color='danger'
                    size='sm'
                    handleclick={() => setVeiculoUtilizacao(vu)}
                  >
                    <MdDelete size={24} />
                  </ModalButton>

                  <Button
                    className='ml-1'
                    color='warning'
                    size='sm'
                    handleclick={() => handleEdit(vu)}
                  >
                    <MdEdit size={24} />
                  </Button>
                </TbCol>
              )}
            </TableRow>
          ))}
        </Tbody>
      </Table>

      <Modal
        id='modalDeleteVeiculoUtilizacao'
        handleClick={() => handleDelete(veiculoUtilizacao)}
        handleCancelarClick={() => setVeiculoUtilizacao(null)}
        btnColor='danger'
        size='sm'
        text='Você tem certeza de que deseja remover este veic. utilização/forma farmacêutica?'
        title='Remover veic. utilização/forma farmacêutica'
      />
    </div>
  ) : (
    <p className='not-found'>
      Nenhum veic. utilização/forma farmacêutica encontrado
    </p>
  );
}

VeiculoUtilizacaoList.propTypes = {
  veiculosUtilizacao: PropTypes.array.isRequired,
  readonly: PropTypes.bool,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
};

VeiculoUtilizacaoList.defaultProps = {
  readonly: false,
};

export default VeiculoUtilizacaoList;
