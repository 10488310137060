import React from 'react'
import './styles.css'

import PropTypes from 'prop-types'

function Tbody({ children }) {
  return (
    <tbody>
      {children}
    </tbody>
  )
}

Tbody.propTypes = {
  children: PropTypes.any
}

export default Tbody;
