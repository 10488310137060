import React, { useState } from 'react';
import './styles.css';
import PropTypes from 'prop-types';

import Table, {
  TableRow,
  Tbody,
  TbodyCol,
  Thead,
} from '../../../components/Table';
import { MdAdd, MdFavorite } from 'react-icons/md';
import ModalViewAtivo from '../../AtivosMedicoListContainer/AtivoList/ModalViewAtivo';
import ButtonView from '../../../components/ButtonView';
import ButtonRemove from '../../../components/ButtonRemove';
import ModalButton from '../../../components/ModalButton';
import Media from 'react-media';
import ModalSelectUso from '../../AtivosMedicoListContainer/AtivoList/ModalSelectUso';

function AtivoList({
  ativos,
  onUnfavorite: handleUnfavorite,
  onRemoveAtivo: handleRemoveAtivo,
  onAddAtivoReceita: handleAddAtivoReceita,
}) {
  const [ativo, setAtivo] = useState(null);

  return (
    <div>
      {ativos.length === 0 ? (
        <p>Nenhum ativo encontrado...</p>
      ) : (
        <Table card>
          <Thead
            dark
            columns={[
              'Descrição',
              'Indicação de uso',
              'Uso',
              'Observação',
              '*',
            ]}
          />

          <Tbody>
            {ativos.map((ativo) => (
              <TableRow className='upper' key={ativo.atv_in_codigo}>
                <TbodyCol label='Descrição' alignMiddle>
                  {ativo.atv_st_descricao}
                </TbodyCol>
                <TbodyCol label='Indicação de uso' alignMiddle>
                  {ativo.atv_st_indicacaouso}
                </TbodyCol>
                <TbodyCol label='Uso' alignMiddle>
                  {ativo.atv_st_uso}
                </TbodyCol>
                <TbodyCol label='Observação' alignMiddle>
                  {ativo.atv_st_observacao}
                </TbodyCol>
                <Media query={{ maxWidth: 600 }}>
                  {(matches) => (
                    <TbodyCol
                      alignMiddle
                      flex={!matches}
                      alignItemsCenter={!matches}
                    >
                      <ButtonView
                        target='#modalViewAtivo'
                        onClick={() => setAtivo(ativo)}
                        className='mr-1'
                      />

                      <ButtonRemove
                        className='mr-1'
                        onClick={() => handleRemoveAtivo(ativo)}
                      />

                      <ModalButton
                        target='#modalSelectUso'
                        color='success'
                        size='sm'
                        className='mr-1'
                        handleclick={() => setAtivo(ativo)}
                        title='Adicionar à receita'
                      >
                        <MdAdd size={18} />
                      </ModalButton>

                      <MdFavorite
                        size={24}
                        color='#f03434'
                        className='cursor-pointer'
                        onClick={() => handleUnfavorite(ativo)}
                      />
                    </TbodyCol>
                  )}
                </Media>
              </TableRow>
            ))}
          </Tbody>
        </Table>
      )}

      <ModalViewAtivo id='modalViewAtivo' ativo={ativo} />
      <ModalSelectUso
        id='modalSelectUso'
        ativo={ativo}
        onSelectUso={handleAddAtivoReceita}
      />
    </div>
  );
}

AtivoList.propTypes = {
  ativos: PropTypes.array.isRequired,
  onUnfavorite: PropTypes.func,
  onRemoveAtivo: PropTypes.func,
  onAddAtivoReceita: PropTypes.func,
};

export default AtivoList;
