import api from './api'
import { toast } from 'react-toastify'

export function findById(id, token) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await api.get(`farmacias/${id}`, {
        headers: { authorization: `Bearer ${token}` }
      });

      return resolve(response.data.data);
    } catch (e) {
      toast.error("Falha ao buscar farmácias");
      return reject(e);
    }
  })
}
