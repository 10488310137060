import api from './api';
import { toast } from 'react-toastify'

export function fetchAllLogsMedico(
  farmaciaId,
  medicoId,
  searchFilters = { ref: "" },
  paginationFilters = { page: 1, itemsPerPage: 15 }
) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      const response = await api.get(`farmacias/${farmaciaId}/medicos/${medicoId}/logs`, {
        headers: { authorization: `Bearer ${token}` },
        params: {
          ref: searchFilters.ref,
          page: paginationFilters.page,
          itemsPerPage: paginationFilters.itemsPerPage
        }
      });

      return resolve(response.data);
    } catch (e) {
      if (e.response.data?.error.message) {
        toast.error(e.response.data?.error.message);
        return reject(e);
      }

      toast.error("Falha ao buscar logs dos medicamentos");
      return reject(e);
    }
  })
}
