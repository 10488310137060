import api from './api';
import { toast } from 'react-toastify'

export function findAll(farmaciaId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');
      
      const response = await api.get(`farmacias/${farmaciaId}/usuarios`, {
        headers: { authorization: `bearer ${token}` }
      });

      return resolve(response.data.data);
    } catch (e) {
      return reject(e);
    }
  })
}

export function findById(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      const response = await api.get(`usuarios/${id}`, {
        headers: { authorization: `bearer ${token}` }
      });

      return resolve(response.data.data);
    } catch (e) {
      toast.error('Erro ao buscar usuário');
      return reject(e);
    }
  })
}

export function save(user) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      const response = await api.post('usuarios', user, {
        headers: { authorization: `bearer ${token}` }
      });

      return resolve(response.data.data);
    } catch (e) {
      if(e.response.data.error && e.response.data.error.status === 'EMAIL_ALREADY_REGISTERED') {
        toast.error("Este e-mail já foi cadastrado");
      } else {
        toast.error("Falha ao cadastrar usuário");
      }

      return reject(e);
    }
  })
}

export function update(id, user) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      await api.put(`usuarios/${id}`, user, {
        headers: { authorization: `bearer ${token}` }
      });

      return resolve();
    } catch (e) {
      toast.error("Falha ao salvar usuário");
      return reject(e);
    }
  })
}

export function remove(usuario) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      await api.delete(`usuarios/${usuario.usu_in_codigo}`, {
        headers: { authorization: `bearer ${token}` }
      });

      return resolve();
    } catch (e) {
      toast.error("Falha ao remover usuário");
      return reject(e);
    }
  })
}
