import React, { useState } from 'react';
import './styles.css';
import PropTypes from 'prop-types';

import Table, {
  Thead as TableHeader,
  TableRow,
  Tbody,
  TbodyCol as TbCol,
} from '../../../components/Table';
import { MdSearch, MdEdit, MdDelete } from 'react-icons/md';
import Button from '../../../components/Button';
import ModalButton from '../../../components/ModalButton';
import ModalDelete from './ModalDelete';
import ModalView from './ModalView';
import ModalEdit from './ModalEdit';
import { insertIf } from '../../../utils/arrays';

function AtivoList({
  readonly,
  formulaAtivos,
  hideViewButton,
  modalRemove,
  onRemoveLink: handleRemoveLink,
  onUpdateLink: handleUpdateLink,
  onUpdateConcentracao: handleUpdateConcentracao,
  onLinkButtonClick: handleLinkButtonClick,
}) {
  const [formulaAtivo, setFormulaAtivo] = useState(null);

  return (
    <div>
      <Table>
        <TableHeader
          dark
          columns={[
            'Ativo',
            'Uso',
            'Prescrito',
            ...insertIf(!readonly, 'Ação'),
          ]}
        />

        <Tbody>
          {formulaAtivos.map((fa) => (
            <TableRow className='upper' key={fa.fat_in_codigo}>
              <TbCol alignMiddle label='Ativo'>
                {fa.ativo.atv_st_descricao}
              </TbCol>
              <TbCol alignMiddle label='Uso'>
                {fa.fat_st_tipouso || ' - '}
              </TbCol>
              <TbCol alignMiddle label='Prescrito'>
                {handleUpdateConcentracao ? (
                  <input
                    className='form-control text-center'
                    name='concentracaoPrescrita'
                    value={fa.fat_st_concentracaoprescrita}
                    onChange={(e) =>
                      handleUpdateConcentracao(fa.fat_in_codigo, e.target.value)
                    }
                  />
                ) : (
                  fa.fat_st_concentracaoprescrita
                )}
              </TbCol>
              {readonly ? null : (
                <TbCol alignMiddle>
                  {hideViewButton ? null : (
                    <ModalButton
                      type='button'
                      size='sm'
                      color='info'
                      target='#modalView'
                      handleclick={() => setFormulaAtivo(fa)}
                    >
                      <MdSearch size={18} />
                    </ModalButton>
                  )}

                  {handleUpdateLink ? (
                    <ModalButton
                      type='button'
                      className='ml-1'
                      size='sm'
                      color='warning'
                      target='#modalEdit'
                      handleclick={() => setFormulaAtivo(fa)}
                    >
                      <MdEdit size={18} />
                    </ModalButton>
                  ) : null}

                  {modalRemove ? (
                    <ModalButton
                      type='button'
                      className='ml-1'
                      size='sm'
                      color='danger'
                      target='#modalDelete'
                      handleclick={() => setFormulaAtivo(fa)}
                    >
                      <MdDelete size={18} />
                    </ModalButton>
                  ) : (
                    <Button
                      type='button'
                      className='ml-1'
                      size='sm'
                      color='danger'
                      handleclick={() =>
                        handleRemoveLink(fa.ativo.atv_in_codigo)
                      }
                    >
                      <MdDelete size={18} />
                    </Button>
                  )}
                </TbCol>
              )}
            </TableRow>
          ))}

          {handleLinkButtonClick ? (
            <TableRow>
              <TbCol colspan={3} />
              {readonly ? null : (
                <TbCol>
                  <Button
                    color='secondary'
                    size='sm'
                    handleclick={handleLinkButtonClick}
                  >
                    Vincular ativos
                  </Button>
                </TbCol>
              )}
            </TableRow>
          ) : null}
        </Tbody>
      </Table>

      <ModalDelete
        onRemoveLink={() => handleRemoveLink(formulaAtivo.atv_in_codigo)}
        onCancelarClick={() => setFormulaAtivo(null)}
      />

      <ModalView
        formulaAtivo={formulaAtivo}
        onClick={() => setFormulaAtivo(null)}
        onCancelarClick={() => setFormulaAtivo(null)}
      />

      {handleUpdateLink ? (
        <ModalEdit
          formulaAtivo={formulaAtivo}
          onSubmit={handleUpdateLink}
          onCancelarClick={() => setFormulaAtivo(null)}
        />
      ) : null}
    </div>
  );
}

AtivoList.propTypes = {
  readonly: PropTypes.bool,
  formulaAtivos: PropTypes.array,
  /** Hide the view button */
  hideViewButton: PropTypes.bool,
  onRemoveLink: PropTypes.func,
  /** 'Editar' button action | if false, then hide the button */
  onUpdateLink: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  /** 'Vincular Ativos' button action | if false, then hide the button */
  onLinkButtonClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  /** Open confirmation modal before remove? Default is true */
  modalRemove: PropTypes.bool,
  /** Function to update concentração prescrita */
  onUpdateConcentracao: PropTypes.func,
};

AtivoList.defaultProps = {
  onUpdateLink: false,
  onLinkButtonClick: false,
  modalRemove: true,
};

export default AtivoList;
