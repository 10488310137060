import React, { useRef, useState } from 'react';
import './styles.css';

import PropTypes from 'prop-types';
import { Form } from '@unform/web';
import Input from '../../../components/Form/input';
import Button from '../../../components/Button';
import { MdClear, MdSearch } from 'react-icons/md';
import Row from '../../../components/Row';
import Grid from '../../../components/Grid';

function ReceitaSearch({
  onSubmit: handleSubmit,
  onClear: handleClear,
  placeholder,
}) {
  const formRef = useRef(null);

  const [ref, setRef] = useState('');
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');

  function resetForm() {
    setFrom('');
    setTo('');
    setRef('');
  }

  function submitForm({ ref, from, to }) {
    handleSubmit({ ref, from, to });
  }

  return (
    <div className='search-container'>
      <Form ref={formRef} onSubmit={submitForm}>
        <Row>
          <Grid cols='12 6'>
            <Input
              id='from'
              type='date'
              name='from'
              value={from}
              onChange={(e) => setFrom(e.target.value)}
            />
          </Grid>
          <Grid cols='12 6'>
            <Input
              id='to'
              type='date'
              name='to'
              value={to}
              onChange={(e) => setTo(e.target.value)}
            />
          </Grid>
        </Row>

        <Row>
          <Grid cols='12 10'>
            <Input
              id='ref'
              name='ref'
              value={ref}
              onChange={(e) => setRef(e.target.value)}
              placeholder={placeholder}
            />
          </Grid>

          <Grid cols='12 2'>
            <Button color='info' type='submit'>
              <MdSearch size={18} />
            </Button>

            <Button
              type='button'
              className='ml-2'
              color='info'
              handleclick={() => {
                handleClear();
                resetForm();
              }}
            >
              <MdClear size={18} />
            </Button>
          </Grid>
        </Row>
      </Form>

      <hr className='divider' />
    </div>
  );
}

ReceitaSearch.defaultProps = {
  placeholder: 'Pesquise por paciente, médico ou medicamento',
};

ReceitaSearch.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClear: PropTypes.func,
  placeholder: PropTypes.string,
};

export default ReceitaSearch;
