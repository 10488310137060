import api from './api';
import { toast } from 'react-toastify';

//ATIVOS
export function findEspecialidadesByAtivo(farmaciaId, ativoId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      const response = await api.get(
        `farmacias/${farmaciaId}/especialidades/${ativoId}/ativos/`,
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );

      return resolve(response.data.data);
    } catch (e) {
      toast.error('Falha ao buscar especialidades vinculadas ao médico');
      return reject(e);
    }
  });
}

export function linkEspecialidadeToAtivo(farmaciaId, ativoId, especId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      await api.post(
        `farmacias/${farmaciaId}/especialidades/${ativoId}/ativos/${especId}`,
        {},
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );

      return resolve();
    } catch (e) {
      toast.error('Falha ao vincular especialidade ao médico');
      return reject(e);
    }
  });
}

export function unlinkEspecialidadeAtivo(farmaciaId, ativoId, especId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      await api.delete(
        `farmacias/${farmaciaId}/especialidades/${ativoId}/ativos/${especId}`,
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );

      return resolve();
    } catch (e) {
      toast.error('Falha ao desvincular especialidade do médico');
      return reject(e);
    }
  });
}

//INDUSTRIALIZADOS
export function findEspecialidadesByIndustrializado(
  farmaciaId,
  industrializadoId
) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      const response = await api.get(
        `farmacias/${farmaciaId}/especialidades/${industrializadoId}/industrializados/`,
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );

      return resolve(response.data.data);
    } catch (e) {
      toast.error('Falha ao buscar especialidades vinculadas ao médico');
      return reject(e);
    }
  });
}

export function linkEspecialidadeToIndustrializado(
  farmaciaId,
  industrializadoId,
  especId
) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      await api.post(
        `farmacias/${farmaciaId}/especialidades/${industrializadoId}/industrializados/${especId}`,
        {},
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );

      return resolve();
    } catch (e) {
      toast.error('Falha ao vincular especialidade ao médico');
      return reject(e);
    }
  });
}

export function unlinkEspecialidadeIndustrializado(
  farmaciaId,
  industrializadoId,
  especId
) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      await api.delete(
        `farmacias/${farmaciaId}/especialidades/${industrializadoId}/industrializados/${especId}`,
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );

      return resolve();
    } catch (e) {
      toast.error('Falha ao desvincular especialidade do médico');
      return reject(e);
    }
  });
}

//FORMULAS
export function findEspecialidadesByFormula(farmaciaId, formulaId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      const response = await api.get(
        `farmacias/${farmaciaId}/especialidades/${formulaId}/industrializados/`,
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );

      return resolve(response.data.data);
    } catch (e) {
      toast.error('Falha ao buscar especialidades vinculadas ao médico');
      return reject(e);
    }
  });
}

export function linkEspecialidadeToFormula(farmaciaId, formulaId, especId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      await api.post(
        `farmacias/${farmaciaId}/especialidades/${formulaId}/formulas/${especId}`,
        {},
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );

      return resolve();
    } catch (e) {
      toast.error('Falha ao vincular especialidade ao médico');
      return reject(e);
    }
  });
}

export function unlinkEspecialidadeFormula(farmaciaId, formulaId, especId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      await api.delete(
        `farmacias/${farmaciaId}/especialidades/${formulaId}/formulas/${especId}`,
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );

      return resolve();
    } catch (e) {
      toast.error('Falha ao desvincular especialidade do médico');
      return reject(e);
    }
  });
}
