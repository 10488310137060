const INITIAL_STATE = {
  industrializados: []
}

export default function medicamentosPendentesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'INDUSTRIALIZADOS_PENDENTES_SET_INDUSTRIALIZADOS':
      return {
        ...state,
        industrializados: action.payload
      }
    case 'INDUSTRIALIZADOS_PENDENTES_CLEAR_INDUSTRIALIZADOS':
      return {
        ...state,
        ...INITIAL_STATE
      }
    default:
      return state
  }
}
