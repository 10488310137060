import React from 'react';
import PropTypes from 'prop-types';

// import Button from '@bit/ericktrettel.franchini.button'
import Button from '../../components/Button';

function CloseModalButton({ children, ...props }) {
  return (
    <Button {...props} data-dismiss='modal'>
      {children}
    </Button>
  );
}

CloseModalButton.propTypes = {
  children: PropTypes.any.isRequired,
};

export default CloseModalButton;
