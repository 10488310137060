import React from 'react'
import './styles.css'
import PropTypes from 'prop-types'

function Badge({ children, color }) {
  return <span className={`badge badge-${color}`}>{children}</span>
}

Badge.propTypes = {
  children: PropTypes.any.isRequired,
  color: PropTypes.string,
}

Badge.defaultProps = {
  color: 'secondary'
}

export default Badge;
