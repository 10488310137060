import api from './api';
import { toast } from 'react-toastify'

export function findAll() {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');
      
      const response = await api.get("pacientes", {
        headers: { authorization: `bearer ${token}` }
      });

      return resolve(response.data.data);
    } catch (e) {
      toast.error('Erro ao buscar pacientes');
      return reject(e);
    }
  })
}

export function findById(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      const response = await api.get(`pacientes/${id}`, {
        headers: { authorization: `bearer ${token}` }
      });

      return resolve(response.data.data);
    } catch (e) {
      toast.error('Erro ao buscar paciente');
      return reject(e);
    }
  })
}

export function findAllByMedico(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      const response = await api.get(`pacientes/medico/${id}`, {
        headers: { authorization: `bearer ${token}` }
      });

      return resolve(response.data.data);
    } catch (e) {
      toast.error('Erro ao buscar pacientes');
      return reject(e);
    }
  })
}

export function save(paciente) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      const response = await api.post('pacientes', paciente, {
        headers: { authorization: `bearer ${token}` }
      });

      return resolve(response.data.data);
    } catch (e) {
      if(e.response.data.error && e.response.data.error.status === 'EMAIL_ALREADY_REGISTERED') {
        toast.error("Este e-mail já foi cadastrado");
      } else {
        toast.error("Falha ao cadastrar paciente");
      }

      return reject(e);
    }
  })
}

export function update(id, paciente) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      await api.put(`pacientes/${id}`, paciente, {
        headers: { authorization: `bearer ${token}` }
      });

      return resolve();
    } catch (e) {
      toast.error("Falha ao salvar paciente");
      return reject(e);
    }
  })
}

export function remove(paciente) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      await api.delete(`pacientes/${paciente.pac_in_codigo}`, {
        headers: { authorization: `bearer ${token}` }
      });

      return resolve();
    } catch (e) {
      toast.error("Falha ao remover paciente");
      return reject(e);
    }
  })
}
