import { findAll } from "../services/ViaService";
import { toast } from "react-toastify";

const setVias = (vias) => {
  return {
    type: "SET_VIAS",
    payload: vias,
  };
};

export const getVias = () => {
  return async (dispatch) => {
    try {
      const vias = await findAll();

      return dispatch(setVias(vias));
    } catch (e) {
      console.log("action vias error: ", e);
      toast.error("Erro ao buscar vias");
    }
  };
};
