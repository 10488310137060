import React, { useState, useEffect } from 'react'
import './styles.css'

import AtivoList from './AtivoList'
import Title from '../../components/Title'
import Skeleton from 'react-loading-skeleton'
import { fetchAtivosPendentes, clearAtivos } from '../../stores/ativosPendentesActions'
import { useSelector, useDispatch } from 'react-redux'
import { validateAtivo } from '../../services/MedicamentosPendentesService'

export default function AtivosPendentesListContainer() {
  const [isFirst, setFirst] = useState(true);
  const [isLoading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const ativos = useSelector(state => state.ativo.pendentes.ativos);

  const farmaciaId = localStorage.getItem('FORMULARIO_FARMACIA_ID');

  // fetch all ativos pendentes
  useEffect(() => {
    dispatch(fetchAtivosPendentes(farmaciaId));

    return () => dispatch(clearAtivos());
  }, []);

  // set loading
  useEffect(() => {
    if (isFirst) setFirst(false);
    else setLoading(false);
  }, [ativos]);

  async function handleValidate(ativo) {
    setLoading(true);

    await validateAtivo(farmaciaId, ativo.atv_in_codigo);

    dispatch(fetchAtivosPendentes(farmaciaId));
  }

  return (
    <div className="ativo-container">
      <Title link="/ativos">Ativos pendentes</Title>

      {isLoading
        ? <Skeleton count={5} height={30} />
        : <AtivoList ativos={ativos}
          onValidate={handleValidate} />}
    </div>
  )
}
