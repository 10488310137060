import { nop } from './baseActions'
import { findFavoriteFormulas } from '../services/FavoriteFormulasService'
import { unlinkFormulaMedico } from '../services/LinkMedicoToMedicamentoService'

const setFormulas = formulas => {
  return {
    type: 'FAVORITE_FORMULAS_SET_FORMULAS',
    payload: formulas
  }
}

export const setPage = page => {
  return {
    type: 'FAVORITE_FORMULAS_SET_PAGE',
    payload: page
  }
}

export const setItemsPerPage = itemsPerPage => {
  return {
    type: 'FAVORITE_FORMULAS_SET_ITEMS_PER_PAGE',
    payload: itemsPerPage
  }
}

const setTotalItems = totalItems => {
  return {
    type: 'FAVORITE_FORMULAS_SET_TOTAL_ITEMS',
    payload: totalItems
  }
}

export const setRef = ref => {
  return {
    type: 'FAVORITE_FORMULAS_SET_REF',
    payload: ref
  }
}

export const clearAll = () => {
  return {
    type: 'FAVORITE_FORMULAS_CLEAR_ALL'
  }
}

export const fetchMedicoFavoriteFormulas = (farmaciaId, usuarioId, page = 1, itemsPerPage = 15, ref = '') => {
  return async dispatch => {
    try {
      const response = await findFavoriteFormulas(farmaciaId, usuarioId, page, itemsPerPage, ref);

      return dispatch([
        setFormulas(response.data),
        setTotalItems(response["_metadata"].totalItems)
      ]);
    } catch (e) {
      return dispatch(nop());
    }
  }
}

export const removeLinkFormulaMedico = (farmaciaId, usuarioId, formulaId) => {
  return async (dispatch, getState) => {
    try {
      await unlinkFormulaMedico(farmaciaId, usuarioId, formulaId);

      const { pagination, search } = getState().medico.formula.list;

      return dispatch(fetchMedicoFavoriteFormulas(
        farmaciaId,
        usuarioId,
        pagination.page,
        pagination.itemsPerPage,
        search.ref,
      ));
    } catch (e) {
      return dispatch(nop());
    }
  }
}
