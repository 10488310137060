import React from 'react'
import './styles.css'
import PropTypes from 'prop-types'

function Table({ children, fixed, card, borderless, small, headerSm, hoverable }) {
  let tableClasses = 'table table-fluid table-striped text-center ';
  if (fixed) tableClasses = tableClasses.concat('table-fixed ');
  if (borderless) tableClasses = tableClasses.concat('table-borderless ');
  if (hoverable) tableClasses = tableClasses.concat('table-hover ');
  if (headerSm || small) tableClasses = tableClasses.concat('table-sm ');

  let containerClasses = 'table-container-responsive ';
  if (small) containerClasses = containerClasses.concat('table-small ');

  return (
    <div id={card ? 'card' : null} className={containerClasses}>
      <table className={tableClasses}>
        {children}
      </table>
    </div>
  )
}

Table.propTypes = {
  children: PropTypes.any.isRequired,
  fixed: PropTypes.bool,
  card: PropTypes.bool,
  borderless: PropTypes.bool,
  small: PropTypes.bool,
  hoverable: PropTypes.bool,
  headerSm: PropTypes.bool,
}

export default Table;
