import React from 'react';
import PropTypes from 'prop-types';

import Button from '../Button';
import { MdDelete } from 'react-icons/md';

import './styles.css';

function ButtonRemove({ onClick: handleClick, className, ...props }) {
  return (
    <Button
      color='danger'
      size='sm'
      className={className}
      handleclick={handleClick}
      {...props}
    >
      <MdDelete size={18} />
    </Button>
  );
}

ButtonRemove.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default ButtonRemove;
