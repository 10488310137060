import { nop } from './baseActions'
import { findById } from '../services/UsuarioService'
import { findAll as findAllModelos } from '../services/ReceitaModeloService'

const setUsuario = usuario => {
  return {
    type: 'USUARIO_EDIT_SET_USUARIO',
    payload: usuario
  }
}

const setReceitaModelos = modelos => {
  return {
    type: 'USUARIO_EDIT_SET_RECEITA_MODELOS',
    payload: modelos
  }
}

export const clearUsuario = () => {
  return {
    type: 'USUARIO_EDIT_CLEAR_USUARIO'
  }
}

export const fetchById = id => {
  return async dispatch => {
    try {
      const usuario = await findById(id);

      return dispatch(setUsuario(usuario));
    } catch (e) {
      return dispatch(nop());
    }
  }
}

export const fetchAllModelosReceita = farmaciaId => {
  return async dispatch => {
    try {
      const modelos = await findAllModelos(farmaciaId);

      return dispatch(setReceitaModelos(modelos));
    } catch(e) {
      return dispatch(nop());
    }
  }
}
