const INITIAL_STATE = {
  unidade: null
}

export default function unidadeEditReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'UNIDADE_EDIT_SET_UNIDADE':
      return {
        ...state,
        unidade: action.payload
      }
    case 'UNIDADE_EDIT_CLEAR_ALL':
      return {
        ...state,
        ...INITIAL_STATE
      }
    default:
      return state
  }
}
