import React, { useState, useEffect } from 'react';
import './styles.css';

import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchAllFormulas,
  removeFormula as deleteFormula,
  clearFormulas,
  setPage,
  setRef,
  setStartsWith,
} from '../../stores/formulaListActions';
import Title from '../../components/Title';
import ButtonAdd from '../../components/ButtonAdd';
import Button from '../../components/Button';
import Skeleton from 'react-loading-skeleton';
import Row from '../../components/Row';
import Grid from '../../components/Grid';
import FormulaList from './FormulaList';
import Pagination from '../../components/Pagination';
import FormulaSearch from './FormulaSearch';
import { unlinkFormulaMedico } from '../../services/LinkMedicoToMedicamentoService';

export default function FormulaListContainer() {
  const [isLoading, setLoading] = useState(true);
  const [isFirst, setFirst] = useState(true);

  const history = useHistory();
  const dispatch = useDispatch();

  const formulas = useSelector((state) => state.formula.list.formulas);

  // pagination
  const page = useSelector((state) => state.formula.list.pagination.page);
  const itemsPerPage = useSelector(
    (state) => state.formula.list.pagination.itemsPerPage
  );
  const totalItems = useSelector(
    (state) => state.formula.list.pagination.totalItems
  );

  // search
  const ref = useSelector((state) => state.formula.list.search.ref);
  const startsWith = useSelector(
    (state) => state.formula.list.search.startsWith
  );

  const farmaciaId = localStorage.getItem('FORMULARIO_FARMACIA_ID');

  useEffect(() => {
    dispatch(fetchAllFormulas(farmaciaId, '', 1, itemsPerPage, false));

    return () => dispatch(clearFormulas());
  }, []);

  useEffect(() => {
    if (!isFirst) setLoading(false);
    else setFirst(false);
  }, [formulas]);

  function removeFormula(formula) {
    dispatch(deleteFormula(farmaciaId, formula));
  }

  function editFormula(formula) {
    history.push(`/formulas/${formula.for_in_codigo}/edit`);
  }

  async function removeLinkFormulaMedico(medicoFormula) {
    setLoading(true);

    await unlinkFormulaMedico(
      farmaciaId,
      medicoFormula.usu_in_codigo,
      medicoFormula.for_in_codigo
    );

    dispatch(fetchAllFormulas(farmaciaId, ref, page, itemsPerPage, startsWith));
  }

  function handlePaginate(pgNumber) {
    if (pgNumber === page) return;

    setLoading(true);

    dispatch([
      setPage(pgNumber),
      fetchAllFormulas(farmaciaId, ref, pgNumber, itemsPerPage, startsWith),
    ]);
  }

  function handleSearch({ ref: refText, startsWith: searchStartsWith }) {
    if (refText === ref && searchStartsWith === startsWith) return;

    setLoading(true);

    dispatch([
      setPage(1),
      setRef(refText),
      setStartsWith(searchStartsWith),
      fetchAllFormulas(farmaciaId, refText, 1, itemsPerPage, searchStartsWith),
    ]);
  }

  function handleClearSearch() {
    setLoading(true);

    dispatch([
      setPage(1),
      setRef(''),
      setStartsWith(false),
      fetchAllFormulas(farmaciaId, '', 1, itemsPerPage, false),
    ]);
  }

  return (
    <div className='formula-container'>
      <Title
        link='/'
        component={() => [
          <Button
            key={1}
            color='secondary'
            size='sm'
            className='mr-1'
            handleclick={() => history.push('/formulas/pendentes')}
          >
            Fórmulas pendentes
          </Button>,
          <ButtonAdd key={2} onClick={() => history.push('/formulas/new')} />,
        ]}
      >
        Listagem de fórmulas
      </Title>

      <Row>
        <Grid cols='12'>
          <FormulaSearch onSubmit={handleSearch} onClear={handleClearSearch} />
        </Grid>
      </Row>

      <Row>
        <Grid cols='12'>
          {isLoading ? (
            <Skeleton count={5} height={30} />
          ) : (
            <FormulaList
              formulas={formulas}
              onDelete={removeFormula}
              onEdit={editFormula}
              onRemoveLinkFormulaMedico={removeLinkFormulaMedico}
            />
          )}
        </Grid>
      </Row>

      {formulas.length === 0 ? null : (
        <Row>
          <Grid cols='12'>
            <Pagination
              page={page}
              itemsPerPage={itemsPerPage}
              totalItems={totalItems}
              paginate={handlePaginate}
            />
          </Grid>
        </Row>
      )}
    </div>
  );
}
