import { nop } from './baseActions'
import { findById } from '../services/UnidadeService'

const setUnidade = unidade => {
  return {
    type: 'UNIDADE_EDIT_SET_UNIDADE',
    payload: unidade
  }
}

export const clearUnidade = () => {
  return {
    type: 'UNIDADE_EDIT_CLEAR_ALL'
  }
}

export const fetchById = (farmaciaId, id) => {
  return async dispatch => {
    try {
      const unidade = await findById(farmaciaId, id);

      return dispatch(setUnidade(unidade));
    } catch (e) {
      return dispatch(nop());
    }
  }
}
