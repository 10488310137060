import React, { useState, useEffect, useRef } from 'react';
import './styles.css';

import { useParams, useHistory, useLocation } from 'react-router-dom';
import Title from '../../components/Title';
import { Form } from '@unform/web';
import { Input, TextArea, Checkbox, Select } from '../../components/Form';
import Button from '../../components/Button';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { setLoading } from '../../stores/loadingActions';
import {
  fetchById,
  fetchAtivosByFormula,
  fetchUnidades,
  clearFormula,
  setEditing,
  fetchVeiculoUtilizacaoById,
  searchVeiculosUtilizacao as searchVeiculos,
} from '../../stores/medicoFormulaEditActions';
import Row from '../../components/Row';
import Grid from '../../components/Grid';
import { save as saveFormula, update } from '../../services/FormulaService';
import {
  removeLinkAtivoFormula,
  updateLinkAtivoFormula,
} from '../../services/AtivoFormulaService';
import AtivoList from '../FormulaEdit/AtivoList';
import { linkMultipleAtivoToFormula } from '../../services/FormulaAtivoService';
import { toast } from 'react-toastify';
import { setGoBackPath } from '../../stores/linkFormulaAtivoActions';
import { setHidden } from '../../stores/navbarActions';
import {
  setPage as setListPage,
  setRef as setListRef,
} from '../../stores/medicoFormulasListActions';
import {
  setPage as setFavoritesPage,
  setRef as setFavoritesRef,
} from '../../stores/favoriteFormulasActions';
import ModalSearchVeiculosUtilizacaoResults from '../ResultListPage/NewFormulaWizard/ModalSearchVeiculosUtilizacaoResults';
import ModalButton from '../../components/ModalButton';
import { save as saveVeiculoUtilizacao } from '../../services/VeiculoUtilizacaoService';

import { getVias } from '../../stores/viaActions';

export default function FormulaMedicoEdit() {
  const { formulaId } = useParams();
  const history = useHistory();
  const { state: redirectParams } = useLocation();
  const { origin } = redirectParams;

  const dispatch = useDispatch();
  const formula = useSelector((state) => state.medico.formula.edit.formula);
  const formulaAtivos = useSelector(
    (state) => state.medico.formula.edit.ativos
  );

  // medico id
  const { usu_in_codigo: medicoId } = useSelector((state) => state.auth.user);

  const formRef = useRef(null);

  const farmaciaId = localStorage.getItem('FORMULARIO_FARMACIA_ID');

  const [nome, setNome] = useState('');
  const [posologia, setPosologia] = useState('');
  const [unidade, setUnidade] = useState(-1);
  const [veiculoUtilizacao, setVeiculoUtilizacao] = useState(null);
  const [qsp, setQSP] = useState(false);
  const [quantidadeVeiculo, setQuantidadeVeiculo] = useState(0);
  const [obs, setObs] = useState('');

  const [selectedVia, setSelectedVia] = useState(-1);

  // fields are locked
  const [locked, setLocked] = useState(true);

  // modal results loading
  const [isFirst, setFirst] = useState(true);
  const [modalLoading, setModalLoading] = useState(true);

  // first time searching veiculos utilizacao
  const [veiculosFirstSearch, setVeiculosFirstSearch] = useState(true);

  // user is editing formula
  const isEditing = useSelector((state) => state.medico.formula.edit.isEditing);

  const unidades = useSelector((state) => state.medico.formula.edit.unidades);
  const veiculosUtilizacao = useSelector(
    (state) => state.medico.formula.edit.veiculosUtilizacao
  );
  const initialVeiculoUtilizacao = useSelector(
    (state) => state.medico.formula.edit.veiculoUtilizacao
  );

  const vias = useSelector((state) => state.vias.vias);

  useEffect(() => {
    // fetch initial formula info by formula id
    dispatch([
      fetchById(farmaciaId, formulaId),
      fetchAtivosByFormula(farmaciaId, formulaId),
      fetchUnidades(farmaciaId),
      getVias(),
    ]);

    // if user was editing, then continue
    if (isEditing) setLocked(false);

    return () => dispatch(clearFormula());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formula) {
      setNome(formula.for_st_nome);
      setPosologia(formula.for_st_posologia);
      setUnidade(formula.uni_in_codigo);
      setVeiculoUtilizacao(formula.veu_in_codigo);
      setQuantidadeVeiculo(formula.for_in_quantidadeveiculo);
      setQSP(formula.for_bo_qsp);
      setObs(formula.for_st_observacao);
      setSelectedVia(formula.via_in_codigo);
      console.log('veio aqui: ', formula.via_in_codigo);
      // find veiculo formula
      if (formula.veu_in_codigo)
        dispatch(fetchVeiculoUtilizacaoById(farmaciaId, formula.veu_in_codigo));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formula]);

  useEffect(() => {
    // load initial veiculo utilizacao
    if (initialVeiculoUtilizacao) {
      setVeiculoUtilizacao(initialVeiculoUtilizacao);
      document.getElementById('veiculoUtilizacao').value =
        initialVeiculoUtilizacao.veu_st_descricao;
    }
  }, [initialVeiculoUtilizacao]);

  useEffect(() => {
    // user search a veiculo utilizacao
    if (veiculoUtilizacao) {
      document.getElementById('veiculoUtilizacao').value =
        veiculoUtilizacao.veu_st_descricao;
    }
  }, [veiculoUtilizacao]);

  useEffect(() => {
    // modal results loading
    if (isFirst) setFirst(false);
    else setModalLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [veiculosUtilizacao]);

  async function handleSaveNewVeiculoUtilizacao({
    veu_st_descricao,
    veu_st_tipo,
  }) {
    dispatch(setLoading(true));

    const veiculoUtilizacao = await saveVeiculoUtilizacao(farmaciaId, {
      veu_st_descricao,
      veu_st_tipo,
    });

    setVeiculoUtilizacao(veiculoUtilizacao);

    dispatch(setLoading(false));
  }

  function handleSelectVeiculoUtilizacao(veiculoUtilizacao) {
    setVeiculoUtilizacao(veiculoUtilizacao);
  }

  function searchVeiculosUtilizacao(ref) {
    setModalLoading(true);

    dispatch(searchVeiculos(farmaciaId, ref));

    // open modal to show results
    document.getElementById('modal_results_button').click();
  }

  function handleSearchVeiculoUtilizacao(e) {
    if (e.key === 'Enter' || (e.key === 'Tab' && veiculosFirstSearch)) {
      e.preventDefault();

      setVeiculosFirstSearch(false);

      // search veiculoUtilizacao
      searchVeiculosUtilizacao(e.target.value);
    }
  }

  async function handleSubmit(data, { reset }) {
    try {
      if (!veiculoUtilizacao) {
        toast.warning('Selecione um veic. utilização/forma farmacêutica');
        return;
      }

      // start loading
      // user is not editing formula anymore
      dispatch([setLoading(true), setEditing(false)]);

      // validation
      const schema = Yup.object().shape({
        for_st_nome: Yup.string().required('O nome é obrigatório'),
        for_st_posologia: Yup.string(),
        for_st_observacao: Yup.string(),
      });

      const formula = {
        ...data,
        veu_in_codigo: veiculoUtilizacao.veu_in_codigo,
        for_bo_disponivel: true,
      };

      // extract key 'veiculoUtilizacao' from formula obj
      var formulaToSave = Object.assign({}, formula);
      delete formulaToSave.veiculoUtilizacao;

      await schema.validate(formulaToSave, {
        abortEarly: false,
      });

      // update values of new formula
      await update(farmaciaId, formulaId, formulaToSave);

      dispatch([setLoading(false), setEditing(false), setHidden(false)]);

      // reset form data
      reset();

      // reset errors
      formRef.current.setErrors({});

      // redirect user
      history.push('/formulas');
    } catch (e) {
      if (e instanceof Yup.ValidationError) {
        const errorMessages = {};

        e.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });

        formRef.current.setErrors(errorMessages);
      }

      dispatch(setLoading(false));
    }
  }

  function goToLinkFormulaAtivoPage() {
    dispatch(setGoBackPath(`/m/formulas/${formula.for_in_codigo}/edit`));

    history.push(`/formulas/${formula.for_in_codigo}/link/ativos`);
  }

  async function handleUpdateFormulaAtivo({
    ativoId,
    doseMinima,
    doseMaxima,
    doseDiaria,
    dosePrescrita,
  }) {
    dispatch(setLoading(true));

    await updateLinkAtivoFormula(
      farmaciaId,
      ativoId,
      formula.for_in_codigo,
      doseMinima,
      doseMaxima,
      doseDiaria,
      dosePrescrita
    );

    dispatch([
      setLoading(false),
      fetchAtivosByFormula(farmaciaId, formula.for_in_codigo),
    ]);
  }

  async function removeLink(ativoId) {
    dispatch(setLoading(true));

    await removeLinkAtivoFormula(farmaciaId, ativoId, formula.for_in_codigo);

    dispatch([
      setLoading(false),
      fetchById(farmaciaId, formula.for_in_codigo),
      fetchAtivosByFormula(farmaciaId, formula.for_in_codigo),
    ]);
  }

  async function cloneFormula() {
    try {
      dispatch(setLoading(true));

      // save formula
      const {
        for_in_codigo,
        far_in_codigo,
        createdAt,
        updatedAt,
        ...formulaClone
      } = formula;

      const clone = await saveFormula(farmaciaId, {
        ...formulaClone,
        for_ch_status: 'P',
        usu_in_codigo: medicoId,
      });

      // link ativos
      await linkMultipleAtivoToFormula(
        farmaciaId,
        clone.for_in_codigo,
        formulaAtivos
      );

      dispatch([
        // set formula on state
        fetchById(farmaciaId, clone.for_in_codigo),
        // find ativos by formula
        fetchAtivosByFormula(farmaciaId, clone.for_in_codigo),
        // set is editing on redux
        setEditing(true),
        // hide navbar
        setHidden(true),
      ]);

      setLocked(false);
    } catch (e) {
      toast.error('Falha ao clonar a fórmula');
    } finally {
      dispatch(setLoading(false));
    }
  }

  function cancelEdit() {
    setLocked(true);
    dispatch([
      setEditing(false),
      // show navbar
      setHidden(false),
    ]);
  }

  function handleGoBack() {
    const { ref, page } = redirectParams;

    if (origin === '/formulas') {
      // formula list
      dispatch([setListPage(page), setListRef(ref)]);
    } else {
      // formula favorites
      dispatch([setFavoritesPage(page), setFavoritesRef(ref)]);
    }
  }

  function ActionButtons() {
    return locked ? (
      <Button size='block' handleclick={() => setLocked(false)}>
        Habilitar campos
      </Button>
    ) : (
      <Row>
        <Grid cols='12 6'>
          <Button color='secondary' size='block' handleclick={cancelEdit}>
            Cancelar
          </Button>
        </Grid>

        <Grid cols='12 6'>
          <Button type='submit' size='block'>
            Salvar
          </Button>
        </Grid>
      </Row>
    );
  }

  function CloneButton() {
    return (
      <Button
        size='sm'
        color='secondary'
        title='Para editar uma fórmula, você deve cloná-la'
        handleclick={cloneFormula}
      >
        Clonar
      </Button>
    );
  }

  return (
    <div className='formula-container'>
      <Title
        link={origin || '/formulas'}
        onClick={handleGoBack}
        hideButton={isEditing}
        component={isEditing ? null : () => <CloneButton />}
      >
        {locked ? 'Informações da fórmula' : 'Editar'}
      </Title>

      <Row>
        <Grid cols='12 6'>
          <div className='form-container'>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <Input
                id='for_st_nome'
                label='* Nome'
                className='form-control upper'
                name='for_st_nome'
                value={nome}
                onChange={(e) => setNome(e.target.value)}
                disabled={locked}
                required
              />

              <Input
                id='for_st_posologia'
                label='Posologia'
                className='form-control upper'
                name='for_st_posologia'
                value={posologia}
                onChange={(e) => setPosologia(e.target.value)}
                disabled={locked}
              />

              <Input
                id='veiculoUtilizacao'
                name='veiculoUtilizacao'
                label='Veic. utilização/Forma farmacêutica'
                className='form-control upper'
                placeholder='Pesquise por descrição'
                onKeyPress={handleSearchVeiculoUtilizacao}
                disabled={locked}
              >
                <small className='text-muted'>
                  Pressione "Enter" para pesquisar.
                </small>
              </Input>

              <Row>
                <Grid cols='12 6'>
                  <Select
                    id='via_in_codigo'
                    name='via_in_codigo'
                    className='form-control upper'
                    value={selectedVia}
                    onChange={(e) => setSelectedVia(e.target.value)}
                    disabled={locked}
                  >
                    <option value={-1} disabled>
                      Selecione...
                    </option>
                    {vias.map((via) => (
                      <option key={via.via_in_codigo} value={via.via_in_codigo}>
                        {via.via_st_nome}
                      </option>
                    ))}
                  </Select>
                </Grid>
              </Row>

              <Row>
                <Grid cols='12 6'>
                  <Checkbox
                    type='checkbox'
                    id='for_bo_qsp'
                    label='Veículo com QSP'
                    name='for_bo_qsp'
                    value={qsp}
                    onChange={(e) => setQSP(e.target.checked)}
                    checked={qsp}
                    disabled={locked}
                  />
                </Grid>
                <Grid cols='12 6'>
                  <Row>
                    <Grid cols='12 6'>
                      <Input
                        type='number'
                        id='for_in_quantidadeveiculo'
                        name='for_in_quantidadeveiculo'
                        value={quantidadeVeiculo}
                        onChange={(e) => setQuantidadeVeiculo(e.target.value)}
                        disabled={locked}
                        required
                      />
                    </Grid>
                    <Grid cols='12 6'>
                      <Select
                        id='uni_in_codigo'
                        name='uni_in_codigo'
                        className='form-control upper'
                        value={unidade}
                        onChange={(e) => setUnidade(e.target.value)}
                        disabled={locked}
                      >
                        <option value={-1} disabled>
                          Selecione...
                        </option>
                        {unidades.map(
                          ({ uni_in_codigo: codigo, uni_st_sigla: sigla }) => (
                            <option key={codigo} value={codigo}>
                              {sigla}
                            </option>
                          )
                        )}
                      </Select>
                    </Grid>
                  </Row>
                </Grid>
              </Row>

              <TextArea
                id='for_st_observacao'
                label='Observação'
                className='form-control upper'
                name='for_st_observacao'
                onChange={(e) => setObs(e.target.value)}
                value={obs}
                disabled={locked}
              ></TextArea>

              <div className='w-100 mb-3'>
                <small className='text-muted align-end'>
                  Os campos marcados com (*) são obrigatórios
                </small>
              </div>

              {locked ? null : <ActionButtons />}
            </Form>
          </div>
        </Grid>

        <Grid cols='12 6'>
          <AtivoList
            readonly={locked}
            formulaAtivos={formulaAtivos}
            onLinkButtonClick={goToLinkFormulaAtivoPage}
            onUpdateLink={handleUpdateFormulaAtivo}
            onRemoveLink={removeLink}
          />
        </Grid>
      </Row>

      {/* veiculosUtilizacao search results modal */}
      <ModalButton
        id='modal_results_button'
        target='#modal_results'
        className='d-none'
      />
      <ModalSearchVeiculosUtilizacaoResults
        id='modal_results'
        veiculosUtilizacao={veiculosUtilizacao}
        onSelectVeiculo={handleSelectVeiculoUtilizacao}
        onSaveNewVeiculoUtilizacao={handleSaveNewVeiculoUtilizacao}
        loading={modalLoading}
        setLoading={setModalLoading}
      />
    </div>
  );
}
