import api from './api'
import { toast } from 'react-toastify'
import { toUpperCase } from '../utils/objects'

export function findAll(farmaciaId, paginationFilters = { page: 1, itemsPerPage: 15 }) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      const response = await api.get(`farmacias/${farmaciaId}/veiculos_utilizacao`, {
        headers: { authorization: `Bearer ${token}` },
        params: { ...paginationFilters }
      });

      return resolve(response.data);
    } catch (e) {
      toast.error("Falha ao buscar os veículos de utilização");
      return reject(e);
    }
  })
}

export function findById(farmaciaId, veiculoId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      const response = await api.get(`farmacias/${farmaciaId}/veiculos_utilizacao/${veiculoId}`, {
        headers: { authorization: `Bearer ${token}` }
      });

      return resolve(response.data.data);
    } catch (e) {
      toast.error("Falha ao buscar o veic. utilização/forma farmacêutica");
      return reject(e);
    }
  })
}

export function save(farmaciaId, veiculoUtilizacao) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      const response = await api.post(`farmacias/${farmaciaId}/veiculos_utilizacao`, toUpperCase(veiculoUtilizacao), {
        headers: { authorization: `Bearer ${token}` }
      });

      return resolve(response.data.data);
    } catch (e) {
      toast.error("Falha ao salvar o veic. utilização/forma farmacêutica");
      return reject(e);
    }
  })
}

export function update(farmaciaId, veiculoId, veiculoUtilizacao) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      await api.put(`farmacias/${farmaciaId}/veiculos_utilizacao/${veiculoId}`, toUpperCase(veiculoUtilizacao), {
        headers: { authorization: `Bearer ${token}` }
      });

      return resolve();
    } catch (e) {
      toast.error("Falha ao salvar o veic. utilização/forma farmacêutica");
      return reject(e);
    }
  })
}

export function remove(farmaciaId, veiculoUtilizacao) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      await api.delete(`farmacias/${farmaciaId}/veiculos_utilizacao/${veiculoUtilizacao.veu_in_codigo}`, {
        headers: { authorization: `Bearer ${token}` }
      });

      return resolve();
    } catch (e) {
      toast.error("Falha ao remover o veic. utilização/forma farmacêutica");
      return reject(e);
    }
  })
}
