const INITIAL_STATE = {
  allMedicos: [],
  selectedMedico: null,
  allMedicamentos: [],
  linksMedicoMedicamentos: [],
  search: {
    ref: '',
    tipo: null
  },
  pagination: {
    page: 1,
    itemsPerPage: 20,
    totalItems: 0
  }
}

export default function linkMedicoToMedicamentoReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'LINK_MEDICO_MEDICAMENTO_SET_MEDICOS':
      return {
        ...state,
        allMedicos: action.payload
      }
    case 'LINK_MEDICO_MEDICAMENTO_SET_MEDICO':
      return {
        ...state,
        selectedMedico: action.payload
      }
    case 'LINK_MEDICO_MEDICAMENTO_SET_MEDICAMENTOS':
      return {
        ...state,
        allMedicamentos: action.payload
      }
    case 'LINK_MEDICO_MEDICAMENTO_SET_LINKS':
      return {
        ...state,
        linksMedicoMedicamentos: action.payload
      }
    case 'LINK_MEDICO_MEDICAMENTO_SET_REF':
      return {
        ...state,
        search: {
          ...state.search,
          ref: action.payload
        }
      }
    case 'LINK_MEDICO_MEDICAMENTO_SET_TIPO':
      return {
        ...state,
        search: {
          ...state.search,
          tipo: action.payload
        }
      }
    case 'LINK_MEDICO_MEDICAMENTO_SET_PAGE':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.payload
        }
      }
    case 'LINK_MEDICO_MEDICAMENTO_SET_ITEMS_PER_PAGE':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          itemsPerPage: action.payload
        }
      }
    case 'LINK_MEDICO_MEDICAMENTO_SET_TOTAL_ITEMS':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          totalItems: action.payload
        }
      }
    case 'LINK_MEDICO_MEDICAMENTO_CLEAR_ALL':
      return {
        ...state,
        ...INITIAL_STATE
      }
    default:
      return state;
  }
}
