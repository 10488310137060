const INITIAL_STATE = {
  medicamentos: [],
  ativos: [],
  receitaAtivos: [],
  veiculosUtilizacao: [],
  formulaVeiculoUtilizacao: null,
  unidades: [],
  favorites: {
    ativos: [],
    formulas: [],
    industrializados: []
  },
  search: {
    ref: '',
    startsWith: false,
    favorites: false,
    tipos: ['ATIVO', 'FORMULA', 'INDUSTRIALIZADO']
  },
  pagination: {
    page: 1,
    itemsPerPage: 20,
    totalItems: 0
  }
}

export default function searchMedicamentosReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SEARCH_MEDICAMENTOS_SET_MEDICAMENTOS':
      return {
        ...state,
        medicamentos: action.payload
      }
    case 'SEARCH_MEDICAMENTOS_SET_ATIVOS':
      return {
        ...state,
        ativos: action.payload
      }
    case 'SEARCH_MEDICAMENTOS_SET_RECEITA_ATIVOS':
      return {
        ...state,
        receitaAtivos: action.payload
      }
    case 'SEARCH_MEDICAMENTOS_ADD_ATIVO_RECEITA':
      return {
        ...state,
        receitaAtivos: [...state.receitaAtivos, action.payload]
      }
    case 'SEARCH_MEDICAMENTOS_REMOVE_ATIVO':
      // mark ativos that are going to be removed
      function markAtivoToRemove(ativo) {
        if (ativo["atv_in_codigo"] === action.payload || ativo["codigo"] === action.payload) {
          return {
            ...ativo,
            remove: true
          }
        }

        return ativo;
      }

      const ativosToRemove = state.ativos.map(markAtivoToRemove);
      const receitaAtivosToRemove = state.receitaAtivos.map(markAtivoToRemove);

      // remove ativos
      const newAtivos = ativosToRemove.filter(ativo => !ativo.remove);
      const newReceitaAtivos = receitaAtivosToRemove.filter(ativo => !ativo.remove);

      return {
        ...state,
        ativos: newAtivos,
        receitaAtivos: newReceitaAtivos
      }
    case 'SEARCH_MEDICAMENTOS_CLEAR_ALL_ATIVOS':
      return {
        ...state,
        ativos: [],
        receitaAtivos: []
      }
    case 'SEARCH_MEDICAMENTOS_SET_VEICULOS_UTILIZACAO':
      return {
        ...state,
        veiculosUtilizacao: action.payload
      }
    case 'SEARCH_MEDICAMENTOS_SET_FORMULA_VEICULO_UTILIZACAO':
      return {
        ...state,
        formulaVeiculoUtilizacao: action.payload
      }
    case 'SEARCH_MEDICAMENTOS_CLEAR_FORMULA_VEICULO_UTILIZACAO':
      return {
        ...state,
        formulaVeiculoUtilizacao: null
      }
    case 'SEARCH_MEDICAMENTOS_SET_UNIDADES':
      return {
        ...state,
        unidades: action.payload
      }
    case 'SEARCH_MEDICAMENTOS_SET_FAVORITES_ATIVOS':
      return {
        ...state,
        favorites: {
          ...state.favorites,
          ativos: action.payload
        }
      }
    case 'SEARCH_MEDICAMENTOS_SET_FAVORITES_FORMULAS':
      return {
        ...state,
        favorites: {
          ...state.favorites,
          formulas: action.payload
        }
      }
    case 'SEARCH_MEDICAMENTOS_SET_FAVORITES_INDUSTRIALIZADOS':
      return {
        ...state,
        favorites: {
          ...state.favorites,
          industrializados: action.payload
        }
      }
    case 'SEARCH_MEDICAMENTOS_SET_REF':
      return {
        ...state,
        search: {
          ...state.search,
          ref: action.payload
        }
      }
    case 'SEARCH_MEDICAMENTOS_SET_STARTS_WITH':
      return {
        ...state,
        search: {
          ...state.search,
          startsWith: action.payload
        }
      }
    case 'SEARCH_MEDICAMENTOS_SET_FAVORITES':
      return {
        ...state,
        search: {
          ...state.search,
          favorites: action.payload
        }
      }
    case 'SEARCH_MEDICAMENTOS_SET_TIPOS':
      return {
        ...state,
        search: {
          ...state.search,
          tipos: action.payload
        }
      }
    case 'SEARCH_MEDICAMENTOS_SET_PAGE':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.payload
        }
      }
    case 'SEARCH_MEDICAMENTOS_SET_ITEMS_PER_PAGE':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          itemsPerPage: action.payload
        }
      }
    case 'SEARCH_MEDICAMENTOS_SET_TOTAL_ITEMS':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          totalItems: action.payload
        }
      }
    case 'SEARCH_MEDICAMENTOS_CLEAR_PAGINATION':
      return {
        ...state,
        pagination: {
          page: 1,
          itemsPerPage: 20,
          totalItems: 0
        }
      }
    case 'SEARCH_MEDICAMENTOS_CLEAR_SEARCH':
      return {
        ...state,
        search: {
          ref: ''
        }
      }
    case 'SEARCH_MEDICAMENTOS_CLEAR_MEDICAMENTOS':
      return {
        ...state,
        ...INITIAL_STATE
      }
    default:
      return state
  }
}
