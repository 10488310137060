import styled from 'styled-components';

export const ReceitaPageContainer = styled.div`
  @media print {
    button.button-print,
    a,
    hr {
      display: none;
    }
  }
`;
