import React, { useState } from 'react'
import './styles.css'
import PropTypes from 'prop-types'

import Table, { Thead as TableHeader, TableRow, Tbody, TbodyCol as TbCol } from '../../../components/Table'
// import defaultImg from '../../../assets/default-img.png'
// import { storage } from '../../../config/firebase'
// import Media from 'react-media'
import ButtonFavorite from '../../../components/ButtonFavorite'
import ButtonView from '../../../components/ButtonView'
import ButtonRemove from '../../../components/ButtonRemove'
import ModalViewIndustrializado from './ModalViewIndustrializado'
import ButtonAdd from '../../../components/ButtonAdd'
import Media from 'react-media'

function MedicamentoList({ medicamentos, favorites, receita,
  onFavorite: handleFavorite,
  onUnfavorite: handleUnfavorite,
  onAddIndustrializadoReceita: handleAddIndustrializadoReceita,
  onRemoveIndustrializado: handleRemoveLinkMedicamento }) {

  const [industrializado, setIndustrializado] = useState(null);
  // const [image, setImage] = useState(null);

  // useEffect(() => {
  //   medicamentos.forEach(medicamento => {
  //     if (medicamento.med_st_imagem) {
  //       const ref = storage.ref(medicamento.med_st_imagem);

  //       ref.getDownloadURL()
  //         .then(img => document.getElementById(`img-${medicamento.med_in_codigo}`).src = img)
  //         .catch(err => console.log("Erro ao buscar imagem: ", err));
  //     }
  //   })
  // }, []);

  return (
    <>
      {medicamentos.length > 0 ? (
        <Table card>
          <TableHeader dark
            columns={[
              'Nome',
              'Fabricante',
              'Recomendação de uso',
              'Posologia',
              'Observação',
              'Ação']} />

          <Tbody>
            {medicamentos.map(medicamento => {
              const isMedicamentoOnReceita = receita.find(med => (med.codigo === medicamento.med_in_codigo && med.tipo === 'INDUSTRIALIZADO'));

              return (
                <TableRow className="upper" key={medicamento.med_in_codigo}>
                  <TbCol alignMiddle>
                    {medicamento.med_st_nome}
                  </TbCol>
                  <TbCol alignMiddle label="Fabricante">
                    {medicamento.med_st_fabricante || '-'}
                  </TbCol>
                  <TbCol alignMiddle label="Recomendação de uso">
                    {medicamento.med_st_recomendacaouso || '-'}
                  </TbCol>
                  <TbCol alignMiddle label="Posologia">
                    {medicamento.med_st_posologia || '-'}
                  </TbCol>
                  <TbCol alignMiddle label="Obs">
                    {medicamento.med_st_observacao || '-'}
                  </TbCol>
                  <Media query={{ maxWidth: 599 }}>
                    {matches => (
                      <TbCol flex={!matches} alignMiddle>
                        <ButtonView target="#modalViewIndustrializado"
                          className="mr-1"
                          onClick={() => setIndustrializado(medicamento)} />

                        {isMedicamentoOnReceita ? null : (
                          <ButtonAdd className="mr-1"
                            onClick={() => handleAddIndustrializadoReceita(medicamento)} />
                        )}

                        <ButtonRemove className="mr-1" onClick={() => handleRemoveLinkMedicamento(medicamento)} />

                        <ButtonFavorite isFavorite={!!favorites.find(fav => fav.med_in_codigo === medicamento.med_in_codigo)}
                          onFavorite={() => handleFavorite(medicamento)}
                          onUnfavorite={() => handleUnfavorite(medicamento)} />
                      </TbCol>
                    )}
                  </Media>
                </TableRow>
              )
            })}
          </Tbody>
        </Table>
      ) : <p className="not-found">Nenhum medicamento encontrado</p>}

      <ModalViewIndustrializado id="modalViewIndustrializado" industrializado={industrializado} />
    </>
  )
}

MedicamentoList.propTypes = {
  medicamentos: PropTypes.array.isRequired,
  favorites: PropTypes.array,
  receita: PropTypes.array,
  onFavorite: PropTypes.func,
  onUnfavorite: PropTypes.func,
}

export default MedicamentoList;