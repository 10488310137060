import React, { useState, useEffect } from 'react';
import './styles.css';

import { useSelector, useDispatch } from 'react-redux';
import {
  fetchAllIndustrializadosByMedico,
  removeLinkMedicamentoMedico,
  fetchFavoriteIndustrializados,
  clearIndustrializados,
  setPage,
  setRef,
  setStartsWith,
} from '../../stores/medicoIndustrializadosListActions';
import Title from '../../components/Title';
import Skeleton from 'react-loading-skeleton';
import Row from '../../components/Row';
import Grid from '../../components/Grid';
import MedicamentoList from './MedicamentoList';
import {
  favoriteIndustrializado,
  unfavoriteIndustrializado,
} from '../../services/FavoriteIndustrializadosService';
import Button from '../../components/Button';
import { MdFavorite } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { setLoading as loading } from '../../stores/loadingActions';
import Pagination from '../../components/Pagination';
import Search from '../MedicamentoListContainer/MedicamentoSearch';
import { addMedicamento } from '../../stores/newReceitaActions';

export default function MedicamentoMedicoListContainer() {
  const [isLoading, setLoading] = useState(true);
  const [isFirst, setFirst] = useState(true);

  const dispatch = useDispatch();

  const industrializados = useSelector(
    (state) => state.medico.industrializado.industrializados
  );
  const usuarioId = useSelector((state) => state.auth.user.usu_in_codigo);

  const favorites = useSelector(
    (state) => state.medico.industrializado.favorites
  );

  // pagination
  const page = useSelector(
    (state) => state.medico.industrializado.pagination.page
  );
  const itemsPerPage = useSelector(
    (state) => state.medico.industrializado.pagination.itemsPerPage
  );
  const totalItems = useSelector(
    (state) => state.medico.industrializado.pagination.totalItems
  );

  // receita
  const receita = useSelector((state) => state.receita.new.medicamentos);

  // search
  const ref = useSelector((state) => state.medico.industrializado.search.ref);
  const startsWith = useSelector(
    (state) => state.medico.industrializado.search.startsWith
  );

  const farmaciaId = localStorage.getItem('FORMULARIO_FARMACIA_ID');

  const history = useHistory();

  useEffect(() => {
    dispatch([
      fetchAllIndustrializadosByMedico(
        farmaciaId,
        usuarioId,
        '',
        1,
        itemsPerPage,
        false
      ),
      fetchFavoriteIndustrializados(farmaciaId, usuarioId),
    ]);

    return () => dispatch(clearIndustrializados());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isFirst) setLoading(false);
    else setFirst(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [industrializados]);

  async function handleFavorite(industrializado) {
    dispatch(loading(true));

    await favoriteIndustrializado(
      farmaciaId,
      usuarioId,
      industrializado.med_in_codigo
    );

    dispatch([
      fetchFavoriteIndustrializados(farmaciaId, usuarioId),
      loading(false),
    ]);
  }

  async function handleUnfavorite(industrializado) {
    dispatch(loading(true));

    await unfavoriteIndustrializado(
      farmaciaId,
      usuarioId,
      industrializado.med_in_codigo
    );

    dispatch([
      fetchFavoriteIndustrializados(farmaciaId, usuarioId),
      loading(false),
    ]);
  }

  function handlePaginate(pgNumber) {
    if (pgNumber === page) return;

    setLoading(true);

    dispatch([
      setPage(pgNumber),
      fetchAllIndustrializadosByMedico(
        farmaciaId,
        usuarioId,
        ref,
        pgNumber,
        itemsPerPage,
        startsWith
      ),
    ]);
  }

  function handleSearch({ ref: refText, startsWith: searchStartsWith }) {
    setLoading(true);

    dispatch([
      setPage(1),
      setRef(refText),
      setStartsWith(searchStartsWith),
      fetchAllIndustrializadosByMedico(
        farmaciaId,
        usuarioId,
        refText,
        1,
        itemsPerPage,
        searchStartsWith
      ),
    ]);
  }

  function handleClearSearch() {
    setLoading(true);

    dispatch([
      setPage(1),
      setRef(''),
      setStartsWith(false),
      fetchAllIndustrializadosByMedico(
        farmaciaId,
        usuarioId,
        '',
        1,
        itemsPerPage,
        false
      ),
    ]);
  }

  function sugestNewMedicamentoIndustrializado() {
    history.push('/medicamentos/new', { status: 'P', usuarioId });
  }

  function handleRemoveLinkMedicamento(industrializado) {
    setLoading(true);

    dispatch(
      removeLinkMedicamentoMedico(
        farmaciaId,
        usuarioId,
        industrializado.med_in_codigo
      )
    );
  }

  function ButtonFavorites() {
    return (
      <Button
        color='secondary'
        size='sm'
        className='d-flex align-items-center mr-1'
        handleclick={() =>
          history.push(`/medicos/${usuarioId}/industrializados/favoritos`)
        }
      >
        Favoritos
        <MdFavorite size={18} />
      </Button>
    );
  }

  function ButtonSugest() {
    return (
      <Button
        color='secondary'
        size='sm'
        handleclick={sugestNewMedicamentoIndustrializado}
      >
        Adicionar industrializado
      </Button>
    );
  }

  function handleAddIndustrializadoReceita(industrializado) {
    dispatch(
      addMedicamento({
        codigo: industrializado.med_in_codigo,
        nome: industrializado.med_st_nome,
        uso: industrializado.med_st_recomendacaouso,
        fabricante: industrializado.med_st_fabricante,
        posologia: industrializado.med_st_posologia,
        observacao: industrializado.med_st_observacao,
        imagem: industrializado.med_st_imagem,
        tipo: 'INDUSTRIALIZADO',
        resumo: `${industrializado.med_st_nome}\n${industrializado.med_st_posologia}`,
      })
    );

    history.push('/receita');
  }

  return (
    <div className='industrializado-container'>
      <Title
        link='/'
        component={() => [
          <ButtonFavorites key={ButtonFavorites} />,
          <ButtonSugest key={ButtonSugest} />,
        ]}
      >
        Listagem de industrializados
      </Title>

      <Row>
        <Grid cols='12'>
          <Search onSearch={handleSearch} onClear={handleClearSearch} />
        </Grid>
      </Row>

      <Row>
        <Grid cols='12'>
          {isLoading ? (
            <Skeleton count={5} height={30} />
          ) : (
            <MedicamentoList
              medicamentos={industrializados}
              favorites={favorites}
              onFavorite={handleFavorite}
              onUnfavorite={handleUnfavorite}
              onAddIndustrializadoReceita={handleAddIndustrializadoReceita}
              onRemoveIndustrializado={handleRemoveLinkMedicamento}
              receita={receita}
            />
          )}
        </Grid>
      </Row>

      {industrializados.length > 0 ? (
        <Row>
          <Grid cols='12'>
            <Pagination
              page={page}
              itemsPerPage={itemsPerPage}
              totalItems={totalItems}
              paginate={handlePaginate}
            />
          </Grid>
        </Row>
      ) : null}
    </div>
  );
}
