import api from './api'
import { toast } from 'react-toastify'

export function findAllRepresentantesByFarmacia(farmaciaId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      const response = await api.get(`/farmacias/${farmaciaId}/representantes`, {
        headers: { authorization: `bearer ${token}` }
      });

      return resolve(response.data.data);
    } catch (e) {
      toast.error('Falha ao buscar representantes');
      return reject(e);
    }
  })
}
