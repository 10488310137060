import React, { useState, useEffect } from 'react';
import './styles.css';
import PropTypes from 'prop-types';

import Button from '../../../components/Button';
import ModalButton from '../../../components/ModalButton';
import Card, {
  CardContainer,
  CardBody,
  CardTitle,
  CardText,
} from '../../../components/Card';
import Row from '../../../components/Row';
import Grid from '../../../components/Grid';
import ModalLink from './ModalLink';

function FormulaList({
  formulas,
  ativo,
  onUnlinkFormulaAtivo: handleUnlinkFormulaAtivo,
  onLinkFormulaAtivo: handleLinkFormulaAtivo,
}) {
  const [formula, setFormula] = useState(null);

  const [uso, setUso] = useState('');

  const [concentracaoMinima, setConcentracaoMinima] = useState('');
  const [concentracaoMaxima, setConcentracaoMaxima] = useState('');
  const [concentracaoDiaria, setConcentracaoDiaria] = useState('');
  const [concentracaoPrescrita, setConcentracaoPrescrita] = useState('');

  // set concentrações
  useEffect(() => {
    if (ativo?.atv_st_uso === 'TOPICO') {
      setUso('TOPICO');

      setConcentracaoMinima(ativo.atv_st_concentracaominima_topico);
      setConcentracaoMaxima(ativo.atv_st_concentracaomaxima_topico);
      setConcentracaoDiaria(ativo.atv_st_concentracaodiaria_topico);
    }

    if (ativo?.atv_st_uso === 'ORAL') {
      setUso('ORAL');

      setConcentracaoMinima(ativo.atv_st_concentracaominima_oral);
      setConcentracaoMaxima(ativo.atv_st_concentracaomaxima_oral);
      setConcentracaoDiaria(ativo.atv_st_concentracaodiaria_oral);
    }
  }, [ativo]);

  useEffect(() => {
    if (uso === 'TOPICO') {
      setConcentracaoMinima(ativo.atv_st_concentracaominima_topico);
      setConcentracaoMaxima(ativo.atv_st_concentracaomaxima_topico);
      setConcentracaoDiaria(ativo.atv_st_concentracaodiaria_topico);
    }

    if (uso === 'ORAL') {
      setConcentracaoMinima(ativo.atv_st_concentracaominima_oral);
      setConcentracaoMaxima(ativo.atv_st_concentracaomaxima_oral);
      setConcentracaoDiaria(ativo.atv_st_concentracaodiaria_oral);
    }
  }, [uso]);

  function clearState() {
    setFormula(null);
    setConcentracaoPrescrita('');
  }

  function handleConfirmModal() {
    handleLinkFormulaAtivo(
      formula,
      concentracaoMinima,
      concentracaoMaxima,
      concentracaoDiaria,
      concentracaoPrescrita,
      uso
    );
    clearState();
  }

  return (
    <div>
      <CardContainer>
        {formulas.length > 0 ? (
          formulas.map((form) => (
            <Card key={form.for_in_codigo}>
              <Row>
                <Grid cols='12'>
                  <CardBody pl0>
                    <CardTitle className='upper'>{form.for_st_nome}</CardTitle>
                    <CardText>
                      <small className='text-muted'>
                        {form.for_bo_disponivel
                          ? 'Disponível'
                          : 'Não disponível'}
                      </small>
                    </CardText>

                    {form.linked ? (
                      <Button
                        className='float-right'
                        size='sm'
                        color='danger'
                        handleclick={() => handleUnlinkFormulaAtivo(form)}
                      >
                        Desvincular
                      </Button>
                    ) : (
                      <ModalButton
                        className='float-right'
                        size='sm'
                        color='info'
                        target='#modalVincular'
                        handleclick={() => setFormula(form)}
                      >
                        Vincular
                      </ModalButton>
                    )}
                  </CardBody>
                </Grid>
              </Row>
            </Card>
          ))
        ) : (
          <p>Nenhuma fórmula encontrada ...</p>
        )}
      </CardContainer>

      <ModalLink
        formula={formula}
        doseMinima={concentracaoMinima}
        doseMaxima={concentracaoMaxima}
        doseDiaria={concentracaoDiaria}
        dosePrescrita={concentracaoPrescrita}
        uso={uso}
        setUso={setUso}
        ativo={ativo}
        onCancelarClick={clearState}
        onClick={handleConfirmModal}
        setDosePrescrita={setConcentracaoPrescrita}
      />
    </div>
  );
}

FormulaList.propTypes = {
  formulas: PropTypes.array.isRequired,
  ativo: PropTypes.object.isRequired,
  onLinkFormulaAtivo: PropTypes.func,
  onUnlinkFormulaAtivo: PropTypes.func,
};

export default FormulaList;
