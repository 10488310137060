import React, { useEffect, useState } from 'react';
import './styles.css';
import PropTypes from 'prop-types';

import Modal from '../../../../components/Modal';
import ButtonGroup, { Button as Btn } from '../../../../components/ButtonGroup';
import Button from '../../../../components/Button';
import { toast } from 'react-toastify';

function ModalSelectUso({ id, ativo, onSelectUso: handleSelectUso }) {
  const [concentracaoPrescrita, setConcentracaoPrescrita] = useState('');
  const [uso, setUso] = useState(null);

  useEffect(() => {
    setConcentracaoPrescrita('');
    setUso(null);
  }, [ativo]);

  function handleChange(e) {
    if (e.target.checked) {
      setConcentracaoPrescrita(
        e.target.value === 'TOPICO'
          ? ativo.atv_st_concentracaodiaria_topico
          : ativo.atv_st_concentracaodiaria_oral
      );

      setUso(e.target.value);
    }
  }

  function handleSubmit() {
    if (!uso) {
      toast.warning('Selecione o uso do medicamento');
      return;
    }

    if (!concentracaoPrescrita) {
      toast.warning('Informe a concentração do medicamento');
      return;
    }

    handleSelectUso(ativo, uso, concentracaoPrescrita);
  }

  return (
    <Modal id={id} title='Selecione o tipo de uso do ativo' hideFooter>
      <label>Selecione o uso do ativo</label>
      <div className='d-flex justify-content-center mb-4'>
        <ButtonGroup>
          <Btn
            name='topico'
            id='TOPICO'
            value='TOPICO'
            disabled={ativo?.atv_st_uso === 'ORAL'}
            checked={uso === 'TOPICO'}
            onChange={handleChange}
          >
            TÓPICO
          </Btn>
          <Btn
            name='oral'
            id='ORAL'
            value='ORAL'
            disabled={ativo?.atv_st_uso === 'TOPICO'}
            checked={uso === 'ORAL'}
            onChange={handleChange}
          >
            ORAL
          </Btn>
        </ButtonGroup>
      </div>

      <div className='form-group mb-3'>
        <label htmlFor='concentracaoPrescrita'>Concentração prescrita</label>
        <input
          type='text'
          className='form-control'
          id='concentracaoPrescrita'
          name='concentracaoPrescrita'
          value={concentracaoPrescrita}
          onChange={(e) => setConcentracaoPrescrita(e.target.value)}
        />
      </div>

      <Button
        className='float-right'
        data-dismiss='modal'
        size='sm'
        handleclick={handleSubmit}
      >
        Continuar
      </Button>
    </Modal>
  );
}

ModalSelectUso.propTypes = {
  id: PropTypes.string.isRequired,
  onSelectUso: PropTypes.func,
};

export default ModalSelectUso;
