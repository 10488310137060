import React, { useRef, useEffect } from 'react';
import './styles.css';

import { changePassword } from '../../services/NewPasswordService';
import { fetchUserById, clearUser } from '../../stores/newPasswordActions';
import { setLoading } from '../../stores/loadingActions';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import Title from '../../components/Title';
import Row from '../../components/Row';
import Grid from '../../components/Grid';
import Button from '../../components/Button';
import { Form } from '@unform/web';
import { Input } from '../../components/Form';
import bcrypt from 'bcryptjs';
import * as Yup from 'yup';

export default function NewPassword() {
  const { id } = useParams();
  const history = useHistory();
  const formRef = useRef();

  const dispatch = useDispatch();
  const usuario = useSelector((state) => state.newPassword.usuario);

  useEffect(() => {
    dispatch(fetchUserById(id));

    return () => dispatch(clearUser());
  }, []);

  async function handleSubmit(data, { reset }) {
    try {
      // set loading
      dispatch(setLoading(true));

      // validation
      const schema = Yup.object().shape({
        oldPassword: Yup.string()
          .required('Informe sua senha antiga')
          .min(6, 'As senhas devem ter no mínimo 6 caracteres'),
        newPassword: Yup.string()
          .required('Informe sua nova senha')
          .min(6, 'As senhas devem ter no mínimo 6 caracteres'),
        confirmPassword: Yup.string()
          .required('Confirme sua senha')
          .oneOf([Yup.ref('newPassword'), null], 'As senhas devem ser iguais')
          .min(6, 'As senhas devem ter no mínimo 6 caracteres'),
      });

      // check old password
      const isOldPassword = bcrypt.compareSync(
        data.oldPassword,
        usuario.usu_st_senha
      );
      if (!isOldPassword) {
        throw new Yup.ValidationError({
          name: 'ValidationError',
          value: data.oldPassword,
          path: 'oldPassword',
          type: 'oneOf',
          errors: ['Sua senha antiga está incorreta'],
          inner: [],
          message: 'Sua senha antiga está incorreta',
        });
      }

      await schema.validate(data, {
        abortEarly: false,
      });

      await changePassword(id, data.newPassword);

      dispatch(setLoading(false));

      // clear form
      reset();

      formRef.current.setErrors({});

      // redirect user
      history.push('/');
    } catch (e) {
      if (e instanceof Yup.ValidationError) {
        const errorMessages = {};

        e.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });

        formRef.current.setErrors(errorMessages);
      }

      dispatch(setLoading(false));
    }
  }

  return (
    <div className='password-container'>
      <Title link='/'>Alterar senha</Title>

      <Row>
        <Grid cols='12'>
          <div className='form-container'>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <Input
                id='oldPassword'
                label='Senha antiga'
                name='oldPassword'
                type='password'
                placeholder='******'
                required
              />

              <Input
                id='newPassword'
                label='Nova senha'
                name='newPassword'
                type='password'
                placeholder='******'
                required
              >
                <small className='text-muted'>
                  A senha deve conter no mínimo 6 caracteres
                </small>
              </Input>

              <Input
                id='confirmPassword'
                label='Confirme sua senha'
                name='confirmPassword'
                type='password'
                placeholder='******'
                required
              />

              <Button type='submit' size='block'>
                Enviar
              </Button>
            </Form>
          </div>
        </Grid>
      </Row>
    </div>
  );
}
