import React from 'react';
import PropTypes from 'prop-types';

import Table, {
  TableRow,
  Tbody,
  TbodyCol,
  Thead,
} from '../../../components/Table';
import Button from '../../../components/Button';
import { MdArrowForward } from 'react-icons/md';

function RepresentanteList({ representantes, onSelect: handleSelect }) {
  return representantes.length === 0 ? (
    <p>Nenhum representante encontrado...</p>
  ) : (
    <Table>
      <Thead dark columns={['Representante', 'Selecionar']} />

      <Tbody>
        {representantes.map((representante) => (
          <TableRow key={representante.usu_in_codigo}>
            <TbodyCol label='Representante'>
              {representante.usu_st_nome}
            </TbodyCol>

            <TbodyCol>
              <Button
                size='sm'
                color='info'
                handleclick={() => handleSelect(representante)}
              >
                <MdArrowForward size={18} />
              </Button>
            </TbodyCol>
          </TableRow>
        ))}
      </Tbody>
    </Table>
  );
}

RepresentanteList.propTypes = {
  representantes: PropTypes.array.isRequired,
  readonly: PropTypes.bool,
  onSelect: PropTypes.func,
};

export default RepresentanteList;
