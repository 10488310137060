import React, { useState, useEffect } from 'react';
import './styles.css';
import PropTypes from 'prop-types';
import ButtonGroup, { Button } from '../../../../components/ButtonGroup';

import Modal from '../../../../components/Modal';

function ModalVincular({
  ativo,
  dosePrescrita,
  setDosePrescrita,
  onModalConfirm: handleModalConfirm,
  onCancelarClick: handleCancelarClick,
}) {
  const [uso, setUso] = useState('');

  useEffect(() => {
    if (ativo?.atv_st_uso !== 'AMBOS') setUso(ativo?.atv_st_uso);
  }, [ativo]);

  return (
    <Modal
      id='modalVincular'
      btnText='Vincular'
      title='Vincular este ativo a fórmula'
      handleCancelarClick={handleCancelarClick}
      handleClick={() => handleModalConfirm(uso)}
    >
      <div>
        <p className='mb-4'>
          Vincular <b>{ativo?.atv_st_descricao || 'o ativo'}</b> a fórmula?
        </p>

        <div className='d-flex justify-content-center mb-4'>
          <ButtonGroup>
            <Button
              radio
              name='topico'
              id='TOPICO'
              checked={uso === 'TOPICO'}
              disabled={ativo?.atv_st_uso === 'ORAL'}
              onChange={(e) => setUso(e.target.checked && 'TOPICO')}
            >
              TÓPICO
            </Button>
            <Button
              radio
              name='oral'
              id='ORAL'
              checked={uso === 'ORAL'}
              disabled={ativo?.atv_st_uso === 'TOPICO'}
              onChange={(e) => setUso(e.target.checked && 'ORAL')}
            >
              ORAL
            </Button>
          </ButtonGroup>
        </div>

        {uso === 'TOPICO' ? (
          <>
            <div className='form-group mb-2'>
              <label htmlFor='concentracaoMinima_topico'>
                Concentração mínima (topico)
              </label>

              <input
                className='form-control'
                id='concentracaoMinima_topico'
                name='concentracaoMinima_topico'
                placeholder='Concentração mínima'
                value={ativo?.atv_st_concentracaominima_topico || ''}
                disabled
              />
            </div>

            <div className='form-group mb-2'>
              <label htmlFor='concentracaoMaxima_topico'>
                Concentração máxima (topico)
              </label>

              <input
                className='form-control'
                id='concentracaoMaxima_topico'
                name='concentracaoMaxima_topico'
                placeholder='Concentração máxima'
                value={ativo?.atv_st_concentracaomaxima_topico || ''}
                disabled
              />
            </div>

            <div className='form-group mb-2'>
              <label htmlFor='concentracaoDiaria_topico'>
                Concentração usual (topico)
              </label>

              <input
                className='form-control'
                id='concentracaoDiaria_topico'
                name='concentracaoDiaria_topico'
                placeholder='Concentração usual'
                value={ativo?.atv_st_concentracaodiaria_topico || ''}
                disabled
              />
            </div>
          </>
        ) : null}

        {uso === 'ORAL' ? (
          <>
            <div className='form-group mb-2'>
              <label htmlFor='concentracaoMinima_oral'>
                Concentração mínima (oral)
              </label>

              <input
                className='form-control'
                id='concentracaoMinima_oral'
                name='concentracaoMinima_oral'
                placeholder='Concentração mínima'
                value={ativo?.atv_st_concentracaominima_oral || ''}
                disabled
              />
            </div>

            <div className='form-group mb-2'>
              <label htmlFor='concentracaoMaxima_oral'>
                Concentração máxima (oral)
              </label>

              <input
                className='form-control'
                id='concentracaoMaxima_oral'
                name='concentracaoMaxima_oral'
                placeholder='Concentração máxima'
                value={ativo?.atv_st_concentracaomaxima_oral || ''}
                disabled
              />
            </div>

            <div className='form-group mb-2'>
              <label htmlFor='concentracaoDiaria_oral'>
                Concentração usual (oral)
              </label>

              <input
                className='form-control'
                id='concentracaoDiaria_oral'
                name='concentracaoDiaria_oral'
                placeholder='Concentração usual'
                value={ativo?.atv_st_concentracaodiaria_oral || ''}
                disabled
              />
            </div>
          </>
        ) : null}

        <div className='form-group mb-2'>
          <label htmlFor='dosePrescrita'>Concentração prescrita</label>

          <input
            className='form-control'
            id='dosePrescrita'
            name='dosePrescrita'
            placeholder='Concentração prescrita'
            value={dosePrescrita}
            onChange={(e) => setDosePrescrita(e.target.value)}
          />
        </div>
      </div>
    </Modal>
  );
}

ModalVincular.propTypes = {
  ativo: PropTypes.object,
  dosePrescrita: PropTypes.string,
  setDosePrescrita: PropTypes.func,
  onModalConfirm: PropTypes.func,
  onCancelarClick: PropTypes.func,
};

export default ModalVincular;
