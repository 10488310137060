import { useEffect, useState, useCallback } from 'react';
import { storage } from '../config/firebase';

export default function useStorageBlobURL(blobPath) {
  const [url, setURL] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const fetchBlobURL = useCallback(async path => {
    try {
      const url = await storage.ref(path).getDownloadURL()

      setURL(url);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (blobPath) fetchBlobURL(blobPath);
  }, [blobPath, fetchBlobURL]);

  return {
    url,
    error,
    loading
  };
}
