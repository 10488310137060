import { nop } from './baseActions'
import { findById } from '../services/AtivoService'
import { findLogsByAtivo } from '../services/AtivoLogsService'

const setAtivo = ativo => {
  return {
    type: 'ATIVO_EDIT_SET_ATIVO',
    payload: ativo
  }
}

export const setGoBack = path => {
  return {
    type: 'ATIVO_EDIT_SET_GO_BACK',
    payload: path
  }
}

const setLogs = logs => {
  return {
    type: 'ATIVO_EDIT_SET_LOGS',
    payload: logs
  }
}

export const clearAtivo = () => {
  return {
    type: 'ATIVO_EDIT_CLEAR_ATIVO'
  }
}

export const fetchById = (farmaciaId, ativoId) => {
  return async dispatch => {
    try {
      const ativo = await findById(farmaciaId, ativoId);

      return dispatch(setAtivo(ativo));
    } catch (e) {
      return dispatch(nop());
    }
  }
}

export const fetchLogsByAtivo = (farmaciaId, ativoId) => {
  return async dispatch => {
    try {
      const logs = await findLogsByAtivo(farmaciaId, ativoId);

      return dispatch(setLogs(logs));
    } catch (e) {
      return dispatch(nop());
    }
  }
}
