const INITIAL_STATE = {
  receitas: [],
  pagination: {
    page: 1,
    itemsPerPage: 15,
    totalItems: 0,
  }
}

export default function receitaRepresentanteListReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'RECEITA_REPRESENTANTE_LIST_SET_RECEITAS':
      return {
        ...state,
        receitas: action.payload
      }
    case 'RECEITA_REPRESENTANTE_LIST_PAGINATION_SET_PAGE':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.payload,
        }
      }
    case 'RECEITA_REPRESENTANTE_LIST_PAGINATION_SET_ITEMS_PER_PAGE':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          itemsPerPage: action.payload,
        }
      }
    case 'RECEITA_REPRESENTANTE_LIST_PAGINATION_SET_TOTAL_ITEMS':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          totalItems: action.payload,
        }
      }
    case 'RECEITA_REPRESENTANTE_LIST_CLEAR_ALL':
      return {
        ...state,
        ...INITIAL_STATE
      }
    default:
      return state
  }
}
