import { nop } from './baseActions'
import { findAtivos } from '../services/FindMedicamentosByMedicoService'
import { findAllFavoriteAtivos } from '../services/FavoriteAtivosService'
import { unlinkAtivoMedico } from '../services/LinkMedicoToMedicamentoService'

const setAtivos = ativos => {
  return {
    type: 'MEDICOS_ATIVOS_SET_ATIVOS',
    payload: ativos
  }
}

const setFavorites = favorites => {
  return {
    type: 'MEDICOS_ATIVOS_SET_FAVORITES',
    payload: favorites
  }
}

export const setPage = page => {
  return {
    type: 'MEDICOS_ATIVOS_SET_PAGE',
    payload: page
  }
}

export const setItemsPerPage = itemsPerPage => {
  return {
    type: 'MEDICOS_ATIVOS_SET_ITEMS_PER_PAGE',
    payload: itemsPerPage
  }
}

export const setTotalItems = totalItems => {
  return {
    type: 'MEDICOS_ATIVOS_SET_TOTAL_ITEMS',
    payload: totalItems
  }
}

export const setRef = ref => {
  return {
    type: 'MEDICOS_ATIVOS_SET_REF',
    payload: ref
  }
}

export const setStartsWith = startsWith => {
  return {
    type: 'MEDICOS_ATIVOS_SET_STARTS_WITH',
    payload: startsWith
  }
}

export const clearAtivos = () => {
  return {
    type: 'MEDICOS_ATIVOS_CLEAR_ATIVOS'
  }
}

export const fetchAllAtivosByMedico = (farmaciaId, usuarioId, ref, page, itemsPerPage, startsWith) => {
  return async dispatch => {
    try {
      const response = await findAtivos(farmaciaId, usuarioId, ref, page, itemsPerPage, startsWith);

      return dispatch([
        setAtivos(response.data),
        setTotalItems(response["_metadata"].totalItems)
      ]);
    } catch (e) {
      return dispatch(nop());
    }
  }
}

export const fetchAllFavoriteAtivos = (farmaciaId, usuarioId) => {
  return async dispatch => {
    try {
      const favorites = await findAllFavoriteAtivos(farmaciaId, usuarioId);

      return dispatch(setFavorites(favorites));
    } catch (e) {
      return dispatch(nop());
    }
  }
}

export const removeLinkAtivoMedico = (farmaciaId, usuarioId, ativoId) => {
  return async (dispatch, getState) => {
    try {
      await unlinkAtivoMedico(farmaciaId, usuarioId, ativoId);

      const { search, pagination } = getState().medico.ativo;

      return dispatch(fetchAllAtivosByMedico(
        farmaciaId,
        usuarioId,
        search.ref,
        pagination.page,
        pagination.itemsPerPage
      ));
    } catch (e) {
      return dispatch(nop());
    }
  }
}
