import api from './api'
import { toast } from 'react-toastify'

export function findAtivosFormulas(farmaciaId, ativoId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      const response = await api.get(`farmacias/${farmaciaId}/ativos/${ativoId}/formulas`, {
        headers: { authorization: `Bearer ${token}` }
      });

      return resolve(response.data.data);
    } catch (e) {
      return reject(e);
    }
  })
}

export function linkAtivoToFormula(farmaciaId, ativoId, formulaId, doseMinima, doseMaxima, doseDiaria, dosePrescrita, uso) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      await api.post(`farmacias/${farmaciaId}/ativos/${ativoId}/formulas/${formulaId}`, {
        doseMinima,
        doseMaxima,
        doseDiaria,
        dosePrescrita,
        uso
      }, {
        headers: { authorization: `Bearer ${token}` }
      });

      return resolve();
    } catch (e) {
      toast.error("Falha ao vincular o ativo a essa fórmula");
      return reject(e);
    }
  })
}

export function updateLinkAtivoFormula(farmaciaId, ativoId, formulaId, doseMinima, doseMaxima, doseDiaria, dosePrescrita) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      await api.put(`farmacias/${farmaciaId}/ativos/${ativoId}/formulas/${formulaId}`, {
        doseMinima,
        doseMaxima,
        doseDiaria,
        dosePrescrita
      }, {
        headers: { authorization: `Bearer ${token}` }
      });

      return resolve();
    } catch (e) {
      toast.error("Falha ao atualizar informações do vínculo do ativo a fórmula");
      return reject(e);
    }
  })
}

export function removeLinkAtivoFormula(farmaciaId, ativoId, formulaId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      await api.delete(`farmacias/${farmaciaId}/ativos/${ativoId}/formulas/${formulaId}`, {
        headers: { authorization: `Bearer ${token}` }
      });

      return resolve();
    } catch (e) {
      return reject(e);
    }
  })
}
