const INITIAL_STATE = {
  modelo: null
}

export default function receitaModeloEditReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'RECEITA_MODELO_EDIT_SET_MODELO':
      return {
        ...state,
        modelo: action.payload
      }
    case 'RECEITA_MODELO_EDIT_CLEAR_ALL':
      return {
        ...state,
        ...INITIAL_STATE
      }
    default:
      return state
  }
}
