import React, { useState, useEffect, useCallback } from 'react';
import './styles.css';

import Title from '../../components/Title';
import ReceitaList from './ReceitaList';
import { useSelector, useDispatch } from 'react-redux';
import {
  setMedicos,
  clearAll,
  setSelectedMedico,
  setReceitas,
  setPage,
  setTotalItems,
  setRef,
  setFrom,
  setTo,
} from '../../stores/receitaListActions';
import Skeleton from 'react-loading-skeleton';
import { useHistory, useParams } from 'react-router-dom';
import SearchReceita from './ReceitaSearch';
import MedicoList from '../LinkMedicamentosToMedicoContainer/MedicoList';
import { findAllMedicosByFarmacia } from '../../services/FindMedicosService';
import { searchByMedico } from '../../services/SearchReceitaService';
import Pagination from '../../components/Pagination';
import { findById } from '../../services/UsuarioService';
import { toast } from 'react-toastify';
import {
  addMedicamento,
  clearMedicamentos,
} from '../../stores/newReceitaActions';
import { findAllMedicamentosByMedicoAndReceita } from '../../services/FindMedicamentosByReceita';

export default function ReceitaListContainer() {
  const [isLoading, setLoading] = useState(true);

  const [step, setStep] = useState('SELECT_MEDICO');

  const dispatch = useDispatch();
  const medicos = useSelector((state) => state.receita.list.medicos.list);
  const selectedMedico = useSelector(
    (state) => state.receita.list.medicos.selectedMedico
  );
  const nome_medico = selectedMedico?.usu_st_nome;
  const receitas = useSelector((state) => state.receita.list.receitas);

  // pagination
  const page = useSelector((state) => state.receita.list.pagination.page);
  const itemsPerPage = useSelector(
    (state) => state.receita.list.pagination.itemsPerPage
  );
  const totalItems = useSelector(
    (state) => state.receita.list.pagination.totalItems
  );

  // search
  const ref = useSelector((state) => state.receita.list.search.ref);
  const from = useSelector((state) => state.receita.list.search.from);
  const to = useSelector((state) => state.receita.list.search.to);

  const { usu_ch_tipo: role } = useSelector((state) => state.auth.user);
  const { medicoId } = useParams();

  const farmaciaId = localStorage.getItem('FORMULARIO_FARMACIA_ID');

  const history = useHistory();

  const fetchMedicos = useCallback(async () => {
    setLoading(true);

    const response = await findAllMedicosByFarmacia(farmaciaId);

    dispatch(setMedicos(response));

    setLoading(false);
  }, [farmaciaId, dispatch]);

  const fetchReceitasByMedico = useCallback(
    async (
      { ref = '', from, to } = { ref: '' },
      { page = 1, itemsPerPage = 15 } = { page: 1, itemsPerPage: 15 },
      medico = selectedMedico
    ) => {
      setLoading(true);

      const response = await searchByMedico(
        farmaciaId,
        medico.usu_in_codigo,
        { ref, from, to },
        { page, itemsPerPage }
      );

      dispatch([
        setReceitas(response.data),
        setPage(page),
        setTotalItems(response['_metadata'].totalItems),
        setRef(ref),
        setFrom(from),
        setTo(to),
      ]);

      setLoading(false);
    },
    [dispatch, farmaciaId, selectedMedico]
  );

  const fetchMedicoById = useCallback(
    async (medicoId) => {
      const medico = await findById(medicoId);

      fetchReceitasByMedico({ ref: '' }, { page: 1, itemsPerPage }, medico);

      dispatch(setSelectedMedico(medico));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [dispatch, itemsPerPage]
  );

  useEffect(() => {
    if (medicoId) {
      fetchMedicoById(medicoId);

      setStep('SEARCH_RECEITA');
    } else {
      fetchMedicos();
    }

    return () => dispatch(clearAll());
  }, [dispatch, fetchMedicos, fetchMedicoById, medicoId, itemsPerPage]);

  function handleSelectMedico(medico) {
    setLoading(true);

    dispatch(setSelectedMedico(medico));

    fetchReceitasByMedico({ ref: '' }, { page: 1, itemsPerPage: 15 }, medico);

    setStep('SEARCH_RECEITA');
  }

  function viewReceita(receita) {
    const win = window.open(`/receitas/${receita.rec_in_codigo}`, "_blank");
    win.focus();
    // history.push(`/receitas/${receita.rec_in_codigo}`);
  }

  async function handleCopyReceita(receita) {
    if (role !== 'M') return;

    dispatch(clearMedicamentos());

    // fetch medicamentos by receita
    const medicamentos = await findAllMedicamentosByMedicoAndReceita(
      farmaciaId,
      medicoId,
      receita.rec_in_codigo
    );

    medicamentos.forEach((medicamento) => {
      dispatch(
        addMedicamento({
          nome: medicamento.rfm_st_nomemedicamento,
          uso: medicamento.rfm_st_recomendacaouso,
          posologia: medicamento.rfm_st_posologia,
          observacao: medicamento.rfm_st_observacao,
          qsp: medicamento.rfm_bo_qsp,
          quantidadeVeiculo: medicamento.rfm_in_quantidadeveiculo,
          veiculoUtilizacao: medicamento.rfm_st_veiculoutilizacao,
          unidade: medicamento.rfm_st_unidade,
          tipo: medicamento.rfm_st_tipomedicamento,
          ativos: medicamento.ativos.map((ativo) => ({
            ...ativo,
            fat_in_codigo: ativo.rfa_in_codigo,
            fat_st_concentracaoprescrita: ativo.rfa_st_concentracaoprescrita,
          })),
        })
      );
    });

    toast.info('Receita copiada');
  }
  function renderStep(step) {
    return (
      {
        SELECT_MEDICO: (
          <MedicoList medicos={medicos} onSelect={handleSelectMedico} />
        ),
        SEARCH_RECEITA: (
          <>
            <SearchReceita
              placeholder='Pesquise por paciente ou medicamento'
              onSubmit={fetchReceitasByMedico}
              onClear={fetchReceitasByMedico}
            />

            <ReceitaList
              receitas={receitas}
              role={role}
              onViewReceita={viewReceita}
              readonly={role !== 'M'}
              onCopyReceita={handleCopyReceita}
            />

            <Pagination
              page={page}
              itemsPerPage={itemsPerPage}
              totalItems={totalItems}
              paginate={(page) =>
                fetchReceitasByMedico(
                  { ref, from, to },
                  { page, itemsPerPage },
                  selectedMedico
                )
              }
            />
          </>
        ),
      }[step] || <p>Algo deu errado...</p>
    );
  }

  function renderTitle(step) {
    return (
      {
        SELECT_MEDICO: <Title link='/'>Selecione um médico </Title>,
        SEARCH_RECEITA: (
          <Title link='/receitas' onClick={() => setStep('SELECT_MEDICO')}>
            Receitas - {nome_medico}
          </Title>
        ),
      }[step] || <Title link='/'>Listagem de receitas</Title>
    );
  }

  return (
    <div className='receita-container'>
      {renderTitle(step)}

      {isLoading ? <Skeleton count={5} height={35} /> : renderStep(step)}
    </div>
  );
}
