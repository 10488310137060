import React from 'react';
import './styles.css';
import PropTypes from 'prop-types';

import ModalButton from '../../components/ModalButton';
import { MdSearch } from 'react-icons/md';

function ButtonView({ onClick: handleClick, className, target, ...props }) {
  return (
    <ModalButton
      target={target}
      color='info'
      size='sm'
      className={className}
      handleclick={handleClick}
      {...props}
    >
      <MdSearch size={18} />
    </ModalButton>
  );
}

ButtonView.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  target: PropTypes.string,
};

export default ButtonView;
