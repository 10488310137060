const INITIAL_STATE = {
  allMedicamentos: [],
  selectedEspecialidade: null,
  allEspecialidades: [],
  linksMedicamentosEspecialidades: [],
  search: {
    ref: '',
    tipo: null,
  },
  pagination: {
    page: 1,
    itemsPerPage: 20,
    totalItems: 0,
  },
};

export default function linkEspecialidadeToMedicamentoReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case 'LINK_MEDICAMENTO_ESPECIALIDADE_SET_ESPECIALIDADE':
      return {
        ...state,
        selectedEspecialidade: action.payload,
      };
    case 'LINK_MEDICAMENTO_ESPECIALIDADE_SET_MEDICAMENTOS':
      return {
        ...state,
        allMedicamentos: action.payload,
      };
    case 'LINK_MEDICAMENTO_ESPECIALIDADE_SET_MEDICAMENTO':
      return {
        ...state,
        selectedMedico: action.payload,
      };
    case 'LINK_MEDICAMENTO_ESPECIALIDADE_SET_ESPECIALIDADES':
      return {
        ...state,
        allEspecialidades: action.payload,
      };
    case 'LINK_MEDICAMENTO_ESPECIALIDADE_SET_LINKS':
      return {
        ...state,
        linksMedicamentosEspecialidades: action.payload,
      };
    case 'LINK_MEDICAMENTO_ESPECIALIDADE_SET_REF':
      return {
        ...state,
        search: {
          ...state.search,
          ref: action.payload,
        },
      };
    case 'LINK_MEDICAMENTO_ESPECIALIDADE_SET_TIPO':
      return {
        ...state,
        search: {
          ...state.search,
          tipo: action.payload,
        },
      };
    case 'LINK_MEDICAMENTO_ESPECIALIDADE_SET_PAGE':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.payload,
        },
      };
    case 'LINK_MEDICAMENTO_ESPECIALIDADE_SET_ITEMS_PER_PAGE':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          itemsPerPage: action.payload,
        },
      };
    case 'LINK_MEDICAMENTO_ESPECIALIDADE_SET_TOTAL_ITEMS':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          totalItems: action.payload,
        },
      };
    case 'LINK_MEDICAMENTO_ESPECIALIDADE_CLEAR_ALL':
      return {
        ...state,
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
}
