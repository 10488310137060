import { nop } from './baseActions'
import { toast } from 'react-toastify'
import { remove } from '../services/MedicamentoService'
import { searchMedicamentosIndustrializados } from '../services/SearchMedicamentosIndustrializadosService'
import { setLoading } from './loadingActions'

const setMedicamentos = medicamentos => {
  return {
    type: 'MEDICAMENTO_LIST_FETCH_MEDICAMENTOS',
    payload: medicamentos
  }
}

export const setPage = page => {
  return {
    type: 'MEDICAMENTO_LIST_SET_PAGE',
    payload: page
  }
}

export const setItemsPerPage = itemsPerPage => {
  return {
    type: 'MEDICAMENTO_LIST_SET_ITEMS_PER_PAGE',
    payload: itemsPerPage
  }
}

export const setTotalItems = totalItems => {
  return {
    type: 'MEDICAMENTO_LIST_SET_TOTAL_ITEMS',
    payload: totalItems
  }
}

export const clearPagination = () => {
  return {
    type: 'MEDICAMENTO_LIST_CLEAR_PAGINATION'
  }
}

export const setRef = ref => {
  return {
    type: 'MEDICAMENTO_LIST_SET_REF',
    payload: ref
  }
}

export const setStartsWith = startsWith => {
  return {
    type: 'MEDICAMENTO_LIST_SET_STARTS_WITH',
    payload: startsWith
  }
}

export const clearSearch = () => {
  return {
    type: 'MEDICAMENTO_LIST_CLEAR_SEARCH'
  }
}

export const clearMedicamentos = () => {
  return {
    type: 'MEDICAMENTO_LIST_CLEAR_MEDICAMENTOS'
  }
}

export const fetchAllMedicamentos = (farmaciaId, ref = '', page = 1, itemsPerPage = 15, startsWith = false) => {
  return async dispatch => {
    try {
      const response = await searchMedicamentosIndustrializados(farmaciaId, ref, page, itemsPerPage, startsWith);

      return dispatch([
        setMedicamentos(response.data),
        setTotalItems(response["_metadata"].totalItems)
      ]);
    } catch (e) {
      toast.error("Falha ao buscar medicamentos");
      return dispatch(nop());
    }
  }
}

export const removeMedicamento = (farmaciaId, medicamento) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));

      await remove(farmaciaId, medicamento.med_in_codigo);

      return dispatch([
        setLoading(false),
        fetchAllMedicamentos(farmaciaId)
      ]);
    } catch (e) {
      toast.error("Falha ao remover medicamento");
      return dispatch(setLoading(false));
    }
  }
}
