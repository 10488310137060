import api from './api'
import { toast } from 'react-toastify'

export function favoriteFormula(farmaciaId, usuarioId, formulaId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      await api.post(`farmacias/${farmaciaId}/usuarios/${usuarioId}/formulas/${formulaId}/favorite`, {}, {
        headers: { authorization: `Bearer ${token}` }
      });

      return resolve();
    } catch (e) {
      toast.error("Falha ao adicionar fórmula aos favoritos");
      return reject(e);
    }
  })
}

export function unfavoriteFormula(farmaciaId, usuarioId, formulaId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      await api.delete(`farmacias/${farmaciaId}/usuarios/${usuarioId}/formulas/${formulaId}/favorite`, {
        headers: { authorization: `Bearer ${token}` }
      });

      return resolve();
    } catch (e) {
      toast.error("Falha ao remover fórmula dos favoritos");
      return reject(e);
    }
  })
}

export function findFavoriteFormulas(farmaciaId, usuarioId, page = 1, itemsPerPage = 15, ref = '') {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      const response = await api.get(`farmacias/${farmaciaId}/usuarios/${usuarioId}/formulas/favorites`, {
        headers: { authorization: `Bearer ${token}` },
        params: { ref, page, itemsPerPage }
      });

      return resolve(response.data);
    } catch (e) {
      return reject(e);
    }
  })
}

export function findAllFavoriteFormulas(farmaciaId, usuarioId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      const response = await api.get(`farmacias/${farmaciaId}/usuarios/${usuarioId}/formulas/favorites/all`, {
        headers: { authorization: `Bearer ${token}` }
      });

      return resolve(response.data.data);
    } catch (e) {
      return reject(e);
    }
  })
}
