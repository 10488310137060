import { nop } from './baseActions'
import { toast } from 'react-toastify'
import { findAll, remove } from '../services/VeiculoUtilizacaoService'
import { setLoading } from './loadingActions'

const setVeiculosUtilizacao = veiculosUtilizacao => {
  return {
    type: 'VEICULO_UTILIZACAO_LIST_FETCH_VEICULOS_UTILIZACAO',
    payload: veiculosUtilizacao
  }
}

export const clearVeiculosUtilizacao = () => {
  return {
    type: 'VEICULO_UTILIZACAO_LIST_CLEAR_VEICULOS_UTILIZACAO'
  }
}

export const setPage = page => {
  return {
    type: 'VEICULO_UTILIZACAO_LIST_PAGINATION_SET_PAGE',
    payload: page,
  }
}

export const setItemsPerPage = itemsPerPage => {
  return {
    type: 'VEICULO_UTILIZACAO_LIST_PAGINATION_SET_ITEMS_PER_PAGE',
    payload: itemsPerPage,
  }
}

export const setTotalItems = totalItems => {
  return {
    type: 'VEICULO_UTILIZACAO_LIST_PAGINATION_SET_TOTAL_ITEMS',
    payload: totalItems,
  }
}

export const fetchAllVeiculosUtilizacao = (farmaciaId, paginationFilters) => {
  return async dispatch => {
    try {
      const response = await findAll(farmaciaId, paginationFilters);

      return dispatch([
        setVeiculosUtilizacao(response.data),
        setTotalItems(response["_metadata"].totalItems)
      ]);
    } catch (e) {
      toast.error('Falha ao buscar veículos de utilização');
      return dispatch(nop());
    }
  }
}

export const removeVeiculoUtilizacao = (farmaciaId, veiculoUtilizacao) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      await remove(farmaciaId, veiculoUtilizacao);

      return dispatch([
        fetchAllVeiculosUtilizacao(farmaciaId),
        setLoading(false)
      ]);
    } catch (e) {
      toast.error('Falha ao remover veic. utilização/forma farmacêutica');
      return dispatch(setLoading(false));
    }
  }
}
