const INITIAL_STATE = {
  veiculoUtilizacao: null,
  medicos: [],
}

export default function veiculoUtilizacaoEditReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'VEICULO_UTILIZACAO_EDIT_SET_VEICULO_UTILIZACAO':
      return {
        ...state,
        veiculoUtilizacao: action.payload
      }
    case 'VEICULO_UTILIZACAO_EDIT_SET_MEDICOS':
      return {
        ...state,
        medicos: action.payload
      }
    case 'VEICULO_UTILIZACAO_EDIT_CLEAR_VEICULO_UTILIZACAO':
      return {
        ...state,
        ...INITIAL_STATE
      }
    default:
      return state
  }
}
