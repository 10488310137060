import React, { useEffect, useState } from 'react';
import './styles.css';
import PropTypes from 'prop-types';

import Table, {
  TableRow,
  Tbody,
  TbodyCol,
  Thead,
} from '../../../components/Table';
import Button from '../../../components/Button';
import { MdClose } from 'react-icons/md';
import ModalButton from '../../../components/ModalButton';
import ModalPreImpressao from './ModalPreImpressao';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import {
  saveMedicamentosReceita,
  save as saveReceita,
} from '../../../services/ReceitaService';
import { findAllByMedico } from '../../../services/PacienteService';
import {
  setPaciente,
  setReceita,
  setReceberOrcamento,
} from '../../../stores/newReceitaActions';
import { useHistory } from 'react-router-dom';

function Summary({
  medicamentos,
  onRemove: handleRemove,
  onClear: handleClear,
  small,
  hideTitle,
  pacienteRecebeOrcamentos,
  optionalButton: OptionalButton,
}) {
  const farmaciaId = localStorage.getItem('FORMULARIO_FARMACIA_ID');

  const { usu_in_codigo: usuarioId } = useSelector((state) => state.auth.user);

  const vias = useSelector((state) => state.vias.vias);

  const dispatch = useDispatch();

  const history = useHistory();

  useEffect(() => {
    async function getPacientes() {
      // console.log('getting pacs 2');
      const pacs = await findAllByMedico(usuarioId);
      setPacientes(pacs);
    }

    getPacientes();
  }, []);
  const [pacientes, setPacientes] = useState([]);

  function getIdade(dataNascimento) {
    return moment().diff(dataNascimento, 'years') || 0;
  }

  function getPacienteInitialText(paciente, receita) {
    const pacienteInfoText = `${
      receita ? `Prontuário: ${receita.rec_in_codigo} ` : ''
    }${paciente?.pac_st_nome ? `Nome: ${paciente.pac_st_nome}` : ''}${
      paciente?.pac_st_telefone
        ? `\n Telefone: ${paciente.pac_st_telefone}`
        : ''
    }
      ${
        paciente?.sexo
          ? `Sexo: ${
              paciente.sexo === 'M'
                ? 'Masculino'
                : paciente.sexo === 'F'
                ? 'Feminino'
                : 'Prefiro não informar'
            }`
          : ''
      }    ${
      paciente?.dataNascimento
        ? `Data de nascimento: ${moment(paciente.dataNascimento).format(
            'DD/MM/YYYY'
          )}`
        : ''
    }    ${
      paciente?.dataNascimento
        ? `Idade: ${getIdade(paciente.dataNascimento)} ANOS`
        : ''
    }`;

    return pacienteInfoText;
  }

  // function getMedicamentosInitialText(medicamentos, vias) {
  //   let viasArray = [];

  //   vias.map((via) => {
  //     const viaId = via.via_in_codigo;
  //     const fullVia = via;
  //     medicamentos.map((med) => {
  //       // console.log("array: ", viasArray);
  //       // console.log("id pai e med: ", viaId, " ", med.via);
  //       // console.log("id med e include: ", med.via, viasArray.includes(fullVia));
  //       const medId = med.via;
  //       const idMed = parseInt(medId);
  //       if (idMed === viaId) {
  //         viasArray.includes(fullVia)
  //           ? console.log('ja tem')
  //           : viasArray.push(fullVia);
  //         console.log('adicionado');
  //       }
  //     });
  //   });

  //   // console.log("vias array: ", viasArray);
  //   // console.log("funcao text meds: ", medicamentos, " e vias: ", vias);

  //   let medicamentosInfoText = '';

  //   viasArray.map((via) => {
  //     const viaPai = via;
  //     // medicamentosInfoText +=
  //     //   viaPai.via_st_nome +
  //     //   ` --------------------------------------------------------------------------- \n`;
  //     medicamentos.map((medicamento, count) => {
  //       console.log('medicamento no map: ', medicamento);
  //       if (medicamento.vias == viaPai.via_in_codigo) {
  //         if (medicamento.resumo)
  //           medicamentosInfoText +=
  //             count + 1 + ' - ' + medicamento.resumo + '\n\n';
  //         else {
  //           medicamentosInfoText +=
  //             count +
  //             1 +
  //             ' - ' +
  //             medicamento.nome +
  //             '............................................................................................ \n' +
  //             +(medicamento.veiculoUtilizacao || '') +
  //             ' ' +
  //             (medicamento.quantidadeVeiculo || '') +
  //             ' ' +
  //             (medicamento.unidade || '') +
  //             // "\n" +
  //             (medicamento.posologia || '') +
  //             '\n' +
  //             (medicamento.observacao || '') +
  //             '\n';
  //         }
  //         // console.log("txt no if: ", medicamentosInfoText)
  //       } else {
  //         // console.log("txt no else: ", medicamentosInfoText)
  //         return null;
  //       }
  //     });
  //   });
  //   // medicamentos.forEach((medicamento, count) => {
  //   //   if (medicamento.resumo) medicamentosInfoText += (count + 1) + ' - ' + medicamento.resumo + '\n\n';
  //   //   else {
  //   //     medicamentosInfoText += (count + 1) + ' - ' + medicamento.nome +
  //   //       '............................................................................................' +
  //   //       + (medicamento.veiculoUtilizacao || '') + ' ' + (medicamento.quantidadeVeiculo || '') + ' ' + (medicamento.unidade || '') + '\n'
  //   //       + (medicamento.posologia || '') + '\n'
  //   //       + (medicamento.observacao || '') + '\n'
  //   //   }
  //   // });

  //   return medicamentosInfoText;
  // }

  function getViaName(viaId) {
    for (var i = 0; i < vias.length; i++) {
      if (vias[i].via_in_codigo == viaId) {
        return vias[i].via_st_nome;
      }
    }
  }

  function getMedicamentosInitialText(medicamentos) {
    console.log('getting here2: ', medicamentos);
    let medicamentosInfoText = '';
    let viaName = '';
    medicamentos.forEach((medicamento, count) => {
      if (medicamento.via) {
        viaName = getViaName(medicamento.via);
      }
      if (medicamento.resumo)
        medicamentosInfoText += count + 1 + ' - ' + medicamento.resumo + '\n\n';
      else {
        medicamentosInfoText +=
          count +
          1 +
          ' - ' +
          medicamento.nome +
          '............................................................................................' +
          +(medicamento.veiculoUtilizacao || '') +
          ' ' +
          (medicamento.quantidadeVeiculo || '') +
          ' ' +
          (medicamento.unidade || '') +
          '\n' +
          (medicamento.posologia || '') +
          '\n' +
          (medicamento.observacao || '') +
          '\n' +
          viaName +
          '\n';
      }
    });

    return medicamentosInfoText;
  }

  function buildTxtReceita(paciente, medicamentos) {
    // console.log('AQUI O PACIENTE AQUI E: ', paciente);
    // console.log('AQUI O MEDICAMENTOS AQUI E: ', medicamentos);
    const pacienteFieldValue = paciente ? getPacienteInitialText(paciente) : '';
    const medicamentosFieldValue = getMedicamentosInitialText(
      medicamentos,
      vias
    );

    const plainTextReceita = `${pacienteFieldValue}

  Medicamentos:
  ${medicamentosFieldValue}`;

    return plainTextReceita;
  }

  async function handleSetPaciente(paciente, receberOrcamentos) {
    const txtReceita = buildTxtReceita(paciente, medicamentos);
    // console.log('txt receita aqui: ', txtReceita);
    const pacienteId = paciente.pac_in_codigo;

    // save receita
    const receita = await saveReceita(farmaciaId, usuarioId, pacienteId, {
      // rec_st_nomepaciente: paciente.pac_st_nome,
      // rec_st_telefonepaciente: paciente.pac_st_telefone,
      // rec_st_emailpaciente: paciente.pac_st_emailcontato,
      rec_st_txtreceita: txtReceita,
    });
    //trocar nome, tel, email por usu_in_codigo
    // console.log('olha a receita: ', receita);
    // save medicamentos
    await saveMedicamentosReceita(
      farmaciaId,
      usuarioId,
      receita.rec_in_codigo,
      medicamentos.map((medicamento) => ({
        rfm_st_nomemedicamento: medicamento.nome,
        rfm_st_veiculoutilizacao: medicamento.veiculoUtilizacao,
        rfm_st_recomendacaouso: medicamento.uso,
        rfm_st_posologia: medicamento.posologia,
        rfm_st_observacao: medicamento.observacao,
        rfm_bo_modificado: false,
        rfm_st_tipomedicamento: medicamento.tipo,
        rfm_st_unidade: medicamento.unidade,
        rfm_in_quantidadeveiculo: medicamento.quantidadeVeiculo,
        rfm_bo_qsp: medicamento.qsp,
        ativos:
          medicamento.ativos?.map((ativo) => ({
            atv_in_codigo: ativo.atv_in_codigo || ativo.codigo,
            rfa_st_concentracaoprescrita: ativo.fat_st_concentracaoprescrita,
          })) || [],
      }))
    );

    console.log('=======================');
    console.log(receita);
    console.log(paciente);
    console.log(receberOrcamentos);
    console.log('=======================');
    dispatch([
      setReceita(receita),
      setPaciente(paciente),
      (receberOrcamentos = true ? setReceberOrcamento(true) : null),
    ]);

    history.push('/receita/new');
  }

  async function handleSkipSetPaciente() {
    const txtReceita = buildTxtReceita(null, medicamentos);

    // save receita
    const receita = await saveReceita(farmaciaId, usuarioId, null, {
      rec_st_txtreceita: txtReceita,
    });

    // save medicamentos
    await saveMedicamentosReceita(
      farmaciaId,
      usuarioId,
      receita.rec_in_codigo,
      medicamentos.map((medicamento) => ({
        rfm_st_nomemedicamento: medicamento.nome,
        rfm_st_veiculoutilizacao: medicamento.veiculoUtilizacao,
        rfm_st_recomendacaouso: medicamento.uso,
        rfm_st_posologia: medicamento.posologia,
        rfm_st_observacao: medicamento.observacao,
        rfm_bo_modificado: false,
        rfm_st_tipomedicamento: medicamento.tipo,
        rfm_st_unidade: medicamento.unidade,
        rfm_in_quantidadeveiculo: medicamento.quantidadeVeiculo,
        rfm_bo_qsp: medicamento.qsp,
        ativos:
          medicamento.ativos?.map((ativo) => ({
            atv_in_codigo: ativo.atv_in_codigo || ativo.codigo,
            rfa_st_concentracaoprescrita: ativo.fat_st_concentracaoprescrita,
          })) || [],
      }))
    );

    dispatch(setReceita(receita));

    history.push('/receita/new');
  }

  return (
    <div>
      {hideTitle ? null : (
        <p className='text-center font-weight-bold'>Receita</p>
      )}

      {medicamentos.length === 0 ? (
        <p>Nenhum medicamento adicionado</p>
      ) : (
        <div className='d-flex flex-column'>
          <div className='mb-1 justify-content-end'>
            <Button
              size='sm'
              color='danger'
              className='float-right'
              handleclick={handleClear}
            >
              Limpar
            </Button>
          </div>

          <Table small={small} headerSm>
            <Thead dark columns={['Nome', 'Tipo', 'Ação']} />

            <Tbody>
              {medicamentos.map((medicamento, index) => (
                <TableRow key={index}>
                  <TbodyCol label='Nome'>{medicamento.nome}</TbodyCol>
                  <TbodyCol label='Tipo'>
                    {medicamento.tipo === 'ATIVO'
                      ? 'Ativo'
                      : medicamento.tipo === 'FORMULA'
                      ? 'Fórmula'
                      : medicamento.tipo === 'INDUSTRIALIZADO'
                      ? 'Industrializado'
                      : ' - '}
                  </TbodyCol>
                  <TbodyCol>
                    <Button
                      size='sm'
                      color='outline-danger'
                      handleclick={() => handleRemove(medicamento)}
                    >
                      <MdClose size={18} />
                    </Button>

                    {OptionalButton && medicamento.tipo === 'FORMULA' ? (
                      <OptionalButton formula={medicamento} />
                    ) : null}
                  </TbodyCol>
                </TableRow>
              ))}
              <TableRow>
                <TbodyCol colspan={2} />
                <TbodyCol colspan={2}>
                  <ModalButton
                    target='#modalPreImpressao'
                    color='secondary'
                    size='sm'
                  >
                    Pré-impressão
                  </ModalButton>
                </TbodyCol>
              </TableRow>
            </Tbody>
          </Table>

          <ModalPreImpressao
            id='modalPreImpressao'
            onSetPaciente={handleSetPaciente}
            onSkipSetPaciente={handleSkipSetPaciente}
            pacienteRecebeOrcamentos={pacienteRecebeOrcamentos}
            pacientesList={pacientes}
          />
        </div>
      )}
    </div>
  );
}

Summary.propTypes = {
  medicamentos: PropTypes.array.isRequired,
  onRemove: PropTypes.func,
  onClear: PropTypes.func,
  small: PropTypes.bool,
  /** An optional button to be rendered on the side of the remove button */
  optionalButton: PropTypes.any,
  /** Default checked for if paciente aceita receber orçamentos */
  pacienteRecebeOrcamentos: PropTypes.bool,
};

Summary.defaultProps = {
  small: false,
};

export default Summary;
