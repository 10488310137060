import api from './api'

export function changePassword(userId, password) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('FORMULARIO_JSONWEBTOKEN');

      await api.put(`usuarios/${userId}/password`, { password }, {
        headers: { authorization: `bearer ${token}` }
      });

      return resolve();
    } catch (e) {
      return reject(e);
    }
  })
}
