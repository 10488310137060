import { save } from '../services/UsuarioService';
import { signIn } from '../services/AuthService';
import { findById as findFarmaciaById } from '../services/FarmaciaService';
import { nop } from './baseActions';
import { setLoading } from './loadingActions';
import { toast } from 'react-toastify';

const loginDispatch = (user) => {
  return {
    type: 'LOGIN',
    payload: user,
  };
};

export const logout = () => {
  return {
    type: 'LOGOUT',
  };
};

export const register = (user) => {
  return async (dispatch) => {
    try {
      const response = await save(user);

      return dispatch(loginDispatch(response.data.data));
    } catch (e) {
      toast.error('Erro ao cadastrar');
      return dispatch(nop());
    }
  };
};

export const login = (email, password) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));

      const response = await signIn(email, password);
      const farmacia = await findFarmaciaById(
        response.data.data.far_in_codigo,
        response.data.token
      );

      return dispatch([
        loginDispatch({
          user: response.data.data,
          token: response.data.token,
          expiration: response.data.expiration,
          farmacia,
        }),
        setLoading(false),
      ]);
    } catch (e) {
      console.log('error: ', e);
      if (e.message === 'Network Error') {
        toast.error('Falha na conexão');
      }

      const errorCode = e.response?.data?.error && e.response?.data?.error.code;

      if (errorCode === 'EMAIL_NOT_FOUND') {
        toast.error('Nenhum usuário cadastrado com este e-mail');
      }

      if (errorCode === 'INVALID_PASSWORD') {
        toast.error('Senha inválida');
      }

      return dispatch(setLoading(false));
    }
  };
};
