import React, { useState, useEffect } from 'react';
import './styles.css';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import Row from '../../components/Row';
import Grid from '../../components/Grid';
import {
  fetchAllEspecialidades,
  removeEspecialidade,
  clearEspecialidades,
} from '../../stores/especialidadeListActions';
import EspecialidadesList from './EspecialidadesList';
import ButtonAdd from '../../components/ButtonAdd';
import Title from '../../components/Title';

export default function UnidadeListContainer() {
  const [isFirst, setFirst] = useState(true);
  const [isLoading, setLoading] = useState(true);

  const history = useHistory();
  const dispatch = useDispatch();

  const especialidades = useSelector(
    (state) => state.especialidades.list.especialidades
  );

  const farmaciaId = localStorage.getItem('FORMULARIO_FARMACIA_ID');

  useEffect(() => {
    dispatch(fetchAllEspecialidades(farmaciaId));

    return () => dispatch(clearEspecialidades());
  }, []);

  useEffect(() => {
    if (!isFirst) setLoading(false);
    else setFirst(false);
  }, [especialidades]);

  function removeUnidade(espec) {
    dispatch(removeEspecialidade(farmaciaId, espec));
  }

  function editUnidade(espec) {
    history.push(`/especialidades/${espec.esp_in_codigo}/edit`);
  }

  return (
    <div className='especialidade-container'>
      <Title
        link='/'
        component={() => (
          <ButtonAdd onClick={() => history.push('/especialidades/new')} />
        )}
      >
        Listagem de especialidades
      </Title>

      <Row>
        <Grid cols='12'>
          {isLoading ? (
            <Skeleton count={5} height={30} />
          ) : (
            <EspecialidadesList
              especialidades={especialidades}
              handleEdit={editUnidade}
              handleDelete={removeUnidade}
            />
          )}
        </Grid>
      </Row>
    </div>
  );
}
