import React from 'react'
import './styles.css'
import PropTypes from 'prop-types'

function PacienteInfoField({ value, setValue }) {
  return (
    <div className="w600px receita-field-container">
      <textarea className="form-control receita-field h100px" value={value}
        onChange={e => setValue(e.target.value)}></textarea>
    </div>
  )
}

PacienteInfoField.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func,
}

export default PacienteInfoField;
