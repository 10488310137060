const INITIAL_STATE = {
  ativo: null,
  goBack: null,
  logs: []
}

export default function ativoEditReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'ATIVO_EDIT_SET_ATIVO':
      return {
        ...state,
        ativo: action.payload
      }
    case 'ATIVO_EDIT_SET_GO_BACK':
      return {
        ...state,
        goBack: action.payload
      }
    case 'ATIVO_EDIT_SET_LOGS':
      return {
        ...state,
        logs: action.payload
      }
    case 'ATIVO_EDIT_CLEAR_ATIVO':
      return {
        ...state,
        ...INITIAL_STATE
      }
    default:
      return state
  }
}
