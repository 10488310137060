import React, { useState, useEffect } from 'react';
import './styles.css';

import Title from '../../components/Title';
import { useParams } from 'react-router-dom';
import Row from '../../components/Row';
import Grid from '../../components/Grid';
import Button from '../../components/Button';
import { useSelector, useDispatch } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import {
  fetchVeiculosUtilizacaoByFarmacia,
  fetchAllLinksAtivoVeiculoUtilizacao,
  clearAtivosVeiculosUtilizacao,
} from '../../stores/linkAtivoVeiculoActions';
import {
  linkAtivoToVeiculoUtilizacao,
  linkMultipleVeiculosUtilizacaoToAtivo,
  removeLinkAtivoVeiculo,
} from '../../services/AtivoVeiculoService';
import Search from '../VeiculoUtilizacaoListContainer/VeiculoUtilizacaoSearch';
import VeiculosUtilizacaoList from './VeiculosUtilizacaoList';

export default function LinkAtivoVeiculo() {
  const { id: ativoId } = useParams();
  const farmaciaId = localStorage.getItem('FORMULARIO_FARMACIA_ID');

  const [isFirst, setFirst] = useState(true);
  const [isLoading, setLoading] = useState(true);

  const dispatch = useDispatch();

  // veiculos de utilização already linked to ativo
  const linksAtivoVeiculo = useSelector(
    (state) => state.linkAtivoVeiculoUtilizcao.ativosVeiculos
  );

  // all veiculos utilização by farmacia
  const allVeiculosUtilizacao = useSelector(
    (state) => state.linkAtivoVeiculoUtilizcao.veiculosUtilizacao
  );

  const veiculosUtilizacao = allVeiculosUtilizacao.map((veu) => ({
    ...veu,
    linked: linksAtivoVeiculo.find(
      (link) => link.veu_in_codigo === veu.veu_in_codigo
    ),
  }));

  useEffect(() => {
    dispatch([
      fetchVeiculosUtilizacaoByFarmacia(farmaciaId),
      fetchAllLinksAtivoVeiculoUtilizacao(farmaciaId, ativoId),
    ]);

    return () => dispatch(clearAtivosVeiculosUtilizacao());
  }, []);

  useEffect(() => {
    if (isFirst) setFirst(false);
    else setLoading(false);
  }, [allVeiculosUtilizacao]);

  function ButtonLinkAll() {
    return (
      <Button
        size='sm'
        color='primary'
        handleclick={async () => {
          setLoading(true);

          // remove already linked
          // remove attr linked from veiculosUtilizacao
          const items = veiculosUtilizacao
            .filter((veu) => !veu.linked)
            .map((veu) => {
              const { linked, ...veiculoUtilizacao } = veu;
              return veiculoUtilizacao;
            });

          await linkMultipleVeiculosUtilizacaoToAtivo(
            farmaciaId,
            ativoId,
            items
          );
          dispatch(fetchAllLinksAtivoVeiculoUtilizacao(farmaciaId, ativoId));

          setLoading(false);
        }}
      >
        Vincular todos
      </Button>
    );
  }

  function handleSearch(data) {
    setLoading(true);

    const ref = data.ref;
    const tipo = data.tipo != -1 ? data.tipo : null;

    dispatch([
      fetchVeiculosUtilizacaoByFarmacia(farmaciaId, ref, tipo),
      fetchAllLinksAtivoVeiculoUtilizacao(farmaciaId, ativoId),
    ]);
  }

  function handleClearSearch() {
    setLoading(true);

    dispatch([
      fetchVeiculosUtilizacaoByFarmacia(farmaciaId),
      fetchAllLinksAtivoVeiculoUtilizacao(farmaciaId, ativoId),
    ]);
  }

  async function handleUnlinkAtivoVeiculo(veiculo) {
    setLoading(true);

    await removeLinkAtivoVeiculo(farmaciaId, ativoId, veiculo.veu_in_codigo);
    dispatch(fetchAllLinksAtivoVeiculoUtilizacao(farmaciaId, ativoId));
  }

  async function handleLinkAtivoVeiculo(veiculo) {
    setLoading(true);

    await linkAtivoToVeiculoUtilizacao(
      farmaciaId,
      ativoId,
      veiculo.veu_in_codigo
    );
    dispatch(fetchAllLinksAtivoVeiculoUtilizacao(farmaciaId, ativoId));
  }

  return (
    <div className='ativo-container'>
      <Title
        link={`/ativos/${ativoId}/edit`}
        component={() => <ButtonLinkAll />}
      >
        Selecione um veic. utilização/forma farmacêutica para vincular
      </Title>

      <Row>
        <Grid cols='12'>
          <Search onSubmit={handleSearch} onClear={handleClearSearch} />
        </Grid>
      </Row>

      <Row>
        <Grid cols='12'>
          {isLoading ? (
            <Skeleton count={10} width={500} height={60} />
          ) : (
            <VeiculosUtilizacaoList
              veiculosUtilizacao={veiculosUtilizacao}
              onUnlinkAtivoVeiculo={handleUnlinkAtivoVeiculo}
              onLinkAtivoVeiculo={handleLinkAtivoVeiculo}
            />
          )}
        </Grid>
      </Row>
    </div>
  );
}
